// @ts-nocheck
import React, { useContext } from "react";
import { ProfileContext, PictureMessagesOptions } from "../context";
import { ProviderContext } from "../../../../../context/Provider";
import RadioButton from "../../../../../components/RadioButton";
import { CustomModal } from "../../../../../components/Modal";
import styles from "./styles.module.css";

import {
  Label,
  Input,
  Badge,
  CustomInput,
} from "reactstrap";
import { Dropdown } from "semantic-ui-react";
import { toast } from "react-toastify";

const Header = () => {

  const {
    state,
    action: {
      onChange,
      onMessageChange,
      onCloseApproveModal,
      onCloseDisableModal,
    }
  } = useContext(ProfileContext);

  const {
    state: { provider },
    action: { approveProfilePicture, approveProvider, disableProvider }
  } = useContext(ProviderContext);

  const approvePicture = () => {
    const { approve, disapproval_message } = state;
    const data = { approve, disapproval_message }
    if (disapproval_message === "" && approve === false)
      toast.error("Especifique o motivo pela não aprovação")
    else approveProfilePicture(provider._id, data);
  };

  const approve = () => {
    approveProvider(provider._id)
  }

  const disable = () => {
    const { disable, deactivation_message } = state;
    const data = { disable, deactivation_message }
    if (deactivation_message === "" && disable === false)
      toast.error("Especifique o motivo pelo bloqueio")
    else disableProvider(provider._id, data)
  }

  return (
    <div className={styles.statusRow}>
      <div className={styles.statusColumnLeft}>
        <h3 className={styles.badge}>
          <Badge
            color={state.is_approved ? "success" : "secondary"}
          >
            {state.is_approved ? "Aprovado" : "Não aprovado"}
          </Badge>
        </h3>
        <h3 className={styles.badge}>
          <Badge
            color={state.is_disabled ? "secondary" : "primary"}
          >
            {state.is_disabled ? "Bloqueado" : "Ativo"}
          </Badge>
        </h3>
      </div>

      <div className={styles.statusColumnRight}>
        {
          state.is_active && (
            <h3 className={styles.badge}>
              <Badge
                color={state.is_available ? "success" : "warning"}
              >
                {state.is_available ? "Disponível" : "Em corrida"}
              </Badge>
            </h3>
          )
        }
        <h3 className={styles.badge}>
          <Badge
            color={state.is_active ? "success" : "secondary"}
          >
            {state.is_active ? "Online" : "Offline"}
          </Badge>
        </h3>
        <h3 className={styles.badge}>
          <Badge color="primary">ID: {state.id}</Badge>
        </h3>
        <Dropdown
          button
          className="icon ml-3"
          icon="bars"
          floating
        >
          <Dropdown.Menu direction="left">
            <CustomModal
              title="Aprovar imagem de perfil"
              footer={true}
              handleOnClick={approvePicture}
              handleOnClose={onCloseApproveModal}
              trigger={(toggle) => (
                <Dropdown.Item
                  className="mb-2 mt-2"
                  onClick={toggle}
                >
                  Aprovar/desaprovar imagem de perfil
                </Dropdown.Item>
              )}
            >
              <React.Fragment>
                <CustomInput
                  type="switch"
                  bsSize="lg"
                  label={state.approve ? "Aprovar" : "Não aprovar"}
                  id="approve"
                  name="approve"
                  checked={state.approve}
                  onChange={onChange}
                />
                <Label className="mt-3">Especifique o motivo pela desaprovação</Label>
                <RadioButton
                  disabled={state.message_disabled}
                  value={state.disapproval_message}
                  name="disapproval_message"
                  options={PictureMessagesOptions}
                  onChange={onMessageChange}
                />
              </React.Fragment>
            </CustomModal>
            <Dropdown.Item className="mb-2" onClick={approve}>Aprovar cadastro</Dropdown.Item>
            <CustomModal
              title="Boquear/Desbloquear cadastro"
              footer={true}
              handleOnClick={disable}
              handleOnClose={onCloseDisableModal}
              trigger={toggle => (
                <Dropdown.Item
                  className="mb-2"
                  onClick={toggle}
                >
                  Bloquear/Desbloquear cadastro
                </Dropdown.Item>
              )}
            >
              <React.Fragment>
              <CustomInput
                  type="switch"
                  bsSize="lg"
                  label={state.disable ? "Desbloquear" : "Bloquear"}
                  id="disable"
                  name="disable"
                  checked={state.disable}
                  onChange={onChange}
                />
                <Label className="mt-3">Especifique o motivo pelo bloqueio</Label>
                <Input type="textarea" name="deactivation_message" onChange={onChange} disabled={state.deactivation_message_disable} />
              </React.Fragment>
            </CustomModal>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

export default Header;