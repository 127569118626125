
//import actions from 'redux-auto'

export default function customizer(customizer, action) {
  customizer = {
    squaredCorners: false,
    withBoxShadow: false,
    topNavigation: false
  }
  return customizer;
}

export function before (appsState, action){
  return action.payload;
}

export function after (newAppsState, action, oldAppsState){
  return newAppsState;
}
