// @ts-nocheck
// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.css';

import { Icon } from "semantic-ui-react";

type Props = {
  src: string;
  showInput?: boolean;
  onChange(i: File | string): void;
};

const ProfileImage = ({ src, showInput = false, onChange }: Props) => {
  const imageRef = useRef(null)
  const reader = new FileReader();

  const [image, setImage] = useState<File | string | null>(null);

  const updateImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (imageRef && files) {
      // @ts-ignore
      reader.onload = (e) => imageRef.current.setAttribute("src", e.target.result)
      reader.readAsDataURL(files[0])
    }
    files && setImage(files[0]);
  };

  const updateImageFromSource = (source: string) => {
    if (imageRef) {
      // @ts-ignore
      imageRef.current.setAttribute("src", source)
    }
  }

  useEffect(() => {
    // @ts-ignore
    updateImageFromSource(src)
  }, [])

  useEffect(() => {
    if (image) onChange(image)
  }, [image])

  return (
    <div className={styles.container}>
      <div className="profile__avatar">
        <img
          ref={imageRef}
          alt=""
        />
        <div
          className={styles.overlay}
          style={showInput === false ? { display: 'none' } : {}}
        >
          <Icon name="camera" className="link black large" />
          <input
            type="file"
            accept="image/*"
            className={styles.profileInput}
            style={showInput === false ? { display: 'none' } : {}}
            onChange={updateImage}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileImage;