// @ts-nocheck
import React, { useContext, useEffect } from "react";
import FilterProvider from "./context";
import { FilterContext } from "./context";
import { ResultContext } from "../../context/Result";
import Loader from "../../components/Loader";
import CompanyTable from "./components/CompanyTable";
import Filter from "./components/Filter";
import CSVLink from "react-csv";
import moment from "moment";
import { formatNumber } from "../../utils/masks";

import { Container, Card, CardBody, Col, Row, Table } from "reactstrap";

const headers = [
  "Empresa",
  "Qtd. entregas",
  "R$ Total"
];

const dayWeek = [
  "Domingo",
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sábado"
];

const CompanyResultsCompare = () => {
  const {
    state: { companyResults, companyResultsCompare, isCompanyResultsLoaded }
  } = useContext(ResultContext);

  // const {
  //   state: { frequency, distance }
  // } = useContext(FilterContext);
  const formatDataTable = () => {
    if(companyResults.length && companyResultsCompare.length){
      let current = compileData(companyResults);
      let compare = compileData(companyResultsCompare);

      let allCompanies: any[] = [];
      allCompanies = current.companies;
      compare.companies.map(c => {
        if(allCompanies.filter(ac => ac.id == c.id).length == 0){
          allCompanies.push(c);
        }
      })

      let resultData: any[] = [];
      allCompanies.map(ac => {
        let data: any = [];
        current.dates.map((d, i) => {
          let currentPeriod: any = {};
          let comparePeriod: any = {};

          let currentCompany = current.data.filter(cdf => cdf.company.id == ac.id);
          if(currentCompany.length > 0){
            let currentCompanyData = currentCompany[0].data;
            currentPeriod = currentCompanyData[i];
          }

          let compareCompany = compare.data.filter(cdf => cdf.company.id == ac.id);
          if(compareCompany.length > 0){
            let compareCompanyData = compareCompany[0].data;
            comparePeriod = compareCompanyData[i];
          }
          let percent = 0;
          if(currentPeriod.delivers > 0){
            percent = comparePeriod.delivers > 0 ? (currentPeriod.delivers / comparePeriod.delivers)-1 : 1;
          }else{
            percent = comparePeriod.delivers > 0 ? -1 : 0; 
          }

          data.push({
            period: d,
            current:{
              period: currentPeriod.period,
              delivers: (currentPeriod.delivers ? currentPeriod.delivers : 0),
              for_company: (currentPeriod.for_company ? currentPeriod.for_company : 0),
              for_provider: (currentPeriod.for_provider ? currentPeriod.for_provider : 0)
            },
            compare:{
              period: comparePeriod.period,
              delivers: (comparePeriod.delivers ? comparePeriod.delivers : 0),
              for_company: (comparePeriod.for_company ? comparePeriod.for_company : 0),
              for_provider: (comparePeriod.for_provider ? comparePeriod.for_provider : 0)
            },
            diff: (currentPeriod.delivers ? currentPeriod.delivers : 0) - (comparePeriod.delivers ? comparePeriod.delivers : 0),
            percent: percent * 100
          });

        });

        let total_current_delivers = data.reduce((prev: number, r: any) => prev + r.current.delivers, 0);
        let total_compare_delivers = data.reduce((prev: number, r: any) => prev + r.compare.delivers, 0);
        let total_percent = 0;

        if(total_current_delivers > 0){
          total_percent = total_compare_delivers > 0 ? (total_current_delivers / total_compare_delivers)-1 : 1;
        }else{
          total_percent = total_compare_delivers > 0 ? -1 : 0; 
        }

        resultData.push({
          company: ac,
          data: data,
          total:{
            current_delivers: total_current_delivers,
            compare_delivers: total_compare_delivers,
            percent: total_percent * 100,
            diff: (total_current_delivers - total_compare_delivers > 0 ? total_current_delivers - total_compare_delivers : 0)
          }
        })
      });

      resultData.sort(function(a, b){
        return a.total.percent - b.total.percent;
      })

      return resultData;
    }
    return [];
  }

  const allDates = (dataResult: any) => {
    let allDates: any[] = [];
    dataResult.map((r:any) => {
      if(allDates.filter(ad => ad.date == r.date).length == 0){
        allDates.push(r.date);
      }
    })
    allDates.reverse();
    // console.log(allDates);
    return allDates;
  }

  const compileData = (dataResult: any)=>{
    let allCompanies: any[] = [];
    let allDates: any[] = [];
    let resultData: any[] = [];

    dataResult.map((r:any) => {
      if(allDates.filter(ad => ad.date == r.date).length == 0){
        allDates.push(r.date);
      }

      r.companies.map((c: any) => {
        if(allCompanies.filter(ac => ac.id == c.id).length == 0){
          allCompanies.push({id: c.id, name: c.name});
        }
      }) 
    })
    allDates.reverse();

    allCompanies.map(ac => {
      let res: any = {};
      res.company = ac;
      res.data = [];
      allDates.map(ad => {
        dataResult.filter((cr:any) => cr.date == ad).map((dc:any) => {
          let dcc = dc.companies.filter((dcc: any) => dcc.id == ac.id);
          // console.log(dcc);
          if(dcc.length > 0){
            res.data.push({
              period: ad,
              delivers: dcc[0].delivers.total, 
              for_company: dcc[0].for_company.total,
              for_provider: dcc[0].for_provider.total,
            });
          }else{
            res.data.push({
              period: ad, 
              delivers: 0, 
              for_company: 0,
              for_provider: 0
            });
          }
        })
      })
      resultData.push(res);
    })
    return {dates: allDates, companies: allCompanies, data: resultData};
  }
  //"#10cf99" / "#db2828" 
  return (
    <FilterProvider>
      <Container><h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333"}}>RESULTADOS POR EMPRESAS <small>COMPARATIVO PERÍODO ATUAL x ANTERIOR</small></h3></Container>
      <Container>
        <Card>
          <Filter compare={ true }/>
          <CardBody>
            {isCompanyResultsLoaded ? (
              <Table hover striped responsive className="table-condensed">
                <thead>
                  <tr>
                      <th rowSpan={3}>EMPRESA</th>
                      <th rowSpan={2} colSpan={3} style={{fontSize:13, backgroundColor:"#EEE", borderLeft:"solid 2px #CCC", borderRight:"solid 2px #CCC"}}>TOTAL</th>
                      {/* {allDates(companyResults).map((d,i) => {
                        return <th key={i} colSpan={3} style={{textAlign:"center", backgroundColor: ((i%2) == 0 ? "#AAAAAA11" : "transparent")}}>{d}</th>
                      })} */}
                  </tr>
                  <tr>
                    {allDates(companyResults).map((d,i) => {
                        return <>
                          <th key={9999 + i} style={{fontSize:9, textAlign:"right", backgroundColor: ((i%2) == 1 ? "#AAAAAA11" : "transparent")}}>{moment(allDates(companyResultsCompare)[i]).format("DD/MM")}</th>
                          <th key={9999 + i} style={{fontSize:9, textAlign:"right", backgroundColor: ((i%2) == 1 ? "#AAAAAA11" : "transparent")}}>{moment(d).format("DD/MM")}</th>
                          <th key={9999 + i}  style={{fontSize:9, textAlign:"right", backgroundColor: ((i%2) == 1 ? "#AAAAAA11" : "transparent")}}></th>
                        </>
                      })}
                  </tr>
                  <tr>
                    <th key={999} style={{fontSize:9, textAlign:"right", backgroundColor:"#EEE", borderLeft:"solid 2px #CCC", }}>PREV</th>
                    <th key={999} style={{fontSize:9, textAlign:"right", backgroundColor:"#EEE"}}>REAL</th>
                    <th key={999} style={{fontSize:9, textAlign:"right", backgroundColor:"#EEE", borderRight:"solid 2px #CCC"}}>%</th>
                    {allDates(companyResults).map((d,i) => {
                        return <>
                          <th key={9999 + i} style={{fontSize:9, textAlign:"right", backgroundColor: ((i%2) == 1 ? "#AAAAAA11" : "transparent")}}>PREV</th>
                          <th key={9999 + i}  style={{fontSize:9, textAlign:"right", backgroundColor: ((i%2) == 1 ? "#AAAAAA11" : "transparent")}}>REAL</th>
                          <th key={9999 + i}  style={{fontSize:9, textAlign:"right", backgroundColor: ((i%2) == 1 ? "#AAAAAA11" : "transparent")}}>%</th>
                        </>
                      })}
                  </tr>
                </thead>
                <tbody style={{fontSize: "12px"}}>
                <tr style={{backgroundColor:"#EEE", fontWeight:"bold", borderTop:"solid 2px #CCC", borderBottom:"solid 2px #CCC"}}>
                  <td >TOTAL</td>
                  <td style={{textAlign:"right", borderLeft:"solid 2px #CCC"}}>{formatDataTable().reduce((prev: number, r: any) => prev + r.total.compare_delivers, 0) }</td>
                  <td style={{textAlign:"right"}}>{formatDataTable().reduce((prev: number, r: any) => prev + r.total.current_delivers, 0) }</td>
                  <td style={{textAlign:"right", borderRight:"solid 2px #CCC"}}>
                    {(formatDataTable().reduce((prev: number, r: any) => prev + r.total.compare_delivers, 0) == 0 ? 100 : ((formatDataTable().reduce((prev: number, r: any) => prev + r.total.current_delivers, 0))  / formatDataTable().reduce((prev: number, r: any) => prev + r.total.compare_delivers, 0)-1) * 100).toFixed(0)}%
                  </td>
                
                
                  {allDates(companyResults).map((d,i) => {
                    return <>
                      <td style={{textAlign:"right", backgroundColor: ((i%2) == 0 ? "#AAAAAA11" : "transparent")}}>
                        {formatDataTable().reduce((prev: number, r: any) => prev + r.data[i].compare.delivers, 0)}
                      </td>
                      <td style={{textAlign:"right", backgroundColor: ((i%2) == 0 ? "#AAAAAA11" : "transparent")}}>
                      {formatDataTable().reduce((prev: number, r: any) => prev + r.data[i].current.delivers, 0)}
                      </td>
                      <td style={{textAlign:"right", backgroundColor: ((i%2) == 0 ? "#AAAAAA11" : "transparent")}}>{formatDataTable().reduce((prev: number, r: any) => prev + r.data[i].compare.delivers, 0) == 0 ? 100 : 
                      (formatDataTable().reduce((prev: number, r: any) => prev + r.data[i].current.delivers, 0) / formatDataTable().reduce((prev: number, r: any) => prev + r.data[i].compare.delivers, 0)*100).toFixed(0)}%</td>
                    </>
                  })}
                </tr>
                {formatDataTable().map((result: any, index: number) => (
                    <tr>
                      <td>{result.company.name}</td>
                      <td style={{textAlign:"right", backgroundColor:"#EEE", borderLeft:"solid 2px #CCC"}}>{result.total.compare_delivers}</td>
                      <td style={{textAlign:"right", backgroundColor:"#EEE"}}>{result.total.current_delivers}</td>
                      <td style={{textAlign:"right", backgroundColor:"#EEE", borderRight:"solid 2px #CCC", color:(parseInt(result.total.percent) == 0 ? "inherit" : (parseInt(result.total.percent) > 0 ? "#10cf99" : "#db2828"))}}>{(parseInt(result.total.percent) > 0 ? "+" : "")}{parseInt(result.total.percent)}%</td>
                      {result.data.map( (r: any, i: any) => (
                        <>
                          <td style={{textAlign:"right", backgroundColor: ((i%2) == 1 ? "#AAAAAA11" : "transparent")}}>{r.compare.delivers}</td>
                          <td style={{textAlign:"right", backgroundColor: ((i%2) == 1 ? "#AAAAAA11" : "transparent")}}>{r.current.delivers}</td>
                          <td style={{textAlign:"right", backgroundColor: ((i%2) == 1 ? "#AAAAAA11" : "transparent"), color:(r.percent == 0 ? "inherit" : (r.percent > 0 ? "#10cf99" : "#db2828"))}}>{(r.percent > 0 ? "+" : "")}{parseInt(r.percent)}%</td>
                        </>
                      ))}
                    </tr>
                ))}
                </tbody>
              </Table>
            ) : (
                <Loader />
              )}
          </CardBody>
        </Card>
      </Container>
    </FilterProvider>
  );
};

const transformData = (results: any[]) => {
  const companiesTable: any[] = [];
  results.map(day => {
    day.companies.map((company: any) => (
      companiesTable.push([
        moment(day.date).format("DD/MM/YYYY"),
        company.name,
        company.delivers.total,
        formatNumber(company.for_company.total),
      ])
    ));
  });
  return companiesTable;
};

export default CompanyResultsCompare;
