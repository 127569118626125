// @ts-nocheck
import React from "react";
import { AuthContext } from "../Auth";
import { UserModel, TripHistoryModel, TripHistory, AttendanceModel } from "./user.types";
import { getPaymentMode } from "../../utils/translateEnums";
import { City } from "../Provider/provider.types";
import { FilterState } from "../../pages/Users/Filter";
import { FormState } from "../../pages/UserPanel/components/UserProfile/formHook";
import { formatNumber, onlyNumber } from "../../utils/masks";
import { toast } from "react-toastify";

interface UserContext {
  state: State;
  action: {
    getCities(country: string): Promise<void>;
    searchUsers(page: number, filter?: FilterState): Promise<void>;
    getUser(userId: string): Promise<void>;
    updateUser(userId: string, form: FormState): Promise<void>;
    updatePassword(userId: string, password: string): Promise<void>;
    getTripHistory(userId: string, page: number): Promise<void>;
    getAttendanceHistory(userId: string, page: number): Promise<void>;
  };
}

export const UserContext = React.createContext({} as UserContext);

interface State {
  users: any[];
  isUsersLoaded: boolean;
  usersPagination: any;
  cities: City[];
  isCitiesLoaded: boolean;
  user: UserModel;
  isUserLoaded: boolean;
  trip: TripHistory[];
  isTripLoaded: boolean;
  tripPagination: any;
  attendance: AttendanceModel[];
  isAttendanceLoaded: boolean;
  attendancePagination: any;
  update: boolean;
};

class UserProvider extends React.PureComponent<{}, State> {

  static contextType = AuthContext;

  constructor(props: any) {
    super(props);

    this.state = {
      users: [],
      isUsersLoaded: false,
      usersPagination: {},
      cities: [],
      isCitiesLoaded: false,
      user: {} as UserModel,
      isUserLoaded: false,
      trip: [],
      tripPagination: {},
      isTripLoaded: false,
      attendance: [],
      attendancePagination: {},
      isAttendanceLoaded: false,
      update: false,
    }
  }

  getCities = async (country: string): Promise<void> => {
    const { request } = this.context.action;
    const url = 'admin/v1/cities_list_by_country';
    this.setState({
      isCitiesLoaded: false,
    }, async () => {
      try {
        const response = await request("POST", url, { countryname: country });
        if (response.data.success) {
          const { data } = response.data;
          const cities = data.map((city: any) => ({
            id: city._id,
            name: city.cityname
          }));
          this.setState({
            cities,
            isCitiesLoaded: true,
          });
        }
      } catch (error) {
        // console.log(error)
      }
    });
  }

  searchUsers = async (page: number, filter?: FilterState): Promise<void> => {
    const { request } = this.context.action;
    const url = "admin/v1/users/";
    const data: any = !!filter ? this.transformFilterForm(filter) : {};
    data['page_size'] = 13;
    data['page'] = page;

    this.setState({
      isUsersLoaded: false
    }, async () => {
      try {
        const response = await request("POST", url, data)
        // console.log(response)
        if (response.data.success) {
          const { count, current_page, pages, providers } = response.data.data;
          const usersPagination = {
            count,
            current_page,
            pages
          };
          this.setState({
            users: providers,
            isUsersLoaded: true,
            usersPagination,
          });
        }
      } catch (error) {
        this.setState({ users: [], isUsersLoaded: true },
          toast.info.bind(null, "Usuários não encontrados")
        )
        // console.log(error)
      }
    })
  };

  getUser = async (userId: string): Promise<void> => {
    const { request } = this.context.action;
    const url = `admin/v1/users/${userId}`;

    this.setState({
      isUserLoaded: false
    }, async () => {
      try {
        const response = await request("GET", url);
        if (response.data.success) {
          const { data } = response.data;
          const picture = data.picture
            ? data.picture.startsWith('user_profile')
              ? `${this.context.host}storage/${data.picture}`
              : data.picture
            : "";
          this.setState({
            update: false,
            user: { ...data, picture },
            isUserLoaded: true
          });
        }
      } catch (error) {
        // console.log(error)
      }
    })
  }

  updateUser = async (userId: string, form: FormState): Promise<void> => {
    const { request } = this.context.action;
    const url = `admin/v1/users/${userId}`;

    const data = {
      first_name: form.first_name,
      last_name: form.last_name,
      gender: form.gender,
      phone: onlyNumber(form.phone),
      email: form.email,
      document: onlyNumber(form.document),
      rg: form.rg,
      zipcode: form.zipcode,
      address: form.address,
      address_number: form.address_number,
      address_complement: form.address_complement,
      city: form.city,
      user_picture: form.file
    };

    const formData = this.toFormData(data);
    try {
      const response = await request("PUT", url, formData);
      if (response.data.success) {
        this.setState({
          update: true,
          isUserLoaded: false,
        },
          toast.success.bind(null, "Perfil atualizado!")
        )
      }
    } catch (error) {
      // console.log(error)
      toast.error("Erro ao atualizar o cadastro do usuário");
    }
  };

  updatePassword = async (userId: string, password: string): Promise<void> => {
    const { request } = this.context.action;
    const url = `admin/v1/users/${userId}/password`;
    try {
      const response = await request("PUT", url, { password: password })
      response.data.success && toast.success("Senha atualizada com sucesso!")
    } catch (error) {
      // console.log(error)
      toast.error("Erro ao atualizar senha!")
    }
  };

  getTripHistory = async (userId: string, page: number): Promise<void> => {
    const { request } = this.context.action;
    const url = `admin/v1/users/${userId}/trips/`;

    this.setState({
      isTripLoaded: false
    }, async () => {
      try {
        const response = await request("POST", url, { page_size: 10, page });
        if (response.data.success) {
          const { count, current_page, pages, trips } = response.data.data;
          const tripPagination = {
            count,
            current_page,
            pages
          }
          this.setState({
            trip: this.transformTripData(trips),
            isTripLoaded: true,
            tripPagination
          })
        } else this.setState({ trip: [], isTripLoaded: true })
      } catch (error) {
        // console.log(error)
        this.setState({ trip: [], isTripLoaded: true })
      }
    })
  };

  getAttendanceHistory = async (userId: string, page: number): Promise<void> => {
    const { request } = this.context.action;
    const url = `admin/v1/users/${userId}/attendances/`;

    this.setState({
      isAttendanceLoaded: false,
    }, async () => {
      try {
        const response = await request("POST", url, { page_size: 10, page });
        if (response.data.success) {
          const { count, current_page, pages, attendances } = response.data.data;
          const attendancePagination = {
            count,
            current_page,
            pages
          }
          this.setState({
            attendance: attendances,
            isAttendanceLoaded: true,
            attendancePagination,
          });
        } else this.setState({ attendance: [], isAttendanceLoaded: true })
        // console.log(response)
      } catch (error) {
        // console.log(error)
        this.setState({ attendance: [], isAttendanceLoaded: true })
      }
    });
  };

  transformTripData = (tripHistory: TripHistoryModel[]): TripHistory[] => {
    const transformTripHistory = tripHistory.map(trip => {
      const provider_name = `${trip.provider_first_name} ${trip.provider_last_name}`;
      const status = trip.is_trip_completed
        ? "Finalizada"
        : trip.is_trip_cancelled
          ? "Cancelada"
          : "";
      const total = `R$ ${formatNumber(trip.total)}`;
      return {
        _id: trip._id,
        unique_id: trip.unique_id,
        start_time: new Date(trip.user_create_time),
        end_time: new Date(trip.provider_trip_end_time),
        provider_name,
        total,
        payment_mode: getPaymentMode(trip.payment_mode),
        status
      }
    });
    return transformTripHistory ? transformTripHistory : [];
  }

  toFormData = (data: any): FormData => {
    let formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key])
    }
    return formData;
  }

  transformFilterForm = (filter: any) => {
    const data = Object.create(null);

    const keys = [
      "start_date",
      "end_date",
      "cityid",
      "gender",
      // "group_name",
    ];

    for (const key of keys) {
      if (filter[key] !== "") data[key] = filter[key]
    }

    if (filter.query !== "" && filter.search !== "")
      data[filter.query] = filter.search

    return data;
  };

  render() {

    const value = {
      state: { ...this.state },
      action: {
        getCities: this.context.action.executeOnlyIfAuthenticated(this.getCities, true),
        searchUsers: this.searchUsers,
        getUser: this.getUser,
        updateUser: this.updateUser,
        updatePassword: this.updatePassword,
        getTripHistory: this.getTripHistory,
        getAttendanceHistory: this.getAttendanceHistory,
      }
    };

    return (
      <UserContext.Provider value={value}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;