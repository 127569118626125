// @ts-nocheck
import React, { useContext } from "react";
import { SecondaryDashboardContext } from "../../../context/SecondaryDashboard";
import Panel from "../../../components/Panel";
import { Col, Input } from "reactstrap";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar
} from "recharts";

interface Props {
  reference_distance: string;
  updateParent(reference_distance: string): void;
}

const TripsByDistance = ({ updateParent, reference_distance }: Props) => {
  const {
    state: {
      tripsByDistance: { isLoading, referenceDistance, tripsAbove, tripsBelow }
    }
  } = useContext(SecondaryDashboardContext);

  const transformData = () => {
    const result: any[] = [];
    let above: any = {};
    let bellow: any = {};

    above["name"] = `Acima de ${referenceDistance} Km`;
    tripsAbove.map(trips => {
      above[trips.typeName] = trips.totalTrips;
    });
    result.push(above);

    bellow["name"] = `Abaixo de ${referenceDistance} Km`;
    tripsBelow.map(trips => {
      bellow[trips.typeName] = trips.totalTrips;
    });
    result.push(bellow);
    return result;
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    updateParent(value);
  };

  return (
    <Panel lg={4} xl={4} md={4} title="Corridas/Distância" refresh={isLoading}>
      <Col lg={6} md={12} className="mb-4">
        <div style={{ color: "#999", fontStyle: "italic" }}>
          <small>Filtrar distância de referência</small>
        </div>
        <Input
          placeholder="2,5 km"
          name="reference_distance"
          value={reference_distance}
          onChange={handleOnChange}
        />
      </Col>
      <ResponsiveContainer height={327.5}>
        <BarChart data={transformData()}>
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Moto entrega" fill="#8884d8" />
          <Bar dataKey="Bike entrega" fill="#f8b68b" />
        </BarChart>
      </ResponsiveContainer>
    </Panel>
  );
};

export default TripsByDistance;
