// @ts-nocheck
import React from "react";
import moment from "moment";
import { AuthContext } from "./Auth";

import { toast } from "react-toastify";

const FinanceContext = React.createContext({});
class FinanceProvider extends React.PureComponent {
  static contextType = AuthContext;

  initialState = {
    categories: [],
    cities:[],
    registers:[],
    loadingRegisters: false
  };

  state = this.initialState;

  getCategories = async (type = null) => {
    const { request } = this.context.action;
    try {
      const url = `admin/v1/control-financial/category/all${type ? `?type=` + type : ''}`;
      const response = await request("GET", url);
      if (response.data.success) {
        this.setState({
          categories: response.data.data
        });
      }
    } catch (error) {
      console.log(error)
    }

    return true;
  };

  addCategory = async (fields) => {
    const { request } = this.context.action;
    try {
      const response = await request("POST", `admin/v1/control-financial/category/add`, fields);
    } catch (error) {
      console.log(error)
    }
  };

  getRegisters = async (filter, order = '') => {
    const { request } = this.context.action;
    this.setState({
      registers:[],
      loadingRegisters: true
    }, async () => {
      try {
        const url = `admin/v1/control-financial/register/all`;
        const response = await request("POST", url, filter);
        console.log(response.data.data);
        if (response.data.success) {
          let response_data = response.data.data; 
          switch (order) {
            case 'value_asc':
              response_data.sort((a,b) => a.value - b.value)
              break;
            case 'value_desc':
              response_data.sort((a,b) => b.value - a.value)
              break;
          }

          this.setState({
            registers: response_data,
            loadingRegisters: false
          });
        }
      } catch (error) {
        console.log(error)
      }
    });
  };

  addRegister = async (fields) => {
    const { request } = this.context.action;
    try {
      const response = await request("POST", `admin/v1/control-financial/register/add`, fields);
    } catch (error) {
      console.log(error)
    }
  };

  updateRegister = async (fields) => {
    const { request } = this.context.action;
    try {
      const response = await request("POST", `admin/v1/control-financial/register/update`, fields);
    } catch (error) {
      console.log(error)
    }
  };

  updateCompanyActive = async (fields) => {
    const { request } = this.context.action;
    try {
      const response = await request("PUT", `admin/v1/company/${fields.company_id}/is_active`, fields);
    } catch (error) {
      console.log(error)
    }
  };


  removeRegister = async (fields) => {
    const { request } = this.context.action;
    try {
      const response = await request("POST", `admin/v1/control-financial/register/remove`, fields);
    } catch (error) {
      console.log(error)
    }
  };

  WeeklyClose = async (fields) => {
    const { request } = this.context.action;
    try {
      const response = await request("POST", `admin/v1/control-financial/register/invoices`, fields);
    } catch (error) {
      console.log(error)
    }
  };

  getCities = () => {
    const { request } = this.context.action

    this.setState({
      cities: []
    }, async () => {
      try {
        const response = await request('GET', 'admin/v1/cities')

        if (response.data.success)
          this.setState({ cities: response.data.data })
        else throw new Error('Erro ao buscar as cidades.')

      } catch (err) {
        this.setState({
          cities: []
        }, toast.error.bind(null, err.message))
      }
    });
    return true;
  }



  render() {
    const { executeOnlyIfAuthenticated } = this.context.action;

    const value = {
      state: this.state,
      action: {
        getCategories: executeOnlyIfAuthenticated(this.getCategories),
        addCategory: executeOnlyIfAuthenticated(this.addCategory),
        getCities: executeOnlyIfAuthenticated(this.getCities),
        addRegister: executeOnlyIfAuthenticated(this.addRegister),
        updateRegister: executeOnlyIfAuthenticated(this.updateRegister),
        updateCompanyActive: executeOnlyIfAuthenticated(this.updateCompanyActive),
        removeRegister: executeOnlyIfAuthenticated(this.removeRegister),
        getRegisters: executeOnlyIfAuthenticated(this.getRegisters),
        WeeklyClose: executeOnlyIfAuthenticated(this.WeeklyClose),
      }
    };

    return (
      <FinanceContext.Provider value={value}>
        {this.props.children}
      </FinanceContext.Provider>
    );
  }
}

export { FinanceContext, FinanceProvider as default };
