// @ts-nocheck
import React, { useState, useContext } from "react";
import { CustomModal } from "../../../../components/Modal";
import { UploadFile } from "../../../../components/DragNDrop";
import { DocumentModel } from "../../../../context/Provider/provider.types";
import { ProviderContext } from "../../../../context/Provider";
import RadioButton from "../../../../components/RadioButton";
import styles from "../styles.module.css";

// external libraries
import { DateInput } from "@opuscapita/react-dates";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import {
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  FormGroup,
  Label,
  Badge,
  CustomInput
} from "reactstrap";

type Props = {
  data: DocumentModel[];
};

type State = {
  provider_doc: File | null;
  doc_expiry_date: Date | null;
  message_disabled: boolean;
  disapproval_message: string;
  approve: boolean;
};

const ProviderDocuments = ({ data }: Props) => {

  const {
    action: { updateDocument, approveDocument }
  } = useContext(ProviderContext);

  const [state, setState] = useState<State>({
    doc_expiry_date: null,
    provider_doc: null,
    message_disabled: false,
    disapproval_message: '',
    approve: false
  });

  const onDocumentChange = (file: File) => {
    setState(prevState => ({
      ...prevState,
      provider_doc: file
    }));
  };

  const onDateChange = (date: Date) => {
    setState(prevState => ({
      ...prevState,
      doc_expiry_date: date
    }))
  };

  const onSubmit = (providerId: string, documentId: string) => {
    const { provider_doc, doc_expiry_date } = state;
    const data = {
      provider_doc,
      doc_expiry_date: doc_expiry_date && moment(doc_expiry_date).format('YYYYMMDD')
    };
    updateDocument(data, providerId, documentId);
  };

  const onSwitchChange = () => {
    setState(prev => ({
      ...prev,
      approve: !prev.approve,
      message_disabled: !prev.approve,
      disapproval_message: ''
    }))
  }

  const onMessageChange = (name: string, value: string) =>
    setState(prev => ({ ...prev, [name]: value }))

  const approve = (documentId: string, providerId: string) => {
    const { approve, disapproval_message } = state;
    const data = { approve, disapproval_message }
    approveDocument(documentId, providerId, data);
  };

  const onCloseApproveModal = () => {
    setState(prev => ({
      ...prev,
      message_disabled: false,
      disapproval_message: '',
      approve: false
    }));
  };

  return (
    <Card>
      <CardBody>
        <h3>Documentos do entregador</h3>
        <ListGroup className="mt-3">
          {data.map((doc, index) => {
            console.log(doc);
            
            return (
              <ListGroupItem className="p-4" key={index}>
                <div className={styles.listItemContainer}>
                  <div className={styles.listHeaderContainer}>
                    <ListGroupItemHeading className={styles.title}>{doc.name}</ListGroupItemHeading>
                    <ListGroupItemText>
                      <Badge
                        className={styles.badge}
                        color={doc.status === "Não aprovado" ? "danger" : "success"}
                      >
                        {doc.status}
                      </Badge>
                      <Badge className={styles.badge} color={doc.is_uploaded
                        ? doc.status === "Não aprovado" ? "warning" : "success"
                        : "danger"} >
                        {doc.is_uploaded ? "Enviado" : "Não enviado"}
                      </Badge>
                      {
                        moment().isAfter(doc.expired_date) &&
                        <Badge className={styles.badge} color="danger">Documento expirado</Badge>
                      }
                      {
                        doc.is_expired_date && !doc.expired_date &&
                        <Badge className={styles.badge} color="danger">Falta data de vencimento</Badge>
                      }
                    </ListGroupItemText>
                  </div>
                  <CustomModal
                    title="Aprovar documento"
                    footer={true}
                    handleOnClick={() => approve(doc._id, doc.provider_id)}
                    handleOnClose={onCloseApproveModal}
                    trigger={toggle => (
                      <span onClick={toggle} className={styles.icon}>
                        <Icon name="check" className="link black large" />
                      </span>
                    )}
                  >
                    <React.Fragment>
                      <CustomInput
                        type="switch"
                        bsSize="lg"
                        label={state.approve ? "Aprovar" : "Não aprovar"}
                        id="approve"
                        checked={state.approve}
                        onChange={onSwitchChange}
                      />
                      <Label className="mt-3">Especifique o motivo pela desaprovação</Label>
                      <RadioButton
                        disabled={state.message_disabled}
                        value={state.disapproval_message}
                        name="disapproval_message"
                        options={MessagesOptions}
                        onChange={onMessageChange}
                      />
                    </React.Fragment>
                  </CustomModal>
                  <CustomModal
                    size="fullscreen"
                    footer={true}
                    showPrimaryButton={false}
                    secondaryButtonName="Fechar"
                    trigger={toggle => (
                      <span onClick={toggle} className={styles.icon}>
                        <Icon name="folder open" className="link black large" />
                      </span>
                    )}
                  >
                    {
                      doc.document_picture.endsWith('.pdf')
                        ?
                        <embed src={doc.document_picture} className={styles.docPdf} />
                        :
                        // <img src={doc.document_picture} className={styles.docImage} />
                        <div style={{ backgroundImage: `url(${doc.document_picture})` }} className={styles.docImage} />
                    }
                  </CustomModal>
                  <CustomModal
                    title="Atualizar documento"
                    trigger={toggle => (
                      <span onClick={toggle} className={styles.icon}>
                        <Icon name="upload" className="link black large" />
                      </span>
                    )}
                    handleOnClick={() => onSubmit(doc.provider_id, doc._id)}
                  >
                    <React.Fragment>
                      <h5>{doc.name}</h5>
                      <FormGroup>
                        <Label>Data de vencimento</Label>
                        <DateInput
                          value={state.doc_expiry_date}
                          dateFormat="DD/MM/YYYY"
                          // showToTop={true}
                          disabled={false}
                          locale="pt-br"
                          onChange={onDateChange}
                        />
                      </FormGroup>
                      <UploadFile onDocumentChange={onDocumentChange} />
                    </React.Fragment>
                  </CustomModal>
                </div>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

const MessagesOptions = [
  { label: 'Imagem inválida', value: 'invalid_image' },
  { label: 'Data de vencimento inválida', value: 'invalid_expiry_date' },
  { label: 'Documento expirado', value: 'document_expired' },
  { label: 'Documento inválido', value: 'invalid_document' }
];

export default ProviderDocuments;