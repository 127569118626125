// @ts-nocheck
import React, { useEffect, useContext, useState } from "react";
import { CompanyContext } from "../../../context/Company";
import { useToggle } from "../../../hooks/hooks";
import PricePolicy from "./PricePolicy";
import styles from "./styles.module.css";

import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  Input,
  Form,
  Button,
  Table
} from "semantic-ui-react";
import moment from "moment";
import { toast } from "react-toastify";

type Props = {
  companyId: any;
  admin: any;
  isOpen: boolean;
  handleToggle(): void;
};

interface State {
  first_name: string;
  last_name: string
  email: string;
  password: string;
}

const EditAdmin = ({ companyId, admin, isOpen, handleToggle }: Props) => {
  const {
    state: { createAdminLoading },
    action: { createAdmin, updateAdmin }
  } = useContext(CompanyContext);

  const initialState = {
    first_name: admin ? admin.first_name : "",
    last_name: admin ? admin.last_name : "",
    email: admin ? admin.email : "",
    password: ""
  }

  const [state, setState] = useState<State>(initialState);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    if (admin) {
      if (!state.first_name || !state.last_name || !state.email) {
        toast.error("Preencha todos os campos")
      }
      else {
        updateAdmin(companyId, admin._id, state, handleToggle);
      }
    }
    else {
      if (!state.first_name || !state.last_name || !state.email || !state.password) {
        toast.error("Preencha todos os campos")
      }
      else if (state.password.length < 8) {
        toast.error("Crie uma senha com o mínimo de 8 caracteres")
      }
      else {
        createAdmin(companyId, state, handleToggle);
      }
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <ModalHeader>{admin ? `Editar ${admin.display_name}` : "Novo administrador"}</ModalHeader>
      <ModalBody>
        <Form>
          <Form.Input
            className={styles.inputField}
            name="first_name"
            label="Nome"
            placeholder="Nome"
            value={state.first_name}
            onChange={handleOnChange}
          />
          <Form.Input
            className={styles.inputField}
            name="last_name"
            label="Sobrenome"
            placeholder="Sobrenome"
            value={state.last_name}
            onChange={handleOnChange}
          />
          <Form.Input
            className={styles.inputField}
            name="email"
            label="Email"
            placeholder="Email"
            value={state.email}
            onChange={handleOnChange}
          />
          <Form.Input
            type="password"
            className={styles.inputField}
            name="password"
            label="Senha"
            placeholder={admin ? "(Alterar)" : "Senha"}
            value={state.password}
            onChange={handleOnChange}
          />

          <Button
            color="red"
            onClick={handleToggle}
          >
            Cancelar
          </Button>
          <Button
            color="facebook"
            onClick={handleSubmit}
            disabled={createAdminLoading}
            loading={createAdminLoading}
          >
            Salvar
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default EditAdmin;
