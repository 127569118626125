// @ts-nocheck
import React, { useEffect } from "react";
import { useToggle } from "../../hooks/hooks";
import { cx } from "../../utils/classnames";
import style from "./styles.module.css";

type Props = {
  onChange(name: string, value: boolean): void;
  label: string;
  name: string;
  value: boolean;
};

export const Checkbox = ({ name, label, value, onChange }: Props) => {

  const { toggle, active } = useToggle(value);

  useEffect(() => {
    onChange(name, active)
  }, [active]);

  return (
    <div className={style.radio_button}>
      <label className={cx({ [style.checked]: active })}>
        <input
          type="checkbox"
          onChange={toggle}
          checked={active}
        />
        {label}
      </label>
    </div>
  );

}
