// @ts-nocheck
import React, { useContext, useEffect } from "react";
import { SettingsContext } from "../../context/Settings";
import { getAdminType } from "../../utils/translateEnums";
import AdminForm from "./AdminForm";

import { Container, Card, CardBody, Table } from "reactstrap";
import moment from "moment";

const headers = [
  // "ID",
  "Nome",
  "Email",
  "Data de criação",
  "Tipo",
  "Ação"
]

const Credentials = () => {
  const {
    state: { credentials, update },
    action: { getCredentials, getPermissions }
  } = useContext(SettingsContext)

  useEffect(() => {
    getPermissions()
  }, [])

  useEffect(() => {
    getCredentials()
  }, [update])

  return (
    <Container>
      <Card>
        <h3 className="mb-3">Credenciais</h3>
        <CardBody>
          <AdminForm isNew={true} />
          <Table>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {credentials.map((credential, index) => (
                <tr key={index}>
                  <td>{credential.username}</td>
                  <td>{credential.email}</td>
                  <td>{moment(credential.created_at).format("DD/MM/YYYY")}</td>
                  <td>{getAdminType(credential.type)}</td>
                  <td>
                    <AdminForm isNew={false} admin={credential} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
  );
}

export default Credentials;