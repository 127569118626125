// @ts-nocheck
import React, { useContext } from "react";
import { ProviderMetricsContext } from "../../../context/ProviderMetrics";
import Loader from "../../../components/Loader";
import Panel from "../../../components/Panel";

import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from "recharts";
import moment from "moment";

const CoveredDistance = () => {
  const {
    state: { coveredDistance, isCoveredDistanceLoaded }
  } = useContext(ProviderMetricsContext);

  const transformData = (data: any): any[] => {
    return data.dailyDistances
      .sort((prev: any, d: any) => (prev.date - d.date))
      .map((d: any) => ({
        ...d,
        date: moment(d.date).format('DD/MM/YYYY')
      }));
  };


  return (
    <Panel title="Distância percorrida(Km)">
      {isCoveredDistanceLoaded
        ? <React.Fragment>
          <h5>Distância percorrida no mês: {coveredDistance.monthlyDistance} Km</h5>
          <ResponsiveContainer height={260}>
            <LineChart
              data={transformData(coveredDistance)}
              margin={{ top: 20, left: -15 }}
            >
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="totalDistance"
                name="Total de Km rodados"
                stroke="#82ca9d"
              />
            </LineChart>
          </ResponsiveContainer>
        </React.Fragment>
        : <Loader height="260px" />
      }
    </Panel>
  );
};

export default CoveredDistance;