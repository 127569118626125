// @ts-nocheck
import React, { useContext } from "react";
import { ProviderContext } from "../../../context/Provider";
import { formatNumber } from "../../../utils/masks";
import styles from "./styles.module.css";

import { Card, CardBody, Table } from "reactstrap";
import moment from "moment";

const headers = [
  "Data",
  "Horário",
  "Descrição",
  "Valor"
];

const EntryList = () => {

  const {
    state: { statement }
  } = useContext(ProviderContext);

  return (
    <Card>
      <h3>Lista de lançamentos</h3>
      <CardBody className={styles.maxHeight}>
        <Table responsive className="table--bordered">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {statement.map((date) => (
              date.entries.map((entry: any, index: number) => (
                <tr key={index}>
                  <td>{moment(date._id).format("DD/MM/YYYY")}</td>
                  <td>{entry.hour}</td>
                  <td>{entry.description}</td>
                  <td className={entry.type === "i" ? "text-success" : "text-danger"}>
                    {entry.type === "i" ? "+" : "-"} {formatNumber(entry.amount)}
                  </td>
                </tr>
              ))
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default EntryList;