// @ts-nocheck
import React, { useRef, useState, useEffect } from 'react';

const Ranges = ({ minimumRef }) => {
	const rangesRef = useRef(null)
	const [count, setCount] = useState(1)

	function addOne() {
		setCount(count + 1)
	}

	function removeOne() {
		if (count === 1) return
		setCount(count - 1)
	}

	useEffect(() => {
		rangesRef.current.scrollTo({
			top: rangesRef.current.scrollTop + 148,
			behavior: 'smooth',
		})
	}, [count])

	return (
		<React.Fragment>
			<div className="single-input-container">
				<input
					type="number"
					placeholder="Valor Minimo"
					ref={minimumRef}
					className="input"
				/>
			</div>

			<div ref={rangesRef} className="input-container">
				{[...Array(count)].map((_, i) => (
					<div className="input" key={i}>
						<input type="number" placeholder="Distância" id={`distance-${i}`} />
						<input
							type="number"
							placeholder="Coeficiente"
							id={`coefficient-${i}`}
						/>
					</div>
				))}
			</div>
			<div className="ranges-control">
				<i className="fas fa-minus" onClick={removeOne} />
				<i className="fas fa-plus" onClick={addOne} />
			</div>
		</React.Fragment>
	)
}

const PolicyData = ({ policy, variableRef, fixedRef, minimumRef }) => {
	switch (policy) {
		case 'fixed':
			return (
				<div className="single-input-container">
					<input
						className="single-input"
						type="number"
						placeholder="Valor"
						ref={fixedRef}
					/>
				</div>
			)
		case 'variable':
			return (
				<div className="single-input-container">
					<input
						className="single-input"
						type="number"
						placeholder="Multiplicador"
						ref={variableRef}
					/>
				</div>
			)
		case 'range':
			return <Ranges minimumRef={minimumRef} />
		default:
			return null
	}
}

export default PolicyData;
