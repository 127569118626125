// @ts-nocheck
import React, { useContext, useEffect, useState } from "react"
import { CompanyContext } from "../../context/Company"
import sortByProperty from "../../utils/sortByProperty"
import Loader from "../../components/Loader"
import Error from "../../components/Error"
import CompanyDetails from "./components/CompanyDetails"
import Request from "./components/Request"
import "./styles.sass"

import { Container, Card, CardBody, Table } from "reactstrap"

/*
  Centro de custo ->
    address: "R. Avertano Rocha, 228A - Campina, Belém - PA, 66023-120"
    company_id: "5bffe08a13718743ae615f76"
    company_payment_account_id: "5cc718bc1dabfd68b10a32ab"
    contract_id: "5c8156743ce44a2002492eb9"
    created_at: "2019-05-01T16:47:08.454Z"
    location: [-1.4572637, -48.4992843]
    name: "Loja da DOIT"
    updated_at: "2019-05-01T16:47:08.454Z"
*/

const OpenRequests = () => {
  const [requests, setRequests] = useState([])
  const [companyDetailsActive, setCompanyDetailsActive] = useState(false)

  const {
    state: {
      openRequests,
      openRequestsAreLoading,
      openRequestsHasErrored,
      request,
      requestIsLoading,
    },
    action: {
      fetchOpenRequests,
      fetchOpenRequestDetails,
      resetRequestDetails
    }
  } = useContext(CompanyContext)

  useEffect(fetchOpenRequests, [])
  useEffect(() => setRequests(openRequests), [openRequests])

  function fetchCompanyDetails(companyId) {
    fetchOpenRequestDetails(companyId)
    if (!companyDetailsActive) setCompanyDetailsActive(true)
  }

  function toggleCompanyDetails() {
    setCompanyDetailsActive(previous => !previous)
    if (request) resetRequestDetails()
  }

  function filterOpenRequests({ target: { value: searchPattern } }) {
    setRequests(sortByProperty(openRequests.filter(openRequest =>
      openRequest.name.toLowerCase().includes(searchPattern)), "name"))
  }

  return (
    <React.Fragment>
      <CompanyDetails
        request={request}
        active={companyDetailsActive}
        toggle={toggleCompanyDetails}
        loading={requestIsLoading}
      />

      <Container>
        <Card>
          <div className="page-title-container">
            <h3 className="mb-4 page-title">Pedidos em andamento</h3>
          </div>

          <CardBody>
            <div className="table-filter">
              <input type="text" placeholder="Pesquisar..." onChange={filterOpenRequests} />
            </div>
            <Table responsive hover className="table--bordered">
              <thead>
                <tr>
                  <th>Cidade</th>
                  <th>Nome</th>
                  <th>CNPJ (Clique para copiar)</th>
                  <th>Gerente Plat.</th>
                  <th>Email</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {requests.map((request) => (
                  <Request
                    key={request._id}
                    id={request._id}
                    citycode={request.city[0].citycode}
                    name={request.name}
                    doc={request.document}
                    manager={request.platform_manager}
                    email={request.manager_email}
                    approved={request.is_approved}
                    refused={request.is_refused}
                    onClick={fetchCompanyDetails}
                  />
                ))}
              </tbody>
            </Table>
            {openRequestsAreLoading && <Loader height="300px" />}
            {openRequestsHasErrored && <Error message="Não foi possível exibir as empresas" />}
            {openRequests.length === 0 && !openRequestsAreLoading && !openRequestsHasErrored && (
              <Error message="Não existe nenhuma empresa cadastrada" />
            )}
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  )
}

export default OpenRequests
