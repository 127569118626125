// @ts-nocheck
import React, { useContext, useEffect } from "react";
import { ResultContext } from "../../../context/Result";
import { FilterContext } from "../context";
import { toNumber } from "../../../utils/masks";
import DateRange from '../../../components/DateRange';
import Select from "../../../components/Select";

import { Row, Col, Input } from "reactstrap";
import moment from "moment";

const Filter = () => {
  const {
    state: { cities },
    action: { getProviderResults, fetchCities }
  } = useContext(ResultContext);

  const {
    state: { city_id, distance, startDate, endDate, frequency },
    action: { handleDateChange, handleCityChange, handleFrequencyChange, handleOnChange }
  } = useContext(FilterContext);

  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const data: any = {
        date: {
          start: moment(startDate).format('YYYYMMDD'),
          end: moment(endDate).format('YYYYMMDD')
        }
      }
      if (distance !== "")
        data["distance"] = toNumber(distance)
      if (frequency !== "")
        data["frequency"] = frequency;
      if (city_id !== "")
        data["city_id"] = city_id;

      getProviderResults(data)
    }
  }, [distance, startDate, endDate, frequency, city_id]);

  return (
    <Row className="mb-0">
      <Col xl={4} lg={4} md={6}>
        <div style={{ color: '#999', fontStyle: 'italic' }}>
          <small>Filtrar período</small>
        </div>
        <DateRange value={[startDate, endDate]} onChange={handleDateChange} />
      </Col>
      <Col xl={4} lg={4} md={6}>
        <div style={{ color: '#999', fontStyle: 'italic' }}>
          <small>Filtrar cidade</small>
        </div>
        <Select
          name="city_id"
          value={city_id}
          options={cities.map(c => {
            return { label: c.cityname, value: c._id }
          })}
          onChange={handleCityChange}
        />
      </Col>
      <Col xl={4} lg={4} md={6}>
        <div style={{ color: '#999', fontStyle: 'italic' }}>
          <small>Filtrar frequência</small>
        </div>
        <Select
          name="frequency"
          value={frequency}
          options={FrequencyOptions}
          onChange={handleFrequencyChange}
        />
      </Col>
    </Row>
  )
}

const FrequencyOptions = [
  { label: "Diária", value: "d" },
  { label: "Mensal", value: "m" },
  { label: "Anual", value: "y" },
];

export default Filter;