// @ts-nocheck
import React, { useContext } from "react";
import { SecondaryDashboardContext } from "../../../../context/SecondaryDashboard";
import Cards from "./Cards";

import { Row } from "reactstrap"

const ServiceValues = () => {
  const {
    state: {
      serviceValues: {
        currentMonthResults,
        lastMonthResults,
        totalResults
      }
    }
  } = useContext(SecondaryDashboardContext);

  return (
    <React.Fragment>
      <Row>
        {totalResults.length > 0 && <Cards result={totalResults[0]} title="Total" />}
        {lastMonthResults.length > 0 && <Cards result={lastMonthResults[0]} title="Mês passado" />}
        {currentMonthResults.length > 0 && <Cards result={currentMonthResults[0]} title="Mês atual" />}
      </Row>
    </React.Fragment>
  );
}

export default ServiceValues;