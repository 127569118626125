// @ts-nocheck
import React, { useContext, useState, useEffect } from 'react';
import {
	CartesianGrid,
	ComposedChart,
	Line,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
	Legend,
	Bar,
} from 'recharts';
import { withTranslation } from 'react-i18next';
import Panel from '../../../components/Panel';
import Loader from '../../../components/Loader';
import Error from '../../../components/Error';
import OccupancyTooltipContent from './OccupancyTooltipContent';
import { DashboardContext } from '..';
import { Badge } from 'reactstrap';

const colors = ['#1E90FF', '#11cf99', '#b651ff', '#b6e948'];

const TopCards = () => {
 
	const {
		state: { deliveriesMetrics, companiesMetrics, providersMetrics, revenueMetrics },
	} = useContext(DashboardContext);

	return (
		<>
			<Panel md={3} title="Empresas" subhead="">
				<div style={{height:100}}>
					<h1 style={{fontSize:36}}>{companiesMetrics.total ? parseInt(companiesMetrics.total).toLocaleString('pt-BR') : "-"}</h1>
					<Badge pill><h4 style={{color:"#FFF", margin:"2px 5px"}}>{companiesMetrics.customer ? parseInt(companiesMetrics.customer).toLocaleString('pt-BR') : "-"} Clientes</h4></Badge>&nbsp;
					<Badge pill style={{backgroundColor:"#11cf99"}}><h4 style={{color:"#FFF", margin:"2px 5px"}}>{companiesMetrics.active ? parseInt(companiesMetrics.active).toLocaleString('pt-BR') : "-"} Ativas</h4></Badge>
				</div>
				{/* <Loader height="100px" /> */}
			</Panel>
			<Panel md={3} title="Entregadores" subhead="">
				{/* <Loader height="100px" /> */}
				<div style={{height:100}}>
					<h1 style={{fontSize:36}}>{providersMetrics.total ? parseInt(providersMetrics.total).toLocaleString('pt-BR') : "-"}</h1>
					<Badge pill><h4 style={{color:"#FFF", margin:"2px 5px"}}>{providersMetrics.active ? parseInt(providersMetrics.active).toLocaleString('pt-BR') : "-"} Ativos</h4></Badge>&nbsp;
					<Badge pill style={{backgroundColor:"#11cf99"}}><h4 style={{color:"#FFF", margin:"2px 5px"}}>{providersMetrics.now ? parseInt(providersMetrics.now).toLocaleString('pt-BR') : "-"} Online</h4></Badge>
				</div>
			</Panel>
			<Panel md={3} title="Entregas" subhead="">
			<div style={{height:100}}>
					<h1 style={{fontSize:36}}>{deliveriesMetrics.total ? parseInt(deliveriesMetrics.total).toLocaleString('pt-BR') : "-"}</h1>
					<Badge pill><h4 style={{color:"#FFF", margin:"2px 5px"}}>{deliveriesMetrics.completed ? parseInt(deliveriesMetrics.completed).toLocaleString('pt-BR') : "-"} Concluídas</h4></Badge>&nbsp;
					<Badge pill style={{backgroundColor:"#11cf99"}}><h4 style={{color:"#FFF", margin:"2px 5px"}}>{deliveriesMetrics.now ? parseInt(deliveriesMetrics.now).toLocaleString('pt-BR') : "-"} Agora</h4></Badge>
				</div>
				{/* <Loader height="100px" /> */}
			</Panel>
			<Panel md={3} title="Faturamento" subhead="">
			<div style={{height:100}}>
					<h1 style={{fontSize:36}}>R$ {revenueMetrics.totalCompany ? parseInt(revenueMetrics.totalCompany).toLocaleString('pt-BR') : "-"}</h1>
					<Badge pill><h4 style={{color:"#FFF", margin:"2px 5px"}}>R$ {revenueMetrics.providerTotal ? parseInt(revenueMetrics.providerTotal).toLocaleString('pt-BR') : "-"} REP</h4></Badge>&nbsp;
					<Badge pill style={{backgroundColor:"#11cf99"}}><h4 style={{color:"#FFF", margin:"2px 5px"}}>R$ {revenueMetrics.totalCompany && revenueMetrics.providerTotal ? (parseInt(revenueMetrics.totalCompany) - parseInt(revenueMetrics.providerTotal)).toLocaleString('pt-BR') : "-"} REC</h4></Badge>
				</div>
				{/* <Loader height="100px" /> */}
			</Panel>
		</>
	);
};

export default withTranslation('common')(TopCards);
