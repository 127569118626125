// @ts-nocheck
import React from "react";
import ProfileProvider from "./context";
import Header from "./Components/Header";
import Content from "./Components/Content";
import Footer from "./Components/Footer";

import { Card, CardBody, Container } from "reactstrap";

type Props = {
  path: string;
}

const ProviderProfile = ({ path }: Props) => {
  return (
    <div>
      <Card>
        <CardBody>
          <Container>
            <ProfileProvider>
              <Header />
              <Content />
              <hr />
              <Footer path={path} />
            </ProfileProvider>
          </Container>
        </CardBody>
      </Card>
    </div>
  );
};

export default ProviderProfile;
