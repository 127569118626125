// @ts-nocheck
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import Panel from '../../../components/Panel';
import Loader from '../../../components/Loader';
import { DashboardContext } from '..';

const Deliveries = () => {
	const {
		state: { providerPerformance, providerPerformanceIsLoading, providerPerformanceHasErrored },
	} = useContext(DashboardContext);

	const showPerformanceChart =
		!providerPerformanceIsLoading && !providerPerformanceHasErrored && providerPerformance.length > 0;

	return (
		<Panel
			md={12}
			title="Top Entregadores"
			subhead="Entregadores com a maior quantidade de corridas"
			panelClass="dashboard__booking-reservations-panel"
		>
			<div>
				{providerPerformanceIsLoading && <Loader height="500px" />}

				{showPerformanceChart && (
					<Table responsive className="table dashboard__occupancy-table">
						<thead>
							<tr>
								<th>Id</th>
								<th>Nome</th>
								<th>Entregas</th>
							</tr>
						</thead>
						<tbody>
							{providerPerformance.map(provider => (
								<tr key={provider.id}>
									<td className="td-head">{provider.id}</td>
									<td className="td-gray">
										{provider.first_name} {provider.last_name}
									</td>
									<td className="td-blue">{provider.tripCount}</td>
								</tr>
							))}
						</tbody>
					</Table>
				)}
			</div>
		</Panel>
	);
};

export default withTranslation('common')(Deliveries);
