// @ts-nocheck
import React, { useEffect, useContext, useState, useCallback } from "react";
import { FinanceContext } from "../../context/Finance";
import { formatNumber, transformCodeToBank } from "../../utils/masks";
import Filter from "./Filter";

import { Container, Card, CardBody, Table, Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label } from "reactstrap";
import { FaRegThumbsDown } from "react-icons/fa";
import moment from "moment";
import { toast } from "react-toastify";

const Categories = ({ history }) => {
  const {
    state: { categories },
    action: { getCategories }
  } = useContext(FinanceContext);

  useEffect(() => {
    getCategories();
  }, []);

  const category_childs = (id) => {
    return categories.filter(c => c.master == id);
  }

  return (
    <>
    <Container>
      <h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333"}}>CONTROLE FINANCEIRO <small>/ CATEGORIAS</small></h3>
    </Container>
    <Container>
    {categories ? (
      <Row>
        <Col xs={4}>
          <Card>
            <CardBody>
              <div style={{fontWeight:"bold", marginBottom:10, fontSize:11}}>RECEITA <ModalAdd type={'revenue'}/></div>
              {categories.filter(c => c.master == null).map( c => ( c.type == 'revenue' ?
                <>
                  <div style={{padding:"10px 0", borderBottom:"solid 1px #EEE"}}>{c.name}</div>
                  {categories.filter(cc => cc.master == c._id).map(ccc => (<div style={{padding:"10px 0", paddingLeft:20, borderBottom:"solid 1px #EEE"}}>{ccc.name}</div>))}
                </>
              : ''))}
            </CardBody>
          </Card>
        </Col>
        <Col xs={4}>
          <Card>
            <CardBody>
              <div style={{fontWeight:"bold", marginBottom:10, fontSize:11}}>DESPESA <ModalAdd type={'expense'}/></div>
              {categories.filter(c => c.master == null).map( c => ( c.type == 'expense' ?
                <>
                  <div style={{padding:"10px 0", borderBottom:"solid 1px #EEE"}}>{c.name}</div>
                  {categories.filter(cc => cc.master == c._id).map(ccc => (<div style={{padding:"10px 0", paddingLeft:20, borderBottom:"solid 1px #EEE"}}>{ccc.name}</div>))}
                </>
              : ''))}
            </CardBody>
          </Card>
        </Col>
        <Col xs={4}>
          <Card>
            <CardBody>
              <div style={{fontWeight:"bold", marginBottom:10, fontSize:11}}>OUTROS <ModalAdd type={'other'}/></div>
              {categories.filter(c => c.master == null).map( c => ( c.type == 'other' ?
                <>
                  <div style={{padding:"10px 0", borderBottom:"solid 1px #EEE"}}>{c.name}</div>
                  {categories.filter(cc => cc.master == c._id).map(ccc => (<div style={{padding:"10px 0", paddingLeft:20, borderBottom:"solid 1px #EEE"}}>{ccc.name}</div>))}
                </>
              : ''))}
            </CardBody>
          </Card>
        </Col>
      </Row>
    ) : (
      <div style={{padding:30, textAlign:"center"}}>Nenhuma categoria encontrada</div>
    )}
    </Container>
    </>
  );
};

export default Categories;


const ModalAdd = (props) => {
  let { type, name, master} = props;
  
  const {
    state: {categories},
    action: { addCategory, getCategories }
  } = useContext(FinanceContext);

  const [ modal, setModal, ] = useState(false);
  const toggle = () => setModal(!modal);
  
  const [fields, setField] = useState({name:'', master: null, type: type});
  const onChange = useCallback(e => {
    const { value, name } = e.target;
    setField(prev => ({ ...prev, [name]: value }));
  }, []);

	const add = () => {
    console.log(`fields`, fields);
    addCategory(fields);
    toast.success("Categoria adicionada!")
    getCategories();
	}

  const text_title = (type) => {
    switch (type) {
      case 'revenue': return "Receita";
      case 'expense': return "Despesa";
      case 'other': return "Outro";
    
      default:
        break;
    }
  }

	return (
	  <>
		<Button size="sm" style={{margin: '0px', float:"right", padding:"4px 10px", borderRadius:100, fontWeight:"bold"}} outline color="secondary" onClick={toggle}>+</Button>
		<Modal isOpen={modal} toggle={toggle} aria-labelledby="contained-modal-title-vcenter" centered>
		  <ModalHeader toggle={toggle}>Nova Categoria de {text_title(type)}</ModalHeader>
		  <ModalBody>
			  <Form>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Categoria superior</Label>
            <Input id="master" name="master" type="select" onChange={onChange}>
              <option value={null}>Principal</option>
              {categories.filter(c => c.master == null).map( c => ( c.type == type ? <option value={c._id}>{c.name}</option>: ''))}
            </Input>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Nome da categoria</Label>
            <Input id="name" name="name" placeholder="Informe um nome" type="text" onChange={onChange}/>
          </FormGroup>
        </Form>
		  </ModalBody>
		  <ModalFooter>
        <Button color="primary" onClick={() => { add(); toggle()}}>Adicionar</Button>
		  </ModalFooter>
		</Modal>
	  </>
	);
}