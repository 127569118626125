// @ts-nocheck
import React, { useContext, useState } from "react";
import { getPaymentMode } from "./../../../utils/translateEnums";
import { formatNumber } from "../../../utils/masks";
import { TripsContext } from "../../../context/Trips";
import Panel from "../../../components/Panel";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from "reactstrap";

type Props = {
  buttonLabel: any;
  className: any;
}

const TripDirect = ({ buttonLabel, className }: Props) => {
  const {
    state: { selectedTrip, direct_provider_id, isDirecting },
    action: { changeDirectProviderId, directTripToProvider }
  } = useContext(TripsContext);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const actionDirectTripToProvider = async () => {
    const result = await directTripToProvider();
    setTimeout(() => {
      window.location.reload();
    }, 3000);
	};

  return (
    <>
      <Button  style={{marginBottom: 0}} color="dark" onClick={toggle}>{buttonLabel}</Button>
      <Modal centered isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Direcionar entrega</ModalHeader>
        <ModalBody>
          <div style={{marginBottom:10, textAlign:"left"}}>Direciona entrega para um entregador</div>
          <Form>
            <FormGroup>
              <Input onChange={(e) => {changeDirectProviderId(e.target.value)}} type="text" name="provider_id" id="provider_id" placeholder="ID Entregador" />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter style={{justifyContent:"flex-start"}}>
          <Button color="primary" onClick={() => { actionDirectTripToProvider() }}>{isDirecting ? "Aguarde..." : "Direcionar"}</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TripDirect;
