// @ts-nocheck
import React, { useContext } from "react";
import FilterProvider from "./context";
import { ResultContext } from "../../context/Result";
import Loader from "../../components/Loader";
import CompanyTable from "./components/CompanyTable";
import Filter from "./components/Filter";
import CSVLink from "react-csv";
import moment from "moment";
import { formatNumber } from "../../utils/masks";

import { Container, Card, CardBody, Col, Row } from "reactstrap";

const headers = [
  "Empresa",
  "Qtd. entregas",
  "R$ Total"
];

const CompanyResults = () => {
  const {
    state: { companyResults, isCompanyResultsLoaded }
  } = useContext(ResultContext);

  return (
    <FilterProvider>
      <Container><h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333"}}>RESULTADOS POR EMPRESAS</h3></Container>
      <Container>
        <Card>
          <Filter compare={ false }/>
          <CardBody>
            {isCompanyResultsLoaded ? (
              <CompanyTable companyResults={companyResults} />
            ) : (
                <Loader />
              )}
          </CardBody>
        </Card>
      </Container>
    </FilterProvider>
  );
};

const transformData = (results: any[]) => {
  const companiesTable: any[] = [];
  results.map(day => {
    day.companies.map((company: any) => (
      companiesTable.push([
        moment(day.date).format("DD/MM/YYYY"),
        company.name,
        company.delivers.total,
        formatNumber(company.for_company.total),
      ])
    ));
  });
  return companiesTable;
};

export default CompanyResults;
