// @ts-nocheck
import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  // useRef
} from "react";
import { ProviderContext } from "../../../context/Provider";
import { formatPrice, toNumber } from "../../../utils/masks";

import { Card, CardBody, Col, Button } from "reactstrap";
//@ts-ignore
// import debounce from "lodash.debounce";
import {
  Input,
  Dropdown,
  TextArea,
  Form,
  Segment,
  Header
} from "semantic-ui-react";

type Props = { providerId: string };

const EntryForm = ({ providerId }: Props) => {
  // const buttonRef = useRef(null);

  const {
    action: { addEntry }
  } = useContext(ProviderContext);

  const initialState = {
    type: "",
    amount: "0,00",
    description: "",
    disabled: true
  };

  const [state, setState] = useState(initialState);

  const onChange = useCallback((e, data) => {
    e.preventDefault();
    const { name, value } = data;
    if (name === "amount")
      setState(prev => ({ ...prev, [name]: formatPrice(value) }));
    else setState(prev => ({ ...prev, [name]: value }));
  }, []);

  const onSubmit = () => {
    const data = { ...state, amount: toNumber(state.amount) };
    addEntry(providerId, data);
  };

  const clearState = () => setState(initialState);

  // useEffect(() => {
  //   const handleOnKeyDown = debounce((e: React.KeyboardEvent) => {
  //     if (e.which === 13 && buttonRef.current) {
  //       e.preventDefault();
  //       //@ts-ignore
  //       buttonRef.current.onClick();
  //     }
  //   }, 600)
  //   window.addEventListener("keydown", e => handleOnKeyDown(e));
  // }, []);

  useEffect(() => {
    if (state.description === "")
      setState(prev => ({
        ...prev,
        disabled: true
      }));
    else if (state.type === "")
      setState(prev => ({
        ...prev,
        disabled: true
      }));
    else setState(prev => ({ ...prev, disabled: false }));
  }, [state.description, state.type]);

  return (
    <Card>
      <h3>Criar lançamento</h3>
      <CardBody>
        <Input
          action={
            <Dropdown
              button
              basic
              floating
              options={[
                { key: "i", text: "Adicionar", value: "i" },
                { key: "o", text: "Retirar", value: "o" }
              ]}
              placeholder="Tipo de lançamento"
              value={state.type}
              name="type"
              onChange={onChange}
            />
          }
          actionPosition="left"
          value={state.amount}
          name="amount"
          onChange={onChange}
        />
        <Form className="mt-3">
          <Form.Field
            control={TextArea}
            label="Descrição"
            width="sixteen"
            value={state.description}
            name="description"
            onChange={onChange}
          />
        </Form>
        <Header as="h5" className="mt-3">
          Resumo da transação
        </Header>
        <Segment
          inverted
          padded
          textAlign="center"
          color={state.disabled ? "grey" : state.type === "i" ? "green" : "red"}
        >
          {state.disabled
            ? state.type === ""
              ? "Escolha um tipo de lançamento"
              : "Adicione uma descrição"
            : `O valor de R$ ${state.amount} será ${
            state.type === "o" ? "retirado" : "adicionado"
            } ao faturamento do entregador`}
        </Segment>
        <hr />
        <Col className="pl-0">
          <Button onClick={clearState}>Limpar</Button>
          <Button
            // ref={buttonRef}
            className="btn-primary"
            disabled={state.disabled}
            onClick={onSubmit}
          >
            Confirmar
          </Button>
        </Col>
      </CardBody>
    </Card>
  );
};

export default EntryForm;
