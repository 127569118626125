// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { DateRangeInput } from "@opuscapita/react-dates";
import styles from "./styles.module.css";

interface Props {
	value: Date[];
	onChange(dates: Date[]): void;
}

const DateRange = ({ onChange, value }: Props) => {

	const [range, setRange] = useState<Date[]>(value);

	const handleDatesChange = (dates: Date[]) => {
		onChange(dates)
	}

	useEffect(() => {
		setRange(value)
	}, [value])

	return (
		<DateRangeInput
			className={styles.dateInput}
			dateFormat="dd/MM/yyyy"
			locale="pt-br"
			onChange={handleDatesChange}
			value={range}
			modifiers={{
				// disabled: { after: new Date() }
			}}
		/>
	);
}

export default DateRange
