// @ts-nocheck
import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";

import { DateInput } from "@opuscapita/react-dates";

interface Props {
  value: Date;
  onChange(dates: Date): void;
}

const DatePicker = ({ value, onChange }: Props) => {
  const [state, setState] = useState(value)

  const handleDateChange = (date: Date) => {
    onChange(date)
  }

  useEffect(() => {
    setState(value)
  }, [value])

  return (
    <DateInput
      className={styles.dateInput}
      dateFormat="dd/MM/yyyy"
      locale="pt-br"
      onChange={handleDateChange}
      value={state}
      modifiers={{ disabled: { after: new Date() } }}
    />
  );
}

export default DatePicker;