// @ts-nocheck
import React from "react";
import { getPricingPolicy } from "../../../utils/translateEnums";
import { useToggle } from "../../../hooks/hooks";
import CompanyDetail from "./CompanyDetail";
import moment from "moment";


type Props = { company: any };

const ListItem = ({ company }: Props) => {
  const { active, toggle, activate } = useToggle(false);

  return (
    <React.Fragment>
      <tr onClick={activate}>
        <td>{company.document}</td>
        <td>{company.name}</td>
        <td>{company.platform_manager}</td>
        <td>{company.manager_email}</td>
        <td>{company.manager_phone && company.manager_phone != "" ? company.manager_phone : (company.costcenter.filter((c: any) => c.phone && c.phone != "").length ? company.costcenter.filter((c: any) => c.phone && c.phone != "")[0].phone : "")}</td>
        <td>{company.costcenter.length > 0 ? company.costcenter[0].address : 'Sem loja cadastrada'}</td>
        <td style={{textAlign:"right"}}>{ moment(company.created_at).format("DD/MM/YYYY")}</td>
      </tr>
      <CompanyDetail company={company} isOpen={active} handleToggle={toggle} />
    </React.Fragment>
  );
};
export default ListItem;
