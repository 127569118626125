// @ts-nocheck
import React, { createContext, PureComponent } from "react";
import { getPermissionsLabel } from "../utils/translateEnums";
import { AuthContext } from "./Auth";
import { toast } from "react-toastify";

interface SettingsContext {
  state: State;
  action: {
    getCredentials(): void;
    getPermissions(): void;
    createCredential(admin: any, isNew: boolean, adminId?: string): void;
  }
}

export const SettingsContext = createContext({} as SettingsContext);

interface State {
  permissionsOpTions: any[];
  credentials: any[];
  update: boolean;
}

class SettingsProvider extends PureComponent<{}, State> {
  static contextType = AuthContext;

  state = {
    permissionsOpTions: [],
    credentials: [],
    update: false,
  }

  getPermissions = () => {
    const { request } = this.context.action;
    const url = "admin/v1/settings/permissions";

    this.setState({
      permissionsOpTions: [],
    }, async () => {
      try {
        const response = await request("GET", url);
        const { success, data } = response.data;
        if (success)
          this.setState({
            permissionsOpTions: data.map((p: string, index: number) => ({
              value: p,
              label: getPermissionsLabel(p),
              id: index
            })),
            update: false
          })
      } catch (error) {
        this.setState({ permissionsOpTions: [] })
      }
    })
  }

  getCredentials = () => {
    const { request } = this.context.action;
    const url = "admin/v1/settings/credentials";

    this.setState({
      credentials: []
    }, async () => {
      try {
        const response = await request("GET", url);
        const { success, data } = response.data;
        if (success)
          this.setState({ credentials: data })
      } catch (error) {
        this.setState({ credentials: [] })
      }
    })
  }

  createCredential = async (admin: any, isNew: boolean, adminId?: string) => {
    const { request } = this.context.action;
    let url, method = '';

    if (isNew) {
      url = "admin/v1/settings/credentials";
      method = "POST";
    } else {
      url = `admin/v1/settings/credentials/${adminId}`;
      method = "PUT";
    }

    try {
      const response = await request(method, url, this.transformData(admin))
      if (response.data.success)
        this.setState({
          update: true
        },
          isNew
            ? toast.success.bind(null, "Credencial criada com sucesso")
            : toast.success.bind(null, "Credencial atualizada com sucesso")
        )
    } catch (error) {
      toast.error(isNew ? "Erro ao criar credencial" : "Erro ao atualizar credencial")
    }
  }

  transformData = (admin: any) => {
    const data: any = {}
    const keys = ["username", "email", "password", "type"]

    for (const key of keys) {
      if (admin[key] !== "") {
        if (key === "type")
          data[key] = Number(admin[key])
        else
          data[key] = admin[key]
      }
    }

    if (admin.permissions.length > 0) {
      data["permissions"] = admin.permissions
    }

    return data;
  }

  render() {
    const { executeOnlyIfAuthenticated } = this.context.action;

    const value = {
      state: { ...this.state },
      action: {
        getCredentials: executeOnlyIfAuthenticated(this.getCredentials),
        getPermissions: executeOnlyIfAuthenticated(this.getPermissions),
        createCredential: executeOnlyIfAuthenticated(this.createCredential)
      }
    }

    return (
      <SettingsContext.Provider value={value}>
        {this.props.children}
      </SettingsContext.Provider>
    );
  }
}

export default SettingsProvider;