// @ts-nocheck
import React, { useContext, useCallback, useState, useEffect } from "react"
import { CompanyContext } from "../../../context/Company"

const CompanyManagement = ({
  approved,
  refused,
  company,
  onOpenEditForm,
  onOpenWindow
}) => {
  const {
    action: {
      acceptCompany,
      refuseCompany
    }
  } = useContext(CompanyContext)

  const [waiting, setWaiting] = useState(!approved && !refused)

  useEffect(() => {
    setWaiting(!approved && !refused)
  }, [approved, refused])

  const onAcceptCompany =
    useCallback(() => acceptCompany(company), [company])

  const onRefuseCompany =
    useCallback(() => refuseCompany(company), [company])

  if (waiting) return (
    <div className="company-management">
      <div onClick={onAcceptCompany}><i className="fas fa-check accept" />&nbsp;Aceitar</div>
      <div onClick={onRefuseCompany}><i className="fas fa-times refuse" />&nbsp;Recusar</div>
      <div onClick={onOpenEditForm}><i className="fas fa-edit edit" />&nbsp;Editar preço</div>
      <div onClick={onOpenWindow}>{/*<i className="fas fa-edit center" />*/}&nbsp;Centro de Custo</div>
    </div>
  )

  return null
}

export default CompanyManagement
