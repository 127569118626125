// @ts-nocheck
import React from "react";
import {
  getTripStatus,
  getTripStatusColors,
} from "../../../utils/translateEnums";
import { formatNumber } from "../../../utils/masks";
import { RouteComponentProps, withRouter } from "react-router";
import moment from "moment";
import Badge from "reactstrap/lib/Badge";
import { Icon } from "semantic-ui-react";

interface Props extends RouteComponentProps {
  trip: any;
}

const TripListItem = ({ trip, history }: Props) => {
  const redirectToDetails = () => history.push(`/trips/details/${trip._id}`);

  if(trip.user_not_found) console.log(trip);
  
  return (
    <tr>
      <td onClick={redirectToDetails}>
        {trip.unique_id}
      </td>
      <td onClick={redirectToDetails}>
        {trip.city.citycode}
      </td>
      <td
      
        onClick={redirectToDetails}
        style={{ textTransform: "uppercase" }}
      >
        {trip.user.first_name.toLowerCase()} {trip.user.last_name.toLowerCase()}
      </td>
      <td>
        {trip.costcenter.phone ? (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            href={
              "https://wa.me/55" +
              trip.costcenter.phone +
              "?text=Olá, aqui é da Vaay. Está precisando de entregador?"
            }
            target="_blank"
          >
            <Icon name="whatsapp" circular inverted color="green" />
          </a>
        ) : (
          ""
        )}
      </td>
      <td
      
        onClick={redirectToDetails}
        style={{ textTransform: "capitalize" }}
      >
        {(trip.is_trip_cancelled_by_provider
          ? trip.cancel_provider.first_name +
            " " +
            trip.cancel_provider.last_name
          : trip.provider.first_name
          ? trip.provider.first_name + " " + trip.provider.last_name
          : "-"
        ).toLowerCase()}
      </td>
      <td onClick={redirectToDetails}>
        {moment(trip.user_create_time).format("DD/MM/YYYY kk:mm")}
      </td>
      {/* <td>{moment(trip.provider_trip_end_time).format("DD/MM/YYYY kk:mm")}</td> */}
      <td onClick={redirectToDetails}>
        R$ {formatNumber(trip.total_for_company)}
      </td>
      <td onClick={redirectToDetails}>
        R$ {formatNumber(trip.total + (trip.tax_return || 0))}
      </td>
      {/* <td>{trip.type_name}</td> */}
      <td onClick={redirectToDetails}>
        {trip.user_not_found && (
          <Badge color="warning">Sem entregador</Badge>
        )}
        {!!trip.is_trip_cancelled ? (
          <Badge color={trip.is_trip_cancelled_by_provider ? "danger" : "dark"}>
            {trip.is_trip_cancelled_by_provider
              ? "Cancelada - Entregador"
              : "Cancelada - Lojista"}
          </Badge>
        ) : (
          <Badge color={getTripStatusColors(trip.status)}>
            {getTripStatus(trip.status)}
          </Badge>
        )}
      </td>
      <td>
        {!!trip.is_trip_cancelled ? (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            href={
              "https://wa.me/?text=*ATENÇÃO*%0D" +
              trip.user.first_name.toUpperCase() +
              " Chamando!!%0D%0DAlguém disponível?%0D"
            }
            target="_blank"
          >
            <Icon name="bullhorn" circular inverted color="red" />
          </a>
        ) : (
          ""
        )}
      </td>
    </tr>
  );
};

export default withRouter(TripListItem);
