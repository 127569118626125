// @ts-nocheck
import React, { useContext } from "react";
import { SettingsContext } from "../../context/Settings";
import { MultipleCheckbox } from "../../components/Checkbox/MultipleCheckbox";
import { CustomModal } from "../../components/Modal";
import Select from "../../components/Select";
import PlusIcon from "mdi-react/PlusIcon";
import EditIcon from "mdi-react/EditIcon";
import { useAdminForm } from "./formHook";

import { Row, Col, Button, FormGroup, Input, Label } from "reactstrap";

type Props = {
  isNew: boolean;
  admin?: any;
}

const AdminForm = ({ isNew, admin }: Props) => {
  const {
    state: { permissionsOpTions },
    action: { createCredential }
  } = useContext(SettingsContext);

  const {
    state,
    handleOnChange,
    onValuesChage
  } = useAdminForm(isNew ? undefined : admin);

  const submit = () => {
    isNew
      ? createCredential(state, isNew)
      : createCredential(state, isNew, admin._id)
  }

  return (
    <CustomModal
      title={isNew ? "Adicionar administrador" : "Editar administrador"}
      handleOnClick={submit}
      trigger={toggle =>
        isNew ? (
          <Button
            className="float-right btn-primary"
            onClick={toggle}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <PlusIcon size="20px" />
            Adicionar administrador
          </Button>
        ) : (
            <Button className="m-0" onClick={toggle}>
              <EditIcon />
            </Button>
          )
      }
    >
      <Row>
        <Col>
          <FormGroup>
            <Label>Usuário</Label>
            <Input
              name="username"
              value={state.username}
              onChange={handleOnChange}
              disabled={!isNew}
            />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input
              name="email"
              value={state.email}
              onChange={handleOnChange}
              disabled={!isNew}
            />
          </FormGroup>
          <FormGroup>
            <Label>Senha</Label>
            <Input
              type="password"
              name="password"
              value={state.password}
              onChange={handleOnChange}
            />
          </FormGroup>
          <Label>Tipo de administrador</Label>
          <Select
            name="type"
            options={AdminTypesOptions}
            value={state.type}
            onChange={onValuesChage}
          />
        </Col>
        <Col style={{ maxHeight: 310 }} >
          <Label>Permissões</Label>
          <MultipleCheckbox
            styleContainer={{ maxHeight: 278, overflowY: "auto" }}
            name="permissions"
            options={permissionsOpTions}
            values={state.permissions}
            onChange={onValuesChage}
            disabled={state.type === "0" ? true : false}
          />
        </Col>
      </Row>
    </CustomModal>
  )
};

const AdminTypesOptions = [
  { value: "0", label: "Administrador" },
  { value: "1", label: "Sub Adminstrador" },
  { value: "2", label: "Licenciado" },
  { value: "3", label: "Governo" }
];

export default AdminForm;