// @ts-nocheck
import React from 'react';
import { Container, Card, CardBody, Table } from 'reactstrap';
import { ZonesContext } from '../../context/Zones';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import sortByProperty from '../../utils/sortByProperty';

class Cities extends React.PureComponent {
	static contextType = ZonesContext;

	state = {
		cities: [],
		search: '',
	};

	componentDidMount() {
		this.context.action.fetchCities();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.cities.length === 0 && prevState.search === '') {
			this.setState({
				cities: sortByProperty(this.context.state.cities, 'cityname'),
			});
		}
	}

	onChangeSearch = ({ target: { value: searchPattern } }) => {
		if (searchPattern === '') {
			return this.setState({
				cities: sortByProperty(this.context.state.cities, 'full_cityname'),
			});
		}

		const filteredCities = this.context.state.cities.filter(city => {
			return city.full_cityname.toLowerCase().includes(searchPattern);
		});

		this.setState({
			cities: sortByProperty(filteredCities, 'full_cityname'),
			search: searchPattern,
		});
	};

	onClick(cityId) {
		this.props.history.push({
			pathname: '/regions',
			cityId,
		});
	}

	render() {
		const {
			state: { cities, citiesAreLoading, citiesHasErrored },
		} = this.context;

		return (
			<Container>
				<Card>
					<CardBody>
						<div className="table-filter">
							<input type="text" placeholder="Pesquisar..." onChange={this.onChangeSearch} />
						</div>
						<Table responsive hover className="table table-condensed table--bordered">
							<thead>
								<tr>
									<th>Cidade</th>
								</tr>
							</thead>
							<tbody>
								{this.state.cities.map(city => (
									<tr onClick={() => this.onClick(city._id)} key={city._id}>
										<td>{city.cityname}</td>
										<td>{city.full_cityname.split(',')[1]}</td>
									</tr>
								))}
							</tbody>
						</Table>
						{citiesAreLoading && <Loader height="300px" />}
						{citiesHasErrored && <Error message="Não foi possível exibir as cidades" />}
						{cities.length === 0 && !citiesAreLoading && !citiesHasErrored && (
							<Error message="Não existe nenhuma cidade cadastrada" />
						)}
					</CardBody>
				</Card>
			</Container>
		);
	}
}

export default Cities;
