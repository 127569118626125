// @ts-nocheck
import React, { useEffect, useContext } from "react";
import { formatNumber, formatNumberToDistance } from "../../../utils/masks";
import { getPricingPolicy, getVehicleType } from "../../../utils/translateEnums";
import { useToggle } from "../../../hooks/hooks";
import { CompanyContext } from "../../../context/Company";
import PricePolicy from "./PricePolicy";
import Edit from "./Edit";
import Admins from "./Admins";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Col,
  Row,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import Badge from "reactstrap/lib/Badge";
import PricePolicyItem from "./PricePolicyItem";

type Props = {
  company: any;
  isOpen: boolean;
  handleToggle(): void;
};

const CompanyDetail = ({ company, isOpen, handleToggle }: Props) => {
  const {
    state: { pricings, admins },
    action: { fetchCompanyPricings }
  } = useContext(CompanyContext);

  const { active, toggle, activate } = useToggle(false);
  // const { active: price, toggle, activate } = useToggle(false);
  const { active: editOpen, toggle: toggleEdit, activate: activateEdit } = useToggle(false);
  const { active: adminsOpen, toggle: toggleAdmins, activate: activateAdmin } = useToggle(false);

  return (
    <Modal
      style={{ minWidth: 600 }}
      isOpen={isOpen}
      toggle={handleToggle}
      onOpened={() => fetchCompanyPricings(company._id)}>
      <ModalHeader>Detalhes da Empresa</ModalHeader>
      {company && (
        <React.Fragment>
          <ModalBody>
            <div className="text-left">
              {company.is_approved &&
                <>
                  <Badge color="success">
                    Aprovada
                  </Badge>
                  <br />
                </>
              }
              <span><b>Razão social:</b> {company.full_name}</span><br />
              <span><b>Nome fantasia:</b> {company.name}</span><br />
              {/* <br />
              <span>Origem: {company.origin}</span> */}
              <span>Documento: {company.document}</span>
              <hr/>
              <span>DADOS DE CONTATO</span><br/>
              <span>Nome: {company.platform_manager}</span><br />
              <span>Email: {company.manager_email}</span><br />
              <span>Telefone: {admins.length > 0 ? admins[0].phone : ''}</span><br />
              <hr/>
              <span>DADOS DE COBRANÇA</span><br/>
              <span>Nome: {company.financial_manager || company.platform_manager}</span><br />
              <span>Email: {company.financial_email || company.manager_email}</span><br />
              <span>Telefone: {company.financial_phone}</span><br />
              <hr/>
              <span>Criado em: {moment(company.created_at).format("LLL")}</span>
            </div>
            {/* {pricings && pricings.length > 0 && (
              <React.Fragment>
                <hr />
                <h5 className="text-left">Políticas de preço</h5>
                <div className="text-left">
                  <React.Fragment>
                    <Table className="mt-3 mb-3" bordered>
                      <thead>
                        <tr>
                          <th>Veículo</th>
                          <th>Tipo</th>
                          <th>Valor</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {pricings.map(p => (
                          <PricePolicyItem company={company} pricing_policy={p} />
                        ))}
                      </tbody>
                    </Table>
                  </React.Fragment>
                </div>
              </React.Fragment>
            )} */}
          </ModalBody>
          <ModalFooter>
            <Col>
              <Row>
                <Button color="primary" onClick={activateEdit}>Editar</Button>
                <Button color="primary" onClick={activateAdmin}>Administradores</Button>
                {/* <Button color="primary">
                  <Link style={{ color: "#FFF" }} to={`/companies/company_metrics/${company._id}`}>
                    Métricas
                  </Link>
                </Button> */}
              </Row>
              {/* <Row>
              </Row> */}
            </Col>
            {/* <Link to={`/companies/company_wallet/${company._id}`}>
                  <Button className="mr-2" color="primary">
                    Carteira digital
                  </Button>
                </Link> */}
          </ModalFooter>
        </React.Fragment>
      )}
      <Edit
        company={company}
        isOpen={editOpen}
        handleToggle={toggleEdit}
      />
      <Admins
        company={company}
        isOpen={adminsOpen}
        handleToggle={toggleAdmins}
      />
    </Modal>
  );
};

export default CompanyDetail;
