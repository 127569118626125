/* I'm tired */
// @ts-nocheck
import React, { useState, useRef, useContext } from 'react';
import { CompanyContext } from '../../../context/Company';
import PolicyData from './PolicyData';
import { toast } from 'react-toastify';
import classnames from 'classnames';

const isSorted = arr =>
	arr.reduce((acc, current) => {
		if (!acc) return current;
		if (current >= acc) return true;
		else return false;
	}, false);

const EditPolicy = ({ active, company, onClose }) => {
	const {
		action: { editCompanyPolicy },
	} = useContext(CompanyContext);

	const minimumRef = useRef(null);
	const fixedRef = useRef(null);
	const variableRef = useRef(null);

	const [policy, setPolicy] = useState('');

	function onChangeRadio(e) {
		setPolicy(e.target.value);
	}

	function onSubmit(e) {
		e.preventDefault();
		const els = e.target.elements;
		const ranges = [];

		/* Hack */
		const addRanges = (v, i) => {
			let count = null;

			const isDistance = v.id.includes('distance');
			const isCoefficient = v.id.includes('coefficient');

			if (isDistance || isCoefficient) {
				count = v.id.split('-')[1];
				if (!ranges[count]) ranges[count] = {};
			} else return;

			if (isDistance) return (ranges[count].distance = v.value);
			if (isCoefficient) return (ranges[count].coefficient = v.value);
		};

		switch (policy) {
			case 'range': {
				const minimum = minimumRef.current.value;
				Array.prototype.forEach.call(els, addRanges);
				if (!isSorted(ranges.map(r => r.distance)))
					return toast.info('As distâncias precisam estar em ordem crescente');
				return editCompanyPolicy(company, {
					pricing_policy: policy,
					coefficient: minimum,
					ranges,
				});
			}
			case 'variable': {
				const multiplier = variableRef.current.value;
				return editCompanyPolicy(company, {
					pricing_policy: policy,
					coefficient: multiplier,
				});
			}
			case 'fixed': {
				const coefficient = fixedRef.current.value;
				return editCompanyPolicy(company, {
					pricing_policy: policy,
					coefficient,
				});
			}
			default:
				break;
		}
	}

	return (
		<div className={classnames('edit-policy', { active })}>
			<span className="text-small title title--not-bordered">Selecione uma política de Preço</span>

			<span className="close-btn">
				<i className="fas fa-times" onClick={onClose} />
			</span>

			<form className="options" onSubmit={onSubmit}>
				<fieldset onChange={onChangeRadio}>
					<p className="option">
						<input type="radio" name="policy" value="fixed" id="fixed" />
						<div className="hint">Definir o valor que a empresa vai pagar or cada entrega</div>
						<label htmlFor="fixed">Fixa</label>
					</p>
					<p className="option">
						<input type="radio" name="policy" value="variable" id="variable" />
						<div className="hint">
							Definir o multiplicador que definirá o valor da entrega com base no valor calculado para o entregador
						</div>
						<label htmlFor="variable">Variável</label>
					</p>
					<p className="option">
						<input type="radio" name="policy" value="range" id="range" />
						<div className="hint">Definir o valor para cada faixa de distância</div>
						<label htmlFor="range">Por margem de distância</label>
					</p>
				</fieldset>

				<PolicyData policy={policy} variableRef={variableRef} fixedRef={fixedRef} minimumRef={minimumRef} />

				<button type="submit">Confirmar</button>
			</form>
		</div>
	);
};

export default EditPolicy;
