// @ts-nocheck
import React, { useContext } from "react";
// import WithdrawalRequest from "./components/WithdrawalRequest";
import Transactions from "./components/Transactions";
import Filter from "./components/Filter";
import Balance from "./components/Balance";
import { FranchiseContext } from "../../context/Franchise";
// import Loader from "../../components/Loader";

import { withTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";

const Wallet = () => {
  const {
    // state: { isBalanceLoaded, isTransactionsLoaded }
  } = useContext(FranchiseContext);

  return (
    <Container className="dashboard">
      <h3 className="page-title">Carteira Digital</h3>
      <Row>
        <Col sm={12} md={6} lg={7} xl={8}>
          <Filter />
        </Col>
				<Col sm={12} md={6} lg={5} xl={4}>
					<Balance />
				</Col>
      </Row>
      <Transactions />
    </Container>
  );
};

// class Wallet extends Component {
// 	static contextType = FranchiseContext;

// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			filters: {
// 				date: {
// 					start: moment(new Date())
// 						.subtract(7, 'd')
// 						.format('YYYY-MM-DD'),
// 					end: moment(new Date()).format('YYYY-MM-DD'),
// 				},
// 				type: {
// 					o: true,
// 					i: true,
// 					t: true,
// 				},
// 			},
// 		};
// 	}

// 	setFilters = newFilters => {
// 		if (newFilters.date.start === '') newFilters.date.start = this.state.filters.date.start;
// 		if (newFilters.date.end === '') newFilters.date.end = this.state.filters.date.end;

// 		if (newFilters.date.start > newFilters.date.end) {
// 			newFilters.date.start = newFilters.date.end;
// 		}

// 		if (!newFilters.type.o && !newFilters.type.i && !newFilters.type.t) {
// 			newFilters.type.o = newFilters.type.i = newFilters.type.t = true;
// 		}

// 		this.setState({ filters: newFilters }, () => actions.franchiseFinancialTransactions.get({ filters: newFilters }));
// 	};

// 	UNSAFE_componentWillMount() {
// 		// actions.bankList.get();
// 		// actions.franchiseFinancialBalance.get();
// 		// actions.franchiseFinancialTransactions.get({ filters: this.state.filters });
// 		this.context.action.getFranchiseBalance();
// 		this.context.action.getBanks();
// 	}

// 	render() {
// 		const {
// 			t,
// 			franchiseFinancialBalance,
// 			// bankList,
// 			franchiseFinancialTransactions,
// 		} = this.props;

// 		return (
// 			<Container className="dashboard">
// 				{this.context.state.isBalanceLoaded ? (
// 					<>
// 						<Row>
// 							<Col md={12}>
// 								<h3 className="page-title float-left">{t('wallet.page_title')}</h3>
// 								{/* <ButtonToolbar className="float-right" style={{ margin: 0 }}>
//               <WithdrawalRequest franchiseFinancialBalance={franchiseFinancialBalance} bankList={bankList} />
//             </ButtonToolbar> */}
// 							</Col>
// 						</Row>
// 						<Row>
// 							<Filters filters={this.state.filters} setFilters={this.setFilters} />
// 							<Balance
// 								franchiseFinancialBalance={franchiseFinancialBalance}
// 								filters={this.state.filters}
// 								setFilters={this.setFilters}
// 							/>
// 						</Row>
// 						<Row>
// 							<Transactions
// 								filters={this.state.filters}
// 								franchiseFinancialTransactions={franchiseFinancialTransactions}
// 							/>
// 						</Row>
// 					</>
// 				) : (
// 					<Loader />
// 				)}
// 			</Container>
// 		);
// 	}
// }

export default withTranslation("common")(Wallet);
