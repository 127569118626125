// @ts-nocheck
import React from 'react';
import { toast } from 'react-toastify'
import { AuthContext } from './Auth'

const ZonesContext = React.createContext({})

class ZonesProvider extends React.PureComponent {
	static contextType = AuthContext

	initialState = {
		cities: [],
		citiesAreLoading: false,
		citiesHasErrored: false,
		regions: [],
		regionsAreLoading: false,
		regionsHasErrored: false,
		currentlyBeingEdited: null,
		zones: [],
		zonesAreLoading: false,
		zonesHasErrored: false,
	}

	state = this.initialState
	regionUpdateInterface = null

	fetchCities = () => {
		const { request } = this.context.action

		this.setState({
			cities: [],
			citiesAreLoading: true,
			citiesHasErrored: false,
			regions: [],
			zones: []
		}, async () => {
			try {
				const response = await request('GET', 'admin/v1/cities')

				if (response.data.success)
					this.setState({ cities: response.data.data, citiesAreLoading: false })
				else throw new Error('Erro ao buscar as cidades.')

			} catch (err) {
				this.setState({
					cities: [],
					citiesAreLoading: false,
					citiesHasErrored: true,
				}, toast.error.bind(null, err.message))
			}
		})
	}

	fetchRegions = cityId => {
		const { request } = this.context.action

		this.setState({
			regions: [],
			regionsAreLoading: true,
			regionsHasErrored: false,
		}, async () => {
			try {
				const response = await request('GET', `admin/v1/cities/${cityId}/queue_zone`)

				if (response.data.success)
					this.setState({ regions: response.data.data, regionsAreLoading: false })
				else throw new Error('Erro ao buscar as regiões.')

			} catch (err) {
				this.setState({
					regions: [],
					regionsAreLoading: false,
					regionsHasErrored: true,
				}, toast.error.bind(null, err.message))
			}
		})
	}

	fetchCityZone = cityId => {
		const { request } = this.context.action

		this.setState({
			zones: [],
			zonesAreLoading: true,
			zonesHasErrored: false
		}, async () => {
			try {
				const response = await request("GET", `admin/v1/cities/${cityId}/city_zone`)

				if (response.data.success)
					this.setState({ zones: response.data.data, zonesAreLoading: false })
				else throw new Error("Erro ao buscar a zona da cidade.")

			} catch (err) {
				this.setState({
					zones: [],
					zonesAreLoading: false,
					zonesHasErrored: true
				}, toast.error.bind(null, err.message))
			}
		})
	}

  createRegion = async (cityId, title, kmlzone, refetch) => {
    const { request } = this.context.action

    try {
      const response = await request("POST", `admin/v1/cities/${cityId}/queue_zone`, { title, kmlzone })

			if (response.data.success) {
				toast.success(`Região ${title} adicionada com sucesso`)
			} else throw new Error("Falha ao criar região")
    } catch (err) {
      toast.error(err.message)
    } finally {
      this.fetchRegions(cityId)
    }
  }

	createZone = async (cityId, title, kmlzone, refetch) => {
    const { request } = this.context.action

    try {
      const response = await request("POST", `admin/v1/cities/${cityId}/city_zone`, { title, kmlzone })

			if (response.data.success) {
				toast.success(`Zona ${title} adicionada com sucesso`)
			} else throw new Error("Falha ao criar região")
    } catch (err) {
      toast.error(err.message)
    } finally {
      this.fetchCityZone(cityId)
    }
  }

	updateRegion = (regionId, coords) => {
		// do stuff
	}

	editRegion = (regionId) => {
		const { regions } = this.state

		const regionToEdit = regions.find(r => r._id === regionId)

		if (regionToEdit) {
			this.setState({ currentlyBeingEdited: true })

			const newRegion = { ...regionToEdit }

			const updateRegion = (kmlzone) => {
				Object.assign(newRegion, { kmlzone })
			}

			const getRegion = () => {
				return newRegion
			}

			const submitRegion = () => {
				this.setState({ currentlyBeingEdited: false })
				this.updateRegion(newRegion._id, newRegion.coords)
				this.regionUpdateInterface = null
			}

			const cancelEdit = async() => {
				await this.setState({ currentlyBeingEdited: false })
				this.regionUpdateInterface = null
			}

			this.regionUpdateInterface = {
				updateRegion,
				getRegion,
				submitRegion,
				cancelEdit
			}
		}
	}

	render() {
		const { executeOnlyIfAuthenticated } = this.context.action;

		const value = {
			state: this.state,
			action: {
				fetchCities:
					executeOnlyIfAuthenticated(this.fetchCities),
				fetchRegions:
					executeOnlyIfAuthenticated(this.fetchRegions),
				fetchCityZone:
					executeOnlyIfAuthenticated(this.fetchCityZone),
        createRegion:
          executeOnlyIfAuthenticated(this.createRegion),
				createZone:
					executeOnlyIfAuthenticated(this.createZone),
				editRegion: this.editRegion
			},
			regionUpdateInterface: this.regionUpdateInterface
		}

		return <ZonesContext.Provider value={value}>{this.props.children}</ZonesContext.Provider>
	}
}

export { ZonesContext, ZonesProvider as default };
