// @ts-nocheck
import React, { useContext, useEffect } from "react";
import { ProviderMetricsContext } from "../../context/ProviderMetrics";
import ProviderDetails from "./components/ProviderDetails";
import DailyTrips from "./components/DailyTrips";
import CancelledTrips from "./components/CancelledTrips";
import CoveredDistance from "./components/CoveredDistance";
import TripsByDistance from "./components/TripsByDistance";
import OnlineTime from "./components/OnlineTime";
import Filter from "./components/Filter";

import { Container, Col, Row } from "reactstrap";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps<{ providerId: string }> { }

const ProviderMetrics = ({ history, match: { params } }: Props) => {
  const {
    action: {
      getProviderDetails,
      getCoveredDistance
    }
  } = useContext(ProviderMetricsContext);

  useEffect(() => {
    if (!params.providerId) return history.back();
    getProviderDetails(params.providerId)
    getCoveredDistance(params.providerId)
  }, []);

  return (
    <Container>
      <h3 className="mb-4">Métricas do entregador</h3>
      <Row>
        <Col>
          <ProviderDetails />
        </Col>
        <Col>
          <Filter providerId={params.providerId} />
        </Col>
      </Row>
      <Row>
        <OnlineTime />
      </Row>
      <Row>
        <DailyTrips />
      </Row>
      <Row>
        <CancelledTrips />
        <TripsByDistance />
      </Row>
      <Row>
        <CoveredDistance />
      </Row>
    </Container>
  )
};

export default ProviderMetrics;
