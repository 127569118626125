// @ts-nocheck
// @ts-nocheck
import React, { useState, useEffect } from "react";

import { Input } from "reactstrap";

interface SelectOptions {
  label: string;
  value: string;
};

type Props = {
  name: string;
  value: string;
  options: SelectOptions[];
  onChange(value: string, name: string): void;
  disabled?: boolean;
};

const Select = ({
  name,
  disabled = false,
  options,
  value,
  onChange
}: Props) => {

  const [state, setState] = useState<SelectOptions>({
    label: 'Selecione um campo',
    value: ''
  });

  useEffect(() => {
    let intialValue = { label: 'Selecione um campo', value: '' };
    options.find(opt => {
      if (opt.value === value) {
        intialValue = opt
        return true;
      }
      return false
    });
    setState(intialValue);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let result = { label: 'Selecione um campo', value: '' };
    options.find(opt => {
      if (opt.value === value) {
        result = opt;
        return true
      }
      return false
    });
    setState(result);
    onChange(result.value, name);
  }

  return (
    <Input
      disabled={disabled}
      type="select"
      name={name}
      value={state.value}
      onChange={handleChange}
    >
      <option
        value=""
      >
        Selecione um campo
      </option>
      {
        options.map((opt: any, index: number) => (
          <option
            key={index}
            value={opt.value}
          >
            {opt.label}
          </option>
        ))
      }
    </Input>
  );
};

export default Select;