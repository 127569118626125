// @ts-nocheck
import React from "react";
import { cx } from "../../utils/classnames";
//@ts-ignore
// import debounce from "lodash.debounce";
import style from "./styles.module.css";

const debounce = (fn: Function, delay = 400) => {
  let timer: number;
  return function (this: {}) {
    const context = this
    const args = arguments
    clearTimeout(timer);
    setTimeout(() => fn.apply(context, args), delay)
  }
}

export interface CheckboxOptions {
  label: string;
  value: string;
  id: string;
}

interface Props {
  onChange(values: string[], name: string): void;
  options: CheckboxOptions[];
  values: string[];
  name: string;
  disabled?: boolean;
  styleContainer?: any;
}

interface Values extends CheckboxOptions {
  isChecked: boolean;
}

interface State {
  values: Values[];
}

export class MultipleCheckbox extends React.PureComponent<Props, State> {
  updateParentState: any = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      values: this.props.options.map(opt => ({
        ...opt,
        isChecked: this.props.values.includes(opt.value)
      }))
    } as State;

    this.updateParentState = debounce(this._updateParentState, 300);
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState(
      prevState => ({
        values: prevState.values.map(opt => {
          if (value === opt.value) opt.isChecked = !opt.isChecked;
          return opt;
        })
      }),
      this.updateParentState
    );
  };

  _updateParentState = () => {
    const values = this.state.values.filter(v => v.isChecked).map(v => v.value);
    this.props.onChange(values, this.props.name);
  };

  render() {
    return (
      <ul className={style.container} style={!!this.props.styleContainer ? this.props.styleContainer : {}}>
        {this.state.values.map((option, index) => (
          <li className={style.radio_button} key={index}>
            <label className={cx({ [style.checked]: option.isChecked })}>
              <input
                // hidden
                name="name"
                type="checkbox"
                value={option.value}
                onChange={this.handleChange}
                checked={option.isChecked}
                disabled={!!this.props.disabled ? this.props.disabled : false}
              />
              {option.label}
            </label>
          </li>
        ))}
      </ul>
    );
  }
}
