// @ts-nocheck
import React, { useContext, useState, useEffect } from "react";
import { TripsContext } from "../../context/Trips";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";
import TripListItem from "./components/TripListItem";
import Filter from "./components/Filter";

import { Table, Card, CardBody, Container, Col } from "reactstrap";

export const headers = [
  "ID",
  "Cidade",
  "Loja",
  "",
  "Entregador",
  "Data",
  // "DT Fim",
  "R$ Total",
  "R$ Repasse",
  // "Tipo",
  "Status",
  ""
];

const Trips = () => {
  const [filter, setFilter] = useState({});

  const {
    state: { trips, isTripsLoaded, tripsPagination },
    action: { getTrips }
  } = useContext(TripsContext);

  const onChangeFilter = (filter: any) => setFilter(filter);

  const onChangePage = (pageNumber: number) => getTrips(filter, pageNumber);

  useEffect(() => {
    getTrips(filter);
  }, []);

  return (
    <>
    <Container><h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333"}}>ENTREGAS</h3></Container>
    <Container>
      <Filter updateParentFilter={onChangeFilter} monitoring={false} />
      <Card>
        <CardBody>
          {isTripsLoaded ? (
            <Table responsive hover striped className="table--bordered mb-4">
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {trips.map((trip, index) => (
                  <TripListItem key={index} trip={trip} />
                ))}
              </tbody>
            </Table>
          ) : (
            <Loader />
          )}
          <Pagination
            onChangePage={onChangePage}
            current={tripsPagination.current_page}
            total={tripsPagination.pages === 0 ? 1 : tripsPagination.pages}
            initial={1}
            size={30}
          />
        </CardBody>
      </Card>
    </Container>
    </>
  );
};

export default Trips;
