export const getPaymentMode = (id: number | string) => {
	const types: any = {
		0: 'Dinheiro',
		1: 'Crédito',
		2: 'Empresa',
		3: 'Token',
		default: 'N/A',
	};
	return types[id] || types.default;
};

export const getPaymentModeColors = (id: number | string) => {
	const types: any = {
		0: 'success',
		1: 'primary',
		2: 'info',
		3: 'secondary',
		default: 'secondary',
	};
	return types[id] || types.default;
};

export const getTripStatus = (id: number) => {
	const types: any = {
		0: 'Solicitado',
		1: 'Aceita',
		2: 'A camiho da loja',
		3: 'Cancelada',
		4: 'Chegou na loja',
		6: 'Entregando',
		8: 'Retornando a loja',
		9: 'Concluída',
		default: 'N/A',
	};
	return types[id] || types.defalt;
};

export const getTripStatusColors = (id: number) => {
	const types: any = {
		0: 'secondary',
		1: 'warning',
		2: 'warning',
		4: 'primary',
		6: 'success',
		8: 'success',
		9: 'success',
		default: 'N/A',
	};
	return types[id] || types.defalt;
};

export const getTripPaymentMode = (id: string) => {
	const type: any = {
		card: 'Pagamento online',
		cash: 'Dinheiro',
		face_credit: 'Cartão de crédito',
		face_debit: 'Cartão de débito',
		default: 'N/A',
	};
	return type[id] || type.default;
};

export const getTripConfirmationType = (id: string) => {
	const type: any = {
		none: 'Nenhum',
		signature: 'Sinatura',
		qrcode: 'QR Code',
		default: 'N/A',
	};
	return type[id] || type.default;
};

export const getPricingPolicy = (id: string) => {
	const type: any = {
		fixed: 'Fixa',
		variable: 'Variável',
		range: 'Por margem de distância',
		default: 'Inexistente',
	};
	return type[id] || type.default;
};

export const getVehicleType = (id: string) => {
	const type: any = {
		bike: 'Bike',
		moto: 'Moto',
		car: 'Carro',
		truck: 'Caminhão',
		default: '',
	};
	return type[id] || type.default;
};

export const getProvidersType = (id: string) => {
	const type: any = {
		approved: [{ name: 'is_approved', value: 1 }, { name: 'is_disabled', value: 0 }],
		not_approved: [{ name: 'is_approved', value: 0 }],
		disabled: [{ name: 'is_disabled', value: 1 }],
		default: [],
	};
	return type[id] || type.default;
};

export const getScheduleStatus = (id: number) => {
	const type: any = {
		0: 'Aguardando',
		1: 'Confirmado',
		2: 'Concluido',
		3: 'Cancelado',
	};
	return type[id] || type.default;
};

export const getAdminType = (id: number) => {
	const type: any = {
		0: 'Administrador',
		1: 'Sub Adminstrador',
		2: 'Franquia',
		3: 'Governo',
	};
	return type[id];
};

export const getPermissionsLabel = (id: string) => {
	const type: any = {
		dashboard: 'Dashboard',
		results: 'Resultados',
		trips: 'Corridas',
		providers: 'Entregadores',
		schedules: 'Agendamentos',
		companies: 'Empresas',
		cities: 'Cidades',
		financial: 'Financeiro',
		settings: 'Configurações',
	};
	return type[id] || id;
};
