// @ts-nocheck
import React from "react";
import { AuthContext } from "./Auth";

interface ProviderMetricsContext {
  state: State;
  action: {
    getProviderDetails(providerId: string): void;
    getOnlineTime(providerId: string, data: any): void;
    getDailyTrips(providerId: string, data: any): void;
    getCancelledtrips(providerId: string, data: any): void;
    getTripsByDistance(providerId: string, data: any): void;
    getCoveredDistance(providerId: string): void;
  }
}

export const ProviderMetricsContext = React.createContext({} as ProviderMetricsContext);

interface State {
  details: any;
  isDetailsLoaded: boolean;
  onlineTime: any;
  isOnlineTimeLoaded: boolean;
  dailyTrips: any;
  isDailyTripsLoaded: boolean;
  cancelledTrips: any;
  isCancelledTripsLoaded: boolean;
  tripsDistance: any,
  isTripsDistanceLoaded: boolean;
  coveredDistance: any,
  isCoveredDistanceLoaded: boolean;
}

class ProviderMetricsProvider extends React.PureComponent<{}, State> {

  static contextType = AuthContext;

  constructor(props: any) {
    super(props);

    this.state = {
      details: {},
      isDetailsLoaded: false,
      onlineTime: {},
      isOnlineTimeLoaded: false,
      dailyTrips: {},
      isDailyTripsLoaded: false,
      cancelledTrips: {},
      isCancelledTripsLoaded: false,
      tripsDistance: {},
      isTripsDistanceLoaded: false,
      coveredDistance: {},
      isCoveredDistanceLoaded: false,
    }
  }

  getProviderDetails = async (providerId: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/dashboard/base-info`;

    this.setState({ isDetailsLoaded: false }, async () => {
      try {
        const response = await request("GET", url);
        if (response.data.success) {
          // console.log(response.data)
          const { data } = response.data;
          const picture = data.picture.startsWith('provider_profile')
            ? `${this.context.host}storage/${data.picture}`
            : data.picture
          this.setState({
            details: { ...data, picture },
            isDetailsLoaded: true
          })
        }
      } catch (error) {
        // console.log(error)
        this.setState({ details: {}, isDetailsLoaded: true });
      }
    })
  }

  getOnlineTime = async (providerId: string, data: any) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/dashboard/online-time`;

    this.setState({
      isOnlineTimeLoaded: false
    }, async () => {
      try {
        const response = await request("POST", url, data);
        // console.log(response.data)
        if (response.data.success)
          this.setState({
            onlineTime: response.data.data,
            isOnlineTimeLoaded: true
          })
      } catch (error) {
        this.setState({
          onlineTime: { dailyOnlineTime: [], date: "" },
          isOnlineTimeLoaded: true
        })
        // console.log(error)
      }
    })
  };

  getDailyTrips = async (providerId: string, data: any) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/dashboard/daily-trips`

    this.setState({
      isDailyTripsLoaded: false
    }, async () => {
      try {
        const response = await request("POST", url, data);
        // console.log(response.data)
        if (response.data.success)
          this.setState({
            dailyTrips: response.data.data,
            isDailyTripsLoaded: true,
          })
      } catch (error) {
        this.setState({
          dailyTrips: { dailyTrips: [], date: "" },
          isDailyTripsLoaded: true
        })
        // console.log(error)
      }
    })
  };

  getCancelledtrips = async (providerId: string, data: any) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/dashboard/cancelled-trips`;

    this.setState({
      isCancelledTripsLoaded: false
    }, async () => {
      try {
        const response = await request("POST", url, data)
        // console.log(response.data)
        if (response.data.success) {
          const cancelledTrips = !!response.data.data
            ? response.data.data
            : { totalCancellations: 0, cancelledByUser: 0, cancelledByProvider: 0 };
          this.setState({
            cancelledTrips,
            isCancelledTripsLoaded: true
          })
        }
      } catch (error) {
        this.setState({
          cancelledTrips: { cancelledTrips: {}, totalCancellations: "" },
          isCancelledTripsLoaded: true
        })
        // console.log(error)
      }
    })
  };

  getTripsByDistance = async (providerId: string, data: any) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/dashboard/trips-by-distance`;

    this.setState({
      isTripsDistanceLoaded: false
    }, async () => {
      try {
        const response = await request("POST", url, data)
        // console.log(response.data)
        if (response.data.success)
          this.setState({
            tripsDistance: response.data.data,
            isTripsDistanceLoaded: true
          })
      } catch (error) {
        this.setState({
          tripsDistance: { tripsDistance: {}, totalDistance: "" },
          isTripsDistanceLoaded: true
        })
        // console.log(error)
      }
    })
  };

  getCoveredDistance = async (providerId: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/dashboard/covered-distance`;
    this.setState({
      isCoveredDistanceLoaded: false
    }, async () => {
      try {
        const response = await request("POST", url)
        // console.log(response.data)
        if (response.data.success)
          this.setState({
            coveredDistance: response.data.data,
            isCoveredDistanceLoaded: true
          })
      } catch (error) {
        this.setState({
          coveredDistance: { dailyDistances: [], monthlyDistance: "" },
          isCoveredDistanceLoaded: true
        })
        // console.log(error)
      }
    })
  };

  render() {
    const { executeOnlyIfAuthenticated } = this.context.action;

    const value = {
      state: { ...this.state },
      action: {
        getProviderDetails: executeOnlyIfAuthenticated(this.getProviderDetails),
        getOnlineTime: executeOnlyIfAuthenticated(this.getOnlineTime),
        getDailyTrips: executeOnlyIfAuthenticated(this.getDailyTrips),
        getCancelledtrips: executeOnlyIfAuthenticated(this.getCancelledtrips),
        getTripsByDistance: executeOnlyIfAuthenticated(this.getTripsByDistance),
        getCoveredDistance: executeOnlyIfAuthenticated(this.getCoveredDistance)
      }
    }

    return (
      <ProviderMetricsContext.Provider value={value}>
        {this.props.children}
      </ProviderMetricsContext.Provider>
    );
  }
}

export default ProviderMetricsProvider;