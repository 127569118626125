// @ts-nocheck
import React, { useState, useCallback, useEffect }from 'react'
import produce from "immer";

export interface FormState {
    [key: string]: any;
    country: string;
    city: string;
    cityRadius: string;
    latitude: string;
    longitude: string;
    city_cod: string;
    zipcode: string;
    isCheckProviderWallet: boolean;
    minWallet: number;
    isAskUserForFixedFare: boolean;
    airportBusiness: boolean;
    isCityBusiness: boolean;
    zoneBusiness: boolean;
    plans: Array<string>;
    timezone: string;
    isBusiness: boolean;
    isPaymentModeCash: boolean;
    isPaymentModeCard: boolean;
    isPromoApplyForCash: boolean;
    isPromoApplyForCard: boolean;
    isProviderEarningSetInWalletOnCashPayment: boolean;
    isProviderEarningSetInWalletOnOtherPayment: boolean;
  }

  const InitialFormState: FormState = {
      country: "Brasil",
      city: "",
      cityRadius: "",
      latitude: "",
      longitude: "",
      city_cod: "",
      zipcode: "",
      isCheckProviderWallet: false,
      minWallet: 0,
      isAskUserForFixedFare: false,
      airportBusiness: false,
      isCityBusiness: false,
      zoneBusiness: false,
      plans: [],
      timezone: "",
      isBusiness: false,
      isPaymentModeCash: false,
      isPaymentModeCard: false,
      isPromoApplyForCash: false,
      isPromoApplyForCard: false,
      isProviderEarningSetInWalletOnCashPayment: false,
      isProviderEarningSetInWalletOnOtherPayment: false
  }

export const useCityForm = () => {
    const [state, setState] = useState<FormState>(InitialFormState);

    useEffect(() => {
        console.log(state)
        
    }, [state])

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          const { value, name } = e.target;

          setState(prevState => ({
            ...prevState,
            [name]: value
          }));
        },
        []
      );

      const onSelect = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          const { value, name } = e.target;

          setState(
              produce( draft => {
                  if (name === "isCheckProviderWallet"){
                      (value === "Sim") ? 
                      draft.isCheckProviderWallet = true : draft.isCheckProviderWallet = false
                } else if (name === "isAskUserForFixedFare"){
                    (value === "Sim") ? 
                    draft.isAskUserForFixedFare = true : draft.isAskUserForFixedFare = false
                } else if (name === "airportBusiness"){
                    (value === "Sim") ? 
                    draft.airportBusiness = true : draft.airportBusiness = false
                } else if (name === "isCityBusiness"){
                    (value === "Sim") ? 
                    draft.isCityBusiness = true : draft.isCityBusiness = false
                } else if (name === "zoneBusiness"){
                    (value === "Sim") ? 
                    draft.zoneBusiness = true : draft.zoneBusiness = false
                } else if (name === "isBusiness"){
                    (value === "Sim") ? 
                    draft.isBusiness = true : draft.isBusiness = false
                } else if (name === "isPaymentModeCash"){
                    (value === "Sim") ? 
                    draft.isPaymentModeCash = true : draft.isPaymentModeCash = false
                } else if (name === "isPaymentModeCard"){
                    (value === "Sim") ? 
                    draft.isPaymentModeCard = true : draft.isPaymentModeCard = false
                } else if (name === "isPromoApplyForCash"){
                    (value === "Sim") ? 
                    draft.isPromoApplyForCash = true : draft.isPromoApplyForCash = false
                } else if (name === "isPromoApplyForCard"){
                    (value === "Sim") ? 
                    draft.isPromoApplyForCard = true : draft.isPromoApplyForCard = false
                } else if (name === "isProviderEarningSetInWalletOnCashPayment"){
                    (value === "Sim") ? 
                    draft.isProviderEarningSetInWalletOnCashPayment = true : draft.isProviderEarningSetInWalletOnCashPayment = false
                } else if (name === "isProviderEarningSetInWalletOnOtherPayment"){
                    (value === "Sim") ? 
                    draft.isProviderEarningSetInWalletOnOtherPayment = true : draft.isProviderEarningSetInWalletOnOtherPayment = false
                }
              })
          )
        },
        []
      );

      const onMultiSelect = (select: Array<string>) => {
          setState(prevState => ({
              ...prevState,
              plans: select
          }))

      }

      const setLatAndLng = (coordinates: any, address: string) => {
        console.log(coordinates)

        setState(
            produce( draft => {
                draft.latitude = coordinates.lat
                draft.longitude = coordinates.lng
                draft.city = address
            })
        )

      }

      return {
        state,
        onChange,
        onSelect,
        onMultiSelect, 
        setLatAndLng}
    }       