// @ts-nocheck
import React, { useEffect, useContext, useState, useCallback } from "react";
import { FinanceContext } from "../../../context/Finance";
import { formatNumber } from "../../../utils/masks";
import CurrencyInput from 'react-currency-input';

import Panel from '../../../components/Panel';
import {ResponsiveContainer} from 'recharts';
import { Container, Card, CardBody, Table, Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label, Badge, ButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu, CardHeader, ButtonGroup } from "reactstrap";
import { FaRegEdit, FaTimesCircle, FaFilter, FaCheckCircle, FaCircle, FaRegCircle, FaSync, FaWhatsapp, FaLink } from "react-icons/fa";
import moment from "moment";
import { toast } from "react-toastify";

const Invoices = ({}) => {
  const local_user = JSON.parse(localStorage.getItem('user'));
  const {
    state: { categories, cities, registers, date_start, date_end, loadingRegisters },
    action: { getCategories, getCities, getRegisters, updateRegister  }
  } = useContext(FinanceContext);

  useEffect(() => {
    getCategories();
    getCities();
  }, []);

  useEffect(() => {
    loadFilters();

    if(cities.length && categories.length){
      filterRegisters();
    }
  }, [cities, categories]);

  const [fields, setField] = useState({
    type:'revenue',
	city: local_user.franchises[0],
	done: false,
	filter_date: false
  });

  const [filters, setFilters] = useState({
    types:[
      {label: "Receita", active: true, id: "revenue"},
      {label: "Despesa", active: true, id: "expense"},
    ],
    cities: [],
    categories:[],
    isOpen: false
  });

  const [registersFiltered, setRegistersFiltered] = useState([]);

  useEffect(() => {
    if(categories.length && cities.length){
      setRegistersFiltered(apllyViewFilters(registers).sort((a,b) => b.value - a.value));
    }

  }, [registers]);

  useEffect(() => {
    console.log('useEffect filters');
    if(categories.length && cities.length){
      setRegistersFiltered(apllyViewFilters(registers));
    }
  }, [filters]);

  const updateRegisterInRow = useCallback(async (fields) => {
    await updateRegister(fields);
    filterRegisters();
    toast.success("Registro atualizado!")
  });

  const text_type_category = (type) => {
    switch (type) {
      case 'revenue': return "Receita";
      case 'expense': return "Despesa";
      case 'other': return "Outro";
    
      default:
        break;
    }
  }
  
  const loadFilters = async () => {
    let fcities = [];
    fcities.push({label: "Geral", active: true, id: null})
    cities.map(c => { fcities.push({label: `${c.citycode} - ${c.cityname}`, active: true, id: c._id})})
    setFilters(prev => ({...prev, cities: fcities}));

    let fcategories = [];
    categories.map(c => { fcategories.push({label: `${text_type_category(c.type)} - ${c.name}`, active: true, id: c._id})})
    setFilters(prev => ({...prev, categories: fcategories}));

  }

  const toggleFilter = (filter, i) => {
    let newFilters = filters;
    newFilters[filter][i].active = !newFilters[filter][i].active;
    setFilters(prev => ({...prev, newFilters}));
  }

  const toggleFilters = () => {
    setFilters(prev => ({...prev, isOpen: !prev.isOpen}));
  }

  const apllyViewFilters = (registers) => {
    // console.log('Cities', cities);
    // console.log('Categories', categories);
    // console.log('Registers', registers);
    if(registers)
      return registers
        .filter(r => r.type == 'revenue')
        .filter(r =>  r.category == "6180bf8626cae5104dd2c8c4")
        .filter(r => filters.cities.find(f => f.active && f.id == r.city ));
    return registers;
  }

  const [dropdown, setDropdown] = useState({
    isOpen: false
  });

  const dropdownToggle = () => {
    setDropdown(prev => ({...prev, isOpen: !prev.isOpen}));
  }

  const handleDateChange = useCallback((dates) => {
    setField(prev => ({...prev, date_start: dates[0], date_end: dates[1]}));
  },[]);

  const filterRegisters = () => {
    getRegisters(fields);
  }

  return (
    <>
    <Panel md={12} title="Faturas" subhead={"Lista de faturas em aberto"}>
		<ResponsiveContainer height={500}>
			<div style={{height:500, overflowY:'auto'}}>
				<Table striped>
					<thead>
						<tr style={{fontSize:11}}>
							<th>EMPRESA</th>
							<th style={{textAlign:"left"}}>CONTATO</th>
							<th style={{textAlign:"right"}}>VALOR</th>
							<th>STATUS</th>
							<th>DATA</th>
							<th>&bull;&bull;&bull;</th>
						</tr>
					</thead>
					<tbody>
						{registersFiltered.length > 0 ? registersFiltered.map((r,i) => (
						<tr>
							<td>{r.fromCompany.length ? r.fromCompany[0].name.toUpperCase() :null}</td>
							<td style={{whiteSpace: "nowrap"}}>{r.fromCompany[0].financial_phone} <FaWhatsapp onClick={() => window.open(`https://wa.me/${r.fromCompany[0].financial_phone}`,"_blank")}/></td>
							<td style={{whiteSpace: "nowrap", textAlign:"right", color: (r.type == 'revenue' ? '#0da076' : '#db2828')}}>R$ {formatNumber(r.value)}</td>
							<td style={{whiteSpace: "nowrap"}}>
								{r.done ? <FaCheckCircle style={{color:"#11cf99", margin:4}} />: <FaCircle style={{color:"#CCC", margin:4}}/>}
								{r.done ? `Pago` : `Pendente`}
							</td>
							<td style={{whiteSpace: "nowrap"}}>{r.done ? moment(r.payday).format("DD/MM/Y") : moment(r.due_date).format("DD/MM/Y")}</td>
							<td><FaLink onClick={() => window.open(`https://delivery.vaay.app/invoice/${r._id}`,"_blank")}/></td>
						</tr>
						)) : ''}
					</tbody>
				</Table>
			</div>
		</ResponsiveContainer>
	</Panel>
    </>
  );
};

export default Invoices;

const ModalAdd = (props) => {
  let { type, name, master, refreshRegisters} = props;
  
  const {
    state: {categories, cities},
    action: { addRegister }
  } = useContext(FinanceContext);

  const [ modal, setModal, ] = useState(false);
  const toggle = () => setModal(!modal);
  
  const [fields, setField] = useState({
    type: type,
    category: null, 
    city: null, 
    description: '', 
    value: 0, 
    due_date: null, 
    payday: moment().format("YYYY-MM-DD"), 
    done: false,
  });
  const onChange = useCallback(e => {
    const { value, name } = e.target;
    setField(prev => ({ ...prev, [name]: value }));
  }, []);
  const onChangeCheck = useCallback(e => {
    const {name, checked } = e.target;
    setField(prev => ({ ...prev, [name]: checked }));
  }, []);
  const onChangeCurrency = useCallback((event, maskedvalue, floatvalue) => {
    setField(prev => ({ ...prev, value: floatvalue }));
  }, []);

	const add = async () => {
    console.log(`fields`, fields);
    if(!fields.category){ toast.warn("Selecione uma categoria!"); return}
    if(!fields.value){ toast.warn("Informe o valor!"); return}
    if(!fields.due_date){ toast.warn("Informe a data!"); return}
    if(fields.done && !fields.payday){ toast.warn("Informe a data de pagamento, ou desmarque a opção 'Realizado'!"); return}

    if(type == `expense`) fields.value = fields.value*-1;
    fields.due_date = moment(fields.due_date).format('y-MM-DD');
    await addRegister(fields);
    toast.success(text_title(type) + " registrada!")
    refreshRegisters();
	}

  const text_title = (type) => {
    switch (type) {
      case 'revenue': return "Receita";
      case 'expense': return "Despesa";
      case 'other': return "Outro";
    
      default:
        break;
    }
  }

  const color_type = (type) => {
    switch (type) {
      case 'revenue': return "primary";
      case 'expense': return "danger";
      default:
        break;
    }
  }

	return (
	  <>
    <Button color={color_type(type)} onClick={toggle}>{text_title(type)}</Button>
		<Modal isOpen={modal} toggle={toggle} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
		  <ModalHeader toggle={toggle}>Registrar {text_title(type)}</ModalHeader>
		  <ModalBody size="lg">
			  <Form>
          <FormGroup style={{textAlign:"left"}}>
            <Input id="category" name="category" type="select" onChange={onChange}>
              <option value={null}>Categoria:</option>
              {categories.filter(c => c.master == null).map( c => ( c.type == type || c.type == 'other' ? <> 
                <option value={c._id}>{c.name}</option> 
                {categories.filter(cc => cc.master == c._id).map(ccc => (<option value={ccc._id}>--- {ccc.name}</option> ))}
              </>: ''))}
            </Input>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Input id="city" name="city" type="select" onChange={onChange}>
              <option value={null}>Cidade:</option>
              {cities.map( c => ( <option value={c._id}>{c.citycode} - {c.cityname} </option> ))}
            </Input>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Descrição</Label>
            <Input id="description" name="description" placeholder={`Descreva a ` + text_title(type)} type="textarea" onChange={onChange}/>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Valor</Label>
            <CurrencyInput className="form-control" id="value" name="value" type="text" decimalSeparator="," thousandSeparator="." value={fields.value} onChangeEvent={onChangeCurrency}/>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Data vencimento</Label>
            <Input id="due_date" name="due_date" placeholder="dd/mm/yyyy" type="date" onChange={onChange}/>
          </FormGroup>
          <FormGroup style={{textAlign:"left", marginBottom:15}} check>
            <Label check>
              <Input id="done" name="done" type="checkbox" onChange={onChangeCheck} style={{marginTop:0}}/>&nbsp;&nbsp;&nbsp;
              Realizado
            </Label>
          </FormGroup>
          { fields.done ? 
            <FormGroup style={{textAlign:"left"}}>
              <Label>Data pagamento</Label>
              <Input id="payday" name="payday" value={fields.payday} placeholder="dd/mm/yyyy" type="date" onChange={onChange}/>
            </FormGroup>
          : null}
        </Form>
		  </ModalBody>
		  <ModalFooter>
        <Button color={color_type(type)} onClick={() => { add(); toggle()}}>Registrar</Button>
		  </ModalFooter>
		</Modal>
	  </>
	);
}

const ModalWeeklyClose = (props) => {
  
  const {
    action: { WeeklyClose }
  } = useContext(FinanceContext);

  const [ modal, setModal, ] = useState(false);
  const toggle = () => setModal(!modal);
  
  const [fields, setField] = useState({
    start_date: moment().startOf('ISOWeek').format("YYYY-MM-DD"), 
    end_date: moment().endOf('ISOWeek').format("YYYY-MM-DD"),
  });
  const onChange = useCallback(e => {
    const { value, name } = e.target;
    setField(prev => ({ ...prev, [name]: value }));
  }, []);

	const registerWeeklyClose = async () => {
    console.log(`fields`, fields);

    let payload = {};
    payload.date = {};
    payload.date.start = fields.start_date;
    payload.date.end = fields.end_date;
    payload.action = 'insert';
    
    payload.from = 'company';
    // console.log(`payload`, payload);
    await WeeklyClose(payload);

    // payload.from = 'provider';
    // await WeeklyClose(payload);
    toast.success("Registros realizado!")
	}

	return (
	  <>
    <Button color="dark" onClick={toggle}>Fechamento</Button>
		<Modal isOpen={modal} toggle={toggle} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
		  <ModalHeader toggle={toggle}>Fechamento</ModalHeader>
		  <ModalBody size="lg">
			  <Form>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Data Inicio</Label>
            <Input id="start_date" name="start_date" placeholder="dd/mm/yyyy" type="date" value={fields.start_date} onChange={onChange}/>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Data Fim</Label>
            <Input id="end_date" name="end_date" placeholder="dd/mm/yyyy" type="date" value={fields.end_date} onChange={onChange}/>
          </FormGroup>
        </Form>
		  </ModalBody>
		  <ModalFooter>
        <Button color="primary" onClick={() => { registerWeeklyClose(); toggle()}}>Concluir</Button>
		  </ModalFooter>
		</Modal>
	  </>
	);
}

const ModalUpdate = (props) => {
  let { type, category, city, description, value, due_date, done, payday, id, attachment_value, refreshRegisters} = props;
  
  const {
    state: {categories, cities},
    action: { updateRegister }
  } = useContext(FinanceContext);

  const [ modal, setModal, ] = useState(false);
  const toggle = () => setModal(!modal);
  
  const [fields, setField] = useState({
    id: id,
    type: type,
    category: category, 
    city: city, 
    description: description, 
    attachment_value: attachment_value, 
    value: value, 
    due_date: due_date, 
    payday: payday, 
    done: done,
  });
  const onChange = useCallback(e => {
    const { value, name } = e.target;
    setField(prev => ({ ...prev, [name]: value }));
  }, []);
  const onChangeCheck = useCallback(e => {
    const {name, checked } = e.target;
    setField(prev => ({ ...prev, [name]: checked }));
  }, []);
  const onChangeCurrency = useCallback((event, maskedvalue, floatvalue) => {
    setField(prev => ({ ...prev, value: floatvalue }));
  }, []);


	const submit = async () => {
    console.log(`fields`, fields);
    if(!fields.category){ toast.warn("Selecione uma categoria!"); return}
    if(!fields.value){ toast.warn("Informe o valor!"); return}
    if(!fields.due_date){ toast.warn("Informe a data de vencimento!"); return}
    if(fields.done && !fields.payday){ toast.warn("Informe a data de pagamento, ou desmarque a opção 'Realizado'!"); return}

    await updateRegister(fields);
    toast.success(text_title(type) + " atualizada!");
    refreshRegisters();
	}

  const text_title = (type) => {
    switch (type) {
      case 'revenue': return "Receita";
      case 'expense': return "Despesa";
      case 'other': return "Outro";
    
      default:
        break;
    }
  }

  const color_type = (type) => {
    switch (type) {
      case 'revenue': return "primary";
      case 'expense': return "danger";
      default:
        break;
    }
  }

	return (
	  <>
		<FaRegEdit style={{cursor: 'pointer', margin:4}} onClick={toggle}/>
		<Modal isOpen={modal} toggle={toggle} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
		  <ModalHeader toggle={toggle}>Editar {text_title(type)}</ModalHeader>
		  <ModalBody size="lg">
			  <Form>
          <FormGroup style={{textAlign:"left"}}>
            <Input id="category" name="category" value={fields.category} type="select" onChange={onChange}>
              <option value={null}>Categoria:</option>
              {categories.filter(c => c.master == null).map( c => ( c.type == type || c.type == 'other' ? <> 
                <option value={c._id}>{c.name}</option> 
                {categories.filter(cc => cc.master == c._id).map(ccc => (<option value={ccc._id}>--- {ccc.name}</option> ))}
              </>: ''))}
            </Input>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Input id="city" name="city" value={fields.city} type="select" onChange={onChange}>
              <option value={null}>Cidade:</option>
              {cities.map( c => ( <option value={c._id}>{c.citycode} - {c.cityname} </option> ))}
            </Input>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Descrição</Label>
            <Input id="description" name="description" value={fields.description} placeholder={`Descreva a ` + text_title(type)} type="textarea" onChange={onChange}/>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Valor</Label>
            <CurrencyInput className="form-control" id="value" name="value" type="text" decimalSeparator="," thousandSeparator="." value={fields.value} onChangeEvent={onChangeCurrency}/>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Data vencimento</Label>
            <Input id="due_date" name="due_date" value={fields.due_date.substr(0,10)} placeholder="dd/mm/yyyy" type="date" onChange={onChange}/>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Anexo (Texto)</Label>
            <Input id="attachment_value" name="attachment_value" value={fields.attachment_value} placeholder={`Adicione o anexo texto da ` + text_title(type)} type="textarea" onChange={onChange}/>
          </FormGroup>
          <FormGroup style={{textAlign:"left", marginBottom:15}} check>
            <Label check>
              <Input id="done" name="done" checked={fields.done} type="checkbox" onChange={onChangeCheck} style={{marginTop:0}}/>&nbsp;&nbsp;&nbsp;
              Realizado
            </Label>
          </FormGroup>
          { fields.done ? 
            <FormGroup style={{textAlign:"left"}}>
              <Label>Data pagamento</Label>
              <Input id="payday" name="payday" value={fields.payday ? fields.payday.substr(0,10) : null} placeholder="dd/mm/yyyy" type="date" onChange={onChange}/>
            </FormGroup>
          : null}
        </Form>
		  </ModalBody>
		  <ModalFooter>
        <Button color={color_type(type)} onClick={() => { submit(); toggle()}}>Atualizar</Button>
		  </ModalFooter>
		</Modal>
	  </>
	);
}

const ModalRemove = (props) => {
  let { type, category, city, description, value, due_date, done, payday, id, refreshRegisters} = props;
  
  const {
    state: {categories, cities},
    action: { removeRegister }
  } = useContext(FinanceContext);

  const [ modal, setModal, ] = useState(false);
  const toggle = () => setModal(!modal);
  
  const [fields, setField] = useState({
    id: id,
    type: type,
    category: category, 
    city: city, 
    description: description, 
    value: value, 
    due_date: due_date, 
    payday: payday, 
    done: done,
  });

	const submit = async () => {
    await removeRegister(fields);
    toast.success(text_title(type) + " removida!");
    refreshRegisters();
	}

  const text_title = (type) => {
    switch (type) {
      case 'revenue': return "Receita";
      case 'expense': return "Despesa";
      case 'other': return "Outro";
    
      default:
        break;
    }
  }

	return (
	  <>
		<FaTimesCircle style={{cursor: 'pointer', margin:4}} onClick={toggle}/>
		<Modal isOpen={modal} toggle={toggle} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
		  <ModalHeader toggle={toggle}>Remover registro</ModalHeader>
		  <ModalBody size="lg">
			  <div>
          Remover registro financeiro de <strong>{text_title(type)}: </strong>{description}, R$ {parseFloat(value).toLocaleString()} ?
        </div>
		  </ModalBody>
		  <ModalFooter>
        <Button color="" onClick={() => { toggle()}}>Cancelar</Button>
        <Button color="dark" onClick={() => { submit(); toggle()}}>Remover</Button>
		  </ModalFooter>
		</Modal>
	  </>
	);
}