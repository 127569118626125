// @ts-nocheck
import React, { useContext } from "react";
import { ProviderMetricsContext } from "../../../context/ProviderMetrics";
import Loader from "../../../components/Loader";
import Panel from "../../../components/Panel";
import { ResponsiveContainer, Pie, PieChart, Cell, Tooltip, Legend } from "recharts";

const CancelledTrips = () => {
  const {
    state: { cancelledTrips, isCancelledTripsLoaded }
  } = useContext(ProviderMetricsContext);

  const transformData = (data: any) => {
    const result: any[] = [];
    Object.entries(data).map((d, index) => {
      if (index > 0) {
        result.push({
          name: d[0] === "cancelledByUser" ? "Pelo usuário" : "Pelo entregador",
          value: d[1]
        });
      }
    });
    return result;
  };

  return (
    <Panel
      xl={6}
      lg={6}
      md={6}
      title="Entregas canceladas"
    >
      {isCancelledTripsLoaded
        ? <React.Fragment>
          <h5>Entragas canceladas: {cancelledTrips.totalCancellations}</h5>
          <ResponsiveContainer height={260}>
            <PieChart>
              <Tooltip />
              <Legend />
              <Pie
                nameKey="name"
                dataKey="value"
                isAnimationActive={false}
                data={transformData(cancelledTrips)}
                cx="50%"
                cy="50%"
                //   innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                label
                labelLine={false}
              >
                <Cell key="value" fill="#8884d8" />
                <Cell key="value" fill="#f8b68b" />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </React.Fragment>
        : <Loader height="260px" />
      }
    </Panel>
  );
};

export default CancelledTrips;
