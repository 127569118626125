// @ts-nocheck
// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Pagination as Page, PaginationItem, PaginationLink } from "reactstrap";

type Props = {
  initial: number;
  total: number;
  current: number;
  size: number;
  onChangePage(i: number): void;
};

const Pagination = ({ onChangePage, current = 1, total = 1, initial = 1, size }: Props) => {

  const [state, setState] = useState({
    disabledLeft: false,
    disabledRight: false,
    startPage: initial,
    endPage: initial,
    pages: [] as number[],
  })

  const isEven = size % 2 === 0;
  const middle = isEven ? size / 2 : size / 2 - 0.5;

  useEffect(() => {
    current === total
      ? setState(prev => ({ ...prev, disabledRight: true }))
      : setState(prev => ({ ...prev, disabledRight: false }))
    current === initial
      ? setState(prev => ({ ...prev, disabledLeft: true }))
      : setState(prev => ({ ...prev, disabledLeft: false }))
    setLinks(current)
  }, [current, total]);

  const setLinks = async (currentPage: number) => {
    let startPage = initial, endPage = initial;
    if (total <= size) {
      startPage = 1;
      endPage = total;
    } else {
      if (currentPage <= (isEven ? middle + 1 : middle)) {
        startPage = 1;
        endPage = size;
      } else if (currentPage + (isEven ? middle - 1 : middle) >= total) {
        startPage = total - (size - 1);
        endPage = total;
      } else {
        startPage = currentPage - middle;
        endPage = currentPage + (isEven ? middle - 1 : middle);
      }
    }
    const pages = Array.from({ length: (endPage + 1) - startPage }, (_, i) => {
      return i + startPage
    })
    await setState(prev => ({ ...prev, startPage, endPage, pages }))
  }


  return (
    <Page aria-label="Page navigation example" style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <PaginationItem disabled={state.disabledLeft}>
        <PaginationLink first onClick={() => onChangePage(1)} />
      </PaginationItem>
      <PaginationItem disabled={state.disabledLeft}>
        <PaginationLink previous onClick={() => onChangePage(current - 1)} />
      </PaginationItem>
      {state.pages.map((page, index) => (
        <PaginationItem key={index} active={page === current}>
          <PaginationLink onClick={() => onChangePage(page)}>
            {page}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={state.disabledRight}>
        <PaginationLink next onClick={() => onChangePage(current + 1)} />
      </PaginationItem>
      <PaginationItem disabled={state.disabledRight}>
        <PaginationLink last onClick={() => onChangePage(total)} />
      </PaginationItem>
    </Page>
  )
}

export default Pagination;