// @ts-nocheck
// @ts-nocheck
import React, { useEffect, useRef } from "react";
import { useToggle } from "../../hooks/hooks";
import { cx } from "../../utils/classnames";
import styles from "./styles.module.css";

import { Modal, Button } from "semantic-ui-react";
//@ts-ignore
import debounce from "lodash.debounce";

type Props = {
  trigger(toggle: () => void): React.ReactNode;
  children: React.ReactChild;
  title?: string;
  size?: "small" | "mini" | "tiny" | "large" | "fullscreen";
  footer?: boolean;
  showPrimaryButton?: boolean;
  showSecondaryButton?: boolean;
  primaryButtonName?: string;
  secondaryButtonName?: string;
  handleOnClick?: (...params: any) => void;
  handleOnClose?: () => void;
};

export const CustomModal = ({
  trigger,
  children,
  title,
  size,
  footer = true,
  showPrimaryButton = true,
  showSecondaryButton = true,
  primaryButtonName = "Salvar",
  secondaryButtonName = "Cancelar",
  handleOnClick,
  handleOnClose
}: Props) => {
  const buttonRef = useRef(null);

  const { toggle, active } = useToggle(false);

  useEffect(() => {
    if (!active && !!handleOnClose) handleOnClose();
  }, [active]);

  useEffect(() => {
    const handleOnKeyDown = debounce((e: React.KeyboardEvent) => {
      if (e.which === 13 && showPrimaryButton && buttonRef.current) {
        e.preventDefault();
        //@ts-ignore
        buttonRef.current.handleClick();
      }
    }, 600)
    window.addEventListener("keydown", e => handleOnKeyDown(e));
  }, []);

  const onClick = () => {
    toggle();
    handleOnClick && handleOnClick();
  };

  return (
    <React.Fragment>
      {trigger(toggle)}
      {/* @ts-ignore */}
      <Modal
        open={active}
        onClose={toggle}
        size={size}
        centered={true}
        className={styles.container}
        style={size !== "fullscreen" ? { height: "auto" } : { height: "95%" }}
      >
        {!!title && <Modal.Header>{title}</Modal.Header>}
        <Modal.Content
          className={cx({ [styles.contentFullscreen]: size === "fullscreen" })}
        >
          {children}
        </Modal.Content>
        {!!footer && (          
          <Modal.Actions
            className={cx({
              [styles.actionsFullscreen]: size === "fullscreen"
            })}
          >
            {showSecondaryButton && (
              <Button onClick={toggle}>{secondaryButtonName}</Button>
            )}
            {showPrimaryButton && (
              <Button ref={buttonRef} color="blue" onClick={onClick}>
                {primaryButtonName}
              </Button>
            )}
          </Modal.Actions>
        )}
      </Modal>
    </React.Fragment>
  );
};

//setar default props
CustomModal.defaultProps = {
  footer: true,
  size: "small"
};
