// import { PureComponent } from 'react';
import mapSubject from '../../Regions/subjects/MapSubject';
// import ActionQueue from '../ActionQueue';
import { toast } from 'react-toastify';

const getKmlZone = p =>
	p
		.getPath()
		.getArray()
		.map(path => [path.lat(), path.lng(), 0]);

class MapObserver {
	/**
	 * @class MapObserver - Acts like a controller to work with the map
	 * @param  {type} builder A Builder object that helps to decide what should enter on the class
	 */
	constructor(builder) {
		// These props are grabbed from the QueueZones after map init
		this.map = builder.map;
		this.google = builder.google;
		this.drawingManager = builder.drawingManager;

		// Functions grabbed from the ZonesContext
		this.createRegion = builder.createRegion.bind(this);
		this.createZone = builder.createZone.bind(this);

		// Reference to all the polygons in the map
		this.polygons = [];
	}

	observeChanges() {
		mapSubject.subscribe(this);
	}

	centerMapOn(kml) {
		const bounds = new this.google.maps.LatLngBounds();

		for (let i = 0; i < kml.length; i++) {
			const coords = kml[i];

			bounds.extend(new this.google.maps.LatLng(coords[0], coords[1]));
		}

		this.map.setZoom(18);
		this.map.setCenter(bounds.getCenter());
	}

	changePolygonColor(to) {
		if (to === 'zone') {
			this.drawingManager.polygonOptions.fillColor = '#65de99';
			this.drawingManager.polygonOptions.strokeColor = '#008800';
		}

		if (to === 'region') {
			this.drawingManager.polygonOptions.fillColor = '#5b18ab';
			this.drawingManager.polygonOptions.strokeColor = '#673E98';
		}
	}

	addPolygon(p) {
		this.polygons.push(p);
	}

	// I hate you google maps, I hate you
	startListeners(onFinish, onUpdate) {
		this.google.maps.event.addListener(this.drawingManager, 'polygoncomplete', polygon => {
			this.addPolygon(polygon);

			const onEdit = () => onUpdate(getKmlZone(polygon));

			this.google.maps.event.addListener(polygon.getPath(), 'insert_at', onEdit);
			this.google.maps.event.addListener(polygon.getPath(), 'remove_at', onEdit);
			this.google.maps.event.addListener(polygon.getPath(), 'set_at', onEdit);

			this.drawingManager.setDrawingMode(null);

			onFinish(getKmlZone(polygon));
		});
	}

	drawZone(referenceToZone, onUpdateZone) {
		const onFinishZone = path => {
			referenceToZone.kmlzone = path.slice();
		};

		toast.info('Desenhe no mapa e clique em confirmar' + ' para cadastrar a região');
		this.changePolygonColor('zone');
		this.startListeners(onFinishZone, onUpdateZone);
		this.drawingManager.setDrawingMode('polygon');
	}

	drawRegion(referenceToRegion, onUpdateRegion) {
		const onFinishRegion = path => {
			referenceToRegion.kmlzone = path.slice();
		};

		toast.info('Desenhe no mapa e clique em confirmar' + ' para cadastrar a zona de fila');
		this.changePolygonColor('region');
		this.startListeners(onFinishRegion, onUpdateRegion);
		this.drawingManager.setDrawingMode('polygon');
	}

	clearEvents() {
		this.drawingManager.setDrawingMode(null);
		this.drawingManager.setMap(null);
		this.drawingManager.setMap(this.map);
		this.polygons.forEach(p => p.setMap(null));
		setTimeout(() => (this.polygons = []), 500);
		this.google.maps.event.clearListeners(this.drawingManager, 'polygoncomplete');
	}

	getUpdateType(type) {
		const types = {
			createRegion: this.createRegion,
			createZone: this.createZone,
			editZone: () => {},
			editRegion: () => {},
			drawRegion: this.drawRegion,
			drawZone: this.drawZone,
			centerOn: this.centerMapOn,
			clearEvents: this.clearEvents,
			default: () => {},
		};

		return types[type] || types.default;
	}

	update(type, args) {
		if (type == null || typeof type !== 'string') {
			throw Error('type must be a string');
		}

		this.getUpdateType(type).apply(this, args);
	}
}

export default MapObserver;
