// @ts-nocheck
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../context/Auth';
import { CompanyContext } from '../../context/Company';
import SidebarCategory from './SidebarCategory';
import SidebarLink from './SidebarLink';

const SidebarContent = ({ onClick }) => {
	const {
		state: { user },
	} = useContext(AuthContext);

	const {
		state: { crm },
		action: { fetchCRMs },
	} = useContext(CompanyContext);
	
	const [styles, setStyles] = useState({
		dashboard: { display: 'none' },
		results: { display: 'none' },
		wallet: { display: 'none' },
		trips: { display: 'none' },
		providers: { display: 'none' },
		schedules: { display: 'none' },
		companies: { display: 'none' },
		cities: { display: 'none' },
		financial: { display: 'none' },
		settings: { display: 'none' },
	});

	const hideSidebar = onClick;
	
	useEffect(() => {
		fetchCRMs();
	}, []);


	useEffect(() => {
		if (user && user.permissions) {
			const styles = {};
			user.permissions.map(category => {
				styles[category] = { display: 'block' };
			});
			setStyles(prev => ({ ...prev, ...styles }));
		}
	}, [user]);

	return (
		<div className="sidebar__content">
			<ul className="sidebar__block">
				<SidebarLink title="Dashboard - Cidade" icon="rocket" route="/dashboard_city" style={styles.dashboard} />
				<SidebarLink title="Dashboard" icon="rocket" route="/dashboard" style={styles.dashboard} />

				<SidebarCategory title="Relatórios" icon="chart-bars" style={styles.results}>
					<SidebarLink title="Res. por Dia" route="/daily_results" onClick={hideSidebar} />
					<SidebarLink title="Res. por Empresas" route="/company_results" onClick={hideSidebar} />
					<SidebarLink title="Res. por Empresas Comparado" icon="company" route="/company_results_compare" onClick={hideSidebar} />
					<SidebarLink title="Res. por Entregadores" route="/provider_results" onClick={hideSidebar} />
					<SidebarLink title="Res. por Cidade" route="/city_results" onClick={hideSidebar} />
					<SidebarLink title="Retenção de Empresas" icon="company" route="/companies_retention" onClick={hideSidebar} />
				</SidebarCategory>

				<SidebarCategory title="CRMs" icon="funnel" style={styles.results}>
					{crm.map(c => 
						<SidebarLink title={c.name} route={`/crm/${c._id}`} onClick={hideSidebar} />
					)}
				</SidebarCategory>

				<SidebarCategory title="Entregas" icon="list" style={styles.trips}>
					<SidebarLink title="Histórico" route="/trips" onClick={hideSidebar} />
					<SidebarLink title="Monitoramento" route="/trips_monitoring" onClick={hideSidebar} />
					<SidebarLink title="Tempo Real" route="/current_orders" onClick={hideSidebar} />
					<SidebarLink title="Agendamentos" route="/schedules" style={styles.schedules} />
				</SidebarCategory>


				<SidebarCategory title="Entregadores" icon="users" style={styles.providers}>
					<SidebarLink title="Aprovados" route="/approved" onClick={hideSidebar} />
					<SidebarLink title="Não aprovados" route="/notapproved" onClick={hideSidebar} />
					<SidebarLink title="Bloqueados" route="/disabled" onClick={hideSidebar} />
				</SidebarCategory>

				<SidebarCategory title="Empresas" icon="briefcase" style={styles.companies}>
					<SidebarLink title="Aprovadas" icon="company" route="/companies" onClick={hideSidebar} />
					<SidebarLink title="Em Espera" icon="company" route="/open_requests" onClick={hideSidebar} />
				</SidebarCategory>

				<SidebarLink title="Cidades" icon="map" route="/cities" onClick={hideSidebar} style={styles.cities} />

				<SidebarCategory title="Financeiro" icon="exit" style={styles.financial}>
					<SidebarLink
						title="Categorias"
						route="/control_financial/categories"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="Registros"
						route="/control_financial/registers"
						onClick={hideSidebar}
					/>
					{/* <SidebarLink
						title="Fechamento semanal"
						route="/control_financial/weekly_close"
						onClick={hideSidebar}
					/> */}
					<hr/>
					<SidebarLink
						title="Faturas"
						route="/control_financial/invoices"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="Faturas em aberto"
						route="/control_financial/open_invoices"
						onClick={hideSidebar}
					/>
					<hr/>
					<SidebarLink
						title="Repasse "
						route="/control_financial/transfers"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="Repasse em aberto"
						route="/control_financial/open_transfers"
						onClick={hideSidebar}
					/>
					<hr/>
					
					<SidebarLink
						title="Lançamentos por Categorias"
						route="/control_financial/report_categories"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="DRE Operacional"
						route="/control_financial/report_dre"
						onClick={hideSidebar}
					/>
				</SidebarCategory>

				<SidebarCategory title="Configurações" icon="cog" style={styles.settings}>
					<SidebarLink title="Credenciais" route="/credentials" />
				</SidebarCategory>
			</ul>
		</div>
	);
};

export default SidebarContent;
