// @ts-nocheck
import React, { useEffect, useContext } from 'react';
import { FinanceContext } from '../../../../context/Finance';
import { formatNumber } from '../../../../utils/masks';

import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import { CSVLink } from 'react-csv';
import Filter from '../Filter';

export const headers = [
  'ID integração',
  'Nome ou Razão Social',
  'CPF ou CNPJ',
  'Email',
  'Banco',
  'Agência',
  'Dígito da agência',
  'Conta',
  'Dígito da conta',
  'Tipo de Conta (Corrente ou Poupança)',
  'Data de agendamento (opcional)',
  'Valor\n',
];

const Resume = () => {
  const {
    state: { resume, loadingResume, downloadData, textCSV },
    action: { getResume, getDownloadData },
  } = useContext(FinanceContext);

  useEffect(() => {
    getResume();
    getDownloadData();
  }, []);

  const transformData = results => {
    const resultsTable = [];
    resultsTable.push([textCSV, '', '', '', '', '', '', '', '', '', '\n']);
    resultsTable.push(headers);

    results.map(data =>
      resultsTable.push([
        data['ID integração'],
        data['Nome ou Razão Social'],
        data['CPF ou CNPJ'],
        data['Email'],
        data['Banco'],
        data['Agência'],
        data['Dígito da agência'],
        data['Conta'],
        data['Dígito da conta'],
        data['Tipo de Conta (Corrente ou Poupança)'],
        data['Data de agendamento (opcional)'],
        data['Valor'] + '\n',
      ])
    );
    return [resultsTable];
  };

  return (
    <Container>
      {/* <h2 className="m-3">Resumo das Transações</h2> */}
      {!loadingResume ? (
        <>
          <Row className="mt-4">
            <Col>
              <InformationCard title="Transações Negadas" count={resume.denied.count} sum={resume.denied.sum} />
            </Col>
            <Col>
              <InformationCard
                title="Transações Processadas"
                count={resume.processed.count}
                sum={resume.processed.sum}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <InformationCard title="Solicitações em Aberto" count={resume.waiting.count} sum={resume.waiting.sum} />
            </Col>
            <Col>
              <InformationCard title="Total de Transações" count={resume.totals.count} sum={resume.totals.sum} />
            </Col>
          </Row>
        </>
      ) : (
          ''
        )}
      <Row className="text-right">
        <Filter />
        <Col>
          <CSVLink
            data={downloadData.length > 0 ? transformData(downloadData) : []}
            filename={'solicitacoes_de_saque.csv'}
            className="btn btn-primary btn-lg"
          >
            Exportar Solicitações em Aberto
						</CSVLink>
        </Col>
      </Row>
    </Container>
  );
};

const InformationCard = props => {
  const { title, count, sum } = props;

  return (
    <Card>
      <CardBody style={{ textAlign: 'center' }} className="p-4">
        <h3 className="m-2">{title}</h3>
        <h4 className="m-1">Transações: {count}</h4>
        <h4 className="m-1">Total: R$ {formatNumber(sum)}</h4>
      </CardBody>
    </Card>
  );
};

export default Resume;
