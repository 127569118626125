// @ts-nocheck
import React, { useEffect, useContext, useState, useCallback } from "react";
import { FinanceContext } from "../../context/Finance";
import { formatNumber } from "../../utils/masks";
import CurrencyInput from 'react-currency-input';

import { Container, Card, CardBody, Table, Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label, Badge, ButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu, CardHeader, ButtonGroup } from "reactstrap";
import DateRange from '../../components/DateRange';
import { FaRegEdit, FaTimesCircle, FaFilter, FaCheckCircle, FaCircle, FaRegCircle, FaSync, FaWhatsapp } from "react-icons/fa";
import moment from "moment";
import { toast } from "react-toastify";

const Registers = ({}) => {
  const {
    state: { categories, cities, registers, date_start, date_end, loadingRegisters },
    action: { getCategories, getCities, getRegisters, updateRegister  }
  } = useContext(FinanceContext);

  useEffect(() => {
    getCategories();
    getCities();
  }, []);

  useEffect(() => {
    loadFilters();

    if(cities.length && categories.length){
      filterRegisters();
    }
  }, [cities, categories]);

  const [fields, setField] = useState({
    filter_date: false,
    type:'revenue',
    done: false
  });

  const [filters, setFilters] = useState({
    types:[
      {label: "Receita", active: true, id: "revenue"},
      {label: "Despesa", active: true, id: "expense"},
    ],
    cities: [],
    categories:[],
    isOpen: false
  });

  const [registersFiltered, setRegistersFiltered] = useState([]);

  useEffect(() => {
    if(categories.length && cities.length){
      setRegistersFiltered(apllyViewFilters(registers).sort((a,b) => b.total - a.total));
    }

  }, [registers]);

  useEffect(() => {
    console.log('useEffect filters');
    if(categories.length && cities.length){
      setRegistersFiltered(apllyViewFilters(registers));
    }
  }, [filters]);

  const updateRegisterInRow = useCallback(async (fields) => {
    await updateRegister(fields);
    await filterRegisters();
    toast.success("Registro atualizado!");
  });

  const text_type_category = (type) => {
    switch (type) {
      case 'revenue': return "Receita";
      case 'expense': return "Despesa";
      case 'other': return "Outro";
    
      default:
        break;
    }
  }
  
  const loadFilters = async () => {
    let fcities = [];
    fcities.push({label: "Geral", active: true, id: null})
    cities.map(c => { fcities.push({label: `${c.citycode} - ${c.cityname}`, active: true, id: c._id})})
    setFilters(prev => ({...prev, cities: fcities}));

    let fcategories = [];
    categories.map(c => { fcategories.push({label: `${text_type_category(c.type)} - ${c.name}`, active: true, id: c._id})})
    setFilters(prev => ({...prev, categories: fcategories}));

  }

  const toggleFilter = (filter, i) => {
    let newFilters = filters;
    newFilters[filter][i].active = !newFilters[filter][i].active;
    setFilters(prev => ({...prev, newFilters}));
  }

  const toggleFilters = () => {
    setFilters(prev => ({...prev, isOpen: !prev.isOpen}));
  }

  const apllyViewFilters = (registers) => {
    console.log('Registers', registers);
    let companies = [];
    if(registers){
      let register_open_invoices = registers
        .filter(r => r.type == 'revenue')
        // .filter(r =>  r.category == "6180bf8626cae5104dd2c8c4")
        .filter(r =>  r.done === false)
        .filter(r => filters.cities.find(f => f.active && f.id == r.city ));
      
      register_open_invoices.map(r => {
        if(companies.filter(c => c.company == r.company).length == 0){
          companies.push({
            city: r.city,
            category: r.category,
            company: r.company,
            fromCompany: r.fromCompany,
            invoices: register_open_invoices.filter(rr => rr.company == r.company).length,
            total: register_open_invoices.filter(rr => rr.company == r.company).reduce((total, rrr) => total + rrr.value, 0),
            type: r.type
          });
        }
      });
      return companies;
    }
    
    return registers;
  }

  const [dropdown, setDropdown] = useState({
    isOpen: false
  });

  const dropdownToggle = () => {
    setDropdown(prev => ({...prev, isOpen: !prev.isOpen}));
  }

  const handleDateChange = useCallback((dates) => {
    setField(prev => ({...prev, date_start: dates[0], date_end: dates[1]}));
  },[]);

  const filterRegisters = () => {
    getRegisters(fields);
  }

  const CustomFilters = () => {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col xs={12} style={{marginBottom: 10}}><strong>Filtros</strong></Col>
            {/* <Col>
              <div>Tipo</div>
              {filters.types.map((f,i) => 
                <div><Button onClick={() => toggleFilter('types', i)} size="sm" outline color="secondary" style={{margin:'3px 0', padding: '5px 10px'}}>{f.active ? <FaCheckCircle/> : <FaRegCircle />} {f.label}</Button></div>
              )}
            </Col> */}
            {/* <Col>
              <div>Categorias</div>
              {filters.categories.map((f,i) => 
                <div><Button onClick={() => toggleFilter('categories', i)} size="sm" outline color="secondary" style={{margin:'3px 0', padding: '5px 10px'}}>{f.active ? <FaCheckCircle/> : <FaRegCircle />} {f.label}</Button></div>
              )}
            </Col> */}
            <Col>
              <div>Cidades</div>
              {filters.cities.map((f,i) => 
                <div><Button onClick={() => toggleFilter('cities', i)} size="sm" outline color="secondary" style={{margin:'3px 0', padding: '5px 10px'}}>{f.active ? <FaCheckCircle/> : <FaRegCircle />} {f.label}</Button></div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  const WhatsAppCTA = (resume_inv) => {
    let op_invoices = registers.filter(i => i.company == resume_inv.company);

    let qtd_invoices = op_invoices.length;
    let total = formatNumber(op_invoices.reduce((total, i) => total + i.value , 0));
    let phone = resume_inv.fromCompany[0].financial_phone;
    let fphone = `55${phone}`;
    let text = `Olá, você tem ${qtd_invoices} fatura${qtd_invoices > 1 ? 's' : ''} em aberto conosco, no valor de R$ ${total}.`;
    text +=`\n\n----`;
    for(let i = 0; i < op_invoices.length; i++){
      let inv = op_invoices[i];
      if(i>0) text +=`\n`;
      text +=`\n*${inv.description}*`;
      text +=`\nValor: R$ ${formatNumber(inv.value)}`;
      text +=`\nVencimento: ${moment(inv.due_date).format("DD/MM/Y")}`;
      text +=`\nAcesse: https://delivery.vaay.app/invoice/${inv._id}`;
    }
    text +=`\n\n----`;

    text +=`\n_O sistema poderá ser bloqueado caso o pagamento não seja realizado_`;
    text +=`\n_Por favor retorne com o comprovante ao finalizar o pagamento._`;
    text +=`\n_Se você já realizou o pagamento, nos envie o comprovante por favor._`;
    text +=`\n\n---`;
    text +=`\nAcesse todas as suas faturas no seu painel de entregas`;
    let uri_text = window.encodeURIComponent(text);

    window.open(`https://wa.me/${fphone}?text=${uri_text}`,"_blank");
  }

  return (
    <>
    <Container>
      <h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333"}}>CONTROLE FINANCEIRO <small>/ FATURAS EM ABERTO</small>
        { loadingRegisters ? <span style={{float:"right"}}><FaSync className="load__icon" style={{height:10, width:10}}/></span>: null }
      </h3>
    </Container>
    <Container>
      <Row>
        <Col><Button size="sm" color="secondary" onClick={toggleFilters}><FaFilter/> Mais filtros</Button></Col>
        <Col>
          {/* <div><DateRange name='rang_date' value={[fields.date_start, fields.date_end]} onChange={handleDateChange} /></div> */}
        </Col>
        <Col>
          {/* <Button size="sm" 
          // style={{margin: '0px', marginBottom:10, padding:"7px 14px", textTransform:"uppercase", border:"none"}} 
          color="primary" onClick={() => {filterRegisters()}}>Aplicar</Button> */}
        </Col>
        {/* <Col md={3}><ModalAdd type={"revenue"}/></Col> */}
        {/* <Col md={3}></Col> */}
        <Col style={{textAlign:'right'}}>
            {/* <ButtonGroup>
              <ModalAdd refreshRegisters={filterRegisters} type={"revenue"}/>
              <ModalAdd refreshRegisters={filterRegisters} type={"expense"}/>
              <ModalWeeklyClose />
            </ButtonGroup> */}
        </Col>
        <Col style={{display: (filters.isOpen ? 'inherit': 'none')}} xs={12}>
          <CustomFilters />
        </Col>
        <Col xs={12}>
          <Card>
            <CardBody>
              <Table striped>
                <thead>
                  <tr style={{fontSize:11}}>
                    <th>CIDADE</th>
                    <th>EMPRESA</th>
                    <th>DOCUMENTO / RAZAO SOCIAL</th>
                    <th style={{textAlign:"center"}}>CONTATO</th>
                    <th style={{textAlign:"left"}}>QTD FATURAS</th>
                    <th style={{textAlign:"right"}}>R$ TOTAL</th>
                    <th>SITUAÇÃO</th>
                    {/* <th>&bull;&bull;&bull;</th> */}
                  </tr>
                </thead>
                <tbody>
                  {/* {categories.length && cities.length && registers.length > 0 ? apllyViewFilters(registers).map((r,i) => ( */}
                  {registersFiltered.length > 0 ? registersFiltered.map((r,i) => (
                    <tr>
                      <td>{r.city ? <Badge color="secondary" style={{padding:"4px 8px", fontSize:10}}>{cities.filter(c => c._id == r.city)[0].citycode}</Badge>  : 'Geral'}</td>
                      <td>{r.fromCompany.length ? r.fromCompany[0].name.toUpperCase() :null}</td>
                      <td>
                        <div><small>{r.fromCompany.length ? r.fromCompany[0].document :null}</small></div>
                        <div><small>{r.fromCompany.length ? r.fromCompany[0].full_name.toUpperCase() :null}</small></div>
                      </td>
                      <td style={{whiteSpace: "nowrap"}}>{r.fromCompany.length ? 
                        <div>{r.fromCompany[0].financial_phone} <FaWhatsapp onClick={() => WhatsAppCTA(r)}/></div>: null}</td>
                      {/* <td style={{whiteSpace: "nowrap"}}>
                        {r.done ? <FaCheckCircle style={{cursor: 'pointer', color:"#11cf99", margin:4}} onClick={() => {updateRegisterInRow({id: r._id, done: false})}}/>: <FaCircle style={{cursor: 'pointer', color:"#CCC", margin:4}} onClick={() => {updateRegisterInRow({id: r._id, done: true})}}/>}
                        {r.done ? `Pago` : `Pendente`}
                      </td> */}
                      <td>{r.invoices}</td>
                      <td style={{whiteSpace: "nowrap", textAlign:"right", color: (r.type == 'revenue' ? '#0da076' : '#db2828')}}>R$ {formatNumber(r.total)}</td>
                      <td style={{whiteSpace: "nowrap"}}><Badge color="secondary" style={{padding:"4px 8px", fontSize:10}}>{ r.fromCompany.length ? 
                        r.fromCompany[0].is_active < 0 ? 'BLOQUEADO' :
                        r.fromCompany[0].is_active == 0 ? 'AUTOMATICO' :
                        r.fromCompany[0].is_active > 0 ? 'ATIVO' : 'INDEFNIDO'
                        : null}</Badge><ModalUpdateActive is_active={r.fromCompany[0].is_active} company_id={r.fromCompany[0]._id} company_name={r.fromCompany[0].name.toUpperCase()} refreshRegisters={filterRegisters}/></td>
                      {/* <td style={{whiteSpace: "nowrap"}}>
                        <ModalUpdate refreshRegisters={filterRegisters} type={r.type} category={r.category} city={r.city} description={r.description} value={r.value} due_date={r.due_date} done={r.done} payday={r.payday} id={r._id} attachment_value={r.attachment_value}/>
                        <ModalRemove refreshRegisters={filterRegisters} type={r.type} category={r.category} city={r.city} description={r.description} value={r.value} due_date={r.due_date} done={r.done} payday={r.payday} id={r._id}/>
                      </td> */}
                    </tr>
                  )) : ''}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default Registers;

const ModalAdd = (props) => {
  let { type, name, master, refreshRegisters} = props;
  
  const {
    state: {categories, cities},
    action: { addRegister }
  } = useContext(FinanceContext);

  const [ modal, setModal, ] = useState(false);
  const toggle = () => setModal(!modal);
  
  const [fields, setField] = useState({
    type: type,
    category: null, 
    city: null, 
    description: '', 
    value: 0, 
    due_date: null, 
    payday: moment().format("YYYY-MM-DD"), 
    done: false,
  });
  const onChange = useCallback(e => {
    const { value, name } = e.target;
    setField(prev => ({ ...prev, [name]: value }));
  }, []);
  const onChangeCheck = useCallback(e => {
    const {name, checked } = e.target;
    setField(prev => ({ ...prev, [name]: checked }));
  }, []);
  const onChangeCurrency = useCallback((event, maskedvalue, floatvalue) => {
    setField(prev => ({ ...prev, value: floatvalue }));
  }, []);

	const add = async () => {
    console.log(`fields`, fields);
    if(!fields.category){ toast.warn("Selecione uma categoria!"); return}
    if(!fields.value){ toast.warn("Informe o valor!"); return}
    if(!fields.due_date){ toast.warn("Informe a data!"); return}
    if(fields.done && !fields.payday){ toast.warn("Informe a data de pagamento, ou desmarque a opção 'Realizado'!"); return}

    if(type == `expense`) fields.value = fields.value*-1;
    fields.due_date = moment(fields.due_date).format('y-MM-DD');
    await addRegister(fields);
    toast.success(text_title(type) + " registrada!")
    refreshRegisters();
	}

  const text_title = (type) => {
    switch (type) {
      case 'revenue': return "Receita";
      case 'expense': return "Despesa";
      case 'other': return "Outro";
    
      default:
        break;
    }
  }

  const color_type = (type) => {
    switch (type) {
      case 'revenue': return "primary";
      case 'expense': return "danger";
      default:
        break;
    }
  }

	return (
	  <>
    <Button color={color_type(type)} onClick={toggle}>{text_title(type)}</Button>
		<Modal isOpen={modal} toggle={toggle} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
		  <ModalHeader toggle={toggle}>Registrar {text_title(type)}</ModalHeader>
		  <ModalBody size="lg">
			  <Form>
          <FormGroup style={{textAlign:"left"}}>
            <Input id="category" name="category" type="select" onChange={onChange}>
              <option value={null}>Categoria:</option>
              {categories.filter(c => c.master == null).map( c => ( c.type == type || c.type == 'other' ? <> 
                <option value={c._id}>{c.name}</option> 
                {categories.filter(cc => cc.master == c._id).map(ccc => (<option value={ccc._id}>--- {ccc.name}</option> ))}
              </>: ''))}
            </Input>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Input id="city" name="city" type="select" onChange={onChange}>
              <option value={null}>Cidade:</option>
              {cities.map( c => ( <option value={c._id}>{c.citycode} - {c.cityname} </option> ))}
            </Input>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Descrição</Label>
            <Input id="description" name="description" placeholder={`Descreva a ` + text_title(type)} type="textarea" onChange={onChange}/>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Valor</Label>
            <CurrencyInput className="form-control" id="value" name="value" type="text" decimalSeparator="," thousandSeparator="." value={fields.value} onChangeEvent={onChangeCurrency}/>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Data vencimento</Label>
            <Input id="due_date" name="due_date" placeholder="dd/mm/yyyy" type="date" onChange={onChange}/>
          </FormGroup>
          <FormGroup style={{textAlign:"left", marginBottom:15}} check>
            <Label check>
              <Input id="done" name="done" type="checkbox" onChange={onChangeCheck} style={{marginTop:0}}/>&nbsp;&nbsp;&nbsp;
              Realizado
            </Label>
          </FormGroup>
          { fields.done ? 
            <FormGroup style={{textAlign:"left"}}>
              <Label>Data pagamento</Label>
              <Input id="payday" name="payday" value={fields.payday} placeholder="dd/mm/yyyy" type="date" onChange={onChange}/>
            </FormGroup>
          : null}
        </Form>
		  </ModalBody>
		  <ModalFooter>
        <Button color={color_type(type)} onClick={() => { add(); toggle()}}>Registrar</Button>
		  </ModalFooter>
		</Modal>
	  </>
	);
}

const ModalWeeklyClose = (props) => {
  
  const {
    action: { WeeklyClose }
  } = useContext(FinanceContext);

  const [ modal, setModal, ] = useState(false);
  const toggle = () => setModal(!modal);
  
  const [fields, setField] = useState({
    start_date: moment().startOf('ISOWeek').format("YYYY-MM-DD"), 
    end_date: moment().endOf('ISOWeek').format("YYYY-MM-DD"),
  });
  const onChange = useCallback(e => {
    const { value, name } = e.target;
    setField(prev => ({ ...prev, [name]: value }));
  }, []);

	const registerWeeklyClose = async () => {
    console.log(`fields`, fields);

    let payload = {};
    payload.date = {};
    payload.date.start = fields.start_date;
    payload.date.end = fields.end_date;
    payload.action = 'insert';
    
    payload.from = 'company';
    // console.log(`payload`, payload);
    await WeeklyClose(payload);

    // payload.from = 'provider';
    // await WeeklyClose(payload);
    toast.success("Registros realizado!")
	}

	return (
	  <>
    <Button color="dark" onClick={toggle}>Fechamento</Button>
		<Modal isOpen={modal} toggle={toggle} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
		  <ModalHeader toggle={toggle}>Fechamento</ModalHeader>
		  <ModalBody size="lg">
			  <Form>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Data Inicio</Label>
            <Input id="start_date" name="start_date" placeholder="dd/mm/yyyy" type="date" value={fields.start_date} onChange={onChange}/>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Data Fim</Label>
            <Input id="end_date" name="end_date" placeholder="dd/mm/yyyy" type="date" value={fields.end_date} onChange={onChange}/>
          </FormGroup>
        </Form>
		  </ModalBody>
		  <ModalFooter>
        <Button color="primary" onClick={() => { registerWeeklyClose(); toggle()}}>Concluir</Button>
		  </ModalFooter>
		</Modal>
	  </>
	);
}

const ModalUpdateActive = (props) => {
  let { company_id, company_name, is_active, refreshRegisters} = props;
  
  const {
    state: {categories, cities},
    action: { updateCompanyActive }
  } = useContext(FinanceContext);

  const [ modal, setModal, ] = useState(false);
  const toggle = () => setModal(!modal);
  
  const [fields, setField] = useState({
    company_id: company_id,
    is_active: is_active
  });

  const onChange = useCallback(e => {
    const { value, name } = e.target;
    setField(prev => ({ ...prev, [name]: value }));
  }, []);

	const submit = async () => {
    console.log(`fields`, fields);

    await updateCompanyActive(fields);
    toast.success("A situação de atividade da Empresa foi atualizada!");
    await refreshRegisters();
	}

  const color_type = (type) => {
    switch (type) {
      case 'revenue': return "primary";
      case 'expense': return "danger";
      default:
        break;
    }
  }

	return (
	  <>
		<FaRegEdit style={{cursor: 'pointer', margin:4}} onClick={toggle}/>
		<Modal isOpen={modal} toggle={toggle} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
		  <ModalHeader toggle={toggle}>Editar Status - <strong>{company_name}</strong></ModalHeader>
		  <ModalBody size="lg">
			  <Form>
          <FormGroup style={{textAlign:"left"}}>
            <Input id="is_active" name="is_active" value={fields.is_active} type="select" onChange={onChange}>
              <option value={null}>Status de atividade:</option>
              <option value={-1}>Bloqueada</option>
              <option value={0}>Automático</option>
              <option value={1}>Ativa</option>
            </Input>
          </FormGroup>
        </Form>
		  </ModalBody>
		  <ModalFooter>
        <Button color={'primary'} onClick={() => { submit(); toggle()}}>Atualizar</Button>
		  </ModalFooter>
		</Modal>
	  </>
	);
}

const ModalRemove = (props) => {
  let { type, category, city, description, value, due_date, done, payday, id, refreshRegisters} = props;
  
  const {
    state: {categories, cities},
    action: { removeRegister }
  } = useContext(FinanceContext);

  const [ modal, setModal, ] = useState(false);
  const toggle = () => setModal(!modal);
  
  const [fields, setField] = useState({
    id: id,
    type: type,
    category: category, 
    city: city, 
    description: description, 
    value: value, 
    due_date: due_date, 
    payday: payday, 
    done: done,
  });

	const submit = async () => {
    await removeRegister(fields);
    toast.success(text_title(type) + " removida!");
    refreshRegisters();
	}

  const text_title = (type) => {
    switch (type) {
      case 'revenue': return "Receita";
      case 'expense': return "Despesa";
      case 'other': return "Outro";
    
      default:
        break;
    }
  }

	return (
	  <>
		<FaTimesCircle style={{cursor: 'pointer', margin:4}} onClick={toggle}/>
		<Modal isOpen={modal} toggle={toggle} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
		  <ModalHeader toggle={toggle}>Remover registro</ModalHeader>
		  <ModalBody size="lg">
			  <div>
          Remover registro financeiro de <strong>{text_title(type)}: </strong>{description}, R$ {parseFloat(value).toLocaleString()} ?
        </div>
		  </ModalBody>
		  <ModalFooter>
        <Button color="" onClick={() => { toggle()}}>Cancelar</Button>
        <Button color="dark" onClick={() => { submit(); toggle()}}>Remover</Button>
		  </ModalFooter>
		</Modal>
	  </>
	);
}