// @ts-nocheck
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { CompanyContext } from "../../context/Company";
import ListItem from "./components/ListItem";
import moment from 'moment';
import 'moment/locale/pt-br';

import { Container, Card, CardBody, Table, Row, Col, CardTitle, Badge, Modal, ModalHeader, ModalBody, Form, Button, ModalFooter} from "reactstrap";
import { toast } from "react-toastify";
import { FaRegEdit } from "react-icons/fa";
import { FormGroup } from "reactstrap";
import { Input } from "reactstrap";
import { Label } from "reactstrap";
interface Props extends RouteComponentProps<{ crmId: string }> { };

const CRM = ({ history, match: { params } }: Props) => {
  const {
    state: { companies, cities, crm },
    action: { fetchAllCompanies, fetchCities }
  } = useContext(CompanyContext);

  const [crmState, setCrmState ] = useState({
    current_crm: {
      _id: "",
      steps: [{
        name: "",
        order: 0,
        default: false
      }],
      name: "...",
      default: false
    }
  });

  useEffect(() => {
    if(!params.crmId)  return history.push("/");
    if(crm.length) setCrmState(prev => ({...prev, current_crm: crm.find(c => c._id == params.crmId)}));
  },[crm]);

  useEffect(() => {
    fetchAllCompanies();
    fetchCities();
  }, []);

  const prepare_pipeline = (current_crm: any, cities: any, companies: any) => {
    let crm = current_crm;
      if(cities && companies){
        companies.sort(function(a: any, b: any){
          // if(a.costcenter.length == 0 && b.costcenter.length > 0) { return -1; }
          return a.created_at > b.created_at ? -1 : 0;
      });

      let steps = crm.steps;
      steps.map((step: any) => {
        let current_step_companies = [];
        if(crm.default && step.default){
          current_step_companies = companies.filter((c: any) => {
            if(!c.crm) return true;
            else{
              let company_current_crm = c.crm.find((company_crm: any) => company_crm.current_step);
              if(company_current_crm && company_current_crm._id == current_crm._id && company_current_crm.step == step.name) return true
              else return false;
            }
          });
          step.companies = current_step_companies;
        }else{
          current_step_companies = companies.filter((c: any) => {
            if(!c.crm) return false;
            else{
              let company_current_crm = c.crm.find((company_crm: any) => company_crm.current_step);
              if(company_current_crm && company_current_crm._id == current_crm._id && company_current_crm.step == step.name) return true
              else return false;
            }
          });
        }

        step.companies = current_step_companies;
        step.total_companies = current_step_companies.length;
        step.estimated_deliveries = current_step_companies.reduce((total: number, cs: any) => total + (cs.estimated_deliveries || 0), 0);
      });

      return steps;
    }

    return [];
  }

  return (
    <>
    <Container><h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333", textTransform:"uppercase"}}>CRM / PIPELINE {crmState.current_crm.name}</h3></Container>
    <Container>
      <Row>
        {prepare_pipeline(crmState.current_crm, cities, companies).map((step: any) => 
          <Col style={{marginBottom:24}}>
            <div style={{background:"#DDDDDD44", padding:"15px", borderRadius: 6, border:'solid 1px #BBB'}}>
              <div style={{marginBottom:16}}>
                <div style={{textTransform: "uppercase"}}><strong>{step.name}</strong> <Badge>{step.total_companies}</Badge></div>
                <small>TOTAL ENTREGAS: {step.estimated_deliveries}</small>
              </div>
              {step.companies.map((c: any) => 
                <div style={{backgroundColor:'#FFF', padding:12, borderRadius:6, marginBottom:8, position:"relative"}}>
                  <div style={{float:"right"}}><ModalUpdate crm={crmState.current_crm} step={step} company={c}/></div>
                  <Badge>{c.city_id ? cities.find(ct => ct._id == c.city_id).citycode : '-'}</Badge>
                  <div><strong>{c.name}</strong></div>
                  <div><small>{moment(c.created_at).calendar()}</small></div>
                  <div><small>{c.costcenter.length} lojas cadastradas</small></div>
                  <div><small>Entregas Semanal: {c.estimated_deliveries || '-'}</small></div>
                  <div><small>Status: {c.is_approved ? 'Aprovada' : c.is_refused ? 'Reprovada' : 'Pendente'}</small></div>
                  {step.done ? null :
                  <>
                    <hr/>
                    {c.admins.map((admin: any) => 
                      <>
                        <div>{admin.display_name}</div>
                        <div><small>{admin.email}</small></div>
                        <div><small>{admin.phone}</small></div>
                      </>
                    )}
                  </>}
                </div>
              )}
            </div>
          </Col>
        )}
      </Row>
    </Container>
    </>
  );
};

export default CRM;

const ModalUpdate = (props: any) => {
  let {crm, step, company} = props;
  
  const {
    action: {crmCompanyUpdate, acceptCompany}
  } = useContext(CompanyContext);

  const [ modal, setModal, ] = useState(false);
  const toggle = () => setModal(!modal);
  
  const [fields, setField] = useState({
    crm: crm,
    step: step,
    company: company,
    estimated_deliveries: company.estimated_deliveries || 0,
    comment: '',
    is_approved: company.is_approved
  });
  const onChange = useCallback(e => {
    const { value, name } = e.target;
    setField(prev => ({ ...prev, [name]: value }));
  }, []);


	const submit = async () => {
    await crmCompanyUpdate(fields.company._id, fields.crm._id, fields.step, fields.comment, fields.estimated_deliveries);
    // toast.success(`As informações da empresa ${fields.company.name} foram atualizadas!`);
    window.location.reload();
	}

	return (
	  <>
		<FaRegEdit style={{cursor: 'pointer', margin:4}} onClick={toggle}/>
		<Modal isOpen={modal} toggle={toggle} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
		  <ModalHeader toggle={toggle}>Editar</ModalHeader>
		  <ModalBody size="lg">
			  {<Form>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Etapa</Label>
            <Input id="step" name="step" value={fields.step.name} type="select" onChange={onChange}>
              {fields.crm.steps.map((s: any) =>  <option value={s.name}>{s.name}</option>)}
            </Input>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Estregas estimadas</Label>
            <Input id="estimated_deliveries" name="estimated_deliveries" value={fields.estimated_deliveries} type="number"  onChange={onChange}/>
          </FormGroup>
          <FormGroup style={{textAlign:"left"}}>
            <Label>Anotações</Label>
            <Input id="comment" name="comment" value={fields.comment} type="textarea" onChange={onChange}/>
          </FormGroup>
          <div style={{textAlign:"left", padding:"6px 10px", backgroundColor:"#EEE", lineHeight:.9}}>
            {fields.company.comments ? fields.company.comments.map((cm: any) => <div style={{borderBottom:"solid 1px #DDD", marginBottom:5}}><small><strong>{moment(cm.created_at).calendar()}:</strong><br/>{cm.comment}</small></div> ) : null}
          </div>
        </Form> }
		  </ModalBody>
		  <ModalFooter>
        <Button onClick={() => {submit(); toggle()}}>Salvar</Button>
		  </ModalFooter>
		</Modal>
	  </>
	);
}