// @ts-nocheck
import React, { useState, useCallback } from "react";
import { Vehicle } from "../../../../context/Provider/provider.types";

export interface VehicleState {
  _id: string;
  provider_id: string;
  vehicle_name: string;
  vehicle_model: string;
  vehicle_plate_no: string;
  vehicle_color: string;
  service_type: any[];
  doc_expiry_date: Date | null;
  vehicle_passing_year: string;
  vehicle_doc: File | null;
  vehicle_is_approved: boolean;
  vehicle_is_selected: boolean;

  document_picture: string;

  isNew: boolean;
  serviceOptions: any[];
};


export const useVehicleForm = (provider_id: string, servicesType: any[], vehicle?: Vehicle) => {

  const InitialState: VehicleState = {
    _id: '',
    provider_id: provider_id,
    vehicle_name: "",
    vehicle_model: "",
    vehicle_plate_no: "",
    vehicle_color: "",
    service_type: [],
    doc_expiry_date: null,
    vehicle_passing_year: "",
    vehicle_doc: null,
    vehicle_is_approved: false,
    vehicle_is_selected: false,

    document_picture: "",

    isNew: true,
    serviceOptions: transformServiceOptions(servicesType),
  };

  const [state, setState] = useState<VehicleState>(() =>
    vehicle ? transformInitialState(vehicle, servicesType) : InitialState
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setState(prevState => ({
        ...prevState,
        [name]: value
      }));
    },
    []
  );

  const onServicesSelect = useCallback(
    (values: string[], name: string, ) => {
      setState(prevState => ({
        ...prevState,
        [name]: values
      }));
    },
    []
  );

  const onDocumentChange = useCallback((file: File) => {
    setState(prevState => ({
      ...prevState,
      vehicle_doc: file
    }));
  }, []);

  const onDateChange = useCallback((date: Date) => {
    setState(prevState => ({
      ...prevState,
      doc_expiry_date: date
    }));
  }, []);

  const onCheckboxChange = useCallback((name: string, value: boolean) => {
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  return {
    state,
    onChange,
    onServicesSelect,
    onDocumentChange,
    onDateChange,
    onCheckboxChange
  };
};

const transformInitialState = (vehicle: Vehicle, servicesType: any[]): VehicleState => {
  const {
    _id,
    provider_id,
    model,
    name,
    color,
    plate_no,
    passing_year,
    service_type,
    is_approved,
    is_selected,
    document
  } = vehicle;

  const serviceType = service_type.map(s => s._id);

  return {
    _id,
    provider_id,
    vehicle_model: model,
    vehicle_name: name,
    vehicle_plate_no: plate_no,
    vehicle_color: color,
    service_type: serviceType,
    doc_expiry_date: document && document.expired_date !== null ? new Date(document.expired_date) : null,
    vehicle_passing_year: passing_year,
    vehicle_doc: null,
    vehicle_is_approved: is_approved,
    vehicle_is_selected: is_selected,

    document_picture: document ? document.document_picture : '',

    isNew: false,
    serviceOptions: transformServiceOptions(servicesType)
  }
}

const transformServiceOptions = (service_type: any[]): any[] => {
  const serviceOptions = service_type.map(s => ({
    label: `${s.typename} (${s.description})`,
    value: s.typeid,
    id: s._id
  }));
  return serviceOptions;
};