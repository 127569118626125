// @ts-nocheck
import React, { useContext } from "react";
import { ProviderMetricsContext } from "../../../context/ProviderMetrics";
import Loader from "../../../components/Loader";
import Panel from "../../../components/Panel";
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from "recharts";

const OnlineTime = () => {
  const {
    state: { onlineTime, isOnlineTimeLoaded }
  } = useContext(ProviderMetricsContext);

  const transformData = (data: any): any[] => {
    return data.dailyOnlineTime
      //   .sort((prev: any, d: any) => (prev.date - d.date))
      .map((d: any) => ({
        ...d,
        date: d.date,
        avgOnlineTime: data.averageOnlineTime
      }));
  };

  return (
    <Panel title="Tempo online">
      {isOnlineTimeLoaded
        ? <ResponsiveContainer height={260}>
          <LineChart
            data={transformData(onlineTime)}
            margin={{ top: 20, left: -15 }}
          >
            <CartesianGrid strokeDasharray="5 5" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="avgOnlineTime"
              dot={false}
              name="Média de tempo online"
              stroke="#f8b68b"
              strokeDasharray="5 5"
            />
            <Line
              type="monotone"
              dataKey="total_online_time"
              name="Total de tempo online"
              stroke="#82ca9d"
            />
          </LineChart>
        </ResponsiveContainer>
        : <Loader height="260px" />
      }
    </Panel>
  );
};

export default OnlineTime;