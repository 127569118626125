// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";
import { formatPhone } from "../../utils/masks";
import { ProviderContext } from "../../context/Provider";
import { FilterState } from "./Filter";
import Filter from "./Filter";

import { Container, Card, CardBody, Table } from "reactstrap";
import { RouteComponentProps } from "react-router";
import moment from 'moment';

const heads = [
  "ID",
  "Data do cadastro",
  "Nome",
  "Sexo",
  "Cidade",
  "Email",
  "Telefone",
  "Plano",
  "Grupo"
];

interface Props extends RouteComponentProps {
  title: string;
  type: any;
}

const ApprovedProviders = ({ history, title, type }: Props) => {

  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<FilterState>({} as FilterState);

  const {
    state: {
      providers,
      isProvidersLoaded,
      isCitiesLoaded,
      providersPagination
    },
    action: { searchProviders, getCities },
  } = useContext(ProviderContext);

  useEffect(() => {
    searchProviders(type);
    getCities('Brazil');
  }, [])

  useEffect(() => {
    isProvidersLoaded && isCitiesLoaded
      ? setLoading(false)
      : setLoading(true)
  }, [isProvidersLoaded, isCitiesLoaded])

  const onChangePage = (pageNumber: number) =>
    searchProviders(type, pageNumber, filter);

  const onChangeFilter = (filter: FilterState) =>
    setFilter(filter);

  const onClick = (
    e: React.ChangeEvent<HTMLTableRowElement | EventTarget>,
    providerId: string
  ) => {
    e.preventDefault();
    history.push({ pathname: `approved/provider/${providerId}` });
  };

  return (
    <>
    <Container><h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333"}}>ENTREGADORES</h3></Container>
    <Container>
      <Card>
        <CardBody>
          <Filter updateParentFilter={onChangeFilter} type={type} />
          {
            loading ? <Loader />
              : <Table responsive hover className="table--bordered mb-4">
                <thead>
                  <tr>
                    {heads.map((head, index) => (
                      <th key={index}>{head}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {providers.map((provider, index) => (
                    <tr key={index} onClick={e => onClick(e, provider._id)}>
                      <td>{provider.unique_id}</td>
                      <td>{moment(provider.created_at).format("DD/MM/YYYY")}</td>
                      <td>{provider.first_name} {provider.last_name}</td>
                      <td>{provider.gender === "male" ? "Masculino" : "Feminino"}</td>
                      <td>{provider.city}</td>
                      <td>{provider.email}</td>
                      <td>{formatPhone(provider.phone)}</td>
                      <td>{provider.plan !== null ? provider.plan.name : ''}</td>
                      <td>{provider.group_name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
          }
          <Pagination
            onChangePage={onChangePage}
            current={providersPagination.current_page}
            total={providersPagination.pages === 0 ? 1 : providersPagination.pages}
            initial={1}
            size={10}
          />
        </CardBody>
      </Card>
    </Container>
    </>
  );
}

export default ApprovedProviders;