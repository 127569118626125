// @ts-nocheck
import React from 'react';
import { Map, GoogleApiWrapper, Polygon } from 'google-maps-react';
import { ZonesContext } from '../../context/Zones';
import getDrawingManagerConfig from './config/drawingManagerConfig';
import MapObserver from './observers/MapObserver';
import { toast } from 'react-toastify';

const mapStyle = {
	maxWidth: '1600px',
	height: '400px',
	display: 'inline-block',
	position: 'static',
};

const mapContainerStyle = {
	position: 'relative',
	paddingBottom: 402,
	boxShadow: '0 1px 4px -1px rgba(0, 0, 0, 0.4)',
};

const defaultRegionPolygonProps = {
	strokeColor: '#673E98',
	strokeOpacity: 0.8,
	strokeWeight: 2,
	fillColor: '#5b18ab',
	fillOpacity: 0.35,
};

const defaultZonePolygonProps = {
	strokeColor: '#008800',
	strokeOpacity: 0.8,
	strokeWeight: 2,
	fillColor: '#65de99',
	fillOpacity: 0.35,
};

class MapBuilder {
	setMap(map) {
		this.map = map;
		return this;
	}
	setGoogle(google) {
		this.google = google;
		return this;
	}
	setDrawingManager(manager) {
		this.drawingManager = manager;
		return this;
	}
	onCreateRegion(fn) {
		this.createRegion = fn;
		return this;
	}
	onCreateZone(fn) {
		this.createZone = fn;
		return this;
	}
}

class QueueZones extends React.PureComponent {
	static contextType = ZonesContext;

	state = {
		zone: null,
		currentCity: null,
	};

	componentDidMount() {
		const currentCityId = this.props.cityId;

		if (!currentCityId) {
			toast.info('Você precisar selecionar uma cidade para visualizar o mapa');

			return this.props.history.push({
				pathname: '/cities',
			});
		}

		const { cities } = this.context.state;

		const currentCity = cities.find(city => {
			return city._id === currentCityId;
		});

		this.setState({ currentCity });
	}

	componentDidUpdate(prevProps, prevState) {
		const { fetchCityZone } = this.context.action;

		if (prevState.currentCity === null && this.state.currentCity !== null) {
			fetchCityZone(this.state.currentCity._id);
		}

		if (prevState.zone === null && this.context.state.zones.length > 0) {
			this.setState({ zone: this.context.state.zones[0] });
		}
	}

	initMap = (mapProps, map) => {
		const { google } = this.props;
		const { DrawingManager } = google.maps.drawing;

		const drawingManager = new DrawingManager(getDrawingManagerConfig(google, map));

		new MapObserver(
			new MapBuilder()
				.setMap(map)
				.setGoogle(google)
				.setDrawingManager(drawingManager)
				.onCreateRegion(this.context.action.createRegion)
				.onCreateZone(this.context.action.createZone)
		).observeChanges();
	};

	render() {
		const { google } = this.props;
		const { currentCity, zone } = this.state;
		const { regions } = this.context.state;

		if (currentCity === null) {
			return (
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100vw',
						height: '100vh',
					}}
				>
					<div className="load__icon-wrap">
						<svg className="load__icon">
							<path fill="#11cf99" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
						</svg>
					</div>
				</div>
			);
		}

		const initialCenter = {
			lat: currentCity.cityLatLong[0],
			lng: currentCity.cityLatLong[1],
		};

		return (
			<div style={mapContainerStyle}>
				<Map google={google} style={mapStyle} initialCenter={initialCenter} onReady={this.initMap}>
					{regions.map(region => (
						<Polygon
							key={region._id}
							paths={region.kmlzone.map(path => new google.maps.LatLng(path[0], path[1]))}
							{...defaultRegionPolygonProps}
						/>
					))}

					{zone && (
						<Polygon
							paths={zone.kmlzone.map(path => new google.maps.LatLng(path[0], path[1]))}
							{...defaultZonePolygonProps}
						/>
					)}
				</Map>
			</div>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: "AIzaSyA1EWxwJ6-Mtbv-Ihn2jW34ywyVLD3PuEY",
	libraries: ['geometry', 'drawing'],
})(QueueZones);
