// @ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import { ResultContext } from '../../context/Result';
import { getLastWeekDay } from "../../utils/dates";
import { formatNumber } from '../../utils/masks';
import DateRange from '../../components/DateRange';
import Select from "../../components/Select";
import Loader from '../../components/Loader';

import { Container, Card, CardBody, Row, Col, Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const dayWeek = ['Domingo', 'Segunda', 'Terça', 'Quata', 'Quinta', 'Sexta', 'Sábado'];

export const headers = [
  'Data',
  'Entregadores ativos',
  'Viagem',
  'Entregas',
  'Média/Viagem',
  'Pago p/ o entregador',
  'Taxas',
  'Recebido da empresa',
  'Saldo',
];

const DailyResults = () => {
  const {
    state: { dailyResults, isDailyResultsLoaded },
    action: { getDailyResults },
  } = useContext(ResultContext);

  const [state, setState] = useState({
    startDate: getLastWeekDay(),
    endDate: new Date(),
    frequency: 'd',
  });

  const handleDateChange = (dates: Date[]) =>
    setState(prev => ({ ...prev, startDate: dates[0], endDate: dates[1] }));

  const handleFrequencyChange = (value: string) =>
    setState(prev => ({ ...prev, frequency: value }))

  const transformData = (results: any[]) => {
    const resultsTable: any[] = [];
    results.map(day => {
      resultsTable.push([
        moment(day.date).format('DD/MM/YYYY'),
        day.active_providers,
        day.trips,
        day.delivers,
        formatNumber(day.paid_to_provider),
        formatNumber(day.avg_ticket),
        formatNumber(day.service_fee),
        formatNumber(day.paid_by_company),
        formatNumber(day.paid_by_company + day.service_fee - day.paid_to_provider),
      ])
    });
    return resultsTable;
  };

  useEffect(() => {
    if (state.startDate && state.endDate) getDailyResults(state);
  }, [state]);

  return (
    <>
    <Container><h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333"}}>RESULTADOS POR DIA</h3></Container>
    <Container>
      <Card>
        <Row>
          <Col xl={3} lg={5} md={6}>
            <div style={{ color: '#999', fontStyle: 'italic' }}>
              <small>Filtrar período</small>
            </div>
            <DateRange value={[state.startDate, state.endDate]} onChange={handleDateChange} />
          </Col>
          <Col xl={3} lg={5} md={6}>
            <div style={{ color: '#999', fontStyle: 'italic' }}>
              <small>Filtrar frequência</small>
            </div>
            <Select
              name="frequency"
              value={state.frequency}
              options={FrequencyOptions}
              onChange={handleFrequencyChange}
            />
          </Col>
        </Row>
        <CardBody>
          {isDailyResultsLoaded ? (
            <Table responsive className="table-condensed">
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr
                  style={{
                    backgroundColor: "#EEE",
                    fontWeight: "bold",
                    borderBottom: "solid 2px #AAA"
                  }}
                >
                  <td></td>
                  <td>
                    Média: {
                      dailyResults.reduce(
                        (prev: number, r: any) => prev + r.active_providers,
                        0
                      ) /
                      dailyResults.length
                    }
                  </td>
                  <td>
                    {dailyResults.reduce(
                      (prev: number, r: any) => prev + r.trips,
                      0
                    )}
                  </td>
                  <td>
                    {dailyResults.reduce(
                      (prev: number, r: any) => prev + r.delivers,
                      0
                    )}
                  </td>
                  <td>
                    R$ {
                      formatNumber(
                        dailyResults.reduce(
                          (prev: number, r: any) => prev + r.avg_ticket,
                          0
                        ) /
                        dailyResults.length
                      )}
                  </td>
                  <td>
                    R$ {
                      formatNumber(
                        dailyResults.reduce(
                          (prev: number, r: any) => prev + r.paid_to_provider,
                          0
                        )
                      )}
                  </td>
                  <td>
                    R$ {
                      formatNumber(
                        dailyResults.reduce(
                          (prev: number, r: any) => prev + r.service_fee,
                          0
                        )
                      )}
                  </td>
                  <td>
                    R$ {
                      formatNumber(
                        dailyResults.reduce(
                          (prev: number, r: any) => prev + r.paid_by_company,
                          0
                        )
                      )}
                  </td>
                  <td className={
                    dailyResults.reduce(
                      (prev: number, r: any) => prev + r.paid_by_company,
                      0
                    ) +
                      dailyResults.reduce(
                        (prev: number, r: any) => prev + r.service_fee,
                        0
                      ) -
                      dailyResults.reduce(
                        (prev: number, r: any) => prev + r.paid_to_provider,
                        0
                      ) < 0
                      ? "text-danger"
                      : "text-success"
                  }
                  >
                    R$ {
                      formatNumber(
                        dailyResults.reduce(
                          (prev: number, r: any) => prev + r.paid_by_company,
                          0
                        ) +
                        dailyResults.reduce(
                          (prev: number, r: any) => prev + r.service_fee,
                          0
                        ) -
                        dailyResults.reduce(
                          (prev: number, r: any) => prev + r.paid_to_provider,
                          0
                        )
                      )
                    }
                  </td>
                </tr>
                {dailyResults.map((result, index) => (
                  <tr key={index}>
                    {state.frequency === "d"
                      ? <td>{moment(result.date).format('DD/MM/YYYY')} - {dayWeek[moment(result.date).get("day")]}</td>
                      : state.frequency === "m"
                        ? <td>{moment(result.date).format('MM/YYYY')}</td>
                        : <td>{result.date}</td>
                    }
                    <td>{result.active_providers}</td>
                    <td>{result.trips}</td>
                    <td>{result.delivers}</td>
                    <td>R$ {formatNumber(result.avg_ticket)}</td>
                    <td>R$ {formatNumber(result.paid_to_provider)}</td>
                    <td>R$ {formatNumber(result.service_fee)}</td>
                    <td>R$ {formatNumber(result.paid_by_company)}</td>
                    <td className={
                      result.paid_by_company + result.service_fee - result.paid_to_provider < 0
                        ? "text-danger"
                        : "text-success"
                    }>
                      R$ {formatNumber(result.paid_by_company + result.service_fee - result.paid_to_provider)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
              <Loader />
            )}
        </CardBody>
      </Card>
    </Container>
    </>
  );
};

const FrequencyOptions = [
  { label: "Diária", value: "d" },
  { label: "Mensal", value: "m" },
  { label: "Anual", value: "y" },
];

export default withTranslation("common")(DailyResults);
