// @ts-nocheck
import React, { useContext } from "react";
import { ProviderMetricsContext } from "../../../context/ProviderMetrics";
import Loader from "../../../components/Loader";
import styles from "./styles.module.css";

import { Col, Row, Card, CardBody, CardImg, Badge } from "reactstrap";
import { Icon } from "semantic-ui-react";

const ProviderDetails = () => {
  const {
    state: { details, isDetailsLoaded },
  } = useContext(ProviderMetricsContext);

  const { current_vehicle } = details;

  return (
    <Card>
      {isDetailsLoaded
        ? <Row>
          <Col className="pr-0" md="5">
            <CardImg
              top
              src={details.picture}
              alt="provider_image"
              className={styles.providerImage}
            />
          </Col>
          <Col className="pl-0">
            <CardBody>
              <Row>
                <Col md="7">
                  <h3>
                    {details.first_name} {details.last_name}
                  </h3>
                </Col>
                <Col md="5">
                  <h3 className="m-0">
                    <Badge color="primary" className="float-right">
                      {details.unique_id}
                    </Badge>
                  </h3>
                  <h3 className="m-0">
                    <Badge color="light" className="float-right mr-2">
                      <Icon color="yellow" name="star" />
                      {details.rate}
                    </Badge>
                  </h3>
                </Col>
                {Object.entries(current_vehicle).length > 0 &&
                  <>
                    <Col md="12">
                      <Badge color="success">{current_vehicle.service_type[0].typename}</Badge>
                    </Col>
                    <Col md="12">
                      {current_vehicle.service_type[0].typename === "Bike entrega" ? (
                        <p>{current_vehicle.model} - {current_vehicle.color}</p>
                      ) : (
                          <Row>
                            <Col>
                              <p>
                                {current_vehicle.model} - {current_vehicle.color} - {current_vehicle.plate_no}
                              </p>
                            </Col>
                          </Row>
                        )}
                    </Col>
                  </>
                }
              </Row>
            </CardBody>
          </Col>
        </Row>
        : <Loader height="260px" />
      }
    </Card>
  );
}

export default ProviderDetails;