// @ts-nocheck
import React, {useState} from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Input } from "reactstrap";

import { useCityForm } from "../../formHook";

  
const CityAutoComplete = () => {

    const { setLatAndLng } = useCityForm();

    const [address, setAddress] = useState("")

    const handleChange = (address: string) => {
        setAddress(address)
      };
    
    const handleSelect = (address: string) => {
          
        setAddress(address)
        console.log(address)
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => {
              setLatAndLng(latLng, address)
              //console.log('Success', latLng)
                })
          .catch(error => console.error('Error', error));
      };

      return null;

    //   (
    //     <PlacesAutocomplete
    //     value={address}
    //     onChange={handleChange}
    //     onSelect={handleSelect}
    //     >
    //         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
    //         <div>
    //             {/* <Input
    //                 {...getInputProps({
    //                     placeholder: 'Procure cidades...',
    //                     className: 'location-search-input',
    //                 })}
    //             /> */}
    //             <div className="autocomplete-dropdown-container" style={{position: "absolute", zIndex: 1, width: "100%"}}>
    //             {loading && <div style={{backgroundColor: '#ffffff'}}>Carregando...</div>}
    //             {suggestions.map(suggestion => {
    //                 const className = suggestion.active
    //                 ? 'suggestion-item--active'
    //                 : 'suggestion-item';
    //                 const style = suggestion.active
    //                 ? { backgroundColor: '#fafafa', cursor: 'pointer' }
    //                 : { backgroundColor: '#ffffff', cursor: 'pointer' };
    //                 return (
    //                 <div
    //                     {...getSuggestionItemProps(suggestion, {
    //                     className,
    //                     style,
    //                     })}
    //                 >
    //                     <span>{suggestion.description}</span>
    //                 </div>
    //                 );
    //             })}
    //             </div>
    //         </div>
    //         )}
    //     </PlacesAutocomplete>
    //   )
  }

  export default CityAutoComplete