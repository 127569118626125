// @ts-nocheck
import React, { useContext, useState, useEffect } from 'react';
import {
	CartesianGrid,
	ComposedChart,
	Line,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
	Legend,
	Bar,
	Brush,
} from 'recharts';
import { withTranslation } from 'react-i18next';
import Panel from '../../../components/Panel';
import Loader from '../../../components/Loader';
import Error from '../../../components/Error';
import OccupancyTooltipContent from './OccupancyTooltipContent';
import { DashboardContext } from '..';
import moment from 'moment';

const colors = ['#1E90FF', '#11cf99', '#b651ff', '#b6e948', '#e63838'];

const Deliveries = () => {

	const {
		state: { deliveriesDay, deliveriesWeek, deliveriesMonth, deliveriesAreLoading, deliveriesHasErrored, cities_view },
	} = useContext(DashboardContext);

	const showDeliveriesChart = !deliveriesAreLoading && !deliveriesHasErrored;

	const [legend, setLegend] = useState({cityLabels: []});
	const [usingData, setUsingData] = useState({currentWeek:[], lastWeek:[], mediam4Week:[]});

	useEffect(() => {
		loadLegend();
	}, [cities_view]);

	useEffect(() => {
		loadDeliveriesWeekDay();
	}, [deliveriesDay]);

	const textWeekDay = (d) => {
		switch (d) {
			case 1: return "Segunda"; break;
			case 2: return "Terça"; break;
			case 3: return "Quarta"; break;
			case 4: return "Quinta"; break;
			case 5: return "Sexta"; break;
			case 6: return "Sábado"; break;
			case 7: return "Domingo"; break;
			default: break;
		}
	}

	const loadDeliveriesWeekDay = () => {
		if(deliveriesDay.length > 0){
			let dataDeliveries = [];
			dataDeliveries.push(...deliveriesDay);

			let curremtWeek = []; let curremtWeekFinish = false;
			let lastWeek = []; let lastWeekFinish = false;
			let mediam4weeks = [];
			dataDeliveries.reverse().map(d => {
				if(!curremtWeekFinish){
					let data = d.data.split("/");
					if(moment(`${data[2]}-${data[1]}-${data[0]}`).isoWeekday() == 1) curremtWeekFinish = true;
					
					let wday = d;
					wday.name = textWeekDay(moment(`${data[2]}-${data[1]}-${data[0]}`).isoWeekday());
					curremtWeek.push(wday);
					// console.log('curremtWeek', moment(`${data[2]}-${data[1]}-${data[0]}`).toDate(), moment(`${data[2]}-${data[1]}-${data[0]}`).isoWeekday(), wday.name);
				}else{
					if(!lastWeekFinish){
						let data = d.data.split("/");
						if(moment(`${data[2]}-${data[1]}-${data[0]}`).isoWeekday() == 1) lastWeekFinish = true;
						
						let wday = d;
						wday.name = textWeekDay(`${moment(`${data[2]}-${data[1]}-${data[0]}`).isoWeekday()} - Anterior`);
						lastWeek.push(wday);
						// console.log('lastWeek',moment(`${data[2]}-${data[1]}-${data[0]}`).toDate(), moment(`${data[2]}-${data[1]}-${data[0]}`).isoWeekday(), wday.name);
					}

					if(mediam4weeks.length < 28) mediam4weeks.push(d);
				}
			});

			curremtWeek.reverse();
			lastWeek.reverse();

			let dataWeek = [];
			lastWeek.map((d,i) => {
				let x = {};
				Object.keys(d).map(o => { x[`${o}_anterior`] = d[o]});
				Object.keys(d).map(o => { x[`${o}_media`] = parseFloat(mediam4weeks.reduce((r, v) => {
					let d1 = v.data.split("/");
					let d2 = d.data.split("/");
					// console.log(o,d,v, moment(`${d1[2]}-${d1[1]}-${d1[0]}`).isoWeekday(),"==", moment(`${d2[2]}-${d2[1]}-${d2[0]}`).isoWeekday())
					if(moment(`${d1[2]}-${d1[1]}-${d1[0]}`).isoWeekday() == moment(`${d2[2]}-${d2[1]}-${d2[0]}`).isoWeekday()){
						// console.log(o, v[o], r)
						return r += v[o];
					}
					return r;
				},0) / 4).toFixed(0)});


				if(curremtWeek[i]){
					let cw = curremtWeek[i];
					Object.keys(cw).map(o => { x[`${o}`] = cw[o]});
				}
				dataWeek.push(x);
			});
			// console.log(dataWeek[0]);

			// curremtWeek.push(...lastWeek);
			setUsingData(prev => ({...prev, 
				currentWeek: dataWeek,
				// lastWeek: lastWeek
			}));
		}
	}
	const loadLegend = () => {
		if(cities_view.length > 0){
			let cl = [];
			cities_view.map(cv => { if(cv.city_code != "REC") cl.push(cv.city_code) });
			// console.log(cl);
			// Object.keys(deliveriesDay[0]).map(r => {	
			// 	if(r != "total" && r != 'data') cl.push(r);
			// })
			setLegend(prev => ({...prev, cityLabels: cl}));
		}
	}

	return (
		<>
		<Panel md={12} title="Entregas por dia" subhead={"Últimos 90 dias | " + (deliveriesDay.length ? deliveriesDay[deliveriesDay.length - 1].total.toLocaleString('pt-BR') : "-") + ' hoje'}>
			{deliveriesAreLoading ? (
				<Loader height="300px" />
			) : (
				<ResponsiveContainer height={260}>
					{showDeliveriesChart && legend.cityLabels.length > 0 ? (
						<ComposedChart data={deliveriesDay} margin={{ top: 20, left: -15 }}>
							<XAxis dataKey="data" tickLine={false} padding={{ left: 20 }} />
							<YAxis tickLine={false} />
							<Tooltip content={<OccupancyTooltipContent colorForKey={{ uv: '#333' }} />} />
							<CartesianGrid vertical={false} />
							<Brush dataKey="data" height={15} stroke="#8884d8" />
							{/* <Bar dataKey="total" name="Total" fill="#DDDDDD" barSize={20} /> */}
							{legend.cityLabels.map((citycode, idx) => ( cities_view.find(cv => cv.city_code == citycode).view ?
								<Bar
									dot={false}
									type="linear"
									key={citycode}
									name={citycode}
									dataKey={citycode}
									// stroke={colors[idx]}
									fill={"#DDDDDD"}
									// barSize={20}
								/>
							: null ))}
							<Legend /> 
						</ComposedChart>
					) : (
						<Error message="A lista de entregas está vazia." />
					)}
				</ResponsiveContainer>
			)}
		</Panel>
		<Panel md={4} title="Entregas por mes" subhead={"Últimos 12 meses | " + (deliveriesMonth.length ? deliveriesMonth[deliveriesMonth.length - 1].total.toLocaleString('pt-BR') : "-") + ' este mes'}>
			{deliveriesAreLoading ? (
				<Loader height="300px" />
			) : (
				<ResponsiveContainer height={260}>
					{showDeliveriesChart && legend.cityLabels.length > 0? (
						<ComposedChart data={deliveriesMonth} margin={{ top: 20, left: -15 }}>
						<XAxis dataKey="data" padding={{ left: 20 }} />
						<YAxis tickLine={false} />
						<Tooltip content={<OccupancyTooltipContent colorForKey={{ uv: '#333' }} />} />
						<CartesianGrid vertical={false} />
						{/* <Bar dataKey="total" name="Total" fill="#f2f4f7" barSize={20} /> */}
						{/* <Bar dataKey="total" name="Total" fill="#DDDDDD" barSize={20} /> */}
						{legend.cityLabels.map((citycode, idx) => ( cities_view.find(cv => cv.city_code == citycode).view ?
							<Bar
								dot={false}
								type="linear"
								key={citycode}
								name={citycode}
								dataKey={citycode}
								// stroke={colors[idx]}
								fill={"#DDDDDD"}
								// barSize={20}
							/>
						: null ))}
						{/* <Legend /> */}
					</ComposedChart>
					) : (
						<Error message="A lista de entregas está vazia." />
					)}
				</ResponsiveContainer>
			)}
		</Panel>
		<Panel md={4} title="Entregas por semana" subhead={"Últimas 12 semanas | " + (deliveriesWeek.length ? deliveriesWeek[deliveriesWeek.length - 1].total.toLocaleString('pt-BR') : "-") + ' esta semana'}>
			{deliveriesAreLoading ? (
				<Loader height="300px" />
			) : (
				<ResponsiveContainer height={260}>
					{showDeliveriesChart && legend.cityLabels.length > 0 ? (
						<ComposedChart data={deliveriesWeek} margin={{ top: 20, left: -15 }}>
							<XAxis dataKey="data" tickLine={false} padding={{ left: 20 }} />
							<YAxis tickLine={false} />
							<Tooltip content={<OccupancyTooltipContent colorForKey={{ uv: '#333' }} />} />
							<CartesianGrid vertical={false} />
							{/* <Bar dataKey="total" name="Total" fill="#f2f4f7" barSize={20} /> */}
							{/* <Bar dataKey="total" name="Total" fill="#DDDDDD" barSize={20} /> */}
							{legend.cityLabels.map((citycode, idx) => ( cities_view.find(cv => cv.city_code == citycode).view ?
								<Bar
									dot={false}
									type="linear"
									key={citycode}
									name={citycode}
									dataKey={citycode}
									// stroke={colors[idx]}
									fill={"#DDDDDD"}
									// barSize={20}
								/>
							: null ))}
							{/* <Legend /> */}
						</ComposedChart>
					) : (
						<Error message="A lista de entregas está vazia." />
					)}
				</ResponsiveContainer>
			)}
		</Panel>
		<Panel md={4} title="Entregas por dia da semana" subhead={'(A): Semana anterior | (M): Média de 4 semanas'}>
			{deliveriesAreLoading ? (
				<Loader height="300px" />
			) : (
				<ResponsiveContainer height={260}>
					{showDeliveriesChart && usingData.currentWeek.length > 0 && legend.cityLabels.length > 0 ? (
						<ComposedChart data={usingData.currentWeek} margin={{ top: 20, left: -15 }}>
							<XAxis dataKey="name" tickLine={false} padding={{ left: 20 }} />
							<YAxis tickLine={false} />
							<Tooltip content={<OccupancyTooltipContent colorForKey={{ uv: '#333' }} />} />
							<CartesianGrid vertical={false} />
							{/* <Bar dataKey="total" name="Total" fill="#f2f4f7" barSize={20} /> */}
							{/* <Bar dataKey="total_anterior" name="Anterior" fill="#DDDDDD" barSize={20} />
							<Bar dataKey="total_media" name="Média" fill="#AAAAAA" barSize={20} />
							<Bar dataKey="total" name="Atual" fill="#777777" barSize={20} /> */}
							{legend.cityLabels.map((citycode, idx) => [1,2,3].map(n => cities_view.find(cv => cv.city_code == citycode).view ?
								(n == 1 ? 
									 <Bar
										dot={false}
										type="linear"
										key={citycode}
										name={citycode}
										dataKey={citycode}
										// stroke={colors[idx]}
										fill={"#DDDDDD"}
										// barSize={20}
									/>
								: n == 2 ? 
									<Line
										dot={false}
										type="linear"
										strokeDasharray="3 3"
										key={`${citycode}_anterior`}
										name={`${citycode} (A)`}
										dataKey={`${citycode}_anterior`}
										stroke={`${colors[idx]}99`}
									/>
								:
									<Line
										dot={false}
										type="linear"
										strokeDasharray="1 1"
										key={`${citycode}_media`}
										name={`${citycode} (M)`}
										dataKey={`${citycode}_media`}
										stroke={`${colors[idx]}99`}
									/>
								)
							: null ))}
							{/* <Legend /> */}
						</ComposedChart>
					) : (
						<Error message="A lista de entregas está vazia." />
					)}
				</ResponsiveContainer>
			)}
		</Panel>
		</>
	);
};

export default withTranslation('common')(Deliveries);
