// @ts-nocheck
import React from "react"
//import { AuthContext } from "../context/Auth"
import { Route, Redirect } from "react-router-dom"

const PrivateRoute = ({ auth = false, component: Component, ...rest }) => {
  const loggedIn = localStorage.token

  return <Route {...rest} render={props => {
    if (loggedIn) {
      return <Component {...props} />
    } else {
      return <Redirect to="/login" />
    }
  }} />
}

const AuthRoute = ({ auth = false, component: Component, ...rest }) => {
  const loggedIn = localStorage.token

  return <Route {...rest} render={props => {
    if (loggedIn) {
      return <Redirect to="/dashboard" />
    } else {
      return <Component {...props} />
    }
  }} />
}

export {
  PrivateRoute,
  AuthRoute
}
