// @ts-nocheck
import React, { useContext } from 'react';
import { Legend, Pie, PieChart, ResponsiveContainer, Tooltip, Cell } from 'recharts';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import ArrowDownwardIcon from 'mdi-react/ArrowDownwardIcon';
import Panel from '../../../components/Panel';
import Error from '../../../components/Error';
import { DashboardContext } from '..';

const style = {
	left: '8px',
	bottom: '-25px',
	marginTop: '14px',
	lineHeight: '16px',
};

const renderLegend = ({ payload }) => (
	<ul className="dashboard__booking-reservations-chart-legend dashboard__chart-legend">
		{payload.map((entry, index) => (
			<li key={`item-${index}`}>
				<span style={{ backgroundColor: entry.color }} />
				<p>{entry.value}</p>
			</li>
		))}
	</ul>
);

renderLegend.propTypes = {
	payload: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.string,
			vslue: PropTypes.string,
		})
	).isRequired,
};

const Reservations = ({ t }) => {
	const {
		state: { schedules, schedulesIsLoaded },
	} = useContext(DashboardContext);

	const transformData = data => {
		const result = [];
		Object.entries(data).map(d => {
			if (d[0] !== 'total') {
				const name = getTripSchedules(d[0]);
				return result.push({ name, value: d[1] });
			}
			return '';
		});
		return result;
	};

	return (
		<Panel xl={5} lg={6} md={12} title="Agendamentos" panelClass="dashboard__booking-reservations-panel">
			{schedulesIsLoaded ? (
				// <div className="dashboard__booking-reservations">
				// 	<p className="dashboard__booking-reservations-title">Todos os Agendamentos</p>
				// 	<p className="dashboard__booking-reservations-number">{schedules.total}</p>
				// 	<div className="dashboard__booking-reservations-chart">
				// 		<ResponsiveContainer height={260}>
				// 			<PieChart className="dashboard__booking-reservations-chart-container">
				// 				<Tooltip />
				// 				<Legend layout="vertical" verticalAlign="middle" wrapperStyle={style} content={renderLegend} />
				// 				<Pie
				// 					data={transformData(schedules)}
				// 					nameKey="name"
				// 					dataKey="value"
				// 					cy={100}
				// 					innerRadius={57}
				// 					outerRadius={80}
				// 				>
				// 					<Cell key="value" fill="#fbe608" />
				// 					<Cell key="value" fill="#b8e986" />
				// 					<Cell key="value" fill="#ff4861" />
				// 				</Pie>
				// 			</PieChart>
				// 		</ResponsiveContainer>
				// 		{/*<a href="/" className="dashboard__booking-reservations-link">
				//   Download report <ArrowDownwardIcon className="dashboard__booking-reservations-link-icon" />
				// </a>*/}
				// 	</div>
				// </div>
				<React.Fragment>
					<h5 className="mb-4">Total de agendamentos: {schedules.total}</h5>
					<ResponsiveContainer height={300}>
						<PieChart>
							<Tooltip />
							<Legend layout="vertical" verticalAlign="bottom" wrapperStyle={style} content={renderLegend} />
							<Pie
								data={transformData(schedules)}
								nameKey="name"
								dataKey="value"
								cy="60%"
								// cx="70%"
								innerRadius={70}
								outerRadius={100}
							>
								{/* <Cell key="value" fill="#9f9f9f" /> */}
								<Cell key="value" fill="#fbe608" />
								<Cell key="value" fill="#b8e986" />
								<Cell key="value" fill="#ff4861" />
							</Pie>
						</PieChart>
					</ResponsiveContainer>
				</React.Fragment>
			) : (
				<Error message="Não há agendamentos" />
			)}
		</Panel>
	);
};

Reservations.propTypes = {
	t: PropTypes.func.isRequired,
};

const getTripSchedules = id => {
	const type = {
		cancelled: 'Cancelados',
		completed: 'Completados',
		in_progress: 'Em andamento',
		// open: "Abertos",
		default: 'N/A',
	};
	return type[id] || type.default;
};

export default withTranslation('common')(Reservations);
