// @ts-nocheck
import React from "react";
import { formatNumber } from "../../../utils/masks";
import { useToggle } from "../../../hooks/hooks";
// import { Collapse, Col, Row, Card, Table } from "reactstrap";
import moment from "moment";

const dayWeek = [
  "Domingo",
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sábado"
];

type Props = {
  company: any;
  distance: string;
  result: any;
  frequency: string;
}

const ListItem = ({ company, distance, result, frequency }: Props) => {
  const { toggle, active } = useToggle(false);

  return (
    <React.Fragment>
      <tr onClick={toggle}>
      {frequency === "d" ? (
              <td>
                {moment(result.date).format("DD/MM/YYYY")} -{" "}
                {dayWeek[moment(result.date).get("day")]}
              </td>
            ) : frequency === "m" ? (
              <td>{moment(result.date).format("MM/YYYY")}</td>
            ) : (
                  <td>{result.date}</td>
                )}
        <td>{company.id}</td>
        <td>{company.document}</td>
        <td>{company.full_name}</td>
        <td>{company.name}</td>
        <td>{company.delivers.total}</td>
        <td>{formatNumber(company.distance.total)} km</td>
        <td>{formatNumber(company.for_company.total)}</td>
        <td>{formatNumber(company.for_provider.total + company.tax_return.total )}</td>
        <td>{formatNumber(company.for_company.total - (company.for_provider.total + company.tax_return.total))}</td>
      </tr>
    </React.Fragment>
  );
}

export default ListItem;