// @ts-nocheck
import React, { PureComponent } from "react";
import { AuthContext } from "./Auth";

interface CompanyMetricsContext {
  state: State,
  action: {
    getMonthlyTrips(companyId: string, filter: any): void;
    getTripsPerProvider(companyId: string, filter: any): void;
    getTripsByDistance(companyId: string, filter: any): void;
    getServiceValues(companyId: string): void;
  }
}

export const CompanyMetricsContext = React.createContext({} as CompanyMetricsContext)

interface State {
  monthlyTrips: {
    dailyTrips: any[],
    monthlyAvg: number,
    isLoading: boolean,
    hasErrored: boolean,
  },
  tripsPerProvider: any[],
  tripsPerProviderIsLoading: boolean,
  tripsByDistance: {
    referenceDistance: number,
    tripsAbove: any[],
    tripsBelow: any[],
    isLoading: boolean,
    isErrored: boolean
  },
  serviceValues: {
    currentMonthResults: any[],
    lastMonthResults: any[],
    totalResults: any[],
    isLoading: boolean,
    hasErrored: boolean
  }
}

class CompanyMetricsProvider extends PureComponent<{}, State> {
  static contextType = AuthContext;

  constructor(props: any) {
    super(props);

    this.state = {
      monthlyTrips: {
        dailyTrips: [],
        monthlyAvg: 0,
        isLoading: false,
        hasErrored: false,
      },
      tripsPerProvider: [],
      tripsPerProviderIsLoading: false,
      tripsByDistance: {
        referenceDistance: 0,
        tripsAbove: [],
        tripsBelow: [],
        isLoading: false,
        isErrored: false
      },
      serviceValues: {
        currentMonthResults: [],
        lastMonthResults: [],
        totalResults: [],
        isLoading: false,
        hasErrored: false
      }
    }
  }

  getMonthlyTrips = (companyId: string, filter: any) => {
    const { request } = this.context.action;
    const url = `admin/v1/company/${companyId}/dashboard/monthly-trips`;

    this.setState({
      monthlyTrips: {
        dailyTrips: [],
        monthlyAvg: 0,
        isLoading: true,
        hasErrored: false,
      }
    }, async () => {
      try {
        const response = await request("POST", url, filter)
        const { success, data } = response.data;
        if (success) {
          this.setState({
            monthlyTrips: {
              dailyTrips: data.dailyTrips,
              monthlyAvg: data.monthlyAverage,
              isLoading: false,
              hasErrored: false,
            }
          })
        }
      } catch (error) {
        this.setState({
          monthlyTrips: {
            dailyTrips: [],
            monthlyAvg: 0,
            isLoading: false,
            hasErrored: true,
          }
        })
      }
    })
  }

  getTripsPerProvider = (companyId: string, filter: any) => {
    const { request } = this.context.action;
    const url = `admin/v1/company/${companyId}/dashboard/trips-per-provider`;

    this.setState({
      tripsPerProvider: [],
      tripsPerProviderIsLoading: true
    }, async () => {
      try {
        const response = await request("POST", url, filter)
        const { success, data } = response.data;
        if (success) {
          this.setState({
            tripsPerProvider: data.tripsPerProvider,
            tripsPerProviderIsLoading: false
          })
        }
      } catch (error) {
        this.setState({
          tripsPerProvider: [],
          tripsPerProviderIsLoading: false
        })
      }
    })
  }

  getTripsByDistance = (companyId: string, filter: any) => {
    const { request } = this.context.action;
    const url = `admin/v1/company/${companyId}/dashboard/trips-by-distance`;

    this.setState({
      tripsByDistance: {
        referenceDistance: 0,
        tripsAbove: [],
        tripsBelow: [],
        isLoading: true,
        isErrored: false
      }
    }, async () => {
      try {
        const response = await request("POST", url, filter)
        const { success, data: { referenceDistance, tripsAbove, tripsBelow } } = response.data;
        if (success) {
          this.setState({
            tripsByDistance: {
              referenceDistance,
              tripsAbove,
              tripsBelow,
              isLoading: false,
              isErrored: false
            }
          })
        }
      } catch (error) {
        this.setState({
          tripsByDistance: {
            referenceDistance: 0,
            tripsAbove: [],
            tripsBelow: [],
            isLoading: false,
            isErrored: true
          }
        })
      }
    })
  }

  getServiceValues = async (companyId: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/company/${companyId}/dashboard/service-values`;

    this.setState({
      serviceValues: {
        currentMonthResults: [],
        lastMonthResults: [],
        totalResults: [],
        isLoading: true,
        hasErrored: false
      }
    }, async () => {
      try {
        const response = await request("GET", url)
        const { success, data: { currentMonthResults, lastMonthResults, totalResults } } = response.data;
        if (success) {
          this.setState({
            serviceValues: {
              currentMonthResults,
              lastMonthResults,
              totalResults,
              isLoading: false,
              hasErrored: false
            }
          })
        }
      } catch (error) {
        this.setState({
          serviceValues: {
            currentMonthResults: [],
            lastMonthResults: [],
            totalResults: [],
            isLoading: false,
            hasErrored: true
          }
        })
      }
    })
  }

  render() {
    const { executeOnlyIfAuthenticated } = this.context.action

    const value = {
      state: { ...this.state },
      action: {
        getMonthlyTrips: executeOnlyIfAuthenticated(this.getMonthlyTrips),
        getTripsPerProvider: executeOnlyIfAuthenticated(this.getTripsPerProvider),
        getTripsByDistance: executeOnlyIfAuthenticated(this.getTripsByDistance),
        getServiceValues: executeOnlyIfAuthenticated(this.getServiceValues),
      }
    }

    return (
      <CompanyMetricsContext.Provider value={value}>
        {this.props.children}
      </CompanyMetricsContext.Provider>
    );
  }
}

export default CompanyMetricsProvider;