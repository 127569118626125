// @ts-nocheck
import React, { useContext } from "react";
import { ProfileContext, GenderOptions } from "../context";
import ProfileImage from "../../../../../components/ProfileImage";
import Select from "../../../../../components/Select";

import {
  Row,
  Col,
  Input,
  Label,
} from "reactstrap";

const ApprovalStatusOptions = [
  { label: "Em análise", value: "0" },
  { label: "Pendente", value: "1" },
  { label: "Completo", value: "2" },
];

const Content = () => {

  const {
    state,
    action: {
      onChange,
      onImageChange,
      onChangeApprovalStatus,
      onCityChange,
      onChangePlan,
      onGenderChange,
      onChangeTypePix
    }
  } = useContext(ProfileContext);

  return (
    <Row>
      <Col md="6">
        <Row>
          <Col xs="12" md="6" className="mb-3">
            <ProfileImage
              src={state.picture}
              showInput={!state.disabled}
              onChange={onImageChange}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label for="first_name">Nome</Label>
            <Input
              disabled={state.disabled}
              type="text"
              name="first_name"
              onChange={onChange}
              value={state.first_name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label for="last_name">Sobrenome</Label>
            <Input
              disabled={state.disabled}
              type="text"
              name="last_name"
              onChange={onChange}
              value={state.last_name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label for="gender">Sexo</Label>
            <Select
              disabled={state.disabled}
              name="gender"
              value={state.gender}
              onChange={onGenderChange}
              options={GenderOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Label for="cpf">CPF</Label>
            <Input
              disabled={state.disabled}
              type="text"
              name="document"
              onChange={onChange}
              value={state.document}
            />
          </Col>
          <Col md="6">
            <Label for="rg">RG</Label>
            <Input
              disabled={state.disabled}
              type="text"
              name="rg"
              onChange={onChange}
              value={state.rg}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Label for="pix_type">Tipo de PIX</Label>
            <Select
              disabled={state.disabled}
              name="pix_type"
              value={state.pix ? state.pix.type : ''}
              onChange={onChangeTypePix}
              options={[
                {label:"CPF / CNPJ", value:"CPF / CNPJ"},
                {label:"CELULAR", value:"CELULAR"},
                {label:"EMAIL", value:"EMAIL"},
                {label:"ALEATÓRIA", value:"ALEATÓRIA"}
              ]}
            />
            {/* <Input
              disabled={state.disabled}
              type="text"
              name="pix_type"
              onChange={onChange}
              value={state.pix ? state.pix.type : ''}
            /> */}
          </Col>
          <Col md="6">
            <Label for="pix_key">Chave</Label>
            <Input
              disabled={state.disabled}
              type="text"
              name="pix_key"
              onChange={onChange}
              value={state.pix ? state.pix.key : ''}
            />
          </Col>
        </Row>
      </Col>

      <Col md="6">
        <Row>
          <Col>
            <Label for="email">Email</Label>
            <Input
              disabled={state.disabled}
              type="text"
              name="email"
              onChange={onChange}
              value={state.email}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label for="phone">Telefone</Label>
            <Input
              disabled={state.disabled}
              type="text"
              name="phone"
              onChange={onChange}
              value={state.phone}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Endereço</Label>
            <Input
              disabled={state.disabled}
              type="text"
              name="address"
              onChange={onChange}
              value={state.address}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label for="cep">CEP</Label>
            <Input
              disabled={state.disabled}
              type="text"
              name="zipcode"
              onChange={onChange}
              value={state.zipcode}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Label for="country">País</Label>
            <Input
              disabled={state.disabled}
              type="text"
              name="country"
              onChange={onChange}
              value={state.country}
            />
          </Col>
          <Col md="6">
            <Label for="city">Cidade</Label>
            <Select
              disabled={state.disabled}
              name="city"
              value={state.city}
              onChange={onCityChange}
              options={state.cityOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Label for="plano">Plano</Label>
            <Select
              disabled={state.disabled}
              name="plan"
              value={state.plan}
              onChange={onChangePlan}
              options={state.planOptions}
            />
          </Col>
          <Col md="6">
            <Label>Group</Label>
            <Input
              disabled={state.disabled}
              type="text"
              name="group_name"
              onChange={onChange}
              value={state.group_name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label for="approval_status">Situação do cadastro</Label>
            <Select
              disabled={state.disabled}
              name="approval_status"
              value={state.approval_status != undefined ? state.approval_status.toString() : ""}
              onChange={onChangeApprovalStatus}
              options={ApprovalStatusOptions}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Content;
