// @ts-nocheck
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
// import TopbarMail from './TopbarMail';
// import TopbarNotification from './TopbarNotification';
// import TopbarSearch from './TopbarSearch';
import TopbarLanguage from './TopbarLanguage';

class Topbar extends PureComponent {
	static propTypes = {
		changeMobileSidebarVisibility: PropTypes.func.isRequired,
		changeSidebarVisibility: PropTypes.func.isRequired,
	};

	render() {
		// const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;

		return (
			<div className="topbar">
				<div className="topbar__wrapper">
					<div className="topbar__left">
						<Link className="topbar__logo" to="/dashboard" style={{ marginLeft: 30 }} />
					</div>
					<div className="topbar__right">
						<TopbarProfile />
					</div>
				</div>
			</div>
		);
	}
}

export default Topbar;
