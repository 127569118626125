// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import { ResultContext } from "../../context/Result";
import { getLastWeekDay } from "../../utils/dates";
import { formatNumber } from "../../utils/masks";
import DateRange from '../../components/DateRange';
import Loader from "../../components/Loader";

import { Container, Card, CardBody, Table, Col, Row } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { CSVLink } from "react-csv";
import moment from "moment";

const dayWeek = [
  "Domingo",
  "Segunda",
  "Terça",
  "Quata",
  "Quinta",
  "Sexta",
  "Sábado"
];

const headers = [
  "Entregador",
  "Categoria",
  "Qtd. entregas",
  "Distância(Km)",
  "Tempo(minutos)",
  "Recebeu(R$)",
  "Pago pela empresa(R$)",
  "Margem(R$)"
];

const TripResults = () => {

  const [state, setState] = useState({
    startDate: getLastWeekDay(),
    endDate: new Date(),
    frequency: "d"
  });

  const {
    state: { tripResults, isTripResultsLoaded },
    action: { getTripsResults }
  } = useContext(ResultContext);

  const handleDateChange = (dates: Date[]) =>
    setState(prev => ({ ...prev, startDate: dates[0], endDate: dates[1] }));

  const transformData = (results: any[]) => {
    const tripsTable: any[] = [];
    results.map((day: any) => {
      day.trips.map((trip: any) => (
        tripsTable.push([
          moment(day.date).format("DD/MM/YYYY"),
          trip.provider_name,
          trip.type,
          trip.delivers,
          formatNumber(trip.distance),
          formatNumber(trip.time),
          formatNumber(trip.paid_to_provider),
          formatNumber(trip.paid_by_company),
          formatNumber(trip.paid_by_company - trip.paid_to_provider)
        ])
      ));
    });
    return tripsTable;
  };

  useEffect(() => {
    if (state.startDate && state.endDate) getTripsResults(state);
  }, [state]);

  return (
    <Container>
      <Card>
        <h3 className="mb-4">Resultados / Entregas</h3>
        <Row>
          <Col xl={3} lg={5} md={6}>
            <div style={{ color: '#999', fontStyle: 'italic' }}>
              <small>Filtrar período</small>
            </div>
            <DateRange value={[state.startDate, state.endDate]} onChange={handleDateChange} />
          </Col>
        </Row>
        <Col md={6} className="m-0 p-0">
          <CSVLink
            className="btn btn-primary"
            data={!!tripResults ? transformData(tripResults) : []}
            headers={["Data", ...headers]}
            filename="resultados-entregas.csv"
          >
            Exportar
          </CSVLink>
        </Col>
        <CardBody>
          {isTripResultsLoaded
            ? (<Table responsive className="table-condensed">
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              {tripResults.map((day, index) => (
                <tbody key={index}>
                  <tr
                    style={{
                      backgroundColor: "#EEE",
                      fontWeight: "bold",
                      borderBottom: "solid 2px #AAA"
                    }}
                  >
                    {state.frequency === "d"
                      ? <td>{moment(day.date).format('DD/MM/YYYY')} - {dayWeek[moment(day.date).get("day")]}</td>
                      : state.frequency === "m"
                        ? <td>{moment(day.date).format('MM/YYYY')}</td>
                        : <td>{day.date}</td>
                    }
                    <td>
                    </td>
                    <td>
                      {day.trips.reduce(
                        (prev: number, trip: any) => prev + trip.delivers,
                        0
                      )}
                    </td>
                    <td>
                      {formatNumber(
                        day.trips.reduce(
                          (prev: number, trip: any) => prev + trip.distance,
                          0
                        )
                      )}
                    </td>
                    <td>
                      {formatNumber(
                        day.trips.reduce(
                          (prev: number, trip: any) => prev + trip.time,
                          0
                        )
                      )}
                    </td>
                    <td>
                      R$ {formatNumber(
                        day.trips.reduce(
                          (prev: number, trip: any) =>
                            prev + trip.paid_to_provider,
                          0
                        )
                      )}
                    </td>
                    <td>
                      R$ {formatNumber(
                        day.trips.reduce(
                          (prev: number, trip: any) =>
                            prev + trip.paid_by_company,
                          0
                        )
                      )}
                    </td>
                    <td className={
                      day.trips.reduce(
                        (prev: number, trip: any) =>
                          prev + (trip.paid_by_company - trip.paid_to_provider),
                        0
                      ) < 0
                        ? "text-danger"
                        : "text-success"
                    }>
                      R$ {formatNumber(
                        day.trips.reduce(
                          (prev: number, trip: any) =>
                            prev + (trip.paid_by_company - trip.paid_to_provider),
                          0
                        )
                      )}
                    </td>
                  </tr>
                  {day.trips.map((trip: any, index: number) => (
                    <tr key={index}>
                      <td>{trip.provider_name}</td>
                      <td>{trip.type}</td>
                      <td>{trip.delivers}</td>
                      <td>{formatNumber(trip.distance)}</td>
                      <td>{formatNumber(trip.time)}</td>
                      <td>R$ {formatNumber(trip.paid_to_provider)}</td>
                      <td>R$ {formatNumber(trip.paid_by_company)}</td>
                      <td className={
                        trip.paid_by_company - trip.paid_to_provider < 0
                          ? "text-danger"
                          : "text-success"
                      }>
                        R$ {formatNumber(
                          trip.paid_by_company - trip.paid_to_provider
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              ))}
            </Table>)
            : (<Loader />)}
        </CardBody>
      </Card>
    </Container >
  );
};

export default withTranslation("common")(TripResults);
