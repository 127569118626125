// @ts-nocheck
import React, { useEffect, useContext, useState, useCallback } from "react";
import { FinanceContext } from "../../context/Finance";
import { formatNumber } from "../../utils/masks";
import CurrencyInput from 'react-currency-input';
import Filter from "./Filter";

import { Container, Card, CardBody, Table, Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label, Badge, ButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu, CardHeader } from "reactstrap";
import DateRange from '../../components/DateRange';
import { FaRegEdit, FaTimesCircle, FaFilter, FaCheckCircle, FaCircleNotch, FaCircle, FaDotCircle, FaRegCircle, FaSync } from "react-icons/fa";
import moment from "moment";
import { toast } from "react-toastify";

const Registers = ({}) => {
  const {
    state: { categories, cities, registers, date_start, date_end },
    action: { getCategories, getCities, getRegisters }
  } = useContext(FinanceContext);

  useEffect(() => {
    getCategories();
    getCities();
  }, []);

  useEffect(() => {
    loadFilters();
    if(cities.length && categories.length){
      filterRegisters();
    }
  }, [cities, categories]);

  useEffect(() => {
    console.log('registers', registers);
  }, [registers]);

  const [fields, setField] = useState({
    date_start: moment().startOf("month").toDate(),
    date_end: moment().endOf("month").toDate(),
    type:''
  });

  const [filters, setFilters] = useState({
    types:[
      {label: "Receita", active: true, id: "revenue"},
      {label: "Despesa", active: true, id: "expense"},
    ],
    cities: [],
    categories:[],
    isOpen: false
  });

  const text_type_category = (type) => {
    switch (type) {
      case 'revenue': return "Receita";
      case 'expense': return "Despesa";
      case 'other': return "Outro";
    
      default:
        break;
    }
  }
  
  const loadFilters = async () => {
    console.log("loadFilters start");
    let fcities = [];
    fcities.push({label: "Geral", active: true, id: null})
    cities.map(c => { fcities.push({label: `${c.citycode} - ${c.cityname}`, active: true, id: c._id})})
    setFilters(prev => ({...prev, cities: fcities}));
    console.log("loadFilters set cities", cities, fcities);

    let fcategories = [];
    categories.map(c => { fcategories.push({label: `${text_type_category(c.type)} - ${c.name}`, active: true, id: c._id})})
    setFilters(prev => ({...prev, categories: fcategories}));
    console.log("loadFilters set categories", categories, fcategories);

  }
  const toggleFilter = (filter, i) => {
    let newFilters = filters;
    newFilters[filter][i].active = !newFilters[filter][i].active;
    setFilters(prev => ({...prev, newFilters}));
  }

  const toggleFilters = () => {
    setFilters(prev => ({...prev, isOpen: !prev.isOpen}));
  }

  const apllyViewFilters = (registers) => {
    if(registers)
      return registers
        .filter(r => filters.types.find(f => f.active && f.id == r.type ))
        .filter(r => filters.categories.find(f => f.active && f.id == r.category ))
        .filter(r => filters.cities.find(f => f.active && f.id == r.city ));
    return registers;
  }

  const handleDateChange = useCallback((dates) => {
    setField(prev => ({...prev, date_start: dates[0], date_end: dates[1]}));
    console.log('dates', dates);
  },[]);

  const filterRegisters = () => {
    getRegisters(fields);
  }

  return (
    <>
    <Container>
      <h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333"}}>CONTROLE FINANCEIRO <small>/ RESULTADO NO PERIODO</small>
        { categories.length > 0 && cities.length > 0 && registers.length ? null : <span style={{float:"right"}}><FaSync className="load__icon" style={{height:10, width:10}}/></span>}
      </h3>
    </Container>
    <Container>
      <Row>
        <Col><Button size="sm" color="secondary" onClick={toggleFilters}><FaFilter/> Mais filtros</Button></Col>
        <Col md={3}>
          <div><DateRange name='rang_date' value={[fields.date_start, fields.date_end]} onChange={handleDateChange} /></div>
        </Col>
        <Col md={1}><Button size="sm" style={{width:'100%'}} color="primary" onClick={() => {filterRegisters()}}>Aplicar</Button></Col>
        <Col style={{display: (filters.isOpen ? 'inherit': 'none')}} xs={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12} style={{marginBottom: 10}}><strong>Filtros</strong></Col>
                <Col>
                  <div>Tipo</div>
                  {filters.types.map((f,i) => 
                    <div><Button onClick={() => toggleFilter('types', i)} size="sm" outline color="secondary" style={{margin:'3px 0', padding: '5px 10px'}}>{f.active ? <FaCheckCircle/> : <FaRegCircle />} {f.label}</Button></div>
                  )}
                </Col>
                <Col>
                  <div>Categorias</div>
                  {filters.categories.map((f,i) => 
                    <div><Button onClick={() => toggleFilter('categories', i)} size="sm" outline color="secondary" style={{margin:'3px 0', padding: '5px 10px'}}>{f.active ? <FaCheckCircle/> : <FaRegCircle />} {f.label}</Button></div>
                  )}
                </Col>
                <Col>
                  <div>Cidades</div>
                  {filters.cities.map((f,i) => 
                    <div><Button onClick={() => toggleFilter('cities', i)} size="sm" outline color="secondary" style={{margin:'3px 0', padding: '5px 10px'}}>{f.active ? <FaCheckCircle/> : <FaRegCircle />} {f.label}</Button></div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h4>RECEITAS</h4>
              <Table striped>
                <thead>
                  <tr style={{fontSize:11}}>
                    <th style={{textAlign:"left"}}>CATEGORIA</th>
                    <th style={{textAlign:"right"}}>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {registers.length && categories.length && cities.length ? categories.filter(c => c.type == "revenue").map((c,i) => (
                    <tr>
                      <td>{c.name}</td>
                      <td style={{textAlign:"right", color: (c.type == 'revenue' ? '#0da076' : '#db2828')}}>
                        R$ {formatNumber( apllyViewFilters(registers).filter(r => r.category == c._id).reduce((total, r) => total + r.value, 0) )}
                      </td>
                    </tr>
                  )) : ''}
                </tbody>
              </Table>
              <hr/>
              <h4>DESPESAS</h4>
              <Table striped>
                <thead>
                  <tr style={{fontSize:11}}>
                    <th style={{textAlign:"left"}}>CATEGORIA</th>
                    <th style={{textAlign:"right"}}>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {registers.length && categories.length && cities.length ? categories.filter(c => c.type == "expense").map((c,i) => (
                    <tr>
                      <td>{c.name}</td>
                      <td style={{textAlign:"right", color: (c.type == 'revenue' ? '#0da076' : '#db2828')}}>
                        R$ {formatNumber( apllyViewFilters(registers).filter(r => r.category == c._id).reduce((total, r) => total + r.value, 0) )}
                      </td>
                    </tr>
                  )) : ''}
                </tbody>
              </Table>
              <hr/>
              <h4>OUTROS</h4>
              <Table striped>
                <thead>
                  <tr style={{fontSize:11}}>
                    <th style={{textAlign:"left"}}>CATEGORIA</th>
                    <th style={{textAlign:"right"}}>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {registers.length && categories.length && cities.length ? categories.filter(c => c.type == "other").map((c,i) => (
                    <tr>
                      <td>{c.name}</td>
                      <td style={{textAlign:"right", color: ((registers).filter(r => r.category == c._id).reduce((total, r) => total + r.value, 0) >= 0 ? '#0da076' : '#db2828')}}>
                        R$ {formatNumber( apllyViewFilters(registers).filter(r => r.category == c._id).reduce((total, r) => total + r.value, 0) )}
                      </td>
                    </tr>
                  )) : ''}
                </tbody>
              </Table>
              <hr/>
              <Table striped>
                <tbody>
                  {registers.length && categories.length && cities.length ? (
                    <>
                      <tr>
                        <td style={{color: '#0da076'}}>Receitas</td>
                        <td style={{textAlign:"right", color: '#0da076'}}>
                          R$ {formatNumber(apllyViewFilters(registers).filter(r => r.value >= 0).reduce((total, r) => total + r.value, 0))}
                        </td>
                      </tr>
                      <tr>
                        <td style={{color: '#db2828'}}>Despesas</td>
                        <td style={{textAlign:"right", color: '#db2828'}}>
                          R$ {formatNumber(apllyViewFilters(registers).filter(r => r.value < 0).reduce((total, r) => total - r.value, 0))}
                        </td>
                      </tr>
                      <tr>
                        <td style={{color: '#000'}}>Resultado</td>
                        <td style={{textAlign:"right", color: '#000'}}>
                          R$ {formatNumber(apllyViewFilters(registers).reduce((total, r) => total + r.value, 0) )}
                        </td>
                      </tr>
                    </>
                  ) : ''}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default Registers;