// @ts-nocheck
import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

import { AuthContext } from "../../context/Auth"

export default class TopbarProfile extends PureComponent {
  static contextType = AuthContext

	constructor() {
		super();
		this.state = {
			collapse: false,
		};
	}

	logout = (e) => {
    e.preventDefault()
    this.context.action.signout()
	}

	toggle = () => {
		this.setState(prevState => ({ collapse: !prevState.collapse }));
	};

	render() {
		const { collapse } = this.state;
		let user = JSON.parse(localStorage.getItem('user'));
		return (
			<div className="topbar__profile">
				<button className="topbar__avatar" type="button" onClick={this.toggle}>
					{/* <img className="topbar__avatar-img" src={Ava} alt="avatar" /> */}
					<p className="topbar__avatar-name">{user.email}</p>
					<DownIcon className="topbar__icon" />
				</button>
				{collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
				<Collapse isOpen={collapse} className="topbar__menu-wrap">
					<div className="topbar__menu">
						{/* <TopbarMenuLink title="My Profile" icon="user" path="/account/profile" />
            <TopbarMenuLink title="Calendar" icon="calendar-full" path="/default_pages/calendar" />
            <TopbarMenuLink title="Tasks" icon="list" path="/default_pages/calendar" />
            <TopbarMenuLink title="Inbox" icon="inbox" path="/mail" />
            <div className="topbar__menu-divider" /> */}
						{/* <TopbarMenuLink title="Ajustes" icon="cog" path="/account/profile" /> */}
						{/* <TopbarMenuLink title="Lock Screen" icon="lock" path="/lock_screen" /> */}
						<TopbarMenuLink title="Sair" icon="exit" path="#" onClick={this.logout} />
					</div>
				</Collapse>
			</div>
		);
	}
}
