import {
  ProviderModel,
  Provider,
  Vehicle,
  DocumentStatus,
  DisapprovalMessage,
  Pix
} from "./provider.types";
import moment from "moment";

export const toFormData = (data: any): FormData => {
  let formData = new FormData();

  for (const key in data) {
    formData.append(key, data[key])
  }
  return formData;
}

export const transformVehicleForm = (form: any) => {
  const {
    _id,
    provider_id,
    isNew,
    document_picture,
    serviceOptions,
    doc_expiry_date,
    service_type,
    vehicle_is_approved,
    vehicle_is_selected,
    ...vehicleDetails
  } = form;

  const vehicle = {
    doc_expiry_date: doc_expiry_date
      ? moment(doc_expiry_date).format('YYYYMMDD')
      : null,
    service_type: JSON.stringify(service_type),
    vehicle_is_approved: Number(vehicle_is_approved),
    vehicle_is_selected: Number(vehicle_is_selected),
    ...vehicleDetails
  };

  const data = Object.create(null);

  const keys = [
    'vehicle_name',
    'vehicle_model',
    'vehicle_plate_no',
    'vehicle_color',
    'vehicle_passing_year',
    'vehicle_doc',
    'doc_expiry_date',
    'vehicle_is_approved',
    'vehicle_is_selected',
    'service_type'
  ];

  for (const key of keys) {
    if (vehicle[key] !== null) {
      if (isNew) {
        if (
          key !== 'vehicle_is_approved'
          &&
          key !== 'vehicle_is_selected'
        )
          data[key] = vehicle[key];
      }
      else
        data[key] = vehicle[key];
    }
  }
  return { _id, provider_id, isNew, data }
};

export const transformFilterForm = (filter: any) => {
  const data = Object.create(null);

  const keys = [
    "start_date",
    "end_date",
    "cityid",
    "provider_plan",
    "service_type",
    "gender",
    "group_name",
    "approval_status"
  ];

  for (const key of keys) {
    if (filter[key] !== "") data[key] = filter[key]
  }

  if (filter.query !== "" && filter.search !== "")
    data[filter.query] = filter.search

  return data
};

export const transformProviderData = (provider: ProviderModel, host: string): Provider => {
  const {
    _id,
    providerVehicleDocuments,
    vehicles,
    group_name,
    pix,
    ...providerOtherAtributes
  } = provider;

  const documents = provider.providerDocuments.map(doc => {
    const document_picture = doc.document_picture.startsWith('provider_document')
      ? `${host}storage/${doc.document_picture}`
      : doc.document_picture

    const status = DocumentStatus.find(d => !!doc && d.id === doc.status);
    const disapproval_message = DisapprovalMessage.find(d =>
      !!doc && doc.disapproval_message === d.code
    );
    return {
      ...doc,
      document_picture,
      status: !!status ? status.label : '',
      disapproval_message: !!disapproval_message ? disapproval_message.label : ''
    }
  });

  const new_vehicles: Vehicle[] = vehicles.map(v => {
    let doc = providerVehicleDocuments.find(d => d.vehicle_id === v._id);

    const doc_pic = doc
      ? doc.document_picture.startsWith('provider_document')
        ? `${host}storage/${doc.document_picture}`
        : doc.document_picture
      : "";

    const status = DocumentStatus.find(d => !!doc && d.id === doc.status);
    const disapproval_message = DisapprovalMessage.find(d =>
      !!doc && doc.disapproval_message === d.code
    );

    const document = !!doc
      ? {
        ...doc,
        document_picture: doc_pic,
        status: !!status ? status.label : '',
        disapproval_message: !!disapproval_message ? disapproval_message.label : ''
      }
      : undefined;

    return {
      ...v,
      document,
      provider_id: _id
    };
  });

  const picture = provider.picture.startsWith('provider_profile')
    ? `${host}storage/${provider.picture}`
    : provider.picture;

  return {
    ...providerOtherAtributes,
    _id,
    picture,
    documents,
    vehicles: new_vehicles,
    group_name: !!group_name ? group_name : '',
    pix: pix
  }
};