class MapSubject {
  constructor() {
    this.observers = [];
  }

  isSubscribed(fn) {
    return this.observers.indexOf(fn) >= 0;
  }

  subscribe(fn) {
    if (this.isSubscribed(fn)) return;
    this.observers.push(fn);
  }

  unsubscribe(fn) {
    if (this.isSubscribed(fn)) {
      const idx = this.observers.indexOf(fn);
      this.observers.splice(idx, 1);
    }
  }

  notify(type, ...args) {
    this.observers.forEach(observer => observer.update(type, args));
  }
}

export default new MapSubject();
