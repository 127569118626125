// @ts-nocheck
import React, { PureComponent } from 'react';
import { Input, Button } from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { withRouter } from 'react-router';

import { AuthContext } from '../../../context/Auth';

class LogInForm extends PureComponent {
	static contextType = AuthContext;

	state = {
		showPassword: false,
		email: '',
		password: '',
	};

	handleChange = e => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	login() {
		const { signin } = this.context.action;
		signin(this.state.email, this.state.password);
	}

	showPassword = e => {
		e.preventDefault();
		this.setState(prevState => ({ showPassword: !prevState.showPassword }));
	};

	handleKeyDown = e => {
		if (e.which === 13) this.login();
	};

	componentDidUpdate() {
		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.props.history.push('/dashboard');
		}
	}

	render() {
		const { showPassword, email, password } = this.state;

		return (
			<form className="form" onKeyDown={this.handleKeyDown}>
				<div className="form__form-group">
					<span className="form__form-group-label">Usuário</span>
					<div className="form__form-group-field">
						<div className="form__form-group-icon">
							<AccountOutlineIcon />
						</div>
						<Input
							type="email"
							name="email"
							value={email}
							placeholder="email@exemplo.com"
							onChange={this.handleChange}
						/>
					</div>
				</div>
				<div className="form__form-group">
					<span className="form__form-group-label">Senha</span>
					<div className="form__form-group-field">
						<div className="form__form-group-icon">
							<KeyVariantIcon />
						</div>
						<Input
							type={showPassword ? 'text' : 'password'}
							value={password}
							name="password"
							placeholder="&bull;&bull;&bull;&bull;&bull;&bull;"
							onChange={this.handleChange}
						/>
						<button
							type="button"
							className={`form__form-group-button${showPassword ? ' active' : ''}`}
							onClick={e => this.showPassword(e)}
						>
							<EyeIcon />
						</button>
					</div>
				</div>
				<div className="">
					<Button type="button" className="btn btn-primary " onClick={() => this.login()}>
						Entrar
					</Button>
				</div>
			</form>
		);
	}
}

export default withRouter(LogInForm);
