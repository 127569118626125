// @ts-nocheck
import React, { useEffect, useContext, useState } from "react";
import { CompanyContext } from "../../../context/Company";
import { useToggle } from "../../../hooks/hooks";
import PricePolicy from "./PricePolicy";
import styles from "./styles.module.css";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Col,
  Row
} from "reactstrap";
import {
  Input,
  Form,
  Button,
  Select,
  DropdownProps,
  Dropdown,
  Label
} from "semantic-ui-react";
import moment from "moment";

type Props = {
  company: any;
  isOpen: boolean;
  handleToggle(): void;
};

interface State {
  document: string;
  full_name: string;
  name: string;

  platform_manager: string;
  manager_email: string;
  manager_phone: string;

  financial_manager: string;
  financial_email: string;
  financial_phone: string;
}

const Edit = ({ company, isOpen, handleToggle }: Props) => {
  const {
    state: { origins },
    action: { editCompany }
  } = useContext(CompanyContext);

  const { active, toggle, activate } = useToggle(false);
  const [state, setState] = useState<State>({
    document: company.document,
    full_name: company.full_name,
    name: company.name,

    platform_manager: company.platform_manager,
    manager_email: company.manager_email,
    manager_phone:  company.manager_phone,
  
    financial_manager: company.financial_manager,
    financial_email: company.financial_email,
    financial_phone: company.financial_phone,
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState(prev => ({ ...prev, [name]: value }));
  };

  const handleSelect = (h: React.SyntheticEvent<HTMLElement, Event>, e: DropdownProps) => {
    const { name, value } = e;
    setState(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.SyntheticEvent<HTMLElement, Event>) => {
    editCompany(company._id, state);
  }

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} size="lg">
      <ModalHeader>Editar empresa</ModalHeader>
      {company && (
        <ModalBody>
          <Form>
            <div style={{marginBottom:10}}>DADOS DA EMPRESA</div>
            <Form.Input
              className={styles.inputField}
              name="document"
              label="Documento"
              placeholder="Documento"
              value={state.document}
              onChange={handleOnChange}
            />
            <Form.Input
              className={styles.inputField}
              name="full_name"
              label="Razão social"
              placeholder="Razão social"
              value={state.full_name}
              onChange={handleOnChange}
            />
            <Form.Input
              className={styles.inputField}
              name="name"
              label="Nome fantasia"
              placeholder="Nome fantasia"
              value={state.name}
              onChange={handleOnChange}
            />
            {/* <label
              style={{
                display: "block",
                margin: "0 0 .28571429rem 0",
                color: "rgba(0,0,0,.87)",
                fontSize: ".92857143em",
                fontWeight: 700,
                textTransform: "none"
              }}
            >Origem</label>
            <Dropdown
              placeholder="Selecione a origem"
              fluid
              search
              selection
              name="origin_id"
              options={origins}
              onChange={handleSelect}
            /> */}
            <div style={{marginBottom:10, marginTop:20}}>DADOS DE CONTATO</div>
            <Form.Input
              className={styles.inputField}
              name="platform_manager"
              label="Nome"
              placeholder="Nome"
              value={state.platform_manager}
              onChange={handleOnChange}
            />
            <Form.Input
              className={styles.inputField}
              name="manager_email"
              label="Email"
              placeholder="Email"
              value={state.manager_email}
              onChange={handleOnChange}
            />
            <Form.Input
              className={styles.inputField}
              name="manager_phone"
              label="Telefone"
              placeholder="Telefone"
              value={state.manager_phone}
              onChange={handleOnChange}
            />

            <div style={{marginBottom:10, marginTop:20}}>DADOS DE COBRANÇA</div>
            <Form.Input
              className={styles.inputField}
              name="financial_manager"
              label="Nome"
              placeholder="Nome"
              value={state.financial_manager}
              onChange={handleOnChange}
            />
            <Form.Input
              className={styles.inputField}
              name="financial_email"
              label="Email"
              placeholder="Email"
              value={state.financial_email}
              onChange={handleOnChange}
            />
            <Form.Input
              className={styles.inputField}
              name="financial_phone"
              label="Telefone"
              placeholder="Telefone"
              value={state.financial_phone}
              onChange={handleOnChange}
            />

            <Button color="red" onClick={handleToggle}>
              Cancelar
              </Button>
            <Button color="facebook" onClick={handleSubmit}>
              Salvar
              </Button>
          </Form>
        </ModalBody>
      )}
    </Modal>
  );
};

export default Edit;
