// @ts-nocheck
import React, { useContext } from "react";
import { FranchiseContext } from "../../../context/Franchise";
import { Card, CardBody, Badge, Table } from "reactstrap";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { formatNumber } from "../../../utils/masks";

const transactions_type_class = {
  receipt: "success",
  debit: "danger",
  transfer: "warning",
  i: "success",
  o: "danger"
};

const headers = ["Data", "Descrição", "Movimentação", "Valor"];

const Transactions = () => {
  const {
    state: { transactions }
  } = useContext(FranchiseContext);

  return (
    <Card>
      <CardBody>
        <div className={"float-left"}>
          <h5>
            <b>{transactions.length} transações</b>
          </h5>
        </div>
        <div className={"float-right"}>
          <h5>
            <b>
							R$ {formatNumber(
								transactions.reduce((prev, t) => prev + t.value, 0)
							)}
            </b>
          </h5>
        </div>
        <Table responsive hover className="table-condensed">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={index}>
                <td>{moment(transaction.date).format("DD/MM/YYYY")}</td>
                <td>{transaction.description}</td>
                <td>
                  <Badge
                    className="badge-small"
                    color={transactions_type_class[transaction.type]}
                  >
                    {/* {t("wallet." + transaction.type)} */}
                  </Badge>
                </td>
                <td>
                  {formatNumber(
                    transaction.type === "i"
                      ? transaction.value
                      : transaction.value * -1
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

// class Transactions extends PureComponent {
//   componentDidMount() {
//     // actions.franchiseFinancialTransactions.get({ franchise_id: '5b8d99e6755f031da78a6e52', filters: this.props.filters });
//   }

//   // totalTransactions(){
//   // 	let total = 0;
//   // 	this.props.franchiseFinancialTransactions.map((transaction) => ({
//   // 		this.total +=
//   // 	}), total);
//   // }

//   render() {
//     let { t, franchiseFinancialTransactions } = this.props;
//     if (!franchiseFinancialTransactions) franchiseFinancialTransactions = [];
//     let total = franchiseFinancialTransactions.reduce(
//       (prevVal, transaction) =>
//         transaction.type === "i"
//           ? prevVal + transaction.value
//           : prevVal - transaction.value,
//       0
//     );

//     return (
//       <Col md={12}>
//         <Card>
//           <CardBody>
//             <div className={"float-left"}>
//               <h5>
//                 <b>{franchiseFinancialTransactions.length} transações</b>
//               </h5>
//             </div>
//             <div className={"float-right"}>
//               <h5>
//                 <b>
//                   R${" "}
//                   <NumberFormat
//                     value={total}
//                     displayType={"text"}
//                     // thousandSeparator={true}
//                     fixedDecimalScale={true}
//                     decimalScale={2}
//                     thousandSeparator={"."}
//                     decimalSeparator={","}
//                   />
//                 </b>
//               </h5>
//             </div>
//             <Table
//               responsive
//               hover
//               striped
//               className={"table-condensed"}
//               style={{ borderTop: "solid 1px #DDD" }}
//             >
//               <thead>
//                 <tr>
//                   <th width={90}>
//                     <small>DATA / HORA</small>
//                   </th>
//                   <th>
//                     <small>DESCRIÇÃO</small>
//                   </th>
//                   <th>
//                     <small>MOVIMENTAÇÃO</small>
//                   </th>
//                   <th>
//                     <small className="float-right">VALOR</small>
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {franchiseFinancialTransactions.map((transaction, index) => (
//                   <tr key={"t-" + index}>
//                     <td>
//                       <Moment date={transaction.date} format="DD/MM/YYYY" />
//                     </td>
//                     <td>{transaction.description}</td>
//                     <td>
//                       <Badge
//                         className={"badge-small"}
//                         color={transactions_type_class[transaction.type]}
//                       >
//                         {t("wallet." + transaction.type)}
//                       </Badge>
//                     </td>
//                     <td
//                       align="right"
//                       className={
//                         "text-" + transactions_type_class[transaction.type]
//                       }
//                     >
//                       <NumberFormat
//                         value={
//                           transaction.type === "i"
//                             ? transaction.value
//                             : transaction.value * -1
//                         }
//                         displayType={"text"}
//                         // thousandSeparator={true}
//                         fixedDecimalScale={true}
//                         decimalScale={2}
//                         thousandSeparator={"."}
//                         decimalSeparator={","}
//                       />
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </Table>
//           </CardBody>
//         </Card>
//       </Col>
//     );
//   }
// }

export default withTranslation("common")(Transactions);
