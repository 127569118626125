import numeral from 'numeral';
import 'numeral/locales';
numeral.locale('pt-br');
import { banksCodes } from "../utils/bankCodes";

interface MaskChar {
	[x: number]: string;
}

const code: MaskChar = { 2: '.', 5: '-' };
export const maskCode = (str: string): string => {
	const numbers = str.replace(/\D/g, '').split('');
	const doc = numbers.reduce((number, n, i) => `${number}${(code[i] || '') + n}`, '');
	return doc.substr(0, 10);
};

export function formatPrice(str: string): string {
	const value = str.length === 1 ? `0${str}` : str;
	return numeral(Number(parseFloat(value.replace(/[\D]/g, '').replace(/(\d\d?)$/g, '.$1')).toFixed(2))).format(
		'0,0.00'
	);
}

export function formatDistance(str: string): string {
	const value = str.length === 1 ? `0${str}` : str;
	return numeral(Number(parseFloat(value.replace(/[\D]/g, '').replace(/(\d?)$/g, '.$1')).toFixed(2))).format('0,0.0');
}

export function formatNumberToDistance(number?: number): string {
	if (number) {
		return numeral(Number(number.toFixed(2))).format('0,0.0');
	} else return '0,0';
}

export function applyNumberFormat(str: any): string {
	return numeral(str).format('0,0');
}

export const formatNumber = (number?: number): string => {
	if (number) {
		return numeral(Number(number.toFixed(2))).format('0,0.00');
	} else return '0,00';
};

const digit8: MaskChar = { 0: '(', 2: ') ', 6: '-' };
const digit9: MaskChar = { 0: '(', 2: ') ', 7: '-' };
export const formatPhone = (str: string): string => {
	const clean = str.replace(/\D/g, '').split('');
	const format =
		clean.length >= 10
			? clean.reduce((p: string, v, i) => `${p}${(digit9[i] || '') + v}`, '')
			: clean.reduce((p: string, v, i) => `${p}${(digit8[i] || '') + v}`, '');
	return format.substr(0, 15);
};

const cpfChar: MaskChar = { 3: '.', 6: '.', 9: '-' };
export const formatCPF = (str: string): string => {
	const numbers = str.replace(/\D/g, '').split('');
	const doc = numbers.reduce((cpf, n, i) => `${cpf}${(cpfChar[i] || '') + n}`, '');
	return doc.substr(0, 14);
};

const timeChar: MaskChar = { 2: ':' };
export const formatTime = (str: string): string => {
	const numbers = str.replace(/\D/g, '').split('');
	const time = numbers.reduce((hour, minute, i) => `${hour}${(timeChar[i] || '') + minute}`, '');
	return time.substr(0, 5);
};

export const toNumber = (str: string): number => {
	return numeral(str).value();
};

export const onlyNumber = (str: string): string => {
	return str.replace(/\D/g, '');
};

export const transformCodeToBank = (cod: string) => {
  const bankTitle = banksCodes.find(bank => bank.value === cod);
  if (bankTitle) {
    return bankTitle.text;
  } else {
    return cod;
  }
};
