// @ts-nocheck
import React, { useEffect, useContext, useState, useCallback } from "react";
import { FinanceContext } from "../../context/Finance";
import { formatNumber, transformCodeToBank } from "../../utils/masks";
import Filter from "./Filter";

import { Container, Card, CardBody, Table, Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label, Badge } from "reactstrap";
import DateRange from '../../components/DateRange';
import { FaRegThumbsDown } from "react-icons/fa";
import moment from "moment";
import { toast } from "react-toastify";

const Registers = ({}) => {
  const {
    state: { categories, cities, registers, date_start, date_end },
    action: { getCategories, getCities, getRegisters }
  } = useContext(FinanceContext);

  useEffect(() => {
    getCategories();
    getCities();
    getRegisters();
  }, []);

  const [fields, setField] = useState({
    date_start: new Date(),
    date_end: new Date()
  });

  const handleDateChange = useCallback((dates) => {
    setField(prev => ({...prev, date_start: dates[0], date_end: dates[1]}));
    console.log('dates', dates);
  },[]);

  return (
    <>
    <Container>
      <h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333"}}>CONTROLE FINANCEIRO <small>/ LANÇAMENTOS POR CATEGORIA</small></h3>
    </Container>
    <Container>
      <Row>
        <Col md={3}>
          <DateRange name='rang_date' value={[fields.date_start, fields.date_end]} onChange={handleDateChange} />
        </Col>
        <Col md={3}><Button size="sm" style={{margin: '0px', marginBottom:10, padding:"7px 14px", textTransform:"uppercase", border:"none"}} color="primary" onClick={() => {console.log(fields)}}>Aplicar</Button></Col>
        <Col md={3}></Col>
        <Col md={3}></Col>
        <Col xs={6}>
          <Card>
            <CardBody>
              <h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333"}}><small>Receitas</small></h3>
              <Table striped>
                <thead>
                  <tr style={{fontSize:11}}>
                    <th>Categoria</th>
                    <th style={{textAlign:"right"}}>Previsto</th>
                    <th style={{textAlign:"right"}}>Realizado</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.length ? categories.filter(c => c.type == 'revenue').map((c,i) => (
                    <tr>
                      <td>{c.name}</td>
                      <td style={{textAlign:"right"}}>R$ {formatNumber(0)}</td>
                      <td style={{textAlign:"right", color: '#0da076'}}>R$ {formatNumber(0)}</td>
                    </tr> 
                  )) : ''}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col xs={6}>
          <Card>
            <CardBody>
              <h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333"}}><small>Despesas</small></h3>
              <Table striped>
                <thead>
                  <tr style={{fontSize:11}}>
                    <th>Categoria</th>
                    <th style={{textAlign:"right"}}>Previsto</th>
                    <th style={{textAlign:"right"}}>Realizado</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.length ? categories.filter(c => c.type == 'expense').map((c,i) => (
                    <tr>
                      <td>{c.name}</td>
                      <td style={{textAlign:"right"}}>R$ {formatNumber(0)}</td>
                      <td style={{textAlign:"right", color: '#db2828'}}>R$ {formatNumber(0)}</td>
                    </tr>
                  )) : ''}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default Registers;