// @ts-nocheck
import React, { useContext, useEffect, useState, useCallback } from "react";
import { ProviderContext } from "../../../../context/Provider";
import Pagination from "../../../../components/Pagination";
import Loader from "../../../../components/Loader";
import { cx } from "../../../../utils/classnames";
import styles from "../styles.module.css";

import { Table, Card, CardBody } from "reactstrap";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Button,
  Input,
  Label,
} from "reactstrap";
import { useToggle } from "../../../../hooks/hooks";
type Props = {
  providerId: string;
};

interface State {
  title: string;
  text: string;
}

const initialState = {
  title: "",
  text: ""
}

const AttendanceRecords = ({ providerId }: Props) => {
  const {
    state: { attendance, isAttendanceLoaded },
    action: { getAttendanceHistory, createAttendanceHistory }
  } = useContext(ProviderContext);

  const { active, toggle, activate } = useToggle(false);

  const [state, setState] = useState<State>(initialState);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;
    setState(prev => ({ ...prev, [name]: value }));
  }, []);

  const onSubmit = () => {
    toggle();
    createAttendanceHistory(providerId, state.title, state.text);
  }

  useEffect(() => {
    getAttendanceHistory(providerId)
  }, [])

  return (
    <>
      <Card>
        <CardBody>
          <div className={cx(styles.header, "mb-4")}>
            <div className={styles.title}>
              <h3>Histórico de atendimento</h3>
            </div>
            <span className="pr-4">
              <Icon name="add" className="link circular grey" onClick={toggle} />
            </span>
          </div>
          {isAttendanceLoaded ? (
            <Table responsive hover className="table--bordered mb-4">
              <thead>
                <tr>
                  <th>Data de criação</th>
                  <th>Responsável</th>
                  <th>Titulo</th>
                  <th>Comentários</th>
                </tr>
              </thead>
              <tbody>
                {attendance.map((item, index) => (
                  <tr key={index}>
                    <td>{moment(item.created_at).format("DD/MM/YYYY hh:mm")}</td>
                    <td>{item.admin ? item.admin.email : "--"}</td>
                    <td>{item.title}</td>
                    <td>{item.text}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (<Loader />)}
        </CardBody>
      </Card>
      <Modal isOpen={active} toggle={toggle} size="lg">
        <ModalHeader>Novo registro de atendimento</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label for="title">Titulo</Label>
              <Input
                type="text"
                name="title"
                onChange={onChange}
                value={state.title}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label for="text">Comentários</Label>
              <Input
                type="textarea"
                name="text"
                onChange={onChange}
                value={state.text}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSubmit}>
            Registrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AttendanceRecords;
