// @ts-nocheck
import React from "react";
import AlertIcon from "mdi-react/AlertCircleIcon";

export default ({ message }) => (
  <div className="error-screen-fallback">
    <AlertIcon color="#b7b3b3" size="12em" />
    <span className="error-message">{message}</span>
  </div>
);
