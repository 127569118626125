// @ts-nocheck
import React, { PureComponent } from 'react';
import { Container, Row, Button, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import MapContainer from './components/MapContainer.jsx';
import { AuthContext } from '../../context/Auth';
import { toast } from 'react-toastify';

export const DashboardMapContext = React.createContext({});

class DashboardMap extends PureComponent {
	static contextType = AuthContext;
	
	state = {
		deliveries: [], viewDeliveries: false,
		providers: [], viewProviders: true,
		companies: [], viewCompanies: false,
		days_from_company_active: 1
	};
	getProviders = async () => {
		const { request } = this.context.action;
		try {
			const response = await request('GET', 'admin/v1/dashboard/providers-active');

			if (response.data.success) {
			this.setState(prev => ({ ...prev, providers: response.data.data }));
			} else throw new Error("Erro ao buscar entregadores!");
		} catch (err) {
			this.setState(prev => ({ ...prev, companiesRetention: [] }));
			toast.error.bind(null, err.message)
		}
	};

	getDeliveries = async () => {
		const { request } = this.context.action;
		try {
			const response = await request('GET', 'admin/v1/dashboard/deliveries-in-date');

			if (response.data.success) {
				this.setState(prev => ({ ...prev, deliveries: response.data.data }));
			} else throw new Error("Erro ao buscar entregadores!");
		} catch (err) {
			this.setState(prev => ({ ...prev, companiesRetention: [] }));
			toast.error.bind(null, err.message)
		}
	};

	getCompanies = async () => {
		const { request } = this.context.action;
		try {
			const response = await request("POST", "admin/v1/dashboard/companies-last_trips", {city_id: ''});

			if (response.data.success) {
			this.setState(prev => ({ ...prev, companies: response.data.data }));
			} else throw new Error("Erro ao buscar empresas!");
		} catch (err) {
			this.setState(prev => ({ ...prev, companiesRetention: [] }));
			toast.error.bind(null, err.message)
		}
	};

	toggleCompanies = () => this.setState(prev => ({ ...prev, viewCompanies: !prev.viewCompanies }));
	toggleProviders = () => this.setState(prev => ({ ...prev, viewProviders: !prev.viewProviders }));
	toggleDeliveries = () => this.setState(prev => ({ ...prev, viewDeliveries: !prev.viewDeliveries }));
	setActiveDaysCompany = (e) => {
		const { value } = e.target;
		this.setState(prev => ({ ...prev, days_from_company_active: value >= 0 ? value : 1}));
	}

	componentDidMount() {
		this.getProviders();
		this.getDeliveries();
		this.getCompanies();
	}

	render() {
		const value = { state: this.state };
		
		return (
			<DashboardMapContext.Provider value={value}>
				<div style={{position:"fixed", top:60, right:0, padding:20, background:"transparent", zIndex:1, width:"auto"}}>
					<Button color={this.state.viewCompanies ? "primary" : "dark"} style={{marginBottom:0}} onClick={() => this.toggleCompanies()}>EMPRESAS</Button>
					<Button color={this.state.viewProviders ? "primary" : "dark"} style={{marginBottom:0}} onClick={() => this.toggleProviders()}>ENTREGADORES</Button>
					<Button color={this.state.viewDeliveries ? "primary" : "dark"} style={{marginBottom:0}} onClick={() => this.toggleDeliveries()}>ENTREGAS</Button>
					<Input style={{textAlign:"right", width:50, display:"inline-flex"}} placeholder="Dias" value={this.state.days_from_company_active} type="number" onChange={(e) => this.setActiveDaysCompany(e)}/>
				</div>
				<MapContainer />
			</DashboardMapContext.Provider>
		);
	}

}

export default withTranslation('common')(DashboardMap);
