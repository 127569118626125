import { useState, useReducer, useEffect } from 'react';

export const useToggle = (state: boolean = false) => {
	const [active, setActive] = useState(state);

	const toggle = () => setActive(active => !active);
	const disable = () => setActive(false);
	const activate = () => setActive(true);
	return { toggle, disable, activate, active };
};

export const useSelection = (heads: string[], initialState: string = heads[0]) => {
	type Action = {
		type: string;
	};
	type InitState = typeof initialState;
	const reducer = (state: InitState, action: Action) => {
		switch (action.type) {
			case action.type:
				return action.type;
			default:
				throw new Error();
		}
	};

	const [selection, dispatch] = useReducer(reducer, initialState);
	const dispatchSelection = (sel: string) => {
		return dispatch({ type: sel });
	};

	return {
		selection,
		dispatchSelection,
	};
};

export const useDebounce = (value: string, delay: number) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => setDebouncedValue(value), delay);
		return () => clearTimeout(handler);
	}, [value]);

	return debouncedValue;
};