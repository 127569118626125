// @ts-nocheck
import React, { useEffect, useState, useContext } from "react";
import MainWrapper from "./MainWrapper";
import Layout from "../layout";
import Login from "../pages/LogIn/index";
import Dashboard from "../pages/Dashboard/index";
import DashboardCity from "../pages/DashboardCity/index";
import CurrentOrders from "../pages/CurrentOrders/index";
import DashboardMap from "../pages/DashboardMap/index";
import FinancesDashboard from "../pages/FinancesDashboard/index";
import Wallet from "../pages/Wallet/index";
import ProviderPanel from "../pages/ProviderPanel";
import ProviderWallet from "../pages/ProviderWallet";
import ProviderMetrics from "../pages/ProviderMetrics";
import Trips from "../pages/Trips/index";
import TripsMonitoring from "../pages/Trips/monitoring";
import TripPanel from "../pages/TripPanel";
import Providers from "../pages/Providers";
import AddCity from "../pages/AddCity";
import QueueZones from "../pages/QueueZones";
import Cities from "../pages/Cities";
import Regions from "../pages/Regions";
import OpenRequests from "../pages/OpenRequests";
import DailyResults from "../pages/DailyResults";
import ProviderResults from "../pages/ProviderResults";
import ProviderDebts from "../pages/ProviderDebts";
import TripResults from "../pages/TripResults";
import CompanyResults from "../pages/CompanyResults/index";
import CompanyResultsCompare from "../pages/CompanyResults/compare";
import CityResults from "../pages/CityResults";
import Companies from "../pages/Companies";
import CompaniesRetention from "../pages/CompaniesRetention";
import CRMIndex from "../pages/CRM/index";
import CompanyMetrics from "../pages/CompanyMetrics";
import WithDrawals from "../pages/Withdrawals";
import WithDrawalRequests from "../pages/WithdrawalRequests";
import CompanyWallet from "../pages/CompanyWallet";
import Schedules from "../pages/Schedules";
import Credentials from "../pages/Credentials";
import TripOutside from "../pages/TripOutside";
import ControlFinancial from "../pages/ControlFinancial/index";
import ControlFinancialCategories from "../pages/ControlFinancial/categories";
import ControlFinancialRegisters from "../pages/ControlFinancial/registers";
import ControlFinancialRegistersInvoices from "../pages/ControlFinancial/invoices";
import ControlFinancialRegistersOpenInvoices from "../pages/ControlFinancial/open_invoices";
import ControlFinancialRegistersTransfers from "../pages/ControlFinancial/transfers";
import ControlFinancialRegistersOpenTransfers from "../pages/ControlFinancial/open_transfers";
import ControlFinancialReportCategories from "../pages/ControlFinancial/report_categories";
import ControlFinancialReportDRE from "../pages/ControlFinancial/report_dre";

import AuthProvider, { AuthContext } from "../context/Auth";
import TripsProvider from "../context/Trips";
import ProviderProvider from "../context/Provider";
import ZonesProvider from "../context/Zones";
import UserProvider from "../context/User";
import CompanyProvider from "../context/Company";
import CompanyMetricsProvider from "../context/CompanyMetrics";
import ResultProvider from "../context/Result";
import FinanceProvider from "../context/Finance";
import FranchiseProvider from "../context/Franchise";
import ProviderMetricsProvider from "../context/ProviderMetrics";
import SecondaryDashboardProvider from "../context/SecondaryDashboard";
import ScheduleProvider from "../context/Schedule";
import SettingsProvider from "../context/Settings";

import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { PrivateRoute, AuthRoute } from "./PrivateRoute";
import { getProvidersType } from "../utils/translateEnums";
import classnames from "classnames";

const wrappedRoutes = ({ history }) => {
  const { pathname } = history.location;
  const map = pathname.match(/(map)|(zones)/gi);

  const {
    state: { user },
  } = useContext(AuthContext);

  const [permissions, setPermissions] = useState({
    dashboard: false,
    results: false,
    wallet: false,
    trips: false,
    providers: false,
    schedules: false,
    companies: false,
    cities: false,
    financial: false,
    settings: false,
  });

  useEffect(() => {
    if (user && user.permissions) {
      const newPerimissions = { ...permissions };
      user.permissions.map((permission) => {
        newPerimissions[permission] = true;
      });
      setPermissions(newPerimissions);
    }
  }, [user]);



  return (
    <div className={classnames({ map })}>
      <Layout />
      <div className="container__wrap">
        {permissions.dashboard && (
          <>
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/dashboard_city" component={DashboardCity} />
            <Route path="/current_orders" component={CurrentOrders} />
            <Route path="/dashboard_map" component={DashboardMap} />
            <SecondaryDashboardProvider>
              <Route
                path="/secondary_dashboard"
                component={FinancesDashboard}
              />
            </SecondaryDashboardProvider>
          </>
        )}

        {permissions.wallet && (
          <FranchiseProvider>
            <Route path="/wallet" component={Wallet} />
          </FranchiseProvider>
        )}

        {permissions.trips && (
          <>
            <Route exact path="/trips" component={Trips} />
            <Route exact path="/trips_monitoring" component={TripsMonitoring} />
            <Route path="/trips/details/:tripId?" component={TripPanel} />
          </>
        )}

        {permissions.schedules && (
          <ScheduleProvider>
            <Route exact path="/schedules" component={Schedules} />
            <Route
              path="/schedules/trip_details/:tripId"
              component={TripPanel}
            />
          </ScheduleProvider>
        )}

        {permissions.results && (
          <ResultProvider>
            <Route path="/daily_results" component={DailyResults} />
            <Route path="/provider_results" component={ProviderResults} />
            <Route path="/provider_debts" component={ProviderDebts} />
            <Route path="/trip_results" component={TripResults} />
            <Route path="/company_results" component={CompanyResults} />
            <Route
              path="/company_results_compare"
              component={CompanyResultsCompare}
            />
            <Route path="/city_results" component={CityResults} />
          </ResultProvider>
        )}

        {permissions.cities && (
          <>
            <Route path="/cities" component={Cities} />
            <Route path="/regions" component={Regions} />
            <Route exact path="/add_city" component={AddCity} />
            <Route exact path="/zones" component={QueueZones} />
          </>
        )}

        {permissions.providers && (
          <>
            <Route
              exact
              path="/approved"
              render={(routeProps) => (
                <Providers
                  title="Entregadores aprovados"
                  type={getProvidersType("approved")}
                  {...routeProps}
                />
              )}
            />
            <Route
              exact
              path="/notapproved"
              render={(routeProps) => (
                <Providers
                  title="Entregadores não aprovados"
                  type={getProvidersType("not_approved")}
                  {...routeProps}
                />
              )}
            />
            <Route
              exact
              path="/disabled"
              render={(routeProps) => (
                <Providers
                  title="Entregadores bloqueados"
                  type={getProvidersType("disabled")}
                  {...routeProps}
                />
              )}
            />
            <Route
              exact
              path="/approved/provider/:providerId?"
              component={ProviderPanel}
            />
            <Route
              exact
              path="/notapproved/provider/:providerId?"
              component={ProviderPanel}
            />
            <Route
              exact
              path="/disabled/provider/:providerId?"
              component={ProviderPanel}
            />

            <Route
              path="/approved/provider_wallet/:providerId?"
              component={ProviderWallet}
            />
            <Route
              path="/notapproved/provider_wallet/:providerId?"
              component={ProviderWallet}
            />
            <Route
              path="/disabled/provider_wallet/:providerId?"
              component={ProviderWallet}
            />

            <ProviderMetricsProvider>
              <Route
                path="/approved/provider_metrics/:providerId?"
                component={ProviderMetrics}
              />
              <Route
                path="/notapproved/provider_metrics/:providerId?"
                component={ProviderMetrics}
              />
              <Route
                path="/disabled/provider_metrics/:providerId?"
                component={ProviderMetrics}
              />
            </ProviderMetricsProvider>

            <Route
              path="/approved/provider/trip_details/:tripId"
              component={TripPanel}
            />
            <Route
              path="/notapproved/provider/trip_details/:tripId"
              component={TripPanel}
            />
            <Route
              path="/disabled/provider/trip_details/:tripId"
              component={TripPanel}
            />
          </>
        )}

        {permissions.companies && (
          <>
            <Route path="/open_requests" component={OpenRequests} />
            <Route exact path="/companies" component={Companies} />
            <Route
              exact
              path="/companies_retention"
              component={CompaniesRetention}
            />
            {/* <Route path="/companies/company_wallet/:companyId?" component={CompanyWallet} /> */}
            {/* <CompanyMetricsProvider>
							<Route path="/companies/company_metrics/:companyId?" component={CompanyMetrics} />
						</CompanyMetricsProvider> */}

            <Route exact path="/crm/:crmId?" component={CRMIndex} />
          </>
        )}

        {permissions.financial && (
          <>
            <Route
              exact
              path="/control_financial"
              component={ControlFinancial}
            />
            <Route
              exact
              path="/control_financial/categories"
              component={ControlFinancialCategories}
            />
            <Route
              exact
              path="/control_financial/registers"
              component={ControlFinancialRegisters}
            />
            <Route
              exact
              path="/control_financial/invoices"
              component={ControlFinancialRegistersInvoices}
            />
            <Route
              exact
              path="/control_financial/open_invoices"
              component={ControlFinancialRegistersOpenInvoices}
            />
            <Route
              exact
              path="/control_financial/transfers"
              component={ControlFinancialRegistersTransfers}
            />
            <Route
              exact
              path="/control_financial/open_transfers"
              component={ControlFinancialRegistersOpenTransfers}
            />

            <Route
              exact
              path="/control_financial/report_categories"
              component={ControlFinancialReportCategories}
            />
            <Route
              exact
              path="/control_financial/report_dre"
              component={ControlFinancialReportDRE}
            />

            <Route
              exact
              path="/withdrawalrequests"
              component={WithDrawalRequests}
            />
            <Route exact path="/withdrawals" component={WithDrawals} />
            <Route
              path="/withdrawals/provider/:providerId?"
              component={ProviderPanel}
            />
            <Route
              path="/withdrawalrequests/provider/:providerId?"
              component={ProviderPanel}
            />
            <Route
              path="/withdrawals/provider_wallet/:providerId?"
              component={ProviderWallet}
            />
            <Route
              path="/withdrawalrequests/provider_wallet/:providerId?"
              component={ProviderWallet}
            />
            <Route
              path="/withdrawals/provider_metrics/:providerId?"
              component={ProviderMetrics}
            />
            <ProviderMetricsProvider>
              <Route
                path="/withdrawalrequests/provider_metrics/:providerId?"
                component={ProviderMetrics}
              />
            </ProviderMetricsProvider>
          </>
        )}

        {permissions.settings && (
          <SettingsProvider>
            <Route path="/credentials" component={Credentials} />
          </SettingsProvider>
        )}

        <Route path="/trip_outside" component={TripOutside} />

        {permissions.dashboard && (
          <Route exact path="/" component={Dashboard} />
        )}
      </div>
    </div>
  );
};

const Router = () => (
  <MainWrapper>
    <AuthProvider host={"https://api.vaay.app/"}>
      <TripsProvider>
        <UserProvider>
          <ProviderProvider>
            <ZonesProvider>
              <CompanyProvider>
                <FinanceProvider>
                  <Switch>
                    <AuthRoute path="/login" component={Login} />
                    <PrivateRoute path="/" component={wrappedRoutes} />
                  </Switch>
                </FinanceProvider>
              </CompanyProvider>
            </ZonesProvider>
          </ProviderProvider>
        </UserProvider>
      </TripsProvider>
    </AuthProvider>
    <ToastContainer />
  </MainWrapper>
);

export default Router;
