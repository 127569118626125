// @ts-nocheck
import React, { useState, useCallback, useContext } from "react";
import { Provider, City } from "../../../../context/Provider/provider.types";
import { formatPhone, formatCPF } from "../../../../utils/masks";
import { ProviderContext } from "../../../../context/Provider";
import { toast } from "react-toastify";

interface ProfileContext {
  state: ProviderState;
  action: {
    onClick(): void;
    onChange(
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void;
    onImageChange(file: File | string): void;
    onChangeApprovalStatus(value: string): void;
    onCityChange(value: string): void;
    onChangePlan(value: string): void;
    onChangeTypePix(value: string): void;
    onGenderChange(gender: string): void;
    onMessageChange(name: string, value: string): void;
    onCloseApproveModal(): void;
    onCloseDisableModal(): void;
    clearPasswordInput(): void;
    isValid(): boolean;
  };
}

export const ProfileContext = React.createContext({} as ProfileContext);

type Props = {
  children: React.ReactNode;
};

export interface ProviderState {
  providerId: string;
  id: number;
  first_name: string;
  last_name: string;
  gender: string;
  rg: string;
  document: string;
  phone: string;
  address: string;
  zipcode: string;
  city: string;
  country: string;
  email: string;
  plan: string;
  group_name: string;
  picture: string;
  disabled: boolean;
  nameButton: string;
  file: null | string | File;
  cityOptions: any[];
  planOptions: any[];
  password: string;
  confirmPassword: string;
  is_active: boolean;
  is_approved: boolean;
  is_available: boolean;
  is_disabled: boolean;
  approve: boolean;
  message_disabled: boolean;
  disapproval_message: string;
  disable: boolean;
  deactivation_message: string;
  deactivation_message_disable: boolean;
  approval_status: number
  pix: {type:string, key:string}
}

const ProfileProvider = ({ children }: Props) => {
  const {
    state: { provider, cities }
  } = useContext(ProviderContext);

  const [state, setState] = useState<ProviderState>(() =>
    transformInitialState(provider, cities)
  );

  const onClick = () => {
    const disabled = state.disabled ? false : true;
    const nameButton = disabled ? "Editar perfil" : "Salvar";
    setState(prev => ({ ...prev, disabled, nameButton }));
  };

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;

      if (name === "pix_key") {
        setState(prev => ({ ...prev, pix: {type:prev.pix.type, key: value} }));
      }else if (name === "phone") {
        setState(prev => ({ ...prev, [name]: formatPhone(value) }));
      } else if (name === "document") {
        setState(prev => ({ ...prev, [name]: formatCPF(value) }));
      } else if (name === "approve") {
        setState(prev => ({
          ...prev,
          approve: !prev.approve,
          message_disabled: !prev.approve,
          disapproval_message: ""
        }));
      } else if (name === "disable") {
        setState(prev => ({
          ...prev,
          disable: !prev.disable,
          deactivation_message_disable: !prev.disable,
          deactivation_message: "",
        }))
      } else {
        setState(prev => ({ ...prev, [name]: value }));
      }
    },
    []
  );

  const onImageChange = useCallback((file: File | string) => {
    setState(prev => ({ ...prev, file }));
  }, []);

  const onCityChange = useCallback((value: string) => {
    let planOptions: any[] = [];

    cities.find((c: any) => {
      if (c.id === value) {
        planOptions = c.plans.map((p: any) => ({
          value: p.type,
          label: p.name
        }));
        return true;
      } else return false;
    });

    setState(prev => ({
      ...prev,
      city: value,
      planOptions: planOptions
    }));
  }, []);

  const onChangeApprovalStatus = useCallback((value: string) => {
    // @ts-ignore
    setState(prev => ({ ...prev, approval_status: Number(value) }));
  }, []);

  const onChangePlan = useCallback((value: string) => {
    setState(prev => ({ ...prev, plan: value }));
  }, []);
  const onChangeTypePix = useCallback((value: string) => {
    setState(prev => ({ ...prev, pix: {type:value, key: prev.pix.key} }));
  }, []);

  const onGenderChange = useCallback((gender: string) => {
    setState(prev => ({ ...prev, gender }));
  }, []);

  const onMessageChange = useCallback((name: string, value: string) => {
    setState(prev => ({ ...prev, [name]: value }));
  }, []);

  const onCloseApproveModal = () => {
    setState(prev => ({
      ...prev,
      message_disabled: false,
      disapproval_message: "",
      approve: false
    }));
  };

  const onCloseDisableModal = () => {
    setState(prev => ({
      ...prev,
      deactivation_message_disable: false,
      deactivation_message: "",
      disable: false
    }));
  };

  const clearPasswordInput = () =>
    setState(prev => ({ ...prev, password: "", confirmPassword: "" }));

  const isValid = () => {
    let message = "";
    if (state.first_name === "") message = "Insira o nome do entregador";
    else if (state.last_name === "")
      message = "Insira o sobrenome do entregador";
    else if (state.gender === "") message = "Selecione o sexo do entregador";
    else if (state.document === "") message = "Insira o cpf do entregador";
    else if (state.phone === "") message = "Insira o telefone do entregador";
    else if (state.email === "") message = "Insira o email do entregador";
    else if (state.zipcode === "") message = "Insira o CEP do entregador";
    else if (state.city === "") message = "Selecione a cidade do entregador";
    else if (state.plan === "") message = "Selecione o plano do entregador";
    // falta o group_name

    if (!!message) {
      toast.error(message);
      return false;
    }
    return true;
  };

  const value = {
    state,
    action: {
      onClick,
      onChange,
      onImageChange,
      onChangeApprovalStatus,
      onCityChange,
      onChangePlan,
      onChangeTypePix,
      onGenderChange,
      onMessageChange,
      onCloseApproveModal,
      onCloseDisableModal,
      clearPasswordInput,
      isValid
    }
  };

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};

const transformInitialState = (provider: Provider, cities: City[]): ProviderState => {
  const { city, plan } = provider;

  const buffer = {
    atualCity: "",
    atualPlan: "",
    planOptions: []
  };

  const cityOptions = cities.map((c: any) => {
    if (c.name === city) {
      buffer["atualCity"] = c.id;
      buffer["planOptions"] = c.plans.map((p: any) => {
        if (p.name === plan) buffer["atualPlan"] = p.type;
        return { value: p.type, label: p.name };
      });
    }
    return { value: c.id, label: c.name };
  });

  let format_pix = {
    type: provider.pix && provider.pix.type ? provider.pix.type : '', 
    key: provider.pix && provider.pix.key ? provider.pix.key : '' 
  };

  return {
    providerId: provider._id,
    id: provider.unique_id,
    first_name: provider.first_name,
    last_name: provider.last_name,
    gender: provider.gender,
    rg: provider.rg,
    document: formatCPF(provider.document),
    phone: formatPhone(provider.phone),
    address: provider.address,
    zipcode: provider.zipcode,
    city: buffer.atualCity,
    country: provider.country,
    email: provider.email,
    plan: buffer.atualPlan,
    group_name: provider.group_name,
    picture: provider.picture,
    disabled: true,
    nameButton: "Editar Perfil",
    file: null,
    cityOptions,
    planOptions: buffer.planOptions,
    password: "",
    confirmPassword: "",
    is_active: Boolean(provider.is_active),
    is_approved: Boolean(provider.is_approved),
    is_available: Boolean(provider.is_available),
    is_disabled: Boolean(provider.is_disabled),
    approve: false,
    message_disabled: false,
    disapproval_message: "",
    disable: false,
    deactivation_message: "",
    deactivation_message_disable: false,
    approval_status: provider.approval_status,
    pix: format_pix,
  };
};

export const GenderOptions = [
  { value: "male", label: "Masculino" },
  { value: "female", label: "Feminino" }
];

export const PictureMessagesOptions = [
  { label: "Imagem não condiz com a pessoa", value: "picture_does_not_match" },
  { label: "Imagem borrada", value: "blurred_image" },
  { label: "Imagem descentralizada", value: "decentralized_image" }
];

export default ProfileProvider;
