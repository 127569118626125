// @ts-nocheck
import React, { useContext, useState, useEffect } from 'react';
import {
	ResponsiveContainer
} from 'recharts';
import moment from "moment";
import { withTranslation } from 'react-i18next';
import Panel from '../../../components/Panel';
import Loader from '../../../components/Loader';
import Error from '../../../components/Error';
import { DashboardContext } from '..';
import {
	getTripStatus,
	getTripStatusColors
  } from "../../../utils/translateEnums";

import Badge from "reactstrap/lib/Badge";

const colors = ['#1E90FF', '#11cf99', '#b651ff', '#b6e948'];

const MetricsToday = () => {

	const {
		state: { providersActive, deliveriesRunning, cities_view },
	} = useContext(DashboardContext);

	return (
		<>
		<Panel md={6} title="Entregadores online" subhead={"Disponíveis e em entregas"}>
			{providersActive.length == 0 ? (
				<Loader height="300px" />
			) : (
				<ResponsiveContainer height={380}>
					<div style={{height:380, overflowY:'auto'}}>
					{providersActive.map((provider) => { if(cities_view.find(c => c.city_code == provider.city[0].citycode).view) 
						return <div style={{padding:'8px 12px', background: '#f5f5f5', borderRadius:4, marginBottom:6}}>
							<Badge color="dark">{provider.city[0].citycode}</Badge> <a href={"approved/provider/" + provider._id} target="_blank"><Badge color="secondary">ID {("0000" + provider.unique_id).substr(-4)}</Badge></a> {provider.first_name} {provider.last_name} <small style={{color:"#999", fontSize:"11px"}}><i>({moment(provider.updated_at).calendar()})</i></small>
							{provider.current_trips.length == 0 ? <span style={{float:'right', color:'#10cf99', fontSize:11, textTransform:'uppercase'}} title="Disponível">&bull; Disponível</span> : 
								<span style={{float:'right', color:'#ffc107', fontSize:11, textTransform:'uppercase'}} title="Em entrega">&bull; Em entrega</span>
							}
						</div>
					})}
					</div>
				</ResponsiveContainer>
			)}
		</Panel>
		<Panel md={6} title="Entregas em andamento" subhead={"Solicitadas não finalizadas"}>
			{!deliveriesRunning ? (
				<Loader height="300px" />
			) : (
				<ResponsiveContainer height={380}>
					<div style={{height:380, overflowY:'auto'}}>
					{deliveriesRunning.map((delivery) => { if(cities_view.find(c => c.city_code == delivery.city[0].citycode).view) 
						return <div style={{padding:'8px 12px', background: '#f5f5f5', borderRadius:4, marginBottom:6}}>
							<Badge color="dark">{delivery.city[0].citycode}</Badge> <a href={"trips/details/" + delivery._id} target="_blank"><Badge color="secondary">ID {("000000" + delivery.unique_id).substr(-6)}</Badge></a> {moment(delivery.user_create_time).format("DD/MM kk:mm")}
							<Badge color={getTripStatusColors(delivery.status)} style={{float:'right'}}>
								{getTripStatus(delivery.status)}
							</Badge>
							<div>Loja: {delivery.user_first_name} {delivery.user_last_name}</div>
							{delivery.provider.length > 0 ? <div>Entregador: <a href={"approved/provider/" + delivery.provider[0]._id} target="_blank"><Badge color="secondary">ID {("0000" + delivery.provider[0].unique_id).substr(-4)}</Badge></a>  {delivery.provider[0].first_name} {delivery.provider[0].last_name}</div> : ''}
						</div>
					})}
					</div>
				</ResponsiveContainer>
			)}
		</Panel>
		{/* <Panel md={4} title="Funil de conversão" subhead={"Taxa de atendimento das entregas"}>
			{false ? (
				<Loader height="300px" />
			) : (
				<ResponsiveContainer height={260}>
					<div>...</div>
				</ResponsiveContainer>
			)}
		</Panel> */}
		</>
	);
};

export default withTranslation('common')(MetricsToday);
