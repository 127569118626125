// @ts-nocheck
import React, { PureComponent } from "react";
import { AuthContext } from "./Auth";

interface SecondaryDashboardContext {
  state: State,
  action: {
    getTripsPerProvider(filter: any): void;
    getTripsByDistance(filter: any): void;
    getServiceValues(): void;
  }
}

export const SecondaryDashboardContext = React.createContext({} as SecondaryDashboardContext)

interface State {
  tripsPerProvider: {
    list: any[],
    isLoading: boolean,
    hasErrored: boolean,
  },
  tripsByDistance: {
    referenceDistance: number,
    tripsAbove: any[],
    tripsBelow: any[],
    isLoading: boolean,
    hasErrored: boolean
  },
  serviceValues: {
    currentMonthResults: any[],
    lastMonthResults: any[],
    totalResults: any[],
    isLoading: boolean,
    hasErrored: boolean
  }
}

class SecondaryDashboardProvider extends PureComponent<{}, State> {
  static contextType = AuthContext;

  constructor(props: any) {
    super(props);

    this.state = {
      tripsPerProvider: {
        list: [],
        isLoading: false,
        hasErrored: false,
      },
      tripsByDistance: {
        referenceDistance: 0,
        tripsAbove: [],
        tripsBelow: [],
        isLoading: false,
        hasErrored: false
      },
      serviceValues: {
        currentMonthResults: [],
        lastMonthResults: [],
        totalResults: [],
        isLoading: false,
        hasErrored: false
      }
    }
  }

  getTripsPerProvider = async (filter: any) => {
    const { request } = this.context.action;
    const url = `admin/v1/dashboard/trips-per-provider`;

    this.setState({
      tripsPerProvider: {
        list: [],
        isLoading: true,
        hasErrored: false,
      }
    }, async () => {
      try {
        const response = await request("POST", url, filter)
        const { success, data } = response.data;
        if (success) {
          this.setState({
            tripsPerProvider: {
              list: data,
              isLoading: false,
              hasErrored: false,
            }
          })
        }
      } catch (error) {
        this.setState({
          tripsPerProvider: {
            list: [],
            isLoading: false,
            hasErrored: true,
          }
        })
      }
    });
  }

  getTripsByDistance = async (filter: any) => {
    const { request } = this.context.action;
    const url = `admin/v1/dashboard/trips-by-distance`;

    this.setState({
      tripsByDistance: {
        referenceDistance: 0,
        tripsAbove: [],
        tripsBelow: [],
        isLoading: true,
        hasErrored: false
      }
    }, async () => {
      try {
        const response = await request("POST", url, filter)
        const { success, data: { referenceDistance, tripsAbove, tripsBelow } } = response.data;
        if (success) {
          this.setState({
            tripsByDistance: {
              referenceDistance,
              tripsAbove,
              tripsBelow,
              isLoading: false,
              hasErrored: false
            }
          })
        }
      } catch (error) {
        this.setState({
          tripsByDistance: {
            referenceDistance: 0,
            tripsAbove: [],
            tripsBelow: [],
            isLoading: false,
            hasErrored: true
          }
        })
      }
    })
  }

  getServiceValues = async () => {
    const { request } = this.context.action;
    const url = "admin/v1/dashboard/service-values";

    this.setState({
      serviceValues: {
        currentMonthResults: [],
        lastMonthResults: [],
        totalResults: [],
        isLoading: true,
        hasErrored: false
      }
    }, async () => {
      try {
        const response = await request("GET", url)
        const { success, data: { currentMonthResults, lastMonthResults, totalResults } } = response.data;
        if (success) {
          this.setState({
            serviceValues: {
              currentMonthResults,
              lastMonthResults,
              totalResults,
              isLoading: false,
              hasErrored: false
            }
          })
        }
      } catch (error) {
        this.setState({
          serviceValues: {
            currentMonthResults: [],
            lastMonthResults: [],
            totalResults: [],
            isLoading: false,
            hasErrored: true
          }
        })
      }
    })
  }

  render() {
    const { executeOnlyIfAuthenticated } = this.context.action;

    const value = {
      state: { ...this.state },
      action: {
        getTripsPerProvider: executeOnlyIfAuthenticated(this.getTripsPerProvider),
        getTripsByDistance: executeOnlyIfAuthenticated(this.getTripsByDistance),
        getServiceValues: executeOnlyIfAuthenticated(this.getServiceValues),
      }
    }

    return (
      <SecondaryDashboardContext.Provider value={value}>
        {this.props.children}
      </SecondaryDashboardContext.Provider>
    );
  }
}

export default SecondaryDashboardProvider;