// @ts-nocheck
import React from "react";
import LogInForm from "./components/LogInForm";

const LogIn = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">
            <span className="account__logo">
              Vaay
              <span className="account__logo-accent">Dashboard</span>
            </span>
          </h3>
          <h4 className="account__subhead subhead">Gestão e monitoramento v.1.0.0</h4>
        </div>
        <LogInForm onSubmit />
      </div>
    </div>
  </div>
);

export default LogIn;
