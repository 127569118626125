// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { AuthContext } from "../../context/Auth";
import { ref, onValue, query, limitToLast } from "firebase/database";
import { database } from "../../services/firebase";
import { Table, Card, CardBody, Container, Col } from "reactstrap";

export const DashboardContext = React.createContext({});

const Dashboard = () => {
  const [orders, setOrders] = useState([]);

  const {
    state: { user },
  } = useContext(AuthContext);

  const value = { user };

  useEffect(() => {
    const dbRef = ref(database, "pedidos");

    // Crie uma consulta para obter os últimos 10 resultados
    const queryLast10 = query(dbRef, limitToLast(20));

    // Assine a consulta com onValue
    onValue(queryLast10, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setOrders(objetoParaArray(data).reverse());
      } else {
        // Handle the case where there are no data.
        setOrders([]);	
      }
    });
  }, []);

  return (
    <DashboardContext.Provider value={value}>
      <Container className="dashboard">
        <h3 style={{ marginBottom: 20, fontWeight: "normal", color: "#333" }}>
          ENTREGAS
        </h3>
        <Card>
          <CardBody>
            <Table responsive hover striped className="table--bordered mb-4">
              <thead>
                <tr>
                  <th className="border-0">Cliente</th>
                  <th className="border-0">Data</th>
                  <th className="border-0">Telefone</th>
                  <th className="border-0">Tipo</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order.id}>
                    <td>{order.cliente}</td>
                    <td>{order.data}</td>
					<td>{order.telefone}</td>
                    <td>
                      <p
                        style={{
                          backgroundColor:
                            order.tipo === "Pedido Cancelado"
                              ? "#fc5050"
                              : "#32c54a",
                          color: "#fff",
                          fontWeight: "bold",
                          borderRadius: 32,
                          textAlign: "center",
                          fontSize: 12,
						  padding: 5,
                        }}
                      >
                        {order.tipo}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* {orders.map((order) => (
              <div
                key={order.id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  border: "1px solid black",
                  margin: "10px",
                }}
              >
                <p>{order.cliente}</p>
                <p>{order.data}</p>
                <p>{order.sem_entregador}</p>
                <p>{order.tipo}</p>
              </div>
            ))} */}
          </CardBody>
        </Card>
      </Container>
    </DashboardContext.Provider>
  );
};

export default withTranslation("common")(Dashboard);

function objetoParaArray(objeto) {
  const arrayDeObjetos = [];

  for (const chave in objeto) {
    if (objeto.hasOwnProperty(chave)) {
      const objetoAtual = objeto[chave];
      const objetoTransformado = {
        id: chave,
        cliente: objetoAtual.cliente,
        data: objetoAtual.data,
		telefone: objetoAtual.telefone,
        sem_entregador: objetoAtual.sem_entregador,
        tipo: objetoAtual.tipo,
      };
      if (objetoAtual.telefone) {
        objetoTransformado.telefone = objetoAtual.telefone;
      }
      arrayDeObjetos.push(objetoTransformado);
    }
  }

  return arrayDeObjetos;
}
