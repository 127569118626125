// @ts-nocheck
import React, { useEffect, useContext } from "react";
import { FinanceContext } from "../../context/Finance";
import { formatNumber, transformCodeToBank } from "../../utils/masks";
import Filter from "./Filter";

import { Container, Card, CardBody, Table, Button } from "reactstrap";
import { FaRegThumbsDown } from "react-icons/fa";
import moment from "moment";

const heads = [
  "Motorista",
  "Email",
  "Titular",
  "CPF",
  "Banco",
  "Agência",
  "Conta", 
  "Tipo",
  "Valor",
  "Data da solicitação",
  "Data da confirmação",
  "Ação"
];

const WithDrawals = ({ history }) => {
  const {
    state: { withdrawalsProcessedRequests, loading, update },
    action: { getProcessedRequests, cancelRequest }
  } = useContext(FinanceContext);

  const onClick = (e, providerId) => {
    e.preventDefault();
    history.push({
      pathname: `/withdrawals/provider/${providerId}`
    });
  };

  useEffect(() => {
    getProcessedRequests();
  }, []);

  useEffect(() => {
    update && getProcessedRequests();
  }, [update]);

  return (
    <>
    <Container><h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333"}}>CONTROLE FINANCEIRO</h3></Container>
    <Container>
      <Card>
        <Filter/>
        <CardBody>
          {!loading ? (
            <Table responsive hover className="table--bordered mb-4">
              <thead>
                <tr
                  style={{
                    backgroundColor: "#EEE",
                    fontWeight: "bold",
                    borderBottom: "solid 2px #AAA"
                  }}
                >
                  <td>Coluna 1</td>
                  <td>Coluna 2</td>
                  <td>Coluna 3</td>
                  <td>Coluna 4</td>
                  <td>Coluna 5</td>
                  <td>Coluna 6</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>a</td>
                  <td>b</td>
                  <td>c</td>
                  <td>d</td>
                  <td>e</td>
                  <td>f</td>
                </tr>
                <tr>
                  <td>a</td>
                  <td>b</td>
                  <td>c</td>
                  <td>d</td>
                  <td>e</td>
                  <td>f</td>
                </tr>
                <tr>
                  <td>a</td>
                  <td>b</td>
                  <td>c</td>
                  <td>d</td>
                  <td>e</td>
                  <td>f</td>
                </tr>
                <tr>
                  <td>a</td>
                  <td>b</td>
                  <td>c</td>
                  <td>d</td>
                  <td>e</td>
                  <td>f</td>
                </tr>
              </tbody>
            </Table>
          ) : (
            ""
          )}
        </CardBody>
      </Card>
    </Container>
    </>
  );
};

export default WithDrawals;
