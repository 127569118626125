// @ts-nocheck
import React, { useContext, useEffect } from "react";
import { FinanceContext } from '../../context/Finance';
import DateRange from '../../components/DateRange';

import { Col } from "reactstrap";

const Filter = () => {
  const {
    state: { startDate, endDate },
    action: { handleDateChange, getProcessedRequests }
  } = useContext(FinanceContext);
  useEffect(() => {
    if (startDate && endDate) {
      getProcessedRequests(startDate, endDate)
    }
  }, [startDate, endDate]);

  return (
    <Col xl={3} lg={5} md={6}>
      <DateRange value={[startDate, endDate]} onChange={handleDateChange} />
    </Col>
  )
}

export default Filter;