// @ts-nocheck
import React, { PureComponent } from 'react';
import { CompanyContext } from '../../../context/Company';
import NewWindow from 'react-new-window';

class CostCenterWindow extends PureComponent {
	static contextType = CompanyContext;

	componentDidMount() {
		const { company } = this.props;
		if (company) this.context.action.fetchCostCenters(company);
	}

	render() {
		// const { costCenters } = this.context.state
		const { onClose, active } = this.props;

		return active ? (
			<NewWindow onUnload={onClose}>
				<h1>Título</h1>
			</NewWindow>
		) : null;
	}
}

export default CostCenterWindow;
