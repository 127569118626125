// @ts-nocheck
import React, { useEffect, useContext } from "react";
import { getPaymentMode, getTripStatus, getPaymentModeColors, getTripStatusColors } from "../../../../utils/translateEnums";
import { ProviderContext } from "../../../../context/Provider";
import { formatNumber } from "../../../../utils/masks";
import Pagination from "../../../../components/Pagination";
import Loader from "../../../../components/Loader";

import { CardBody, Card, Table, Badge } from "reactstrap";
import { RouteComponentProps } from "react-router";
import moment from "moment";

interface Props {
  providerId: string;
  history: any;
};

const TripRecords = ({ providerId, history }: Props) => {
  const {
    state: { tripPagination, trips, isTripLoaded },
    action: { getTripHistory }
  } = useContext(ProviderContext);

  const onChangePage = (pageNumber: number) =>
    getTripHistory(providerId, pageNumber);

  const handleOnClick = (
    e: React.ChangeEvent<HTMLTableRowElement | EventTarget>,
    tripId: string
  ) => {
    e.preventDefault();
    const parentPath = history.location.pathname.split("/")
    history.push({ pathname: `/${parentPath[1]}/provider/trip_details/${tripId}` });
  }

  useEffect(() => {
    getTripHistory(providerId, 1)
  }, []);

  return (
    <Card>
      <CardBody>
        <h3 className="mb-4">Histórico de viagens</h3>
        {isTripLoaded ? (
          <Table responsive hover className="table--bordered mb-4">
            <thead>
              <tr>
                <th>Id</th>
                <th>Início da corrida</th>
                <th>Término da corrida</th>
                <th>Cliente</th>
                <th>Total</th>
                <th>Pagamento</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {trips.map((trip, index) => (
                <tr key={index} onClick={e => handleOnClick(e, trip._id)}>
                  <td>{trip.unique_id}</td>
                  <td>{moment(trip.user_create_time).format("DD/MM/YYYY kk:mm")}</td>
                  <td>{moment(trip.provider_trip_end_time).format("DD/MM/YYYY kk:mm")}</td>
                  <td>{trip.user_first_name} {trip.user_last_name}</td>
                  <td>R$ {formatNumber(trip.total)}</td>
                  <td>
                    <Badge color={getPaymentModeColors(trip.payment_mode)}>
                      {getPaymentMode(trip.payment_mode)}
                    </Badge>
                  </td>
                  <td>
                    {!!trip.is_trip_cancelled
                      ? (
                        <Badge color="danger">
                          {trip.is_trip_cancelled_by_provider ? "Cancelada pelo entregador" : "Cancelada pelo usuário"}
                        </Badge>
                      )
                      : (
                        <Badge color={getTripStatusColors(trip.status)}>
                          {getTripStatus(trip.status)}
                        </Badge>
                      )
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (<Loader />)}
        <Pagination
          onChangePage={onChangePage}
          current={tripPagination.current_page}
          total={tripPagination.pages === 0 ? 1 : tripPagination.pages}
          initial={1}
          size={5}
        />
      </CardBody>
    </Card>
  );
};

export default TripRecords;
