// @ts-nocheck
// @ts-nocheck
import React from 'react'

import {
    Card,
    CardBody,
    Row,
    Col,
    Input,
    Label
} from "reactstrap";

import { useCityForm, FormState as City } from "./formHook";
import { MultiSelectInput } from '../../components/Multiselect';
import CityAutoComplete from './components/AutoComplete';


const AddCity = () => {

    const {
        state,
        onChange, onSelect, onMultiSelect
      } = useCityForm();

    // useEffect(() => {
    //     console.log(state)
        
    // }, [state])

      const options = [
        {label: "Pagamento Antecipado", value: "Pagamento Antecipado"},
        {label: "Pagamento Parcial", value: "Pagamento Parcial"},
        {label: "Encontro de Contas", value: "Encontro de Contas"},
        {label: "Gratuito", value: "Gratuito"},
      ];


    return (
        <React.Fragment>
            <h1>Adicionar Cidade</h1>
            <Card>
                <CardBody>
                    <Row>
                        <Col md="4">
                        <Label>País</Label>
                        <Input
                        type="text"
                        name="country"
                        onChange={onChange}
                        value={state.country}
                        />
                        </Col>
                        <Col md="4">
                        <Label>Cidade</Label>
                        <CityAutoComplete />
                        {/* <Input
                        type="text"
                        name="city"
                        onChange={onChange}
                        value={state.city}
                        /> */}
                        </Col>
                        <Col md="4">
                        <Label>Raio Da Chamada</Label>
                        <Input
                        type="text"
                        name="cityRadius"
                        onChange={onChange}
                        value={state.cityRadius}
                        />
                        </Col>
                        <Col md="4">
                        <Label>Latitude</Label>
                        <Input
                        type="text"
                        name="latitude"
                        onChange={onChange}
                        value={state.latitude}
                        />
                        </Col>
                        <Col md="4">
                        <Label>Longitude</Label>
                        <Input
                        type="text"
                        name="longitude"
                        onChange={onChange}
                        value={state.longitude}
                        />
                        </Col>
                        <Col md="4">
                        <Label>Código da cidade</Label>
                        <Input
                        type="text"
                        name="city_cod"
                        onChange={onChange}
                        value={state.city_cod}
                        />
                        </Col>
                        <Col md="4">
                        <Label>CEP</Label>
                        <Input
                        type="text"
                        name="zipcode"
                        onChange={onChange}
                        value={state.zipcode}
                        />
                        </Col>
                    </Row>
                    <Row>    
                        <Col md="4">
                        <Label>Verifique o valor da carteira por dinheiro</Label>
                        <Input type="select" name="isCheckProviderWallet" onChange={onSelect}>
                            <option>Sim</option>
                            <option>Não</option>
                        </Input>
                        </Col>
                        <Col md="4">
                        <Label>Motorista Mín. Valor da carteira para dinheiro</Label>
                        <Input
                        type="number"
                        name="minWallet"
                        onChange={onChange}
                        value={state.minWallet}
                        />
                        </Col>
                        <Col md="4">
                        <Label>Pergunte ao usuário por tarifa fixa</Label>
                        <Input type="select" name="isAskUserForFixedFare" onChange={onSelect}>
                            <option>Sim</option>
                            <option>Não</option>
                        </Input>
                        </Col>
                        <Col md="4">
                        <Label>Negócios em aeroportos</Label>
                        <Input type="select" name="airportBusiness" onChange={onSelect}>
                            <option>Sim</option>
                            <option>Não</option>
                        </Input>
                        </Col>
                        <Col md="4">
                        <Label>Negócio da cidade de destino</Label>
                        <Input type="select" name="isCityBusiness" onChange={onSelect}>
                            <option>Sim</option>
                            <option>Não</option>
                        </Input>
                        </Col>
                        <Col md="4">
                        <Label>Negócio da Zona</Label>
                        <Input type="select" name="zoneBusiness" onChange={onSelect}>
                            <option>Sim</option>
                            <option>Não</option>
                        </Input>
                        </Col>
                        <Col md="4">
                        <Label>Selecione os planos de pagamento</Label>
                        <MultiSelectInput options={options} onMultiSelected={onMultiSelect}/>
                        </Col>
                        <Col md="4">
                        <Label>Fuso Horário</Label>
                        <Input
                        type="text"
                        name="timezone"
                        onChange={onChange}
                        value={state.timezone}
                        />
                        </Col>
                        <Col md="4">
                        <Label>Negócios na cidade</Label>
                        <Input type="select" name="isBusiness" onChange={onSelect}>
                            <option>Sim</option>
                            <option>Não</option>
                        </Input>
                        </Col>
                        <Col md="4">
                        <Label>Pagamento em dinheiro</Label>
                        <Input type="select" name="isPaymentModeCash" onChange={onSelect}>
                            <option>Sim</option>
                            <option>Não</option>
                        </Input>
                        </Col>
                        <Col md="4">
                        <Label>Pagamento com cartão</Label>
                        <Input type="select" name="isPaymentModeCard" onChange={onSelect}>
                            <option>Sim</option>
                            <option>Não</option>
                        </Input>
                        </Col>
                        <Col md="4">
                        <Label>Aplicar Promoção com Dinheiro</Label>
                        <Input type="select" name="isPromoApplyForCash" onChange={onSelect}>
                            <option>Sim</option>
                            <option>Não</option>
                        </Input>
                        </Col>
                        <Col md="4">
                        <Label>Aplicar Promoção com Cartão</Label>
                        <Input type="select" name="isPromoApplyForCard" onChange={onSelect}>
                            <option>Sim</option>
                            <option>Não</option>
                        </Input>
                        </Col>
                        <Col md="4">
                        <Label>Valor do motorista por pagamento em dinheiro</Label>
                        <Input type="select" name="isProviderEarningSetInWalletOnCashPayment" onChange={onSelect}>
                            <option>Sim</option>
                            <option>Não</option>
                        </Input>
                        </Col>
                        <Col md="4">
                        <Label>Valor do motorista por outro pagamento</Label>
                        <Input type="select" name="isProviderEarningSetInWalletOnOtherPayment" onChange={onSelect}>
                            <option>Sim</option>
                            <option>Não</option>
                        </Input>
                        </Col>
                    </Row>
                    
                </CardBody>
            </Card>
            

        </React.Fragment>
    )
}

export default AddCity