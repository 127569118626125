// @ts-nocheck
import React from "react";
// @ts-nocheck
import ReactDOM from "react-dom";
import App from "./App";

import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(<App />, document.getElementById("root"));
