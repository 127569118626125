// @ts-nocheck
import React, { useEffect, useContext } from "react";
import { TripsContext } from "../../context/Trips";
import ProviderDetails from "./components/ProviderDetails";
import PaymentDetails from "./components/PaymentDetails";
import TripDirect from "./components/TripDirect";
import ClientDetails from "./components/ClientDetails";
import MapContainer from "./components/MapContainer";
import TripDetails from "./components/TripDetails";
import Deliveries from "./components/Deliveries";
import Loader from "../../components/Loader";
import styles from "./styles.module.css";
import Panel from "../../components/Panel"

import { Badge, Button, Col, Container } from "reactstrap";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps<{ tripId: string }> { };

const TripPanel = ({ history, match: { params } }: Props) => {

  const {
    state: { selectedTrip, isSelectedTripLoaded },
    action: { getTripDetails, cancelTrip },
  } = useContext(TripsContext);

  useEffect(() => {
    if (!params.tripId) return history.push("/trips")
    getTripDetails(params.tripId);
  }, []);

  const cancelThisTrip = (() => {
    cancelTrip(params.tripId)
  })

  const DisplayProviderStatus = ({ status, is_cancelled }: { status: number, is_cancelled: number }) => {
    if (is_cancelled == 1) {
      return <Badge style={{padding: 9, borderRadius: 5}} color="danger">Cancelado</Badge>
    }
    else {
      switch (status) {
        case 2:
          return <Badge style={{padding: 9, borderRadius: 5}} color="warning" >Entregador a caminho</Badge>
        case 4:
          return <Badge style={{padding: 9, borderRadius: 5}} color="warning" >Entregador chegou</Badge>
        case 6:
          return <Badge style={{padding: 9, borderRadius: 5}}color="warning" >Entrega iniciada</Badge>
        case 8:
          return <Badge style={{padding: 9, borderRadius: 5}} color="warning" >Retornando para o estabelecimento</Badge>
        case 9:
          return <Badge style={{padding: 9, borderRadius: 5}} color="success" >Entrega finalizada</Badge>
        default:
          return <Badge style={{padding: 9, borderRadius: 5}} color="dark" >Aguardando confirmação</Badge>
      }
    }
  }

  return (
    <>
      <Container>
        <Col>
          <h3 style={{marginLeft: 15, marginBottom: 20, fontWeight: "normal", color: "#333"}}>
            ENTREGA #{selectedTrip.unique_id}
            <div style={{float:"right"}}>
              {isSelectedTripLoaded ? (<Button style={{marginBottom: 0}} color="danger" onClick={cancelThisTrip} disabled={selectedTrip.is_trip_cancelled === 1}>Cancelar Corrida</Button>) : ""}
              {isSelectedTripLoaded &&
                selectedTrip.status != 2 &&  
                selectedTrip.status != 4 &&  
                selectedTrip.status != 6 &&  
                selectedTrip.status != 8 &&  
                selectedTrip.status != 9 ?  
                (<TripDirect buttonLabel="Direcionar a entregador" className="" />) : ""}
              
              <DisplayProviderStatus status={selectedTrip.status} is_cancelled={selectedTrip.is_trip_cancelled} />
            </div>
          </h3>
        </Col>
      </Container>
      <Container className={styles.container}>
        {isSelectedTripLoaded ? (
          <>
            <Col
              md={5}
              style={{
                padding: 0,
                maxHeight: 'calc(140vh - 130px)',
                overflow: 'auto',
              }}
            >
              <Container>
                <ClientDetails />
                {selectedTrip.status !== 0 &&
                  <ProviderDetails />
                }
                <TripDetails />
                {selectedTrip.destinations.map((d: any, index: number) => (
                  <Deliveries key={index} delivery={d} i={index}/>
                ))}
                {/* <PaymentDetails /> */}
              </Container>
            </Col>
            <Col md={7} style={{ padding: 0 }}>
              <MapContainer />
            </Col>
          </>
        ) : (<Loader />)}
      </Container>
      
      
      
    </>
  );
};

export default TripPanel