// @ts-nocheck
import React from "react";
import { formatNumber } from "../../../utils/masks";
import { useToggle } from "../../../hooks/hooks";
import moment from "moment";
import { Collapse, Col, Row, Card, Table } from "reactstrap";

const dayWeek = [
  "Domingo",
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sábado"
];

type Props = {
  city_id: any;
  city: any;
  result: any;
  frequency: string;
  recipe_total: number;
}

const ListItem = ({ city_id, city, result, frequency, recipe_total }: Props) => {
  const { toggle, active } = useToggle(false);

  return (
    <React.Fragment>
      <tr onClick={toggle}>
        {city_id ? <td>{frequency === "d" ? (<td>{result.data} - {dayWeek[result.day_number]}</td>) : (<td>{result.data}</td>)}</td> : ''}
        <td>{city.citycode}</td>
        <td style={{textAlign:'right'}}>{city.data.quantity_companies}</td>
        <td style={{textAlign:'right'}}>{city.data.quantity_providers}</td>
        <td style={{textAlign:'right'}}>{city.data.delivers}</td>
        <td style={{textAlign:'right'}}>{(result.total > 0 ? ((city.data.delivers / result.total)*100).toFixed(0) + '%' : '-')}</td>
        <td style={{textAlign:'right'}}>R$ {parseFloat(city.data.total_company).toLocaleString()}</td>
        <td style={{textAlign:'right'}}>R$ {parseFloat(city.data.total_provider).toLocaleString()}</td>
        <td style={{textAlign:'right'}}>R$ {(parseFloat(city.data.total_company) - parseFloat(city.data.total_provider)).toLocaleString()}</td>
        <td style={{textAlign:'right'}}>{(recipe_total > 0 ? (((parseFloat(city.data.total_company) - parseFloat(city.data.total_provider)) / recipe_total)*100).toFixed(0) + '%' : '-')}</td>
      </tr>
    </React.Fragment>
  );
}

export default ListItem;