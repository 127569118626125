// @ts-nocheck
import React, { useContext } from "react";
import { ProviderContext } from "../../../context/Provider";
import { formatNumber } from "../../../utils/masks";
import { Row, Col, Card, CardBody } from "reactstrap";
import styles from "./styles.module.css";

const FinancialResume = () => {
  const {
    state: { resume }
  } = useContext(ProviderContext);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody className={`${styles.card} p-5`}>
              <p>Saldo atual</p>
              R$ {formatNumber(resume.balance)}
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody className={`${styles.card} p-5`}>
              <p>Total arrecadado</p>
              R$ {formatNumber(resume.collected)}
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody className={`${styles.card} p-5`}>
              <p>Total debitado</p>
              R$ {formatNumber(resume.debit)}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody className={`${styles.card} p-5`}>
              <p>Valor retirado</p>
              R$ {formatNumber(resume.withdrawal)}
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody className={`${styles.card} p-5`}>
              <p>Crédito inserido pelo administrador</p>
              R$ {formatNumber(resume.admin_credit)}
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody className={`${styles.card} p-5`}>
              <p>Débito inserido pelo administrador</p>
              R$ {formatNumber(resume.admin_debit)}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FinancialResume;