// @ts-nocheck
import React from "react"
import moment from "moment"

const CompanyAdmins = ({ admins }) => {
  return (
    <div className="company-details__admins">
      <span className="text-big title">Administradores <div className="company-details__admins__count">{admins.length}</div></span>
      <div className="cards">
        {admins.map((admin, i) => (
          <div className="card" key={i}>
            <span className="text-small"><b>Nome:</b> {admin.display_name}</span>
            <span className="text-small"><b>Criado em:</b> {moment(admin.created_at).format("LL")}</span>
            <span className="text-small"><b>Email:</b> {admin.email}</span>
            <span className="text-small"><b>Telefone:</b> {admin.phone}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CompanyAdmins
