// @ts-nocheck
import React, { useContext, useState, useEffect } from "react";
import { Checkbox } from "../../components/Checkbox/Checkbox";
import {
  formatPrice,
  onlyNumber,
  toNumber,
  formatTime
} from "../../utils/masks";
import { TripsContext } from "../../context/Trips";
import DatePicker from "../../components/DatePicker";
import Select from "../../components/Select";

import {
  Container,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Col,
  Button
} from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";

const TripOutside = () => {
  const {
    state: { companies, services, isCreateOutsideTripSuccessful },
    action: { getCompanies, getTypes, createTripOutside }
  } = useContext(TripsContext);

  const initialState = {
    cost_center_id: "",
    type_id: "",
    end_address: "",
    provider_unique_id: "",
    amount: "",
    creation_date: new Date(),
    creation_time: "",
    estimated_distance: "",
    update_wallet: false
  }

  const [state, setState] = useState(initialState);

  const handleOnDateChange = (date: Date) =>
    setState(prev => ({ ...prev, creation_date: date }));

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let stateValue = "";
    if (name === "provider_unique_id") stateValue = onlyNumber(value);
    else if (name === "amount" || name === "estimated_distance")
      stateValue = formatPrice(value);
    else if (name === "creation_time") stateValue = formatTime(value);
    else stateValue = value;
    setState(prev => ({ ...prev, [name]: stateValue }));
  };

  const handleOnSelectChange = (value: string, name: string) =>
    setState(prev => ({ ...prev, [name]: value }));

  const handleOnCheckboxChange = (name: string, value: boolean) =>
    setState(prev => ({ ...prev, [name]: value }));

  const isValid = () => {
    let message = "";
    if (state.cost_center_id === "") message = "Selecione uma empresa";
    else if (state.type_id === "") message = "Selecione um serviço";
    else if (state.creation_date === null && state.creation_time === undefined)
      message = "A data de criação não pode ser vazia";
    else if (state.creation_time === "") message = "Insira a hora de criação";
    else if (state.end_address === "") message = "Insira o endereço de destino";
    else if (state.amount === "") message = "Insira o valor da corrida";
    else if (state.provider_unique_id === "")
      message = "Insira o id do entregador";

    if (!!message) {
      toast.error(message);
      return false;
    }
    return true;
  };

  const cleanForm = () => {
    setState(initialState)
    getCompanies()
    getTypes()
  }

  const submit = () => {
    if (isValid()) {
      const {
        provider_unique_id,
        amount,
        creation_date,
        creation_time,
        estimated_distance,
        ...others
      } = state;
      const data: any = { ...others };

      data["provider_unique_id"] = toNumber(provider_unique_id);
      data["amount"] = toNumber(amount);

      if (estimated_distance !== "")
        data["estimated_distance"] = toNumber(estimated_distance);

      const hour = Number(creation_time.split(":")[0]);
      const minutes = Number(creation_time.split(":")[1]);
      data["creation_date"] = moment(state.creation_date)
        .hours(hour)
        .minutes(minutes)
        .format("YYYY-MM-DD kk:mm");

      createTripOutside(data);
    }
  };

  useEffect(() => {
    getCompanies();
    getTypes();
  }, []);

  useEffect(() => {
    isCreateOutsideTripSuccessful && cleanForm()
  }, [isCreateOutsideTripSuccessful])

  return (
    <Container>
      <Card>
        <h3 className="page-title mb-4">Corridas por fora</h3>
        <Col md={12} lg={8} xl={5}>
          <CardBody className="p-5">
            <h4>Cadastrar corrida realizada por fora</h4>
            <FormGroup>
              <Label>Empresa</Label>
              <Select
                name="cost_center_id"
                value={state.cost_center_id}
                options={companies}
                onChange={handleOnSelectChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Tipo de serviço</Label>
              <Select
                name="type_id"
                value={state.type_id}
                options={services}
                onChange={handleOnSelectChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Data de criação</Label>
              <DatePicker
                value={state.creation_date}
                onChange={handleOnDateChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Hora de criação</Label>
              <Input
                placeholder="ex: 15:35"
                name="creation_time"
                value={state.creation_time}
                onChange={handleOnChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Endereço de destino</Label>
              <Input
                name="end_address"
                value={state.end_address}
                onChange={handleOnChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Distância estimada (opcional)</Label>
              <Input
                name="estimated_distance"
                value={state.estimated_distance}
                onChange={handleOnChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Valor da corrida</Label>
              <Input
                name="amount"
                value={state.amount}
                onChange={handleOnChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Id do entregador</Label>
              <Input
                name="provider_unique_id"
                value={state.provider_unique_id}
                onChange={handleOnChange}
              />
            </FormGroup>
            <Checkbox
              label="Atualizar a carteira do entregador"
              name="update_wallet"
              value={state.update_wallet}
              onChange={handleOnCheckboxChange}
            />
            <hr />
            <Button className="btn-secondary mt-4" onClick={cleanForm}>Limpar</Button>
            <Button className="btn-primary mt-4" onClick={submit}>
              Confirmar
            </Button>
          </CardBody>
        </Col>
      </Card>
    </Container>
  );
};

export default TripOutside;
