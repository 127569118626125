// @ts-nocheck
import React, { useEffect, useContext, useState } from "react";
import { ProviderContext } from "../../context/Provider";
import FinancialResume from "./components/FinancialResume";
import EntryForm from "./components/EntryForm";
import EntryList from "./components/EntryList";
import Loader from "../../components/Loader";

import { Container, Row, Col } from "reactstrap";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps<{ providerId: string }> { };

const ProviderWallet = ({ history, match: { params } }: Props) => {
  const [loading, setLoading] = useState(true);

  const {
    state: { isResumeLoaded, isStatementLoaded, update, isProviderLoaded, provider },
    action: { getFinancialResume, getStatement, getProvider }
  } = useContext(ProviderContext);

  useEffect(() => {
    if (!params.providerId) return history.push("/")
    getProvider(params.providerId)
    getFinancialResume(params.providerId)
    getStatement(params.providerId)
  }, [update])

  useEffect(() => {
    isResumeLoaded && isStatementLoaded && isProviderLoaded
      ? setLoading(false)
      : setLoading(true)
  }, [isResumeLoaded, isStatementLoaded, isProviderLoaded])

  return loading
    ? (<Loader />)
    : (
      <Container>
        <h3 className="mb-4">
          Carteira digital de <span style={{ color: "#50525f" }}>{provider.first_name} {provider.last_name}({provider.unique_id})</span>
        </h3>
        <FinancialResume />
        <Row>
          <Col>
            <EntryForm providerId={params.providerId} />
          </Col>
          <Col>
            <EntryList />
          </Col>
        </Row>
      </Container >
    );
}

export default ProviderWallet;