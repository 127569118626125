// @ts-nocheck
import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef
} from "react";
import { ProviderContext } from "../../../context/Provider";
import { getLastWeekDay } from "../../../utils/dates";
import DateRange from "../../../components/DateRange";
import Select from "../../../components/Select";
import styles from "./styles.module.css";

import { Input, Dropdown } from "semantic-ui-react";
import { Col, Label, Button } from "reactstrap";
//@ts-ignore
import debounce from "lodash.debounce";

export interface FilterState {
  start_date: Date;
  end_date: Date;
  cityid: string;
  cityOptions: any[];
  provider_plan: string;
  isPlanDisabled: boolean;
  planOptions: any[];
  service_type: string;
  isServiceDisabled: boolean;
  serviceOptions: any[];
  gender: string;
  group_name: string;
  query: string;
  search: string;
  approval_status: string | number
}

type Props = {
  updateParentFilter(f: FilterState): void;
  type: any;
};

const Filter = ({ updateParentFilter, type }: Props) => {
  const buttonRef = useRef(null);

  const {
    state: { cities, isCitiesLoaded, services, isServicesLoaded },
    action: { getServices, searchProviders, getCities }
  } = useContext(ProviderContext);

  const initialState = {
    start_date: getLastWeekDay(),
    end_date: new Date(),
    cityid: "",
    cityOptions: [],
    provider_plan: "",
    isPlanDisabled: true,
    planOptions: [],
    service_type: "",
    isServiceDisabled: true,
    serviceOptions: [],
    gender: "",
    group_name: "",
    query: "",
    search: "",
    approval_status: ""
  };

  const [state, setState] = useState<FilterState>(initialState);

  const handleOnChange = useCallback((e, data) => {
    const { value, name } = data;
    setState(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleDateChange = (dates: Date[]) =>
    setState(prev => ({ ...prev, start_date: dates[0], end_date: dates[1] }));

  const handleSelectChange = (value: any, name: string) =>
    setState(prev => ({ ...prev, [name]: value }));

  const handleApprovalSelectChange = (value: any, name: string) =>
    setState(prev => ({ ...prev, [name]: value != "" ? Number(value) : "" }));

  const handleCityChange = (value: string) => {
    let planOptions: any[] = [];

    cities.find((c: any) => {
      if (c.id === value) {
        planOptions = c.plans.map((p: any) => ({
          value: p.type,
          label: p.name
        }));
        return true;
      } else return false;
    });

    if (value === "") {
      setState(prev => ({
        ...prev,
        isPlanDisabled: true,
        isServiceDisabled: true
      }));
    } else {
      setState(prev => ({
        ...prev,
        isPlanDisabled: false,
        isServiceDisabled: false
      }));
    }

    setState(prev => ({
      ...prev,
      cityid: value,
      planOptions: planOptions
    }));
  };

  const filter = () => {
    searchProviders(type, 1, state);
    updateParentFilter(state);
  };

  const cleanFilter = () => {
    setState(initialState);
    searchProviders(type);
    updateParentFilter({} as FilterState);
    getCities("Brazil");
  };

  useEffect(() => {
    setState(prev => ({
      ...prev,
      cityOptions: cities.map(c => ({ label: c.name, value: c.id }))
    }));
  }, [isCitiesLoaded]);

  useEffect(() => {
    (state.cityid !== "" && getServices(state.cityid)) ||
      setState(prev => ({ ...prev, serviceOptions: [] }));
  }, [state.cityid]);

  useEffect(() => {
    isServicesLoaded &&
      state.cityid !== "" &&
      setState(prev => ({
        ...prev,
        serviceOptions: services.map(s => ({
          label: `${s.typename} (${s.description})`,
          value: s.typeid
        }))
      }));
  }, [isServicesLoaded]);

  useEffect(() => {
    const handleOnKeyDown = debounce((e: React.KeyboardEvent) => {
      if (e.which === 13 && buttonRef.current) {
        e.preventDefault();
        //@ts-ignore
        buttonRef.current.onClick();
      }
    }, 600)
    window.addEventListener("keydown", e => handleOnKeyDown(e));
  }, []);

  return (
    <div className={styles.filter}>
      <div className={styles.row}>
        <Col>
          <Label>Data do cadastro</Label>
          <DateRange
            value={[state.start_date, state.end_date]}
            onChange={handleDateChange}
          />
        </Col>
        <Col>
          <Label>Cidade</Label>
          <Select
            name="cityid"
            value={state.cityid}
            onChange={handleCityChange}
            options={state.cityOptions}
          />
        </Col>
        <Col>
          <Label>Plano</Label>
          <Select
            disabled={state.isPlanDisabled}
            name="provider_plan"
            value={state.provider_plan}
            onChange={handleSelectChange}
            options={state.planOptions}
          />
        </Col>
        <Col>
          <Label>Serviço</Label>
          <Select
            disabled={state.isServiceDisabled}
            name="service_type"
            value={state.service_type}
            onChange={handleSelectChange}
            options={state.serviceOptions}
          />
        </Col>
      </div>
      <div className={styles.row}>
        <Col md={2}>
          <Label>Situação do cadastro</Label>
          <Select
            name="approval_status"
            value=""
            onChange={handleApprovalSelectChange}
            options={ApprovalStatusOptions}
          />
        </Col>
        <Col md={2}>
          <Label>Sexo</Label>
          <Select
            name="gender"
            value=""
            onChange={handleSelectChange}
            options={GenderOptions}
          />
        </Col>
        <Col md={2}>
          <Label>Grupo</Label>
          <Input
            icon
            className={styles.searchboxWithLabel}
            size="small"
            name="group_name"
            onChange={handleOnChange}
          />
        </Col>
        <Col>
          <Input
            action={
              <Dropdown
                button
                basic
                floating
                labeled
                icon="sliders horizontal"
                className="icon"
                name="query"
                placeholder="Filtro"
                options={QueryOptions}
                value={state.query}
                onChange={handleOnChange}
              />
            }
            actionPosition="left"
            placeholder="Pesquisar"
            icon="search"
            size="small"
            className={styles.searchbox}
            name="search"
            value={state.search}
            onChange={handleOnChange}
          />
        </Col>
        <Col className={styles.center}>
          <Button
            color="secondary"
            className={styles.button}
            onClick={cleanFilter}
          >
            Limpar
          </Button>
          <Button
            ref={buttonRef}
            color="primary"
            className={styles.button}
            onClick={filter}
          >
            Filtrar
          </Button>
        </Col>
      </div>
    </div>
  );
};

const QueryOptions = [
  { key: "unique_id", text: "ID", value: "unique_id" },
  { key: "first_name", text: "Nome", value: "first_name" },
  { key: "last_name", text: "Sobrenome", value: "last_name" },
  { key: "document", text: "CPF", value: "document" },
  { key: "email", text: "Email", value: "email" },
  { key: "phone", text: "Telefone", value: "phone" }
];

const GenderOptions = [
  { label: "Masculino", value: "male" },
  { label: "Feminino", value: "female" }
];

const ApprovalStatusOptions = [
  { label: "Em análise", value: "0" },
  { label: "Pendente", value: "1" },
  { label: "Completo", value: "2" },
];

export default Filter;
