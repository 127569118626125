// @ts-nocheck
import React, { useContext } from "react";
import { TripsContext } from "../../../context/Trips";
import { formatPhone } from "../../../utils/masks";
import Panel from "../../../components/Panel";
import { Badge } from "reactstrap";

const ProviderDetails = () => {
  const {
    state: { selectedTrip }
  } = useContext(TripsContext);

  const { provider } = selectedTrip;

  return (
    <Panel title="Entregador">
      <div>
        <div style={{fontSize:13, fontWeight:"bold"}}><Badge style={{display: "inline"}}>ID {provider.unique_id}</Badge> {provider.first_name} {provider.last_name}</div>
        <div><span>{provider.email} - {formatPhone(provider.phone)}</span></div>
      </div>
    </Panel>
  );
}

export default ProviderDetails;