
//import actions from 'redux-auto'

export default function sidebar(sidebar = { collapse:false }, action) {
  return sidebar;
}

export function before (appsState, action){
  return action.payload;
}

export function after (newAppsState, action, oldAppsState){
  return newAppsState;
}
