// @ts-nocheck
import React, { useContext } from "react";
import { ProviderContext } from "../../../../../context/Provider";
import { CustomModal } from "../../../../../components/Modal";
import { ProfileContext } from "../context";
import { Link } from 'react-router-dom'

import {
  Row,
  Col,
  Input,
  Label,
  Button,
  FormGroup,
} from "reactstrap";
import { toast } from "react-toastify";

interface Props {
  path: string
};

const Footer = ({ path }: Props) => {
  const {
    state: { provider },
    action: { updateProvider, updatePassword }
  } = useContext(ProviderContext);

  const {
    state,
    action: {
      onChange,
      onClick,
      clearPasswordInput,
      isValid
    }
  } = useContext(ProfileContext);

  const parentPath = path.split("/");
  const walletPath = `/${parentPath[1]}/provider_wallet/${provider._id}`;
  const metricsPath = `/${parentPath[1]}/provider_metrics/${provider._id}`;

  const countryCode = provider.country_phone_code.replace("+", "");
  const urlWhatsapp = `https://wa.me/${countryCode}${provider.phone}`;

  const submit = () => {
    if (state.nameButton === "Salvar") {
      if (isValid()) {
        updateProvider(state.providerId, state);
        onClick()
      }
    } else onClick()
  };

  const changePassword = () => {
    const { password, confirmPassword } = state;
    password === confirmPassword
      ? updatePassword(state.providerId, state.password)
      : toast.error("Senhas diferentes!")
  };

  return (
    <Row>
      <Col md="3">
        <CustomModal
          title="Redefinir senha"
          trigger={(toggle: any) => (
            <Button onClick={toggle}>Redefinir Senha</Button>
          )}
          handleOnClick={changePassword}
          handleOnClose={clearPasswordInput}
        >
          <React.Fragment>
            <FormGroup>
              <Label>Nova senha</Label>
              <Input
                type="password"
                name="password"
                value={state.password}
                onChange={onChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Confirmar senha</Label>
              <Input
                type="password"
                name="confirmPassword"
                value={state.confirmPassword}
                onChange={onChange}
              />
            </FormGroup>
          </React.Fragment>
        </CustomModal>
      </Col>
      <Col md="9">
        <div className="float-right">
          <Link to={metricsPath}>
            <Button className="mr-3">Métricas</Button>
          </Link>
          {/* <a
            className="btn btn-secondary"
            // href={`/provider_wallet/${provider._id}`}
            href={walletPath}
          >
            Carteira digital
          </a> */}
          <Link to={walletPath}>
            <Button className="mr-3">Carteira digital</Button>
          </Link>
          <a
            className="btn btn-secondary"
            href={urlWhatsapp}
            target="_blank"
          >Whatsapp</a>
          <Button onClick={submit}>{state.nameButton}</Button>
        </div>
      </Col>
    </Row>
  );
};

export default Footer;
