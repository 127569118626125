// @ts-nocheck
import React, { useContext } from "react";
import { FilterContext } from "../context";
import { formatNumber } from "../../../utils/masks";

import { Table } from "reactstrap";
import moment from "moment";

const dayWeek = [
  "Domingo",
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sábado"
];

const headers = [
  "ID",
  "Entregador",
  "Viajens",
  "Entregas",
  "R$ Med/Viagem",
  "R$ Entregas",
  "R$ Tax Retorno",
  "R$ Total"
];

type Props = {
  providerResults: any;
}

const DataTable = ({ providerResults }: Props) => {
  const {
    state: { frequency, distance }
  } = useContext(FilterContext);

  return (
    <Table hover responsive className="table-condensed">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      {providerResults.map((result: any, index: number) => (
        <tbody key={index}>
          <tr
            style={{
              backgroundColor: "#EEE",
              fontWeight: "bold",
              borderBottom: "solid 2px #AAA"
            }}
          >
            <td>{result.providers.length} mots.</td>
            {frequency === "d"
              ? <td>{moment(result.date).format('DD/MM/YYYY')} - {dayWeek[moment(result.date).get("day")]}</td>
              : frequency === "m"
                ? <td>{moment(result.date).format('MM/YYYY')}</td>
                : <td>{result.date}</td>
            }
            <td>{result.providers.reduce((prev: number, r: any) => prev + r.trips,0)}</td>
            <td>{result.providers.reduce((prev: number, r: any) => prev + r.delivers,0)}</td>
            <td>R$ {formatNumber(result.providers.reduce((prev: number, r: any) => prev + r.total,0) / result.providers.reduce((prev: number, r: any) => prev + r.trips,0))}</td>
            <td>R$ {formatNumber(result.providers.reduce((prev: number, r: any) => prev + r.subtotal,0))}</td>
            <td>R$ {formatNumber(result.providers.reduce((prev: number, r: any) => prev + r.tax_return,0))}</td>
            <td>R$ {formatNumber(result.providers.reduce((prev: number, r: any) => prev + r.total,0))}</td>
          </tr>
          {result.providers.map((provider: any, index: number) => (
            <tr key={index}>
              <td>{provider.id}</td>
              <td>{provider.name}</td>
              <td>{provider.trips}</td>
              <td>{provider.delivers}</td>
              <td>R$ {formatNumber(provider.avg_ticket)}</td>
              <td>R$ {formatNumber(provider.subtotal)}</td>
              <td>R$ {formatNumber(provider.tax_return)}</td>
              <td>R$ {formatNumber(provider.total)}</td>
            </tr>
          ))}
        </tbody>
      ))}
    </Table>
  )
}

export default DataTable;