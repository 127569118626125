// @ts-nocheck
import React, { useContext } from "react";
import { ProviderMetricsContext } from "../../../context/ProviderMetrics";
import Loader from "../../../components/Loader";
import Panel from "../../../components/Panel";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  Legend
} from "recharts";
import moment from "moment";

const DailyTrips = () => {
  const {
    state: { dailyTrips, isDailyTripsLoaded }
  } = useContext(ProviderMetricsContext);

  const transformData = (data: any): any[] => {
    return data.dailyTrips
      .sort((prev: any, d: any) => (prev.date - d.date))
      .map((d: any) => ({
        ...d,
        date: moment(d.date).format('DD/MM/YYYY'),
        monthlyAvg: data.monthlyAverage
      }));
  };

  return (
    <Panel title="Entregas/Dia">
      {isDailyTripsLoaded
        ? <ResponsiveContainer height={260}>
          <LineChart
            data={transformData(dailyTrips)}
            margin={{ top: 20, left: -15 }}
          >
            <CartesianGrid strokeDasharray="5 5" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="monthlyAvg"
              dot={false}
              name="Média de entregas"
              stroke="#f8b68b"
              strokeDasharray="5 5"
            />
            <Line
              type="monotone"
              dataKey="totalTrips"
              name="Total de entregas"
              stroke="#8884d8"
            />
          </LineChart>
        </ResponsiveContainer>
        : <Loader height="260px" />
      }
    </Panel>
  );
};

export default DailyTrips;
