// @ts-nocheck
import React from "react";
import { formatNumber } from "../../../../utils/masks";
import styles from "./styles.module.css";

import {
  Card,
  CardBody,
  Row,
  Col
} from 'reactstrap';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import FinanceIcon from 'mdi-react/FinanceIcon';

type Props = {
  result: any;
  title: string;
}

const Cards = ({ result, title }: Props) => (
  <Col md={12} lg={6} xl={4} className="pr-3 pl-3">
    <Row className="mb-3">
      <Col><h3>{title}</h3> </Col>
    </Row>
    <Row>
      <Col md={6}>
        <Card>
          <CardBody className="dashboard__booking-card">
            <div className="dashboard__booking-total-container">
              <h5 className="dashboard__booking-total-title dashboard__booking-total-title">
                R$ {formatNumber(result.company)}
              </h5>
              <TrendingUpIcon className="dashboard__trend-icon" />
            </div>
            <h5 className="dashboard__booking-total-description">Pago pela empresa</h5>
            {/* <div className="progress-wrap progress-wrap--small progress-wrap--violet progress-wrap--rounded">
            <p className="dashboard__booking-card-progress-label progress__label">87%</p>
            <Progress value={100} />
          </div> */}
          </CardBody>
        </Card>
      </Col>
      <Col md={6}>
        <Card>
          <CardBody className="dashboard__booking-card">
            <div className="dashboard__booking-total-container">
              <h5 className="dashboard__booking-total-title dashboard__booking-total-title--orange">
                R$ {formatNumber(result.provider)}
              </h5>
              <TrendingUpIcon className="dashboard__trend-icon" />
            </div>
            <h5 className="dashboard__booking-total-description">Receita do entregador</h5>
            {/* <div className="progress-wrap progress-wrap--small progress-wrap--violet progress-wrap--rounded">
            <p className="dashboard__booking-card-progress-label progress__label">87%</p>
            <Progress value={100} />
          </div> */}
          </CardBody>
        </Card>
      </Col>
      <Col md={6}>
        <Card>
          <CardBody className="dashboard__booking-card">
            <div className="dashboard__booking-total-container">
              <h5 className="dashboard__booking-total-title dashboard__booking-total-title">
                R$ {formatNumber(result.tax)}
              </h5>
              <TrendingUpIcon className="dashboard__trend-icon" />
            </div>
            <h5 className="dashboard__booking-total-description">Taxa do entregador</h5>
            {/* <div className="progress-wrap progress-wrap--small progress-wrap--violet progress-wrap--rounded">
            <p className="dashboard__booking-card-progress-label progress__label">87%</p>
            <Progress value={100} />
          </div> */}
          </CardBody>
        </Card>
      </Col>
      <Col md={6}>
        <Card>
          <CardBody className="dashboard__booking-card">
            <div className={styles.ribbon}>
              <FinanceIcon size="1.4em" />
            </div>
            <div className="dashboard__booking-total-container">
              {result.receipt > 0 ?
                <h5 className="dashboard__booking-total-title dashboard__booking-total-title--green">
                  R$ {formatNumber(result.receipt)}
                </h5>
                :
                <h5 className="dashboard__booking-total-title dashboard__booking-total-title--red">
                  R$ {formatNumber(result.receipt)}
                </h5>
              }
              <TrendingUpIcon className="dashboard__trend-icon" />
            </div>
            <h5 className="dashboard__booking-total-description">Margem</h5>
            {/* <div className="progress-wrap progress-wrap--small progress-wrap--violet progress-wrap--rounded">
            <p className="dashboard__booking-card-progress-label progress__label">87%</p>
            <Progress value={100} />
          </div> */}
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Col>
);

export default Cards;