// @ts-nocheck
import React, { useContext } from "react";
import { MultipleCheckbox } from "../../../../components/Checkbox/MultipleCheckbox";
import { Checkbox } from "../../../../components/Checkbox/Checkbox";
import { UploadFile } from "../../../../components/DragNDrop";
import { CustomModal } from "../../../../components/Modal";
import { Vehicle } from "../../../../context/Provider/provider.types";
import { ProviderContext } from "../../../../context/Provider";
import { useVehicleForm } from "./formHook";
import styles from "../styles.module.css";

import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import { DateInput } from "@opuscapita/react-dates";
import { Icon } from "semantic-ui-react";

type Props = {
  vehicle?: Vehicle;
  provider_id: string;
  services: any[];
};

const VehicleForm = ({ vehicle, provider_id, services }: Props) => {

  const {
    action: { createVehicle }
  } = useContext(ProviderContext);

  const {
    state,
    onChange,
    onServicesSelect,
    onDocumentChange,
    onDateChange,
    onCheckboxChange
  } = useVehicleForm(provider_id, services, vehicle);

  const submit = () => {
    createVehicle(state)
  };

  return (
    <CustomModal
      title={state.isNew ? "Adicionar veículo" : "Editar veículo"}
      size="large"
      handleOnClick={submit}
      trigger={toggle => (
        <span onClick={toggle} className={styles.icon}>
          <Icon className={!!vehicle ? "edit link black large" : "add link circular grey"} />
        </span>
      )}
    >
      <React.Fragment>
        <Row>
          <Col xs="6" >
            <FormGroup>
              <Label>Nome</Label>
              <Input
                type="text"
                name="vehicle_name"
                value={state.vehicle_name}
                onChange={onChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Modelo</Label>
              <Input
                type="text"
                name="vehicle_model"
                value={state.vehicle_model}
                onChange={onChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Placa</Label>
              <Input
                type="text"
                name="vehicle_plate_no"
                value={state.vehicle_plate_no}
                onChange={onChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Ano</Label>
              <Input
                type="text"
                name="vehicle_passing_year"
                value={state.vehicle_passing_year}
                onChange={onChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Cor</Label>
              <Input
                type="text"
                name="vehicle_color"
                value={state.vehicle_color}
                onChange={onChange}
              />
            </FormGroup>
            {
              !!vehicle &&
              <FormGroup>
                <Label>Status do veiculo</Label>
                <Checkbox
                  name="vehicle_is_approved"
                  label="Aprovado"
                  value={state.vehicle_is_approved}
                  onChange={onCheckboxChange}
                />
                <Checkbox
                  name="vehicle_is_selected"
                  label="Selecionado"
                  value={state.vehicle_is_selected}
                  onChange={onCheckboxChange}
                />
              </FormGroup>
            }
          </Col>
          <Col xs="6" >
            <Label>Serviços</Label>
            <MultipleCheckbox
              name="service_type"
              options={state.serviceOptions}
              values={state.service_type}
              onChange={onServicesSelect}
            />
          </Col>
        </Row>
        <h3>Documento do veiculo</h3>
        <FormGroup>
          <Label>Data de vencimento</Label>
          <DateInput
            value={state.doc_expiry_date}
            dateFormat="DD/MM/YYYY"
            showToTop={true}
            disabled={false}
            locale="pt-br"
            onChange={onDateChange}
          />
        </FormGroup>
        <UploadFile onDocumentChange={onDocumentChange} />
      </React.Fragment>
    </CustomModal>
  );
};

export default VehicleForm;