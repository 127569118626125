// @ts-nocheck
import React from "react";
import { AuthContext } from "./Auth";
import moment from "moment";
import { toast } from "react-toastify";

interface ResultContext {
  state: State;
  action: {
    fetchCities(): void;
    getDailyResults(filter: any): void;
    getCityResults(filter: any): void;
    getProviderResults(filter: any): void;
    getProviderDebts(): void;
    getTripsResults(filter: any): void;
    getCompanyResults(filter: any): void;
    getCompanyResultsCompare(filter: any): void;
  };
};

export const ResultContext = React.createContext({} as ResultContext);

interface State {
  cities: any[];
  cityResults: any[];
  dailyResults: any[];
  isDailyResultsLoaded: boolean;
  providerResults: any[];
  isProviderResultsLoaded: boolean;
  providerDebts: any[];
  isProviderDebtsLoaded: boolean;
  tripResults: any[];
  isTripResultsLoaded: boolean;
  companyResults: any[];
  companyResultsCompare: any[];
  isCompanyResultsLoaded: boolean;
};

class ResultProvider extends React.PureComponent<{}, State> {

  static contextType = AuthContext;

  constructor(props: any) {
    super(props);

    this.state = {
      cities: [],
      cityResults: [],
      dailyResults: [],
      isDailyResultsLoaded: false,
      providerResults: [],
      isProviderResultsLoaded: false,
      providerDebts: [],
      isProviderDebtsLoaded: false,
      tripResults: [],
      isTripResultsLoaded: false,
      companyResults: [],
      companyResultsCompare: [],
      isCompanyResultsLoaded: false
    };
  }

  fetchCities = () => {
    const { request } = this.context.action

    this.setState({
      cities: []
    }, async () => {
      try {
        const response = await request('GET', 'admin/v1/cities')

        if (response.data.success)
          this.setState({ cities: response.data.data })
        else throw new Error('Erro ao buscar as cidades.')

      } catch (err) {
        this.setState({
          cities: []
        }, toast.error.bind(null, err.message))
      }
    })
  }

  getCityResults = (filter: any) => {
    const { request } = this.context.action;
    const url = "admin/v1/results/city-results";
    console.log(filter);
    this.setState({
      cityResults: [],
    }, async () => {
      try {
        const response = await request("POST", url, filter);
        const { success, data } = response.data;
        if (success) {
          this.setState({
            cityResults: data,
            isCompanyResultsLoaded: true
          })
        }
      } catch (error) {
        this.setState({ companyResults: [], isCompanyResultsLoaded: true },
          toast.error.bind(null, "Erro ao buscar resultados de empresa"))
      }
    })
  };

  getDailyResults = (filter: any) => {
    const { request } = this.context.action;
    const url = "admin/v1/results/daily-results";
    const data = {
      date: {
        start: moment(filter.startDate).format('YYYY-MM-DD'),
        end: moment(filter.endDate).format('YYYY-MM-DD')
      },
      frequency: filter.frequency
    }

    this.setState({
      isDailyResultsLoaded: false,
    }, async () => {
      try {
        const response = await request("POST", url, data)
        // console.log(response)
        if (response.data.success) {
          this.setState({
            dailyResults: response.data.data,
            isDailyResultsLoaded: true
          });
        }
      } catch (error) {
        this.setState({
          dailyResults: [],
          isDailyResultsLoaded: true
        }, toast.error.bind(null, "Erro ao buscar resultados por dia"))
        // console.log(error)
      }
    })
  };

  getProviderResults = (filter: any) => {
    const { request } = this.context.action;
    const url = "admin/v1/results/provider-results";
    this.setState({
      isDailyResultsLoaded: false,
    }, async () => {
      try {
        const response = await request("POST", url, filter)
        console.log(response.data)
        if (response.data.success) {
          this.setState({
            providerResults: response.data.data,
            isProviderResultsLoaded: true
          })
        }
      } catch (error) {
        this.setState({ providerResults: [], isProviderResultsLoaded: true },
          toast.error.bind(null, "Erro ao buscar resultados por entregador"))
      }
    })
  };


  getProviderDebts = () => {
    const { request } = this.context.action;
    const url = "admin/v1/results/provider-debts";

    this.setState({
      isProviderDebtsLoaded: false,
    }, async () => {
      try {
        const response = await request("POST", url)
        // console.log(response.data)
        if (response.data.success) {
          this.setState({
            providerDebts: response.data.data,
            isProviderDebtsLoaded: true,
          })
        }
      } catch (error) {
        this.setState({ providerDebts: [], isProviderDebtsLoaded: true },
          toast.error.bind(null, "Erro ao buscar entregadores com débitos"))
      }
    })
  };

  getTripsResults = (filter: any) => {
    const { request } = this.context.action;
    const url = "admin/v1/results/trip-results";
    const data = {
      date: {
        start: moment(filter.startDate).format('YYYY-MM-DD'),
        end: moment(filter.endDate).format('YYYY-MM-DD')
      },
      frequency: filter.frequency
    }

    this.setState({
      isTripResultsLoaded: false,
    }, async () => {
      try {
        const response = await request("POST", url, data)
        // console.log(response)
        if (response.data.success) {
          this.setState({
            tripResults: response.data.data,
            isTripResultsLoaded: true
          })
        }
      } catch (error) {
        this.setState({ tripResults: [], isTripResultsLoaded: true },
          toast.error.bind(null, "Erro ao buscar resultados de entrega"))
      }
    })
  };

  getCompanyResults = (filter: any) => {
    const { request } = this.context.action;
    const url = "admin/v1/results/company-results";

    this.setState({
      isCompanyResultsLoaded: false,
    }, async () => {
      try {
        const response = await request("POST", url, filter);
        const { success, data } = response.data;
        console.log(data);
        if (success) {
          this.setState({
            companyResults: data,
            isCompanyResultsLoaded: true
          })
        }
      } catch (error) {
        this.setState({ companyResults: [], isCompanyResultsLoaded: true },
          toast.error.bind(null, "Erro ao buscar resultados de empresa"))
      }
    })
  };

  getCompanyResultsCompare = async (filter: any) => {
    const { request } = this.context.action;
    const url = "admin/v1/results/company-results-compare";
      try {
        const response = await request("POST", url, filter);
        const { success, data } = response.data;
        if (success) {
          this.setState({
            companyResultsCompare: data
          })
        }
      } catch (error) {
        this.setState({ companyResultsCompare: [] },
          toast.error.bind(null, "Erro ao buscar resultados de empresa"))
      }
  };

  render() {
    const { executeOnlyIfAuthenticated } = this.context.action;

    const value = {
      state: { ...this.state },
      action: {
        fetchCities: executeOnlyIfAuthenticated(this.fetchCities),
        getDailyResults: executeOnlyIfAuthenticated(this.getDailyResults),
        getProviderResults: executeOnlyIfAuthenticated(this.getProviderResults),
        getProviderDebts: executeOnlyIfAuthenticated(this.getProviderDebts),
        getTripsResults: executeOnlyIfAuthenticated(this.getTripsResults),
        getCityResults: executeOnlyIfAuthenticated(this.getCityResults),
        getCompanyResultsCompare: executeOnlyIfAuthenticated(this.getCompanyResultsCompare),
        getCompanyResults: executeOnlyIfAuthenticated(this.getCompanyResults)
      }
    };

    return (
      <ResultContext.Provider value={value}>
        {this.props.children}
      </ResultContext.Provider>
    );
  }
}

export default ResultProvider;