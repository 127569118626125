// @ts-nocheck
import React, { useContext } from "react";
import { TripsContext } from "../../../context/Trips";
import { formatPhone } from "../../../utils/masks";
import Panel from "../../../components/Panel";
import { Badge } from "reactstrap";

const ClientDetails = () => {
  const {
    state: { selectedTrip }
  } = useContext(TripsContext);

  const { user } = selectedTrip;

  return (
    <Panel title="Loja">
      <div>
        <div style={{fontSize:13, fontWeight:"bold"}}><Badge style={{display: "inline"}}>ID {user.unique_id}</Badge> {user.first_name} {user.last_name}</div>
        <div><span>{user.email} - {formatPhone(user.phone)}</span></div>
      </div>
    </Panel>
  );
}

export default ClientDetails;