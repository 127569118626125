// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { SecondaryDashboardContext } from '../../context/SecondaryDashboard';
import { formatDistance, toNumber } from '../../utils/masks';
import { getLastWeekDay } from '../../utils/dates';
import DateRange from '../../components/DateRange';
import TripsPerProvider from "./components/TripsPerProvider";
import TripsByDistance from "./components/TripsByDistance";
import ServiceValues from "./components/ServiceValues";

import {
	// Card,
	// CardBody,
	Container,
	Row,
	Col,
	// Input,
	// Progress
} from 'reactstrap';
import moment from 'moment';
// import TrendingUpIcon from 'mdi-react/TrendingUpIcon';

const FinancesDashboard = () => {
	const {
		action: { getTripsPerProvider, getTripsByDistance, getServiceValues },
	} = useContext(SecondaryDashboardContext);

	const [state, setState] = useState({
		start_date: getLastWeekDay(),
		end_date: new Date(),
		reference_distance: ""
	});

	const handleDateChange = (dates: Date[]) => {
		setState(prev => ({ ...prev, start_date: dates[0], end_date: dates[1] }));
	};

	const handleReferenceDistanceChange = (reference_distance: string) => {
		setState(prev => ({ ...prev, reference_distance: formatDistance(reference_distance) }))
	};

	useEffect(() => {
		if (state.start_date && state.end_date) {
			const data: any = {
				start_date: moment(state.start_date).format('YYYYMMDD'),
				end_date: moment(state.end_date).format("YYYYMMDD")
			}

			getTripsPerProvider(data);

			if (state.reference_distance !== "")
				data["reference_distance"] = toNumber(state.reference_distance)
			getTripsByDistance(data);
		}
	}, [state])

	useEffect(() => {
		getServiceValues();
	}, []);

	return (
		<Container className="dashboard">
			<Row className="mb-4">
				<Col xl={3} lg={5} md={6}>
					<div style={{ color: '#999', fontStyle: 'italic' }}>
						<small>Filtrar período</small>
					</div>
					<DateRange value={[state.start_date, state.end_date]} onChange={handleDateChange} />

				</Col>
				{/* <Col xl={3} lg={5} md={6}>
					<div style={{ color: '#999', fontStyle: 'italic' }}>
						<small>Filtrar distância de referência</small>
					</div>
					<Input placeholder="2,5 Km" name="reference_distance" value={state.reference_distance} onChange={handleOnChange} />
				</Col> */}
			</Row>
			<ServiceValues />
			<Row>
				<TripsPerProvider />
				<TripsByDistance
					reference_distance={state.reference_distance}
					updateParent={handleReferenceDistanceChange}
				/>
			</Row>
			{/* <Row>
				<ServiceValues />
			</Row> */}
		</Container>
	);
};

export default FinancesDashboard;
