// @ts-nocheck
import React, { useState, useEffect } from "react";
import { cx } from "../../utils/classnames";
import styles from "./styles.module.css";

import { useDropzone } from "react-dropzone";
import { Icon } from "semantic-ui-react";

type Props = {
  onDocumentChange(file: File): void;
};

interface State {
  file: File[];
  message: string;
  isPdf: boolean;
};

export const UploadFile = ({ onDocumentChange }: Props) => {
  const [state, setState] = useState<State>({
    file: [],
    message: "Arraste os arquivos aqui ou click para selecionar",
    isPdf: false,
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject
  } = useDropzone({
    accept: "application/pdf, image/jpeg, image/png",
    onDrop: acceptedFiles => {
      setState(prevState => {
        const file = acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        );

        return {
          ...prevState,
          file
        }
      });
    }
  });

  useEffect(() => {
    if (state.file.length) {
      setState(prevState => ({
        ...prevState,
        message: prevState.file[0].name,
        isPdf: prevState.file[0].name.endsWith('.pdf')
      }))
      onDocumentChange(state.file[0])
    }
  }, [state.file]);

  return (
    <div
      {...getRootProps({})}
      className={cx(
        styles.dropContainer,
        { [styles.dragActive]: isDragActive },
        { [styles.dragReject]: isDragReject }
      )}
    >
      <input {...getInputProps()} />
      <p>{state.message}</p>
    </div>
  );
};
