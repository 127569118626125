// @ts-nocheck
import React, {useState, useEffect} from 'react'
import MultiSelect from "@khanacademy/react-multi-select";

type Props = {
    options: any;
    onMultiSelected(select: Array<string>): void;
}

export const MultiSelectInput = ({options, onMultiSelected}: Props) => {

    const [selected, setSelected] = useState([""])


    const onSelected = (selected: Array<string>) => {
        setSelected(selected)
    }
    
    useEffect(() => {
        onMultiSelected(selected)
        //console.log(selected)
        
    }, [selected])

    return (
        <MultiSelect
            options={options}
            selected={selected}
            onSelectedChanged={
                (selected: Array<string>) => onSelected(selected)
                
            }
        />
    )
}
