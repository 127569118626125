// @ts-nocheck
import React from "react";
import { AuthContext } from "../Auth";
import {
  Provider,
  AttendanceModel,
  City
} from "./provider.types";
import {
  toFormData,
  transformVehicleForm,
  transformFilterForm,
  transformProviderData
} from "./provider.utils";
import { ProviderState } from "../../pages/ProviderPanel/components/ProviderProfile/context";
import { VehicleState } from "../../pages/ProviderPanel/components/ProviderVehicles/formHook";
import { FilterState } from "../../pages/Providers/Filter";
import { onlyNumber } from "../../utils/masks";
import { toast } from "react-toastify";

interface ProviderContext {
  state: State;
  action: {
    getCities(country: string): void;
    getServices(cityId: string): void;
    searchProviders(type: any, page?: number, filter?: FilterState): void;
    // Provider
    getProvider(providerId: string): void;
    updateProvider(providerId: string, form: ProviderState): void;
    updatePassword(providerId: string, password: string): void;
    approveProvider(providerId: string): void;
    approveProfilePicture(providerId: string, approveForm: any): void;
    disableProvider(providerId: string, disableForm: any): void;
    // Provider's Document
    updateDocument(data: any, providerId: string, documentId: string): void;
    approveDocument(documentId: string, providerId: string, approveForm: any): void;
    // Provider's Vehicle
    createVehicle(form: VehicleState): void;
    removeVehicle(providerId: string, vehicleId: string): void;
    approveVehicle(vehicleId: string, providerId: string, approveForm: any): void;
    // Trip and Attendance
    getTripHistory(providerId: string, page: number): void;
    createAttendanceHistory(providerId: string, title: string, text: string): void;
    getAttendanceHistory(providerId: string): void;
    // Provider Wallet
    getFinancialResume(providerId: string): void;
    getStatement(providerId: string): void;
    addEntry(providerId: string, data: any): void;
  };
}

export const ProviderContext = React.createContext({} as ProviderContext);

interface State {
  providers: any[];
  providersPagination: any;
  isProvidersLoaded: boolean;
  cities: City[];
  isCitiesLoaded: boolean;
  services: any[];
  isServicesLoaded: boolean;
  provider: Provider;
  isProviderLoaded: boolean;
  trips: any[];
  tripPagination: any;
  isTripLoaded: boolean;
  attendance: AttendanceModel[];
  attendancePagination: any;
  isAttendanceLoaded: boolean;
  resume: any;
  isResumeLoaded: boolean;
  statement: any[];
  isStatementLoaded: boolean;
  update: boolean;
}

class ProviderProvider extends React.PureComponent<{}, State> {

  static contextType = AuthContext;

  constructor(props: any) {
    super(props)

    this.state = {
      providers: [],
      providersPagination: {},
      isProvidersLoaded: false,
      cities: [],
      isCitiesLoaded: false,
      services: [],
      isServicesLoaded: false,
      provider: {} as Provider,
      isProviderLoaded: false,
      trips: [],
      tripPagination: {},
      isTripLoaded: false,
      attendance: [],
      attendancePagination: {},
      isAttendanceLoaded: false,
      resume: {},
      isResumeLoaded: false,
      statement: [],
      isStatementLoaded: false,
      update: false,
    };
  }

  // Provisorio
  getCities = async (country: string) => {
    const { request } = this.context.action;
    const url = 'admin/v1/cities_list_by_country';
    this.setState({
      isCitiesLoaded: false,
    }, async () => {
      try {
        const response = await request("POST", url, { countryname: country });
        // console.log(response)
        if (response.data.success) {
          const { data } = response.data;
          const cities = data.map((city: any) => {
            const plans = city.plans.map((plan: any) => ({
              name: plan.name,
              type: plan.type
            }));
            return {
              id: city._id,
              name: city.cityname,
              plans
            };
          });
          this.setState({
            cities,
            isCitiesLoaded: true,
          });
        }
      } catch (error) {
        toast.error("Erro ao carregar lista de cidades")
      }
    })
  };

  getServices = async (cityId: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/services/${cityId}`;
    this.setState({
      isServicesLoaded: false
    }, async () => {
      try {
        const response = await request("GET", url);
        // console.log(response)
        if (response.data.success) {
          const { data } = response.data;
          const service_type = data.map((s: any) => ({
            typename: s.service_type.typename,
            description: s.service_type.description,
            typeid: s._id
          }));
          this.setState({
            services: service_type,
            isServicesLoaded: true
          });
        }
      } catch (error) {
        toast.error("Erro ao carregar lista de serviços")
      }
    })
  };

  //  Providers
  searchProviders = async (types: any[], page?: number, filter?: FilterState) => {
    const { request } = this.context.action;
    const url = 'admin/v1/providers';
    const data: any = !!filter ? transformFilterForm(filter) : {}
    data['page_size'] = 13;
    data['page'] = !!page ? page : 1;
    types.map(type => {
      data[type.name] = type.value;
    })

    this.setState({
      isProvidersLoaded: false,
    }, async () => {
      try {
        const response = await request("POST", url, data)
        // console.log(response.data)
        if (response.data.success) {
          const { count, current_page, pages, providers } = response.data.data;
          const providersPagination = {
            count,
            current_page,
            pages
          };
          this.setState({
            providers: providers,
            providersPagination,
            isProvidersLoaded: true,
          })
        }
      } catch (error) {
        // console.log(error)
        const providersPagination = {
          count: 0,
          current_page: 1,
          pages: 1
        };
        this.setState({
          providers: [],
          providersPagination,
          isProvidersLoaded: true
        },
          toast.info.bind(null, "Entregadores não encontrados")
        );
      }
    });
  };

  // Provider
  getProvider = async (providerId: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}`;
    this.setState({
      isProviderLoaded: false
    }, async () => {
      try {
        const response = await request("GET", url);
        // console.log(response)
        if (response.data.success) {
          this.setState({
            update: false,
            provider: transformProviderData(response.data.data, this.context.host),
            isProviderLoaded: true,
          });
        }
      } catch (error) {
        toast.error("Erro ao buscar o entregador")
      }
    })
  };

  updateProvider = async (providerId: string, form: ProviderState) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}`;

    const data: any = {
      first_name: form.first_name,
      last_name: form.last_name,
      gender: form.gender,
      document: form.document !== '' ? onlyNumber(form.document) : '',
      phone: onlyNumber(form.phone),
      email: form.email,
      zipcode: form.zipcode,
      address: form.address,
      city_id: form.city,
      plan_type: form.plan,
      provider_picture: form.file,
      approval_status: form.approval_status,
      pix_key: form.pix.key, 
      pix_type: form.pix.type
    };
    if (form.group_name !== '') data['group_name'] = form.group_name;
    const formData = toFormData(data);
    try {
      const response = await request("PUT", url, formData);
      if (response.data.success) {
        this.setState({
          update: true,
          isProviderLoaded: false,
        },
          toast.success.bind(null, "Perfil atualizado!")
        );
      }
    } catch (error) {
      toast.error("Erro ao atualizar o cadastro do entregador")
    }
  }

  updatePassword = async (providerId: string, password: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/password`;
    try {
      const response = await request("PUT", url, { password: password })
      response.data.success && toast.success("Senha atualizada com sucesso!")
    } catch (error) {
      toast.error("Erro ao atualizar senha!")
    }
  };

  approveProvider = async (providerId: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/approval`;

    try {
      const response = await request("GET", url)
      if (response.data.success) {
        this.setState({ update: true },
          toast.success.bind(null, "Entregador aprovado com sucesso")
        )
      }
    }
    catch (error) {
      const message = error.response.data.error.message;
      if (message === "PROVIDER_PICTURE_PENDING_APPROVAL") {
        toast.error("Aprovação pendente da imagem de perfil")
      } else if (message === "PROVIDER_VEHICLE_PENDING_APPROVAL") {
        toast.error("Aprovação pendente dos veículos")
      } else if (message === "PROVIDER_DOCUMENT_PENDING_APPROVAL") {
        toast.error("Aprovação pendente dos documentos")
      } else if (message === "PROVIDER_PENDING_PLAN_UPDATE") {
        toast.error("Atualização pendente do plano do entregador")
      } else toast.error("Erro ao aprovar cadastro")
    }
  };

  approveProfilePicture = async (providerId: string, approveForm: any) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/picture/approval`;
    const { approve, disapproval_message } = approveForm;
    const data: any = {};
    if (approve) data['status'] = 'approved'
    else {
      data['status'] = 'disapproved';
      data['disapproval_message'] = disapproval_message
    }

    try {
      const response = await request("POST", url, data);
      if (response.data.success) {
        this.setState({ update: true },
          toast.success.bind(null, `Imagem de perfil ${approve ? 'aprovada' : 'desaprovada'} com sucesso`))
      }
    } catch (error) {
      toast.error(`Erro ao ${approve ? 'aprovar' : 'desaprovar'} imagem de perfil`)
      // console.log(error)
    }
  };

  disableProvider = async (providerId: string, disableForm: any) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}`
    const data: any = {
      toggle: Number(disableForm.disable),
      deactivation_message: disableForm.deactivation_message
    };
    // if (disableForm.disable === false) 
    //   data['deactivation_message'] = disableForm.deactivation_message

    try {
      const response = await request("POST", url, data);
      console.log(response)
      if (response.data.success) {
        this.setState({ update: true },
          toast.success.bind(null, `Entregador ${disableForm.disable ? 'desbloqueado' : 'bloqueado'} com sucesso`))
      }
    } catch (error) {
      console.log(error)
      toast.error(`Erro ao ${disableForm.disable ? 'desbloquear' : 'bloquear'} entregador`)
    }
  }

  // Documents
  updateDocument = async (data: any, providerId: string, documentId: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/documents/${documentId}`;
    const formData = toFormData(data);

    try {
      const response = await request("PUT", url, formData);
      // console.log(response.data)
      if (response.data.success) {
        this.setState({
          update: true,
          isProviderLoaded: false,
        },
          toast.success.bind(null, "Documento atualizado com sucesso!")
        );
      }
    } catch (error) {
      toast.error("Erro ao atualizar documento")
    }
  };

  approveDocument = async (documentId: string, providerId: string, approveForm: any) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/documents/approval`;
    const { approve, disapproval_message } = approveForm;
    const data: any = { provider_document_id: documentId };
    if (approve) data['status'] = 'approved'
    else {
      data['status'] = 'disapproved'
      data['disapproval_message'] = disapproval_message
    }

    try {
      const response = await request("POST", url, data);
      if (response.data.success) {
        this.setState({ update: true },
          toast.success.bind(null, `Documento ${approve ? 'aprovado' : 'desaprovado'} com sucesso`)
        )
      }
    } catch (error) {
      toast.error(`Erro ao ${approve ? 'aprovar' : 'desaprovar'} documento`)
      // console.log(error)
    }
  };

  // Vehicle
  createVehicle = async (form: VehicleState) => {
    const { request } = this.context.action;
    const { _id, provider_id, isNew, data } = transformVehicleForm(form);
    let url, method = '';
    if (isNew) {
      url = `admin/v1/providers/${provider_id}/vehicles`;
      method = "POST";
    } else {
      url = `admin/v1/providers/${provider_id}/vehicles/${_id}`;
      method = "PUT";
    }
    const formData = toFormData(data);
    try {
      const response = await request(method, url, formData);
      if (response.data.success) {
        this.setState({
          update: true,
          isProviderLoaded: false,
        },
          isNew
            ? toast.success.bind(null, "Veículo criado com sucesso")
            : toast.success.bind(null, "Veículo atualizado com sucesso")
        );
      }
    } catch (error) {
      toast.error(isNew ? "Erro ao criar veículo" : "Erro ao atualizar veículo")
    }
  };

  removeVehicle = async (providerId: string, vehicleId: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/vehicles/${vehicleId}`;
    try {
      const response = await request("DELETE", url);
      if (response.data.success) {
        this.setState({
          update: true,
          isProviderLoaded: false,
        },
          toast.success.bind(null, "Veículo removido com sucesso")
        );
      }
    } catch (error) {
      toast.error("Erro ao remover veículo")
    }
  };

  approveVehicle = async (vehicleId: string, providerId: string, approveForm: any) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/vehicles/approval`;
    const { approve, disapproval_message } = approveForm;

    const data: any = { vehicle_id: vehicleId };
    if (approve) data['status'] = 'approved'
    else {
      data['status'] = 'disapproved'
      data['disapproval_message'] = disapproval_message
    }

    try {
      const response = await request("POST", url, data);
      if (response.data.success) {
        this.setState({ update: true },
          toast.success.bind(null, `Veículo ${approve ? 'aprovado' : 'desaprovado'} com sucesso`)
        )
      }
    } catch (error) {
      toast.error(`Erro ao ${approve ? 'aprovar' : 'desaprovar'} veículo`)
      // console.log(error)
    }
  };

  // Trip & Attendance
  getTripHistory = async (providerId: string, page: number) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/trips/`;
    this.setState({
      isTripLoaded: false
    }, async () => {
      try {
        const response = await request("POST", url, { page_size: 10, page });
        if (response.data.success) {
          const { count, current_page, pages, trips } = response.data.data;
          const tripPagination = {
            count,
            current_page,
            pages
          };
          this.setState({
            trips,
            isTripLoaded: true,
            tripPagination,
          })
        }
      } catch (error) {
        this.setState({ isTripLoaded: true, trips: [] });
      }
    })
  };

  createAttendanceHistory = async (providerId: string, title: string, text: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/attendances/`;
    this.setState({
      isAttendanceLoaded: false,
    }, async () => {
      try {
        const response = await request("POST", url, {title, text});
        if (response.data.success) {
          const attendances = response.data.data;
          this.setState({
            attendance: attendances,
            isAttendanceLoaded: true
          })
        }
      } catch (error) {
        this.setState({ isAttendanceLoaded: true, attendance: [] });
      }
    })
  }

  getAttendanceHistory = async (providerId: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/providers/${providerId}/attendances/`;
    this.setState({
      isAttendanceLoaded: false,
    }, async () => {
      try {
        const response = await request("GET", url);
        if (response.data.success) {
          const attendances = response.data.data;
          this.setState({
            attendance: attendances,
            isAttendanceLoaded: true
          })
        }
      } catch (error) {
        this.setState({ isAttendanceLoaded: true, attendance: [] });
      }
    })
  }

  getFinancialResume = async (providerId: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/provider-financial/${providerId}/resume`;
    this.setState({
      isResumeLoaded: false
    }, async () => {
      try {
        const response = await request("GET", url)
        // console.log(response.data.data)
        if (response.data.success)
          this.setState({
            resume: response.data.data,
            isResumeLoaded: true
          })
      } catch (error) {
        // console.log(error)
        toast.info("Não foi possivel carregar o resumo financeiro do entregador")
      }
    })
  }

  getStatement = async (providerId: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/provider-financial/${providerId}/statement`
    this.setState({
      isStatementLoaded: false
    }, async () => {
      try {
        const response = await request("GET", url)
        // console.log(response.data.data)
        if (response.data.success)
          this.setState({
            statement: response.data.data,
            isStatementLoaded: true,
            update: false
          })
      } catch (error) {
        // console.log(error)
        toast.info("Não foi possivel carregar a lista de lançamentos do entregadors")
      }
    })
  }

  addEntry = async (providerId: string, data: any) => {
    const { request } = this.context.action;
    const url = `admin/v1/provider-financial/${providerId}/entry`
    try {
      const response = await request("POST", url, data)
      if (response.data.success) {
        this.setState({ update: true })
        toast.success("Lançamento criado com sucesso")
      }
    } catch (error) {
      toast.error("Erro ao criar lançamento")
    }
  }

  render() {
    const { executeOnlyIfAuthenticated } = this.context.action;

    const value = {
      state: { ...this.state },
      action: {
        getCities: executeOnlyIfAuthenticated(this.getCities, true),
        getServices: this.getServices,
        searchProviders: this.searchProviders,
        // Provider
        getProvider: this.getProvider,
        updateProvider: this.updateProvider,
        updatePassword: this.updatePassword,
        approveProvider: this.approveProvider,
        approveProfilePicture: this.approveProfilePicture,
        disableProvider: this.disableProvider,
        // Document
        updateDocument: this.updateDocument,
        approveDocument: this.approveDocument,
        // Vehicle
        createVehicle: this.createVehicle,
        removeVehicle: this.removeVehicle,
        approveVehicle: this.approveVehicle,
        // Trip
        getTripHistory: this.getTripHistory,
        // Attendance
        createAttendanceHistory: this.createAttendanceHistory,
        getAttendanceHistory: this.getAttendanceHistory,
        // Wallet
        getFinancialResume: executeOnlyIfAuthenticated(this.getFinancialResume),
        getStatement: executeOnlyIfAuthenticated(this.getStatement),
        addEntry: this.addEntry,
      }
    };
    return (
      <ProviderContext.Provider value={value}>
        {this.props.children}
      </ProviderContext.Provider>
    )
  }
}

export default ProviderProvider;