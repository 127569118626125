// @ts-nocheck
import React from "react"
import formatDocument from "../utils/formatDocument"
import { toast } from "react-toastify"
import { Badge } from "reactstrap"

const renderStatusLabels = (approved, refused) => {
  if (!approved && !refused) {
    return <Badge color="warning">Em Espera</Badge>
  }

  if (approved) {
    return <Badge color="success"><i className="fas fa-check" /></Badge>
  } else {
    return <Badge color="danger"><i className="fas fa-times" /></Badge>
  }
}

const Request = ({ id, name, citycode, doc, manager, email, approved, refused, onClick }) => {
  const copyDocument = () => {
    window.navigator.clipboard.writeText(doc)
    toast.success("CNPJ da empresa copiado para a área de transferência")
  }

  const onItemClick = () => onClick(id)

  return (
    <tr key={id} onClick={onItemClick}>
      <td><Badge size="sm">{citycode}</Badge></td>
      <td>{name}</td>
      <td>{formatDocument(doc)}</td>
      <td>{manager}</td>
      <td>{email}</td>
      <td>{renderStatusLabels(approved, refused)}</td>
    </tr>
  )
}

export default Request
