export const banksCodes = [
    {
      value: "001",
      text: "Banco do Brasil S.A."
    },
    {
      value: "003",
      text: "Banco da Amazônia S.A."
    },
    {
      value: "004",
      text: "Banco do Nordeste do Brasil S.A."
    },
    {
      value: "007",
      text: "Banco Nacional de Desenvolvimento Econômico e Social BNDES"
    },
    {
      value: "010",
      text: "Credicoamo Crédito Rural Cooperativa"
    },
    {
      value: "011",
      text: "Credit Suisse Hedging-Griffo Corretora de Valores S.A."
    },
    {
      value: "012",
      text: "Banco Inbursa S.A."
    },
    {
      value: "014",
      text: "Natixis Brasil S.A. Banco Múltiplo"
    },
    {
      value: "015",
      text: "UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A."
    },
    {
      value: "016",
      text: "Coop de Créd. Mútuo dos Despachantes de Trânsito de SC e Rio Grande do Sul"
    },
    {
      value: "017",
      text: "BNY Mellon Banco S.A."
    },
    {
      value: "018",
      text: "Banco Tricury S.A."
    },
    {
      value: "021",
      text: "Banestes S.A. Banco do Estado do Espírito Santo"
    },
    {
      value: "024",
      text: "Banco Bandepe S.A."
    },
    {
      value: "025",
      text: "Banco Alfa S.A."
    },
    {
      value: "029",
      text: "Banco Itaú Consignado S.A."
    },
    {
      value: "033",
      text: "Banco Santander (Brasil) S. A."
    },
    {
      value: "036",
      text: "Banco Bradesco BBI S.A."
    },
    {
      value: "037",
      text: "Banco do Estado do Pará S.A."
    },
    {
      value: "040",
      text: "Banco Cargill S.A."
    },
    {
      value: "041",
      text: "Banco do Estado do Rio Grande do Sul S.A."
    },
    {
      value: "047",
      text: "Banco do Estado de Sergipe S.A."
    },
    {
      value: "060",
      text: "Confidence Corretora de Câmbio S.A."
    },
    {
      value: "062",
      text: "Hipercard Banco Múltiplo S.A."
    },
    {
      value: "063",
      text: "Banco Bradescard S.A."
    },
    {
      value: "064",
      text: "Goldman Sachs do Brasil  Banco Múltiplo S. A."
    },
    {
      value: "065",
      text: "Banco AndBank (Brasil) S.A."
    },
    {
      value: "066",
      text: "Banco Morgan Stanley S. A."
    },
    {
      value: "069",
      text: "Banco Crefisa S.A."
    },
    {
      value: "070",
      text: "Banco de Brasília S.A."
    },
    {
      value: "074",
      text: "Banco J. Safra S.A."
    },
    {
      value: "075",
      text: "Banco ABN Amro S.A."
    },
    {
      value: "076",
      text: "Banco KDB do Brasil S.A."
    },
    {
      value: "077",
      text: "Banco Inter S.A."
    },
    {
      value: "078",
      text: "Haitong Banco de Investimento do Brasil S.A."
    },
    {
      value: "079",
      text: "Banco Original do Agronegócio S.A."
    },
    {
      value: "080",
      text: "BT Corretora de Câmbio Ltda."
    },
    {
      value: "081",
      text: "BBN Banco Brasileiro de Negocios S.A."
    },
    {
      value: "082",
      text: "Banco Topazio S.A."
    },
    {
      value: "083",
      text: "Banco da China Brasil S.A."
    },
    {
      value: "084",
      text: "Uniprime Norte do Paraná - Cooperativa de Crédito Ltda."
    },
    {
      value: "085",
      text: "Cooperativa Central de Crédito Urbano - Cecred"
    },
    {
      value: "089",
      text: "Cooperativa de Crédito Rural da Região da Mogiana"
    },
    {
      value: "091",
      text: "Central de Cooperativas de Economia e Crédito Mútuo do Est RS - Unicred"
    },
    {
      value: "092",
      text: "BRK S.A. Crédito, Financiamento e Investimento"
    },
    {
      value: "093",
      text: "Pólocred Sociedade de Crédito ao Microempreendedor e à Empresa de Pequeno Porte"
    },
    {
      value: "094",
      text: "Banco Finaxis S.A."
    },
    {
      value: "095",
      text: "Banco Confidence de Câmbio S.A."
    },
    {
      value: "096",
      text: "Banco BMFBovespa de Serviços de Liquidação e Custódia S/A"
    },
    {
      value: "097",
      text: "Cooperativa Central de Crédito Noroeste Brasileiro Ltda - CentralCredi"
    },
    {
      value: "098",
      text: "Credialiança Cooperativa de Crédito Rural"
    },
    {
      value: "099",
      text: "Uniprime Central – Central Interestadual de Cooperativas de Crédito Ltda."
    },
    {
      value: "100",
      text: "Planner Corretora de Valores S.A."
    },
    {
      value: "101",
      text: "Renascença Distribuidora de Títulos e Valores Mobiliários Ltda."
    },
    {
      value: "102",
      text: "XP Investimentos Corretora de Câmbio Títulos e Valores Mobiliários S.A."
    },
    {
      value: "104",
      text: "Caixa Econômica Federal"
    },
    {
      value: "105",
      text: "Lecca Crédito, Financiamento e Investimento S/A"
    },
    {
      value: "107",
      text: "Banco Bocom BBM S.A."
    },
    {
      value: "108",
      text: "PortoCred S.A. Crédito, Financiamento e Investimento"
    },
    {
      value: "111",
      text: "Oliveira Trust Distribuidora de Títulos e Valores Mobiliários S.A."
    },
    {
      value: "113",
      text: "Magliano S.A. Corretora de Cambio e Valores Mobiliarios"
    },
    {
      value: "114",
      text: "Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP"
    },
    {
      value: "117",
      text: "Advanced Corretora de Câmbio Ltda."
    },
    {
      value: "118",
      text: "Standard Chartered Bank (Brasil) S.A. Banco de Investimento"
    },
    {
      value: "119",
      text: "Banco Western Union do Brasil S.A."
    },
    {
      value: "120",
      text: "Banco Rodobens SA"
    },
    {
      value: "121",
      text: "Banco Agibank S.A."
    },
    {
      value: "122",
      text: "Banco Bradesco BERJ S.A."
    },
    {
      value: "124",
      text: "Banco Woori Bank do Brasil S.A."
    },
    {
      value: "125",
      text: "Brasil Plural S.A. Banco Múltiplo"
    },
    {
      value: "126",
      text: "BR Partners Banco de Investimento S.A."
    },
    {
      value: "127",
      text: "Codepe Corretora de Valores e Câmbio S.A."
    },
    {
      value: "128",
      text: "MS Bank S.A. Banco de Câmbio"
    },
    {
      value: "129",
      text: "UBS Brasil Banco de Investimento S.A."
    },
    {
      value: "130",
      text: "Caruana S.A. Sociedade de Crédito, Financiamento e Investimento"
    },
    {
      value: "131",
      text: "Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda."
    },
    {
      value: "132",
      text: "ICBC do Brasil Banco Múltiplo S.A."
    },
    {
      value: "133",
      text: "Confederação Nacional das Cooperativas Centrais de Crédito e Economia Familiar e"
    },
    {
      value: "134",
      text: "BGC Liquidez Distribuidora de Títulos e Valores Mobiliários Ltda."
    },
    {
      value: "135",
      text: "Gradual Corretora de Câmbio, Títulos e Valores Mobiliários S.A."
    },
    {
      value: "136",
      text: "Confederação Nacional das Cooperativas Centrais Unicred Ltda – Unicred do Brasil"
    },
    {
      value: "137",
      text: "Multimoney Corretora de Câmbio Ltda"
    },
    {
      value: "138",
      text: "Get Money Corretora de Câmbio S.A."
    },
    {
      value: "139",
      text: "Intesa Sanpaolo Brasil S.A. - Banco Múltiplo"
    },
    {
      value: "140",
      text: "Easynvest - Título Corretora de Valores SA"
    },
    {
      value: "142",
      text: "Broker Brasil Corretora de Câmbio Ltda."
    },
    {
      value: "143",
      text: "Treviso Corretora de Câmbio S.A."
    },
    {
      value: "144",
      text: "Bexs Banco de Câmbio S.A."
    },
    {
      value: "145",
      text: "Levycam - Corretora de Câmbio e Valores Ltda."
    },
    {
      value: "146",
      text: "Guitta Corretora de Câmbio Ltda."
    },
    {
      value: "149",
      text: "Facta Financeira S.A. - Crédito Financiamento e Investimento"
    },
    {
      value: "157",
      text: "ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda."
    },
    {
      value: "159",
      text: "Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor"
    },
    {
      value: "163",
      text: "Commerzbank Brasil S.A. - Banco Múltiplo"
    },
    {
      value: "169",
      text: "Banco Olé Bonsucesso Consignado S.A."
    },
    {
      value: "172",
      text: "Albatross Corretora de Câmbio e Valores S.A"
    },
    {
      value: "173",
      text: "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A."
    },
    {
      value: "174",
      text: "Pernambucanas Financiadora S.A. Crédito, Financiamento e Investimento"
    },
    {
      value: "177",
      text: "Guide Investimentos S.A. Corretora de Valores"
    },
    {
      value: "180",
      text: "CM Capital Markets Corretora de Câmbio, Títulos e Valores Mobiliários Ltda."
    },
    {
      value: "182",
      text: "Dacasa Financeira S/A - Sociedade de Crédito, Financiamento e Investimento"
    },
    {
      value: "183",
      text: "Socred S.A. - Sociedade de Crédito ao Microempreendedor"
    },
    {
      value: "184",
      text: "Banco Itaú BBA S.A."
    },
    {
      value: "188",
      text: "Ativa Investimentos S.A. Corretora de Títulos Câmbio e Valores"
    },
    {
      value: "189",
      text: "HS Financeira S/A Crédito, Financiamento e Investimentos"
    },
    {
      value: "190",
      text: "Cooperativa de Economia e Crédito Mútuo dos Servidores Públicos Estaduais do Rio"
    },
    {
      value: "191",
      text: "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda."
    },
    {
      value: "194",
      text: "Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda."
    },
    {
      value: "196",
      text: "Fair Corretora de Câmbio S.A."
    },
    {
      value: "197",
      text: "Stone Pagamentos S.A."
    },
    {
      value: "204",
      text: "Banco Bradesco Cartões S.A."
    },
    {
      value: "208",
      text: "Banco BTG Pactual S.A."
    },
    {
      value: "212",
      text: "Banco Original S.A."
    },
    {
      value: "213",
      text: "Banco Arbi S.A."
    },
    {
      value: "217",
      text: "Banco John Deere S.A."
    },
    {
      value: "218",
      text: "Banco BS2 S.A."
    },
    {
      value: "222",
      text: "Banco Credit Agrícole Brasil S.A."
    },
    {
      value: "224",
      text: "Banco Fibra S.A."
    },
    {
      value: "233",
      text: "Banco Cifra S.A."
    },
    {
      value: "237",
      text: "Banco Bradesco S.A."
    },
    {
      value: "241",
      text: "Banco Clássico S.A."
    },
    {
      value: "243",
      text: "Banco Máxima S.A."
    },
    {
      value: "246",
      text: "Banco ABC Brasil S.A."
    },
    {
      value: "249",
      text: "Banco Investcred Unibanco S.A."
    },
    {
      value: "250",
      text: "BCV - Banco de Crédito e Varejo S/A"
    },
    {
      value: "253",
      text: "Bexs Corretora de Câmbio S/A"
    },
    {
      value: "254",
      text: "Parana Banco S. A."
    },
    {
      value: "260",
      text: "Nu Pagamentos S.A."
    },
    {
      value: "265",
      text: "Banco Fator S.A."
    },
    {
      value: "266",
      text: "Banco Cédula S.A."
    },
    {
      value: "268",
      text: "Barigui Companhia Hipotecária"
    },
    {
      value: "269",
      text: "HSBC Brasil S.A. Banco de Investimento"
    },
    {
      value: "271",
      text: "IB Corretora de Câmbio, Títulos e Valores Mobiliários Ltda."
    },
    {
      value: "300",
      text: "Banco de la Nacion Argentina"
    },
    {
      value: "318",
      text: "Banco BMG S.A."
    },
    {
      value: "320",
      text: "China Construction Bank (Brasil) Banco Múltiplo S/A"
    },
    {
      value: "341",
      text: "Itaú Unibanco  S.A."
    },
    {
      value: "366",
      text: "Banco Société Générale Brasil S.A."
    },
    {
      value: "370",
      text: "Banco Mizuho do Brasil S.A."
    },
    {
      value: "376",
      text: "Banco J. P. Morgan S. A."
    },
    {
      value: "389",
      text: "Banco Mercantil do Brasil S.A."
    },
    {
      value: "394",
      text: "Banco Bradesco Financiamentos S.A."
    },
    {
      value: "399",
      text: "Kirton Bank S.A. - Banco Múltiplo"
    },
    {
      value: "412",
      text: "Banco Capital S. A."
    },
    {
      value: "422",
      text: "Banco Safra S.A."
    },
    {
      value: "456",
      text: "Banco MUFG Brasil S.A."
    },
    {
      value: "464",
      text: "Banco Sumitomo Mitsui Brasileiro S.A."
    },
    {
      value: "473",
      text: "Banco Caixa Geral - Brasil S.A."
    },
    {
      value: "477",
      text: "Citibank N.A."
    },
    {
      value: "479",
      text: "Banco ItauBank S.A."
    },
    {
      value: "487",
      text: "Deutsche Bank S.A. - Banco Alemão"
    },
    {
      value: "488",
      text: "JPMorgan Chase Bank, National Association"
    },
    {
      value: "492",
      text: "ING Bank N.V."
    },
    {
      value: "494",
      text: "Banco de La Republica Oriental del Uruguay"
    },
    {
      value: "495",
      text: "Banco de La Provincia de Buenos Aires"
    },
    {
      value: "505",
      text: "Banco Credit Suisse (Brasil) S.A."
    },
    {
      value: "545",
      text: "Senso Corretora de Câmbio e Valores Mobiliários S.A."
    },
    {
      value: "600",
      text: "Banco Luso Brasileiro S.A."
    },
    {
      value: "604",
      text: "Banco Industrial do Brasil S.A."
    },
    {
      value: "610",
      text: "Banco VR S.A."
    },
    {
      value: "611",
      text: "Banco Paulista S.A."
    },
    {
      value: "612",
      text: "Banco Guanabara S.A."
    },
    {
      value: "613",
      text: "Omni Banco S.A."
    },
    {
      value: "623",
      text: "Banco Pan S.A."
    },
    {
      value: "626",
      text: "Banco Ficsa S. A."
    },
    {
      value: "630",
      text: "Banco Intercap S.A."
    },
    {
      value: "633",
      text: "Banco Rendimento S.A."
    },
    {
      value: "634",
      text: "Banco Triângulo S.A."
    },
    {
      value: "637",
      text: "Banco Sofisa S. A."
    },
    {
      value: "641",
      text: "Banco Alvorada S.A."
    },
    {
      value: "643",
      text: "Banco Pine S.A."
    },
    {
      value: "652",
      text: "Itaú Unibanco Holding S.A."
    },
    {
      value: "653",
      text: "Banco Indusval S. A."
    },
    {
      value: "654",
      text: "Banco A. J. Renner S.A."
    },
    {
      value: "655",
      text: "Banco Votorantim S.A."
    },
    {
      value: "707",
      text: "Banco Daycoval S.A."
    },
    {
      value: "712",
      text: "Banco Ourinvest S.A."
    },
    {
      value: "719",
      text: "Banif - Bco Internacional do Funchal (Brasil) S.A."
    },
    {
      value: "735",
      text: "Banco Neon S.A."
    },
    {
      value: "739",
      text: "Banco Cetelem S.A."
    },
    {
      value: "741",
      text: "Banco Ribeirão Preto S.A."
    },
    {
      value: "743",
      text: "Banco Semear S.A."
    },
    {
      value: "745",
      text: "Banco Citibank S.A."
    },
    {
      value: "746",
      text: "Banco Modal S.A."
    },
    {
      value: "747",
      text: "Banco Rabobank International Brasil S.A."
    },
    {
      value: "748",
      text: "Banco Cooperativo Sicredi S. A."
    },
    {
      value: "751",
      text: "Scotiabank Brasil S.A. Banco Múltiplo"
    },
    {
      value: "752",
      text: "Banco BNP Paribas Brasil S.A."
    },
    {
      value: "753",
      text: "Novo Banco Continental S.A. - Banco Múltiplo"
    },
    {
      value: "754",
      text: "Banco Sistema S.A."
    },
    {
      value: "755",
      text: "Bank of America Merrill Lynch Banco Múltiplo S.A."
    },
    {
      value: "756",
      text: "Banco Cooperativo do Brasil S/A - Bancoob"
    },
    {
      value: "757",
      text: "Banco Keb Hana do Brasil S. A."
    }
  ];
  