// @ts-nocheck
import React, { useContext, useState } from "react";
import { TripsContext } from "../../../context/Trips";
import { GoogleApiWrapper, Map, Marker, InfoWindow } from 'google-maps-react';

const MapContainer = ({ google }: any) => {
  const {
    state: { selectedTrip }
  } = useContext(TripsContext);

  const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let labelIndex = 0;

  const [state, setState] = useState({
    origin: selectedTrip.sourceLocation,
    destination: selectedTrip.destinationLocation,
    destinations: selectedTrip.destinations,
    infoWindowOptions: {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: { title: "" },
    }
  });

  const onMarkerClick = (props: any, marker: any, e: React.ChangeEvent) => {
    setState(prev => ({
      ...prev,
      infoWindowOptions: {
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      }
    }))
  };

  const initMap = (mapProps: any, map: any) => {
    const { Polyline, LatLng } = google.maps;

    const path = selectedTrip.route.map((path: any[]) => new LatLng(path[0], path[1]))

    const polyline = new Polyline({
      path,
      strokeColor: "#1a73e8",
      strokeOpacity: 0.6,
      strokeWeight: 6
    })
    polyline.setMap(map)
  }

  return (
    <Map
      google={google}
      zoom={15}
      initialCenter={{
        lat: selectedTrip.route > 0 ? selectedTrip.route[selectedTrip.route.length / 2][0] : state.origin[0],
        lng: selectedTrip.route > 0 ? selectedTrip.route[selectedTrip.route.length / 2][1] : state.origin[1]
      }}
      onReady={initMap}
    >
      <Marker
        title={selectedTrip.source_address}
        position={{
          lat: state.origin[0],
          lng: state.origin[1]
        }}
        onClick={onMarkerClick}
      />
      { 
        state.destinations.map((d: any, index: number) => (
          <Marker
            label={labels[labelIndex++ % labels.length]}
            title={d.address}
            position={{
              lat: d.coordinates[0],
              lng: d.coordinates[1]
            }}
            onClick={onMarkerClick}
          />
        ))
      }
      <InfoWindow
        //@ts-ignore
        marker={state.infoWindowOptions.activeMarker}
        visible={state.infoWindowOptions.showingInfoWindow}>
        <div>
          <p>{state.infoWindowOptions.selectedPlace.title}</p>
        </div>
      </InfoWindow>
    </Map>
  );
};

export default GoogleApiWrapper({
	apiKey: "AIzaSyA1EWxwJ6-Mtbv-Ihn2jW34ywyVLD3PuEY",
  libraries: ["geometry"]
})(MapContainer);
