// @ts-nocheck
import React, { useContext } from "react";
import FilterProvider from "./context";
import { ResultContext } from "../../context/Result";
import Loader from "../../components/Loader";
import DataTable from "./components/DataTable";
import Filter from "./components/Filter";

import { Container, Card, CardBody, Table, Col, Row } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { formatNumber } from "../../utils/masks";
import moment from "moment";
import CSVLink from "react-csv";

const headers = [
  "ID",
  "Entregador",
  "Qtd. viagens",
  "Qtd. entregas",
  "Média/Viagem",
  "Serviços",
  "Taxas"
];

const ProviderResults = () => {
  const {
    state: { providerResults, isProviderResultsLoaded }
  } = useContext(ResultContext);

  return (  
    <FilterProvider>
      <Container><h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333"}}>RESULTADOS POR ENTREGADOR</h3></Container>
      <Container>
        <Card>
          <Filter />
          <CardBody>
            {isProviderResultsLoaded ? (<DataTable providerResults={providerResults} />) : (<Loader />)}
          </CardBody>
        </Card>
      </Container>
    </FilterProvider>
  );
};

const transformData = (results: any[]) => {
  const providerTable: any[] = [];
  results.map(day => {
    day.providers.map((provider: any) => (
      providerTable.push([
        moment(day.date).format("DD/MM/YYYY"),
        provider.id,
        provider.name,
        provider.trips,
        provider.delivers,
        formatNumber(provider.avg_ticket),
        formatNumber(provider.total),
        formatNumber(provider.service_fee)
      ])
    ));
  });
  return providerTable;
};

export default withTranslation("common")(ProviderResults);
