// @ts-nocheck
import React from 'react';
import axios from 'axios';

import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

export const AuthContext = React.createContext({});

class AuthProvider extends React.PureComponent {
	queue = [];

	initialState = {
		loggedIn: false,
		loading: false,
		user: null,
		token: null,
	};

	state = this.initialState;

	addToQueue = fn => {
		// console.log('Adding Function to the Queue');
		this.queue.push(fn);
	};

	runQueue = () => {
		// console.log('Running Authentication Queue', this.queue.length);
		this.queue.forEach(fn => fn());
		setTimeout(() => (this.queue = []), 2000);
	};

	/*
   You must pass this as decorator to any function that
   uses this Context
  */

	executeOnlyIfAuthenticated = fn => {
		return (...args) => {
			const functionToBeExecuted = fn.bind(this, ...args);
			if (this.state.loggedIn) functionToBeExecuted();
			else this.addToQueue(functionToBeExecuted);
		};
	};

	signin = async (email, password) => {
		const request = this.request();

		try {
			const data = { email, password };
			// const { history } = this.props;

			const response = await request('POST', 'admin/v1/signin', data);

			if (response.data.success) {
				const { token, user } = response.data.data;

				const savedData = await this.saveInfoOnLocalStorage(token, user);

				if (savedData) {
					this.setState(
						{
							loggedIn: true,
							loading: false,
							user,
							token,
						},
						toast.info.bind(this, `Sucesso ao logar na sua conta ${user.username}`)
					);
					// history.push('/wallet');
				}
			} else throw new Error('Não foi possível logar na sua conta');
		} catch (err) {
			toast.error(err.message);
		}
	};

	signout = () => {
		const { history } = this.props;
		this.setState(this.initialState, () => {
			history.push('/login');
			localStorage.clear();
		});
	};

	saveInfoOnLocalStorage = (token, user) => {
		return new Promise(resolve => {
			localStorage.setItem('token', token);
			localStorage.setItem('user', JSON.stringify(user));
			resolve(true);
		});
	};

	register = () => {};

	request = savedToken => {
		const { host } = this.props;
		let token = savedToken || this.state.token;

		return async (method, url, data) => {
			if (!token && this.state.token) {
				token = this.state.token;
			}

			const headers = {
				authorization: `JWT ${token}`,
			};

			const options = { method, headers };
			const route = new URL(`${host}${url}`);

			if (method === 'GET' && data) {
				Object.keys(data).forEach(key => {
					route.searchParams.append(key, data[key]);
				});
			} else {
				options.data = data;
			}

			const requestPromise = axios({
				url: route.href,
				method,
				...options,
			});

			return requestPromise;
		};
	};

	isCurrentlyLoggedin = async () => {
		const { token, user } = localStorage;

		if (token && user) {
			this.setState(
				{
					loggedIn: true,
					loading: false,
					user: JSON.parse(user),
					token,
				},
				this.runQueue
			);
		}
	};

	componentDidMount() {
		this.isCurrentlyLoggedin();
	}

	render() {
		const value = {
			host: this.props.host,
			state: {
				...this.state,
			},
			action: {
				signin: this.signin,
				signout: this.signout,
				register: this.register,
				request: this.request(),
				executeOnlyIfAuthenticated: this.executeOnlyIfAuthenticated,
			},
		};

		return <AuthContext.Provider value={value}>{this.props.children}</AuthContext.Provider>;
	}
}

export default withRouter(AuthProvider);
