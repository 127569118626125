// @ts-nocheck
import React from 'react';
import { ZonesContext } from '../../context/Zones';
import sortByProperty from '../../utils/sortByProperty';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import mapSubject from './subjects/MapSubject';
import QueueZones from '../QueueZones';
import './styles.sass';
import {
	Container,
	Card,
	CardBody,
	Table,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
} from 'reactstrap';

class Regions extends React.PureComponent {
	static contextType = ZonesContext;

	state = {
		regions: [],
		regionModal: false,
		zoneModal: false,
		regionTitle: '',
		zoneTitle: '',
		search: '',
		drawingRegion: false,
		drawingZone: false,
	};

	componentDidMount() {
		const currentCityId = this.props.history.location.cityId;

		if (!currentCityId) {
			return this.props.history.push({
				pathname: '/cities',
			});
		}

		this.context.action.fetchRegions(currentCityId);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.regions.length === 0 && prevState.search === '') {
			this.setState({
				regions: sortByProperty(this.context.state.regions, 'title'),
			});
		}
	}

	onChangeSearch = ({ target: { value: searchPattern } }) => {
		if (searchPattern === '') {
			return this.setState({
				regions: sortByProperty(this.context.state.regions, 'title'),
			});
		}

		const filteredRegions = this.context.state.regions.filter(region => {
			return region.title.toLowerCase().includes(searchPattern);
		});

		this.setState({
			regions: sortByProperty(filteredRegions, 'title'),
			search: searchPattern,
		});
	};

	onTableItemClick(id) {
		const { regions } = this.context.state;

		const selectedRegion = regions.find(region => {
			return region._id === id;
		});

		mapSubject.notify('centerOn', selectedRegion.kmlzone);
	}

	onAddZoneClick = () => {
		const newZone = {
			cityId: this.props.history.location.cityId,
			title: this.state.zoneTitle,
			kmlzone: [],
		};

		const onUpdateZone = kmlzone => {
			newZone.kmlzone = kmlzone.slice();
		};

		this.currentlyBeingEditedZone = newZone;

		this.setState({ drawingZone: true }, () => {
			mapSubject.notify('drawZone', newZone, onUpdateZone);
		});

		this.toggleZoneModal();
	};

	onAddRegionClick = () => {
		const newRegion = {
			cityId: this.props.history.location.cityId,
			title: this.state.regionTitle,
			kmlzone: [],
		};

		const onUpdateRegion = kmlzone => {
			newRegion.kmlzone = kmlzone.slice();
		};

		this.currentlyBeingEditedRegion = newRegion;

		this.setState({ drawingRegion: true }, () => {
			mapSubject.notify('drawRegion', newRegion, onUpdateRegion);
		});

		this.toggleRegionModal();
	};

	onConfirm = () => {
		let editType;

		if (this.state.drawingRegion) {
			editType = 'drawingRegion';

			const { cityId, title, kmlzone } = this.currentlyBeingEditedRegion;

			this.context.action.createRegion(cityId, title, kmlzone);
		}

		if (this.state.drawingZone) {
			editType = 'drawingZone';

			const { cityId, title, kmlzone } = this.currentlyBeingEditedZone;

			this.context.action.createZone(cityId, title, kmlzone);
		}

		this.setState({ [editType]: false });

		mapSubject.notify('clearEvents');
	};

	toggleRegionModal = () => {
		this.setState(prev => ({ regionModal: !prev.regionModal }));
	};

	toggleZoneModal = () => {
		this.setState(prev => ({ zoneModal: !prev.zoneModal }));
	};

	onInputChange = ({ target: { value, name: key } }) => {
		this.setState({ [key]: value });
	};

	render() {
		const { drawingRegion, drawingZone } = this.state;

		const {
			state: { zones, regions, regionsAreLoading, regionsHasErrored },
		} = this.context;

		return (
			<Container>
				<QueueZones cityId={this.props.history.location.cityId} />

				<Card>
					<div className="opts">
						{zones.length === 0 && (
							<Button color="primary" disabled={drawingRegion || drawingZone} onClick={this.toggleZoneModal}>
								Adicionar Região
							</Button>
						)}
						<Button color="primary" disabled={drawingRegion || drawingZone} onClick={this.toggleRegionModal}>
							Adicionar Zona de Fila
						</Button>
						<Button color="info" disabled={!drawingRegion && !drawingZone} onClick={this.onConfirm}>
							Confirmar
						</Button>
					</div>

					<CardBody style={{ borderRadius: '0' }}>
						<div className="table-filter">
							<input type="text" placeholder="Pesquisar..." onChange={this.onChangeSearch} />
						</div>

						<Table responsive hover className="table table-condensed table--bordered">
							<thead>
								<tr>
									<th>Zona de Fila (Clique para ver no mapa)</th>
								</tr>
							</thead>
							<tbody>
								{this.state.regions.map(region => (
									<tr onClick={() => this.onTableItemClick(region._id)} key={region._id}>
										<td>{region.title}</td>
									</tr>
								))}
							</tbody>
						</Table>

						{regionsAreLoading && <Loader height="300px" />}

						{regionsHasErrored && <Error message="Não foi possível exibir as cidades" />}

						{regions.length === 0 && !regionsAreLoading && !regionsHasErrored && (
							<Error message="Não existe nenhuma cidade cadastrada" />
						)}
					</CardBody>
				</Card>

				<Modal isOpen={this.state.regionModal} toggle={this.toggleRegionModal}>
					<ModalHeader toggle={this.toggleRegionModal}>Nova Zona de Fila</ModalHeader>
					<ModalBody>
						<Input name="regionTitle" placeholder="Escolha o nome da nova zona" onChange={this.onInputChange} />
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.onAddRegionClick}>
							Confirmar
						</Button>{' '}
						<Button color="secondary" onClick={this.toggleRegionModal}>
							Cancelar
						</Button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.zoneModal} toggle={this.toggleZoneModal}>
					<ModalHeader toggle={this.toggleZoneModal}>Nova Região</ModalHeader>
					<ModalBody>
						<Input name="zoneTitle" placeholder="Escolha o nome da nova região" onChange={this.onInputChange} />
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.onAddZoneClick}>
							Confirmar
						</Button>{' '}
						<Button color="secondary" onClick={this.toggleZoneModal}>
							Cancelar
						</Button>
					</ModalFooter>
				</Modal>
			</Container>
		);
	}
}

export default Regions;
