// @ts-nocheck
import React from "react";
import { AuthContext } from "./Auth";
import moment from "moment";

interface FranchiseContext {
  state: State;
  action: {
    getFranchiseBalance(franchiseId: string): void;
    getBanks(): void;
    getTransactions(franchiseId: string, filter: any): void;
  }
}

export const FranchiseContext = React.createContext({});

interface State {
  balance: any;
  isBalanceLoaded: boolean;
  banks: any[];
  isBanksLoaded: boolean;
  transactions: any[];
  isTransactionsLoaded: boolean;
}

class FranchiseProvider extends React.PureComponent<{}, State> {
  static contextType = AuthContext;

  constructor(props: any) {
    super(props);

    this.state = {
      balance: {},
      isBalanceLoaded: false,
      banks: [],
      isBanksLoaded: false,
      transactions: [],
      isTransactionsLoaded: false
    };
  }

  getFranchiseBalance = (franchiseId: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/franchise/${franchiseId}/financial/balance`;
    this.setState({
      isBalanceLoaded: false
    }, async () => {
      try {
        const response = await request("GET", url);
        if (response.data.success) {
          this.setState({
            balance: response.data.data,
            isBalanceLoaded: true,
          })
        }
        // console.log(response.data)
      } catch (error) {
        // console.log(error)
      }
    })
  }

  getBanks = async () => {
    const { request } = this.context.action;
    const url = "admin/v1/franchise/financial/banks";
    this.setState({
      isBanksLoaded: false
    }, async () => {
      try {
        const response = await request("GET", url);
        if (response.data.success) {
          this.setState({
            banks: response.data.data,
            isBanksLoaded: true,
          })
        }
        // console.log(response.data)
      } catch (error) {
        // console.log(error)
      }
    })
  }

  getTransactions = (franchiseId: string, filter: any) => {
    const { request } = this.context.action;
    const url = `admin/v1/franchise/${franchiseId}/financial/transactions`;
    const data = {
      date: {
        start: moment(filter.startDate).format('YYYY-MM-DD'),
        end: moment(filter.endDate).format('YYYY-MM-DD')
      },
      type: { o: true, i: true, t: true }
    }
    this.setState({
      isTransactionsLoaded: false
    }, async () => {
      try {
        const response = await request("POST", url, data);
        if (response.data.success) {
          // console.log(response.data.data)
          this.setState({
            transactions: response.data.data,
            isTransactionsLoaded: true
          })
        }
      } catch (error) {
        // console.log(error)
        this.setState({ transactions: [], isTransactionsLoaded: true })
      }
    })
  }

  render() {
    const value = {
      state: { ...this.state },
      action: {
        getFranchiseBalance: this.getFranchiseBalance,
        getBanks: this.getBanks,
        getTransactions: this.getTransactions
      }
    }

    return (
      <FranchiseContext.Provider value={value}>
        {this.props.children}
      </FranchiseContext.Provider>
    )
  }
}

export default FranchiseProvider;
