// @ts-nocheck
import React, { useState } from "react"
import Loader from "../../../components/Loader"
import formatDocument from "../utils/formatDocument"
import CompanyManagement from "./CompanyManagement"
import CostCenterWindow from "./CostCenterWindow"
import CompanyAdmins from "./CompanyAdmins"
import EditPolicy from "./EditPolicy"

import { Badge } from "reactstrap"
import classnames from "classnames"
import moment from "moment"

const formatStatus = (approved, refused, pack) => {
  return (
    <div className="badges">
      {approved && !refused && <Badge color="success"><i className="fas fa-checked" />&nbsp;{" "}Aceito</Badge>}
      {!approved && refused && <Badge color="danger"><i className="fas fa-times" />&nbsp;Recusada</Badge>}
      {!approved && !refused && <Badge color="warning">Em Espera</Badge>}
      {pack && <Badge color="info">Pack</Badge>}
    </div>
  )
}

const formatPricingPolicy = (policy) => {
  const policies = { "fixed": "Fixa", "variable": "Variável", "ranged": "Por margem de distância" }
  return policies[policy]
}

const CompanyDetails = ({ request, active, toggle, loading }) => {
  const [editMode, setEditMode] = useState(false)
  const [winActive, setWinActive] = useState(false)

  const onOpenEditForm = () => setEditMode(true)
  const onCloseEditForm = () => setEditMode(false)

  const onOpenWindow = () => {
    if (!winActive) setWinActive(true)
  }

  const onCloseWindow = () => {
    setWinActive(false)
  }

  const onToggle = () => {
    toggle()
    setEditMode(false)
  }

  return (
    <div className={classnames("company-details", { active })}>
      <span className="close-btn">
        <i className="fas fa-times" onClick={onToggle} />
      </span>

      {loading && <Loader height="500px" />}

      {request && (
        <React.Fragment>
          <div className="company-details__wrapper">
            <div className="company-details__general-info">
              <span className="text-big title">{request.company.name} {formatStatus(request.company.is_approved, request.company.is_refused, request.company.is_pack)}</span>
              <span className="text-small"><b>Criado em:</b> {moment(request.company.created_at).format("LL")}</span>
              <span className="text-small"><b>CNPJ:</b> {formatDocument(request.company.document)}</span>
              <span className="text-small"><b>Gerente:</b> {request.company.platform_manager}</span>
              <span className="text-small"><b>Email Gerente:</b> {request.company.manager_email}</span>
              <span className="text-small"><b>Cobrança:</b> {request.company.financial_manager}</span>
              <span className="text-small"><b>Email Cobrança:</b> {request.company.financial_email || request.company.manager_email}</span>
              <span className="text-small"><b>Telefone Cobrança:</b> {request.company.financial_phone}</span>
              <span className="text-small"><b>Politica de Preço:</b> {formatPricingPolicy(request.company.pack_rules.pricing_policy)}</span>
            </div>
            {request.admins &&
              <CompanyAdmins admins={request.admins} />}

            <EditPolicy
              company={request.company._id}
              onEditPolicy={() => {}}
              active={editMode}
              onClose={onCloseEditForm}
            />
          </div>

          <CompanyManagement
            company={request.company._id}
            approved={request.company.is_approved}
            refused={request.company.is_refused}
            onOpenWindow={onOpenWindow}
            onOpenEditForm={onOpenEditForm}
            onCloseEditForm={onCloseEditForm}
          />

          <CostCenterWindow
            active={winActive}
            company={request.company._id}
            onClose={onCloseWindow}
          />
        </React.Fragment>
      )}

    </div>
  )
}

export default CompanyDetails
