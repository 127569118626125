const getMapConfig = (google, map) => ({
  drawingMode: null,
  drawingControl: false,
  polygonOptions: {
    fillColor: "#5b18ab",
    fillOpacity: 0.5,
    strokeWeight: 2,
    strokeColor: "#673E98",
    clickable: true,
    editable: true,
    draggable: true,
    zIndex: 1
  },
  drawingControlOptions: {
    position: google.maps.ControlPosition.TOP_CENTER,
    drawingModes: [
      google.maps.drawing.OverlayType.MARKER,
      google.maps.drawing.OverlayType.POLYGON
    ]
  },
  map
})

export default getMapConfig
