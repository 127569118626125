// @ts-nocheck
import React from "react";
import { useToggle } from "../../../hooks/hooks";
import moment from "moment";


type Props = { company: any };

const ListItem = ({ company }: Props) => {
  const { active, toggle, activate } = useToggle(false);

  return (
    <React.Fragment>
      <tr onClick={activate}>
        <td>{company.city[0]}</td>
        <td>{company.company_name}</td>
        <td>{moment(company.last_trip_at).format("DD/MM/yyyy")}</td>
        <td style={{backgroundColor: moment().subtract(11, 'M').format("yyyyMM") <= moment(company.last_trip_at).format("yyyyMM") ? '#29e7b055' : 'none', textAlign:'center'}}></td>
        <td style={{backgroundColor: moment().subtract(10, 'M').format("yyyyMM") <= moment(company.last_trip_at).format("yyyyMM") ? '#29e7b055' : 'none', textAlign:'center'}}></td>
        <td style={{backgroundColor: moment().subtract(9, 'M').format("yyyyMM") <= moment(company.last_trip_at).format("yyyyMM") ? '#29e7b055' : 'none', textAlign:'center'}}></td>
        <td style={{backgroundColor: moment().subtract(8, 'M').format("yyyyMM") <= moment(company.last_trip_at).format("yyyyMM") ? '#29e7b055' : 'none', textAlign:'center'}}></td>
        <td style={{backgroundColor: moment().subtract(7, 'M').format("yyyyMM") <= moment(company.last_trip_at).format("yyyyMM") ? '#29e7b055' : 'none', textAlign:'center'}}></td>
        <td style={{backgroundColor: moment().subtract(6, 'M').format("yyyyMM") <= moment(company.last_trip_at).format("yyyyMM") ? '#29e7b055' : 'none', textAlign:'center'}}></td>
        <td style={{backgroundColor: moment().subtract(5, 'M').format("yyyyMM") <= moment(company.last_trip_at).format("yyyyMM") ? '#29e7b055' : 'none', textAlign:'center'}}></td>
        <td style={{backgroundColor: moment().subtract(4, 'M').format("yyyyMM") <= moment(company.last_trip_at).format("yyyyMM") ? '#29e7b055' : 'none', textAlign:'center'}}></td>
        <td style={{backgroundColor: moment().subtract(3, 'M').format("yyyyMM") <= moment(company.last_trip_at).format("yyyyMM") ? '#29e7b055' : 'none', textAlign:'center'}}></td>
        <td style={{backgroundColor: moment().subtract(2, 'M').format("yyyyMM") <= moment(company.last_trip_at).format("yyyyMM") ? '#29e7b055' : 'none', textAlign:'center'}}></td>
        <td style={{backgroundColor: moment().subtract(1, 'M').format("yyyyMM") <= moment(company.last_trip_at).format("yyyyMM") ? '#29e7b055' : 'none', textAlign:'center'}}></td>
        <td style={{backgroundColor: moment().format("yyyyMM") <= moment(company.last_trip_at).format("yyyyMM") ? '#29e7b055' : 'none', textAlign:'center'}}></td>
      </tr>
    </React.Fragment>
  );
};

export default ListItem;
