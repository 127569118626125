// @ts-nocheck
import React, { useContext } from "react";
import { getTripStatus } from "./../../../utils/translateEnums";
import { TripsContext } from "../../../context/Trips";
import { formatNumber } from "../../../utils/masks";
import Panel from "../../../components/Panel";

import { Badge, ListGroupItem } from "reactstrap";
import moment from "moment";

const TripDetails = () => {
  const {
    state: { selectedTrip }
  } = useContext(TripsContext);

  return (
    <Panel title="Informações da Entrega">
      <div>
        <div><small><strong>ENDEREÇO DE ORIGEM</strong></small></div>
        <div style={{marginBottom:10}}>{selectedTrip.source_address}</div>
      </div>
      <ListGroupItem>
        <span className="float-right">
          {moment(selectedTrip.created_at).format('DD/MM/YYYY, kk:mm')}
        </span>
        <strong>Solicitado</strong>
      </ListGroupItem>
      <ListGroupItem>
        <span className="float-right">
          {moment(selectedTrip.provider_trip_start_time).format("kk:mm")}
        </span>
        <strong>Iniciado</strong>
      </ListGroupItem>
      <ListGroupItem>
        <span className="float-right">
          {moment(selectedTrip.provider_trip_end_time).format("kk:mm")}
        </span>
        <strong>Finalizado</strong>
      </ListGroupItem>
      {/* <ListGroupItem>
        <span className="float-right">
          {formatNumber(selectedTrip.estimated_distance)} km
        </span>
        <strong>Distancia</strong>
      </ListGroupItem>
      <ListGroupItem>
        <span className="float-right">
          {selectedTrip.estimated_time} min
        </span>
        <strong>Tempo Total</strong>
      </ListGroupItem>
      <ListGroupItem>
        <span className="float-right">
          {selectedTrip.source_address}
        </span>
        <strong>Origem</strong>
      </ListGroupItem>
      <ListGroupItem>
        <span className="float-right">
          {selectedTrip.destination_address}
        </span>
        <strong>Destino final</strong>
      </ListGroupItem> */}
      <ListGroupItem>
        <span className="float-right">
          {getTripStatus(selectedTrip.status)}
        </span>
        <strong>Último status</strong>
      </ListGroupItem>
      <ListGroupItem>
        <span className="float-right">
        R$ {formatNumber(selectedTrip.total_for_company)}
        </span>
        <strong>Total Loja</strong>
      </ListGroupItem>
      <ListGroupItem>
        <span className="float-right">
          R$ {formatNumber(selectedTrip.total + (selectedTrip.tax_return || 0))}
        </span>
        <strong>Total Entregador</strong> <small>(Entrega R${formatNumber(selectedTrip.total)} + Retorno R${formatNumber(selectedTrip.tax_return)})</small>
      </ListGroupItem>
    </Panel>
  );
}

export default TripDetails;