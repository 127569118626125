// @ts-nocheck
import React, { useContext } from "react";
import { ProviderMetricsContext } from "../../../context/ProviderMetrics";
import Loader from "../../../components/Loader";
import Panel from "../../../components/Panel";
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, Cell } from "recharts";

const TripsByDistance = () => {
  const {
    state: { tripsDistance, isTripsDistanceLoaded }
  } = useContext(ProviderMetricsContext);

  const transformData = (data: any) => {
    const result: any[] = [];
    Object.entries(data).map((d) => {
      if (d[0] === "tripsAbove" || d[0] === "tripsBellow") {
        const label = d[0] === "tripsAbove"
          ? `Acima de ${data.referenceDistance} Km`
          : `Abaixo de ${data.referenceDistance} Km`;
        result.push({
          name: label,
          Entregas: d[1]
        });
      }
    });
    return result;
  };

  return (
    <Panel
      xl={6}
      lg={6}
      md={6}
      title="Entregas/Distância"
    >
      {isTripsDistanceLoaded
        ? <React.Fragment>
          <h5>Total de distância: {tripsDistance.totalDistance} Km</h5>
          <ResponsiveContainer height={260}>
            <BarChart data={transformData(tripsDistance)} >
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="Entregas">
                <Cell fill="#8884d8" />
                <Cell fill="#f8b68b" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </React.Fragment>
        : <Loader height="260px" />
      }
    </Panel>
  );
};

export default TripsByDistance;