
//import actions from 'redux-auto'

export default function theme(theme = {}, action) {
  theme = {
    className: 'theme-light',
  };
  return theme;
}

export function before (appsState, action){
  return action.payload;
}

export function after (newAppsState, action, oldAppsState){
  return newAppsState;
}