// @ts-nocheck
import React, { useEffect, useContext } from "react";
import { FinanceContext } from "../../context/Finance";
import { formatNumber, transformCodeToBank } from "../../utils/masks";
import Filter from "./Filter";

import { Container, Card, CardBody, Table, Button } from "reactstrap";
import { FaRegThumbsDown } from "react-icons/fa";
import moment from "moment";

const heads = [
  "Motorista",
  "Email",
  "Titular",
  "CPF",
  "Banco",
  "Agência",
  "Conta",
  "Tipo",
  "Valor",
  "Data da solicitação",
  "Data da confirmação",
  "Ação"
];

const WithDrawals = ({ history }) => {
  const {
    state: { withdrawalsProcessedRequests, loading, update },
    action: { getProcessedRequests, cancelRequest }
  } = useContext(FinanceContext);

  const onClick = (e, providerId) => {
    e.preventDefault();
    history.push({
      pathname: `/withdrawals/provider/${providerId}`
    });
  };

  useEffect(() => {
    getProcessedRequests();
  }, []);

  useEffect(() => {
    update && getProcessedRequests();
  }, [update]);

  return (
    <Container>
      <Card>
        <h3 className="mb-4">Transações Processadas</h3>
        <Filter/>
        <CardBody>
          {!loading ? (
            <Table responsive hover className="table--bordered mb-4">
              <thead>
                <tr
                  style={{
                    backgroundColor: "#EEE",
                    fontWeight: "bold",
                    borderBottom: "solid 2px #AAA"
                  }}
                >
                  <td colSpan="7">
                    Solicitações: {
                      withdrawalsProcessedRequests.length
                    }
                  </td>
                  <td colSpan="5">
                    Total: {formatNumber(
                      withdrawalsProcessedRequests.reduce(
                        (prev, r) => prev + r.total_amount,
                        0
                      )
                    )}
                  </td>
                </tr>
                <tr>
                  {heads.map((head, index) => (
                    <th key={index}>{head}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {withdrawalsProcessedRequests.map((request, index) => (
                  <tr key={index}>
                    <td> {request.provider_unique_id} </td>
                    <td onClick={e => onClick(e, request.provider_id)}>
                      {" "}
                      {request.provider_email}
                    </td>
                    <td> {request.account_owner} </td>
                    <td> {request.owner_document} </td>
                    <td> {transformCodeToBank(request.bank_code)} </td>
                    <td> {request.bank_branch} </td>
                    <td>
                      {" "}
                      {request.bank_account +
                        "-" +
                        request.bank_account_digit}{" "}
                    </td>
                    <td>
                      {" "}
                      {request.account_type === "1"
                        ? "Conta Poupança"
                        : "Conta Corrente"}{" "}
                    </td>
                    <td> {formatNumber(request.total_amount)} </td>
                    <td>
                      {" "}
                      {moment(request.created_at).format(
                        "DD/MM/YYYY, h:mm:ss a"
                      )}{" "}
                    </td>
                    <td>
                      {" "}
                      {moment(request.updated_at).format(
                        "DD/MM/YYYY, h:mm:ss a"
                      )}{" "}
                    </td>
                    <td style={{ display: "flex" }}>
                      <Button
                        onClick={() => {
                          cancelRequest(request._id);
                        }}
                        color="danger"
                        size="sm"
                      >
                        <FaRegThumbsDown />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            ""
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default WithDrawals;
