// @ts-nocheck
import React, { Component, Fragment } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import i18next from 'i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import 'bootstrap/dist/css/bootstrap.css';
import '../scss/app.scss';
import Router from './Router';

import { config as i18nextConfig } from '../translations';

//redux
import { createStore, applyMiddleware, combineReducers } from 'redux';

//react-redux
import { Provider } from 'react-redux';

//redux-auto
import { auto, reducers } from 'redux-auto';

const webpackModules = require.context('../store', true, /^(?!.*\.test\.js$).*\.js$/);
let middleware = applyMiddleware(auto(webpackModules, webpackModules.keys()));

if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) middleware = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(middleware);

// import storeTheme from './store-theme';
// import store from './store-theme';
const store = createStore(combineReducers(reducers), middleware);

i18next.init(i18nextConfig);

class App extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			loaded: false,
		};
	}

	componentDidMount() {
		window.addEventListener('load', () => {
			this.setState({ loading: false });
			setTimeout(() => this.setState({ loaded: true }), 500);
		});
	}

	render() {
		const { loaded, loading } = this.state;
		return (
			<Provider store={store}>
				<BrowserRouter basename="/">
					<I18nextProvider i18n={i18next}>
						<Fragment>
							{!loaded && (
								<div className={`load${loading ? '' : ' loaded'}`}>
									<div className="load__icon-wrap">
										<svg className="load__icon">
											<path fill="#11cf99" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
										</svg>
									</div>
								</div>
							)}
							<div>
								<Router />
							</div>
						</Fragment>
					</I18nextProvider>
				</BrowserRouter>
			</Provider>
		);
	}
}

export default hot(module)(App);
