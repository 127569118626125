// @ts-nocheck
import React from "react";
import { toast } from "react-toastify";
import { AuthContext } from "./Auth";

interface CompanyContext {
  state: State;
  action: {
    setFilterCityId(value: any): void;
    fetchCities(): void;
    fetchCompanies(): void;
    fetchAllCompanies(): void;
    fetchCompaniesRetention(): void;
    fetchOrigins(): void;
    fetchCompanyPricings(companyId: string): void;
    fetchAdmins(companyId: string): void;
    createAdmin(companyId: string, data: any, cb: any): void;
    updateAdmin(companyId: string, adminId: string, data: any, cb: any): void;
    removeAdmin(companyId: string, adminId: string): void;
    fetchOpenRequests(): void;
    fetchOpenRequestDetails(requestId: string): void;
    acceptCompany(companyId: string): void;
    refuseCompany(companyId: string): void;
    editCompany(companyId: string, data: any): void;
    editCompanyPolicy(companyId: string, pricing_policy_id: string, data: any): void;
    fetchCostCenters(companyId: string): void;
    resetRequestDetails(): void;
    fetchCRMs(): void;
    crmCompanyUpdate(company_id: any, crm_id: any, step: any, comment: any, estimated_deliveries: any): void;
  };
}

export const CompanyContext = React.createContext({} as CompanyContext);

interface State {
  city_id: any;
  cities: any[];
  companies: any[];
  companiesRetention: any[];
  companiesAreLoading: boolean;
  companiesHasErrored: boolean;
  openRequests: any[];
  openRequestsAreLoading: boolean;
  openRequestsHasErrored: boolean;
  request: any;
  requestIsLoading: boolean;
  requestHasErrored: boolean;
  costCenters: any[];
  costCentersAreLoading: boolean;
  costCentersHasErrored: boolean;
  origins: any[];
  admins: any[];
  pricings: any[];
  createAdminLoading: boolean;
  crm: any [];
}

class CompanyProvider extends React.PureComponent<{}, State> {
  static contextType = AuthContext;

  initialState = {
    city_id: '',
    cities: [],
    companies: [],
    companiesRetention: [],
    companiesAreLoading: true,
    companiesHasErrored: false,
    openRequests: [],
    openRequestsAreLoading: true,
    openRequestsHasErrored: false,
    request: null,
    requestIsLoading: true,
    requestHasErrored: false,
    costCenters: [],
    costCentersAreLoading: true,
    costCentersHasErrored: false,
    origins: [],
    admins: [],
    pricings: [],
    createAdminLoading: false,
    crm:[]
  };

  state = this.initialState;

  setFilterCityId = (value:any) => {
    this.setState(prev => ({ ...prev, city_id: value }), async () => {
      this.fetchCompaniesRetention();
    });
  }

  fetchCities = () => {
    const { request } = this.context.action;

    this.setState(this.initialState, async () => {
      try {
        const response = await request("GET", "admin/v1/filters/cities");

        if (response.data.success) {
          this.setState({
            cities: response.data.data
          });
        } else throw new Error("Erro ao buscar empresas!");
      } catch (err) {
        this.setState(
          {
            cities: []
          },
          toast.error.bind(null, err.message)
        );
      }
    });
  };

  fetchCompanies = () => {
    const { request } = this.context.action;

    this.setState(this.initialState, async () => {
      try {
        const response = await request("GET", "admin/v1/companies");

        if (response.data.success) {
          let companyData = response.data.data;
          companyData.sort(function(a: any, b: any){
              if(a.costcenter.length == 0 && b.costcenter.length > 0) { return -1; }
              return 0;
          });
          console.log(companyData);

          this.setState({
            companies: companyData,
            companiesAreLoading: false,
            companiesHasErrored: false
          });
          console.log(companyData);
        } else throw new Error("Erro ao buscar empresas!");
      } catch (err) {
        this.setState(
          {
            companies: [],
            companiesAreLoading: false,
            companiesHasErrored: true
          },
          toast.error.bind(null, err.message)
        );
      }
    });
  };

  fetchAllCompanies = () => {
    const { request } = this.context.action;

    this.setState(this.initialState, async () => {
      try {
        const response = await request("GET", "admin/v1/companies/all");

        if (response.data.success) {
          let companyData = response.data.data;
          companyData.sort(function(a: any, b: any){
              if(a.costcenter.length == 0 && b.costcenter.length > 0) { return -1; }
              return 0;
          });

          this.setState({
            companies: companyData,
            companiesAreLoading: false,
            companiesHasErrored: false
          });
          console.log(companyData);
        } else throw new Error("Erro ao buscar empresas!");
      } catch (err) {
        this.setState(
          {
            companies: [],
            companiesAreLoading: false,
            companiesHasErrored: true
          },
          toast.error.bind(null, err.message)
        );
      }
    });
  };

  fetchCompaniesRetention = async () => {
    const { request } = this.context.action;
    try {
      const response = await request("POST", "admin/v1/dashboard/companies-last_trips", {city_id: this.state.city_id});

      if (response.data.success) {
        this.setState(prev => ({ ...prev, companiesRetention: response.data.data }));
      } else throw new Error("Erro ao buscar empresas!");
    } catch (err) {
      this.setState(prev => ({ ...prev, companiesRetention: [] }));
      toast.error.bind(null, err.message)
    }
  };

  fetchCompanyPricings = (companyId: string) => {
    const { request } = this.context.action;

    this.setState({pricings: []}, async () => {
      try {
        const response = await request("GET", `admin/v1/company/${companyId}/pricing`);

        if (response.data.success) {
          this.setState({
            pricings: response.data.data
          });
        }
      } catch (err) {
        this.setState(
          {
            pricings: []
          }
        );
      }
    });
  };

  fetchOrigins = async () => {
    const { request } = this.context.action;

    try {
      const response = await request("GET", "admin/v1/origins");

      if (response.data.success) {
        const origins = response.data.data.map((o: any) => {
          return { key: o._id, value: o._id, text: o.name };
        });
        this.setState({
          origins
        });
      }
    } catch (err) {
      console.log(err)
    }
  };

  fetchAdmins = async (companyId: string) => {
    const { request } = this.context.action;
    try {
      const response = await request(
        "GET",
        `admin/v1/company/${companyId}/admin`
      );
      if (response.data.success) {
        this.setState({
          admins: response.data.data
        });
      }
    } catch (err) {
      console.log(err)
    }
  };

  createAdmin = async (companyId: string, data: any, cb: any) => {
    const { request } = this.context.action;

    this.setState({ createAdminLoading: true }, async () => {
      try {
        const response = await request(
          "POST",
          `admin/v1/company/${companyId}/admin`,
          data
        );

        if (response.data.success) {
          this.setState({
            admins: response.data.data,
            createAdminLoading: false
          });
          toast.success("Administrador criado com sucesso");
          cb();
        }
        else {
          toast.error(response.data.error.message);
          this.setState({
            createAdminLoading: false
          });
        }
      } catch (err) {
        console.log(err)
        toast.error("Erro ao salvar administrador");
        this.setState({
          createAdminLoading: false
        });
      }
    })
  };

  updateAdmin = async (companyId: string, adminId: string, data: any, cb: any) => {
    const { request } = this.context.action;
    this.setState({ createAdminLoading: true }, async () => {
      try {
        const response = await request(
          "PUT",
          `admin/v1/company/${companyId}/admin/${adminId}`,
          data
        );

        if (response.data.success) {
          this.setState({
            admins: response.data.data,
            createAdminLoading: false
          });
          toast.success("Administrador atualizado com sucesso");
          cb();
        }
        else {
          toast.error(response.data.error.message);
          this.setState({
            createAdminLoading: false
          });
        }
      } catch (err) {
        console.log(err)
        toast.error("Erro ao salvar administrador");
        this.setState({
          createAdminLoading: false
        });
      }
    })
  };

  removeAdmin = async (companyId: string, adminId: string) => {
    const { request } = this.context.action;
    try {
      const response = await request(
        "DELETE",
        `admin/v1/company/${companyId}/admin/${adminId}`
      );

      if (response.data.success) {
        this.setState({
          admins: response.data.data
        });
        toast.success("Administrador removido com sucesso");
      }
      else {
        toast.error(response.data.error.message);
      }
    } catch (err) {
      console.log(err)
      toast.error("Falha ao remover administrador");
    }
  };

  editCompany = async (companyId: string, data: any) => {
    const { request } = this.context.action;

    try {
      const response = await request(
        "PUT",
        `admin/v1/company/${companyId}`,
        data
      );

      if (response.data.success) {
        toast.success("Empresa atualizada com sucesso");
      } else
        toast.error("Não foi possível atualizar a empresa.");
    } catch (err) {
      toast.error(err.message);
    } finally {
      this.fetchCompanies();
    }
  };

  fetchOpenRequests = () => {
    const { request } = this.context.action;

    this.setState(this.initialState, async () => {
      try {
        const response = await request("GET", "admin/v1/company/openrequests");

        if (response.data.success) {
          this.setState({
            openRequests: response.data.data,
            openRequestsAreLoading: false,
            openRequestsHasErrored: false
          });
        } else throw new Error("Erro ao buscar empresas!");
      } catch (err) {
        this.setState(
          {
            openRequests: [],
            openRequestsAreLoading: false,
            openRequestsHasErrored: true
          },
          toast.error.bind(null, err.message)
        );
      }
    });
  };

  fetchOpenRequestDetails = (requestId: string) => {
    const { request } = this.context.action;

    this.setState(
      {
        request: null,
        requestIsLoading: true,
        requestHasErrored: false
      },
      async () => {
        try {
          const response = await request(
            "GET",
            `admin/v1/company/${requestId}`
          );

          if (response.data.success) {
            this.setState({
              request: response.data.data,
              requestIsLoading: false,
              requestHasErrored: false
            });
          } else throw new Error("Erro ao buscar detalhes dessa empresa!");
        } catch (err) {
          this.setState(
            {
              request: null,
              requestIsLoading: false,
              requestHasErrored: true
            },
            toast.error.bind(null, err.message)
          );
        }
      }
    );
  };

  resetRequestDetails = () =>
    this.setState({
      request: null,
      requestIsLoading: false,
      requestHasErrored: false
    });

  acceptCompany = async (companyId: string) => {
    const { request } = this.context.action;

    try {
      const response = await request(
        "PUT",
        `admin/v1/company/${companyId}/approve`
      );

      if (response.data.success) {
        // do stuff
        this.fetchOpenRequests();
        this.fetchOpenRequestDetails(companyId);
      } else throw new Error("Não foi possível aprovar a empresa");
    } catch (err) {
      toast.error(err.message);
    }
  };

  refuseCompany = async (companyId: string) => {
    const { request } = this.context.action;

    try {
      const response = await request(
        "PUT",
        `admin/v1/company/${companyId}/refuse`
      );

      if (response.data.success) {
        // do stuff
        this.fetchOpenRequests();
        this.fetchOpenRequestDetails(companyId);
      } else throw new Error("Não foi possível recusar a empresa");
    } catch (err) {
      toast.error(err.message);
    }
  };

  editCompanyPolicy = async (companyId: string, pricing_policy_id: string, data: any) => {
    const { request } = this.context.action;

    try {
      const response = await request(
        "PUT",
        `admin/v1/company/${companyId}/pricing/${pricing_policy_id}`,
        data
      );

      if (response.data.success) {
        toast.success("Política de preço da empresa atualizada com sucesso");
      } else
        throw new Error("Não foi possível atualizar a política da empresa.");
    } catch (err) {
      toast.error(err.message);
    } finally {
      this.fetchCompanies();
    }
  };

  fetchCostCenters = (companyId: string) => {
    const { request } = this.context.action;
    this.setState(
      {
        costCenters: [],
        costCentersAreLoading: true,
        costCentersHasErrored: false
      },
      async () => {
        try {
          const response = await request(
            "GET",
            `admin/v1/company/${companyId}/cost-centers`
          );

          if (response.data.success) {
            this.setState({
              costCenters: response.data.data,
              costCentersAreLoading: false,
              costCentersHasErrored: false
            });
          }
        } catch (err) {
          this.setState(
            {
              costCenters: [],
              costCentersAreLoading: false,
              costCentersHasErrored: true
            },
            toast.error.bind(null, err.message)
          );
        }
      }
    );
  };


  fetchCRMs = () => {
    const { request } = this.context.action;

    this.setState(this.initialState, async () => {
      try {
        const response = await request("GET", "admin/v1/crm");

        if (response.data.success) {
          console.log('CRMs', response.data.data);
          this.setState(prev => ({...prev, crm: response.data.data}));
        } else throw new Error("Erro ao buscar CRMs!");
      } catch (err) {
        this.setState({crm: []}, toast.error.bind(null, err.message));
      }
    });
  }

  crmCompanyUpdate = async (company_id: any, crm_id: any, step: any, comment: any, estimated_deliveries: any) => {
    const { request } = this.context.action;
    try {
      await request("POST", "admin/v1/crm/company_update", {company_id, crm_id, step, comment, estimated_deliveries});
      // if (response.data.success) {
      //   console.log('CRMs', response.data.data);
      //   this.setState(prev => ({...prev, crm: response.data.data}));
      // } else throw new Error("Erro ao atualizar informações do crm na emrpesa!");
    } catch (err) {
      this.setState({crm: []}, toast.error.bind(null, err.message));
    }
  }

  render() {
    const { executeOnlyIfAuthenticated } = this.context.action;

    const value = {
      state: { ...this.state },
      action: {
        setFilterCityId: executeOnlyIfAuthenticated(this.setFilterCityId),
        fetchCities: executeOnlyIfAuthenticated(this.fetchCities),
        fetchCompanies: executeOnlyIfAuthenticated(this.fetchCompanies),
        fetchAllCompanies: executeOnlyIfAuthenticated(this.fetchAllCompanies),
        fetchCompaniesRetention: executeOnlyIfAuthenticated(this.fetchCompaniesRetention),
        fetchOrigins: executeOnlyIfAuthenticated(this.fetchOrigins),
        fetchCompanyPricings: executeOnlyIfAuthenticated(this.fetchCompanyPricings),
        fetchAdmins: executeOnlyIfAuthenticated(this.fetchAdmins),
        createAdmin: executeOnlyIfAuthenticated(this.createAdmin),
        updateAdmin: executeOnlyIfAuthenticated(this.updateAdmin),
        removeAdmin: executeOnlyIfAuthenticated(this.removeAdmin),
        fetchOpenRequests: executeOnlyIfAuthenticated(this.fetchOpenRequests),
        fetchOpenRequestDetails: executeOnlyIfAuthenticated(
          this.fetchOpenRequestDetails
        ),
        acceptCompany: executeOnlyIfAuthenticated(this.acceptCompany),
        refuseCompany: executeOnlyIfAuthenticated(this.refuseCompany),
        editCompany: executeOnlyIfAuthenticated(this.editCompany),
        editCompanyPolicy: executeOnlyIfAuthenticated(this.editCompanyPolicy),
        fetchCostCenters: executeOnlyIfAuthenticated(this.fetchCostCenters),
        fetchCRMs: executeOnlyIfAuthenticated(this.fetchCRMs),
        crmCompanyUpdate: executeOnlyIfAuthenticated(this.crmCompanyUpdate),
        resetRequestDetails: this.resetRequestDetails,
      }
    };

    return (
      <CompanyContext.Provider value={value}>
        {this.props.children}
      </CompanyContext.Provider>
    );
  }
}

export default CompanyProvider;
