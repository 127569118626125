// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../../context/Company";
import ListItem from "./components/ListItem";

import { Container, Card, CardBody, Table, Col } from "reactstrap";
import sortByProperty from "../../utils/sortByProperty";

const Companies = () => {
  const [requests, setRequests] = useState([]);

  const {
    state: { companies },
    action: { fetchCompanies },
  } = useContext(CompanyContext);

  useEffect(() => setRequests(companies), [companies]);

  function filterCompanies({ target: { value: searchPattern } }) {
    setRequests(
      sortByProperty(
        companies.filter(
          (companies) =>
            companies.name.toLowerCase().includes(searchPattern) ||
            companies.platform_manager.toLowerCase().includes(searchPattern)
        )
      )
    );
  }

  useEffect(() => {
    fetchCompanies();
  }, []);

  return (
    <>
      <Container>
        <h3 style={{ marginBottom: 20, fontWeight: "normal", color: "#333" }}>
          EMPRESAS
        </h3>
      </Container>
      <Container>
        <Card>
          <CardBody>
            <div className="table-filter">
              <input
                type="text"
                placeholder="Pesquisar..."
                onChange={filterCompanies}
              />
            </div>
            <Table
              responsive
              striped
              hover
              className="table table-condensed table--bordered"
            >
              <thead>
                <tr>
                  <th>Documento</th>
                  <th>Nome / Razão</th>
                  <th>Responsável</th>
                  <th>Email</th>
                  <th>Telefone</th>
                  <th>Endereço da loja</th>
                  <th style={{ textAlign: "right" }}>Data Cadastro</th>
                </tr>
              </thead>
              <tbody>
                {requests.map((company) => (
                  <ListItem key={company._id} company={company} />
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default Companies;
