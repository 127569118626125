// @ts-nocheck
import React, { useEffect, useContext, useState } from "react";
import { CompanyContext } from "../../../context/Company";
import { useToggle } from "../../../hooks/hooks";
import PricePolicy from "./PricePolicy";
import EditAdmin from "./EditAdmin";
import styles from "./styles.module.css";

import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  Input,
  Form,
  Button,
  Table
} from "semantic-ui-react";
import moment from "moment";

type Props = {
  company: any;
  admin: any;
};

const Admins = ({ company, admin}: Props) => {
  const {
    action: { removeAdmin }
  } = useContext(CompanyContext);

  const { active, toggle, activate } = useToggle(false);
  const { active: editOpen, toggle: toggleEdit, activate: activateEdit } = useToggle(false);

  const startRemove = () => {
    removeAdmin(company._id, admin._id)
  }

  return (
    <Table.Row>
      <Table.Cell>{admin.display_name}</Table.Cell>
      <Table.Cell>{admin.email}</Table.Cell>
      <Table.Cell width={1}>
        <Button size="mini" color="facebook" onClick={activateEdit}>Editar</Button>
        <EditAdmin
          companyId={company._id}
          admin={admin}
          isOpen={editOpen}
          handleToggle={toggleEdit}
        />
      </Table.Cell>
      <Table.Cell width={1}>
        <Button size="mini" color="red" onClick={startRemove}>Excluir</Button>
      </Table.Cell>
    </Table.Row>
  )
};

export default Admins;
