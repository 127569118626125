// @ts-nocheck
import React, { useEffect, useContext, useState } from "react";
import { CompanyContext } from "../../../context/Company";
import { useToggle } from "../../../hooks/hooks";
import PricePolicy from "./PricePolicy";
import ListAdmins from "./ListAdmins";
import EditAdmin from "./EditAdmin";
import styles from "./styles.module.css";

import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  Input,
  Form,
  Button,
  Table
} from "semantic-ui-react";
import moment from "moment";

type Props = {
  company: any;
  isOpen: boolean;
  handleToggle(): void;
};

interface State {
  id: string;
  first_name: string;
  last_name: string
  email: string;
  password: string;
}

const Admins = ({ company, isOpen, handleToggle }: Props) => {
  const {
    state: { admins },
    action: { fetchAdmins }
  } = useContext(CompanyContext);

  const { active, toggle, activate } = useToggle(false);
  const { active: editOpen, toggle: toggleEdit, activate: activateEdit } = useToggle(false);

  const handleSubmit = () => {
    // editCompany(company._id, state);
  }

  useEffect(() => {
    fetchAdmins(company._id);
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} toggle={handleToggle} style={{ maxWidth: 600 }}>
        <ModalHeader style={{ display: "block", textAlign: "left" }}>Administradores de {company.name} <Button floated="right" size="mini" color="facebook" onClick={activateEdit}>Novo</Button></ModalHeader>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {admins.map((admin: any) => (
              <ListAdmins company={company} admin={admin} />
            ))}
          </Table.Body>
        </Table>
      </Modal>

      <EditAdmin
        companyId={company._id}
        admin={null}
        isOpen={editOpen}
        handleToggle={toggleEdit}
      />
    </>
  );
};

export default Admins;
