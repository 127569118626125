// @ts-nocheck
import React, { PureComponent } from 'react';
import { Card, Container, Row, Col, CardBody, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Reservations from './components/Reservations';
import Deliveries from './components/Deliveries';
import TopProviders from './components/TopProviders';
import { AuthContext } from '../../context/Auth';
import { toast } from 'react-toastify';
import TopCards from './components/TopCards';
import MetricsToday from './components/MetricsToday';

export const DashboardContext = React.createContext({});

class Dashboard extends PureComponent {
	static contextType = AuthContext;

	state = {
		//Deliveries
		deliveriesRunning:false,
		providersActive:[],
		providersMetrics:{},
		revenueMetrics:{
			totalCompany: 0,
			providerTotal: 0
		},
		companiesMetrics:{},
		deliveriesMetrics:{},
		deliveriesDay: [],
		deliveriesWeek: [], 
		deliveriesMonth: [],
		deliveriesAreLoading: false, deliveriesHasErrored: false,


		providerPerformance: [],
		providerPerformanceIsLoading: false,
		providerPerformanceHasErrored: false,
		schedules: {},
		schedulesIsLoaded: false,
		cities: [],
		cities_view:[]
	};

	getCities = () => {
		const { request } = this.context.action
		this.setState({
		  cities: [],
		  cities_view: []
		}, async () => {
		  try {
			const response = await request('GET', 'admin/v1/cities')
	
			if (response.data.success){
				let cv = [];
				response.data.data.map(c => {
					cv.push({
						city_id: c._id,
						city_code: c.citycode,
						view: true
					});
				})

				this.setState({ cities: response.data.data, cities_view: cv })

			}
			else throw new Error('Erro ao buscar as cidades.')
	
		  } catch (err) {
			this.setState({
			  cities: []
			}, toast.error.bind(null, err.message))
		  }
		});
		return true;
	}

	setCitiesView = async (id) => {
		let cv = this.state.cities_view;
		if(id == "all"){
			if(cv.filter(c => !c.view).length > 0) cv.map(c => { c.view = true })
			else cv.map(c => { c.view = false })
		} else cv.map(c => { if(c.city_id == id) c.view = !c.view;})

		await this.setState(prev => ({ ...prev, cities_view: cv }));
		this.runDash();
	}

	fetchProvidersActive = (refresh = false) => {
		const { request } = this.context.action;

		this.setState(
			{
				providersActive: (!refresh ? [] : this.state.providersActive)
			},
			async () => {
				try {
					const response = await request('GET', 'admin/v1/dashboard/providers-active');

					if (response.data.success) { 
						// console.log(response.data.data);
						this.setState({
							providersActive: response.data.data,
						});
					} else throw new Error('Falha ao buscar as metricas de entregadores.');
				} catch (err) {
					this.setState(
						{
							providersActive:[]
						},
						toast.error.bind(null, err.message)
					);
				}
			}
		);
	};
	fetchDeliveriesRunning = (refresh = false) => {
		const { request } = this.context.action;

		this.setState(
			{
				deliveriesRunning: (!refresh ? false : this.state.deliveriesRunning)
			},
			async () => {
				try {
					const response = await request('GET', 'admin/v1/dashboard/deliveries-running');
					if (response.data.success) { 
						// console.log(response.data.data);
						this.setState({
							deliveriesRunning: response.data.data,
						});
					} else throw new Error('Falha ao buscar as metricas de entregadores.');
				} catch (err) {
					this.setState(
						{
							deliveriesRunning:[]
						},
						toast.error.bind(null, err.message)
					);
				}
			}
		);
	};
	fetchProvidersMetrics = (refresh = false) => {
		const { request } = this.context.action;

		this.setState(
			{
				providersMetrics: (!refresh ? {} : this.state.providersMetrics)
			},
			async () => {
				try {

					let cities_view_active = [];
					this.state.cities_view.filter(c => c.view).map(cva => {
						cities_view_active.push(cva.city_id);
					});

					const response = await request('POST', 'admin/v1/dashboard/providers-metrics', {cities_id: cities_view_active});

					if (response.data.success) { 
						// console.log(response.data.data);
						this.setState({
							providersMetrics: response.data.data,
						});
					} else throw new Error('Falha ao buscar as metricas de entregadores.');
				} catch (err) {
					this.setState(
						{
							providersMetrics:{}
						},
						toast.error.bind(null, err.message)
					);
				}
			}
		);
	};
	fetchRevenueMetrics = (refresh = false) => {
		const { request } = this.context.action;

		this.setState(
			{
				revenueMetrics: (!refresh ? {
					totalCompany: 0,
					providerTotal: 0
				} : this.state.revenueMetrics)
			},
			async () => {
				try {

					let cities_view_active = [];
					this.state.cities_view.filter(c => c.view).map(cva => {
						cities_view_active.push(cva.city_id);
					});

					const response = await request('POST', 'admin/v1/dashboard/revenue-metrics', {cities_id: cities_view_active});

					if (response.data.success) { 
						// console.log(response.data.data);
						this.setState({
							revenueMetrics: response.data.data,
						});
					} else throw new Error('Falha ao buscar as metricas de faturamento.');
				} catch (err) {
					toast.error.bind(null, err.message)
				}
			}
		);
	};

	fetchCompaniesMetrics = (refresh = false) => {
		const { request } = this.context.action;

		this.setState(
			{
				companiesMetrics: (!refresh ? {} : this.state.companiesMetrics)
			},
			async () => {
				try {
					let cities_view_active = [];
					this.state.cities_view.filter(c => c.view).map(cva => {
						cities_view_active.push(cva.city_id);
					});

					const response = await request('POST', 'admin/v1/dashboard/companies-metrics', {cities_id: cities_view_active});

					if (response.data.success) { 
						// console.log(response.data.data);
						this.setState({
							companiesMetrics: response.data.data,
						});
					} else throw new Error('Falha ao buscar as metricas de empresas.');
				} catch (err) {
					this.setState(
						{
							companiesMetrics:{}
						},
						toast.error.bind(null, err.message)
					);
				}
			}
		);
	};

	fetchDeliveriesMetrics = (refresh = false) => {
		const { request } = this.context.action;

		this.setState(
			{
				deliveriesMetrics: (!refresh ? {} : this.state.deliveriesMetrics)
			},
			async () => {
				try {
					let cities_view_active = [];
					this.state.cities_view.filter(c => c.view).map(cva => {
						cities_view_active.push(cva.city_id);
					});

					const response = await request('POST', 'admin/v1/dashboard/deliveries-metrics', {cities_id: cities_view_active});

					if (response.data.success) { 
						this.setState({
							deliveriesMetrics: response.data.data,
						});
					} else throw new Error('Falha ao buscar as metricas de entregas.');
				} catch (err) {
					this.setState(
						{
							deliveriesMetrics:{}
						},
						toast.error.bind(null, err.message)
					);
				}
			}
		);
	};

	fetchDeliveriesDay = (refresh = false) => {
		const { request } = this.context.action;

		this.setState(
			{
				deliveriesDay: (!refresh ? [] : this.state.deliveriesDay),
				deliveriesAreLoading: (!refresh ? true : this.state.deliveriesAreLoading),
				deliveriesHasErrored: (!refresh ? false : this.state.deliveriesHasErrored),
			},
			async () => {
				try {
					const response = await request('GET', 'admin/v1/dashboard/deliveries-day');

					if (response.data.success) { 
						this.setState({
							deliveriesDay: response.data.data,
							deliveriesAreLoading: false,
							deliveriesHasErrored: false,
						});
					} else throw new Error('Falha ao buscar as entregas.');
				} catch (err) {
					this.setState(
						{
							deliveriesDay: {},
							deliveriesAreLoading: false,
							deliveriesHasErrored: true,
						},
						toast.error.bind(null, err.message)
					);
				}
			}
		);
	};

	fetchDeliveriesWeek = (refresh = false) => {
		const { request } = this.context.action;

		this.setState(
			{
				deliveriesWeek: (!refresh ? [] : this.state.deliveriesWeek),
				deliveriesAreLoading: (!refresh ? true : this.state.deliveriesAreLoading),
				deliveriesHasErrored: (!refresh ? false : this.state.deliveriesHasErrored),
			},
			async () => {
				try {
					const response = await request('GET', 'admin/v1/dashboard/deliveries-week');

					if (response.data.success) { 
						this.setState({
							deliveriesWeek: response.data.data,
							deliveriesAreLoading: false,
							deliveriesHasErrored: false,
						});
					} else throw new Error('Falha ao buscar as entregas.');
				} catch (err) {
					this.setState(
						{
							deliveriesWeek: {},
							deliveriesAreLoading: false,
							deliveriesHasErrored: true,
						},
						toast.error.bind(null, err.message)
					);
				}
			}
		);
	};
	fetchDeliveriesMonth = (refresh = false) => {
		const { request } = this.context.action;

		this.setState(
			{
				deliveriesMonth: (!refresh ? [] : this.state.deliveriesMonth),
				deliveriesAreLoading: (!refresh ? true : this.state.deliveriesAreLoading),
				deliveriesHasErrored: (!refresh ? false : this.state.deliveriesHasErrored),
			},
			async () => {
				try {
					const response = await request('GET', 'admin/v1/dashboard/deliveries-month');

					if (response.data.success) { 
						this.setState({
							deliveriesMonth: response.data.data,
							deliveriesAreLoading: false,
							deliveriesHasErrored: false,
						});
					} else throw new Error('Falha ao buscar as entregas.');
				} catch (err) {
					this.setState(
						{
							deliveriesMonth: {},
							deliveriesAreLoading: false,
							deliveriesHasErrored: true,
						},
						toast.error.bind(null, err.message)
					);
				}
			}
		);
	};

	runDash = (refresh = false) => {
		this.fetchProvidersMetrics(refresh);
		this.fetchRevenueMetrics(refresh);
		this.fetchCompaniesMetrics(refresh);
		this.fetchDeliveriesMetrics(refresh);
		this.fetchDeliveriesDay(refresh);
		this.fetchDeliveriesWeek(refresh);
		this.fetchDeliveriesMonth(refresh);
		this.fetchProvidersActive(refresh);
		this.fetchDeliveriesRunning(refresh);
	}

	init = async () => {
		await this.getCities();
		this.runDash();

		setInterval(() => {
			this.runDash(true);
		},30*1000)
	}

	componentDidMount() {
		this.init();
	}

	render() {
		const value = { state: this.state };

		return (
			<DashboardContext.Provider value={value}>
				<Container>
					<Button color={this.state.cities_view.filter(c => c.view).length == this.state.cities.length ? "primary" : "secondary"} onClick={() => { this.setCitiesView("all") }}>Todas</Button>
					{this.state.cities.map(city => (<Button color={this.state.cities_view.find(c => c.city_id == city._id).view ? "primary" : "secondary"} onClick={() => { this.setCitiesView(city._id) }}>{city.citycode} - {city.cityname}</Button>))}
				</Container>
				<Container className="dashboard">
					<Row>
						<TopCards />
					</Row>
					<Row>
						<Deliveries />
					</Row>
					<Row>
						<MetricsToday />
					</Row>
				</Container>
			</DashboardContext.Provider>
		);
	}
}

export default withTranslation('common')(Dashboard);
