// @ts-nocheck
import React, { useContext } from "react";
import { FilterContext } from "../context";
import { formatNumber } from "../../../utils/masks";
import ListItem from "../components/ListItem";

import { Table } from "reactstrap";
import moment from "moment";

const dayWeek = [
  "Domingo",
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sábado"
];

const headers = [
  "Empresas",
  "Entregadores",
  "Entregas",
  "%",
  "R$ Faturado",
  "R$ Repasse",
  "R$ Receita",
  "%",
];

type Props = {
  cityResults: any;
}

const CompanyTable = ({ cityResults }: Props) => {
  const {
    state: { frequency, distance, city_id }
  } = useContext(FilterContext);

  return (
    <Table hover responsive className="table-condensed">
      <thead>
        <tr>
          {city_id ? <th>Data</th> : ''}
          <th style={{textAlign:'left'}} key={0}>Cidade</th>
          {headers.map((header, index) => (
            <th style={{textAlign:'right'}} key={index+1}>{header}</th>
          ))}
        </tr>
      </thead>
      {cityResults.map((result: any, index: number) => (
        <tbody key={index}>
          {city_id ? '' :
          <tr style={{backgroundColor: "#EEE",fontWeight: "bold",borderBottom: "solid 2px #AAA"}}>
            {frequency === "d" ? (<td>{result.data} - {dayWeek[result.day_number]}</td>) : (<td>{result.data}</td>)}
            <td style={{textAlign:'right'}} >{result.cities.reduce((prev: number, r: any) => prev + r.data.quantity_companies,0)}</td>
            <td style={{textAlign:'right'}} >{result.cities.reduce((prev: number, r: any) => prev + r.data.quantity_providers,0)}</td>
            <td style={{textAlign:'right'}} >{result.cities.reduce((prev: number, r: any) => prev + r.data.delivers,0)}</td>
            <td style={{textAlign:'right'}} >100%</td>
            <td style={{textAlign:'right'}} >R$ {result.cities.reduce((prev: number, r: any) => prev + r.data.total_company,0).toLocaleString()}</td>
            <td style={{textAlign:'right'}} >R$ {result.cities.reduce((prev: number, r: any) => prev + r.data.total_provider,0).toLocaleString()}</td>
            <td style={{textAlign:'right'}} >R$ {(result.cities.reduce((prev: number, r: any) => prev + r.data.total_company,0) - result.cities.reduce((prev: number, r: any) => prev + r.data.total_provider,0)).toLocaleString()}</td>
            <td style={{textAlign:'right'}} >100%</td>
          </tr>}
          {result.cities.map((city: any, index: number) => (
            <ListItem key={index} city_id={city_id} result={result} city={city} recipe_total={(result.cities.reduce((prev: number, r: any) => prev + r.data.total_company,0) - result.cities.reduce((prev: number, r: any) => prev + r.data.total_provider,0))} frequency={frequency}/>
          ))}
        </tbody>
      ))}
    </Table>
  )
}

export default CompanyTable;