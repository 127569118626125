export interface DocumentModel {
  created_at: Date;
  document_id: string;
  document_picture: string;
  expired_date: Date;
  is_document_expired: boolean;
  is_expired_date: boolean;
  is_unique_code: boolean;
  is_uploaded: 0 | 1;
  name: string;
  option: number;
  provider_id: string;
  unique_code: string;
  updated_at: Date;
  disapproval_message: string;
  status: 0 | 1 | 2 | 3 | String;
  __v: number;
  _id: string;
}

export interface VehicleDocumentModel {
  created_at: Date;
  document_id: string;
  document_picture: string;
  expired_date: Date | null;
  is_document_expired: boolean;
  is_expired_date: boolean;
  is_unique_code: boolean;
  is_uploaded: 0 | 1;
  name: string;
  option: number;
  provider_id: string;
  unique_code: string;
  updated_at: Date;
  vehicle_id: string;
  disapproval_message: string;
  status: 0 | 1 | 2 | 3 | String;
  __v: number;
  _id: string;
}

export interface VehicleModel {
  accessibility: string | null;
  admin_type_id: string | null;
  color: string;
  is_approved: boolean;
  is_selected: boolean;
  is_vehicle_documents_expired: boolean;
  model: string;
  name: string;
  passing_year: string;
  plate_no: string;
  service_type: any[];
  _id: string;
}

export interface ProviderModel {
  address: string;
  city: string;
  cityid: string;
  country: string;
  document: string;
  providerDocuments: DocumentModel[];
  email: string;
  first_name: string;
  gender: string;
  is_active: 0 | 1;
  is_approved: 0 | 1;
  is_available: 0 | 1;
  is_disabled: 0 | 1;
  last_name: string;
  phone: string;
  plan: string;
  rg: string;
  unique_id: number;
  providerVehicleDocuments: VehicleDocumentModel[];
  vehicles: VehicleModel[];
  zipcode: string;
  country_phone_code: string;
  picture: string;
  group_name?: string;
  _id: string;
  approval_status: number,
  pix: Pix
}

export interface Vehicle {
  accessibility: string | null;
  admin_type_id: string | null;
  color: string;
  is_approved: boolean;
  is_selected: boolean;
  is_vehicle_documents_expired: boolean;
  model: string;
  name: string;
  passing_year: string;
  plate_no: string;
  service_type: any[];
  _id: string;
  document?: VehicleDocumentModel;
  provider_id: string;
}

export interface Provider {
  address: string;
  city: string;
  cityid: string;
  country: string;
  document: string;
  documents: DocumentModel[];
  email: string;
  first_name: string;
  gender: string;
  is_active: 0 | 1;
  is_approved: 0 | 1;
  is_available: 0 | 1;
  is_disabled: 0 | 1;
  last_name: string;
  phone: string;
  plan: string;
  rg: string;
  unique_id: number;
  vehicles: Vehicle[];
  zipcode: string;
  country_phone_code: string;
  picture: string;
  group_name: string;
  _id: string;
  approval_status: number;
  pix: Pix
}

export interface TripHistoryModel {
  is_trip_cancelled: 0 | 1;
  is_trip_cancelled_by_user: 0 | 1;
  is_trip_completed: 0 | 1;
  payment_mode: 0 | 1 | 2 | 3;
  provider_trip_end_time: Date;
  total: number;
  unique_id: number;
  user_create_time: Date;
  user_first_name: string;
  user_last_name: string;
  _id: string;
}

export interface TripHistory {
  _id: string;
  unique_id: number;
  start_time: Date;
  end_time: Date;
  client_name: string;
  total: string;
  payment_mode: string;
  status: string;
};

export interface AttendanceModel {
  admin: any;
  adminid: string
  created_at: Date;
  providerid: string;
  text: string;
  title: string;
  updated_at: Date;
  __v: number;
  _id: string;
}

export interface City {
  id: string;
  name: string;
  plans: any[];
};

export interface Pix {
  type: string;
  key: string;
};

export const DocumentStatus = [
  { id: 0, label: "Pendente" },
  { id: 1, label: "Enviado" },
  { id: 2, label: "Aprovado" },
  { id: 3, label: "Não aprovado" }
];

export const DisapprovalMessage = [
  { code: 'invalid_image', label: 'inválida' },
  { code: 'invalid_expiry_date', label: 'Data de vencimento inválida' },
  { code: 'document_expired', label: 'Documento expirado' },
  { code: 'invalid_document', label: 'Documento inválido' }
];