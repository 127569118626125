// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import { ProviderContext } from "../../context/Provider";
import AttendanceRecords from "./components/AttendanceRecords";
import ProviderDocuments from "./components/ProviderDocuments";
import ProviderVehicles from "./components/ProviderVehicles";
import ProviderProfile from "./components/ProviderProfile";
import TripRecords from "./components/TripRecords";
import Loader from "../../components/Loader";

import { RouteComponentProps } from "react-router";
import { Container, Col, Row } from "reactstrap";

interface Props extends RouteComponentProps<{ providerId: string }> { };

const ProviderPanel = ({ history, match: { params } }: Props) => {

  const {
    state: {
      update,
      isCitiesLoaded,
      services,
      isServicesLoaded,
      provider,
      isProviderLoaded,
    },
    action: {
      getProvider,
      getCities,
      getServices,
    }
  } = useContext(ProviderContext);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!params.providerId) return history.push("/")
    getProvider(params.providerId);
  }, [update]);

  useEffect(() => {
    if (isProviderLoaded) {
      getCities(provider.country)
      getServices(provider.cityid)
    }
  }, [isProviderLoaded])

  useEffect(() => {
    isProviderLoaded && isServicesLoaded && isCitiesLoaded
      ? setLoading(false)
      : setLoading(true)
  },
    [
      isProviderLoaded,
      isServicesLoaded,
      isCitiesLoaded
    ])

  return loading
    ? (<Loader />)
    : (
      <Container>
        <h3 className="page-title float-left text_bold">Painel do entregador</h3>
        <ProviderProfile path={history.location.pathname} />
        <Row>
          <Col>
            <ProviderDocuments data={provider.documents} />
          </Col>
          <Col>
            <ProviderVehicles
              data={provider.vehicles}
              provider_id={params.providerId}
              services={services}
            />
          </Col>
        </Row>
        <TripRecords providerId={params.providerId} history={history} />
        <AttendanceRecords providerId={params.providerId} />
      </Container>
    );
};

export default ProviderPanel;

