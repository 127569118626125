// @ts-nocheck
import React from "react";
import { getTripPaymentMode, getTripConfirmationType } from "./../../../utils/translateEnums";
import { formatNumber } from "../../../utils/masks";
import Panel from "../../../components/Panel";

import { ListGroupItem } from "reactstrap";
import moment from "moment";

type Props = {
  delivery: any;
  i: any;
}

const Deliveries = ({ delivery, i }: Props) => {
  return (
    <Panel title={`DESTINO ${i+1} - Entrege em ${moment(delivery.delivered_at).format("DD/MM/YYYY, kk:mm")}`}>
      <div>
        <div><small><strong>ENDEREÇO</strong></small></div>
        <div style={{marginBottom:5}}>
          {delivery.address}
          <div>{delivery.address_complement != "" ? "Complemento: " + delivery.address_complement : ""}</div>
        </div>
        <div style={{marginBottom:10}}><small><strong>DISTANCIA DA ORIGEM: </strong></small> {delivery.distance < 1 ? (parseInt((delivery.distance*1000).toFixed(0))) + " m" :  formatNumber(delivery.distance) + " km" }</div>
      </div>
      <ListGroupItem>
        <strong>Recebedor</strong>
        <div>Nome: {delivery.user_name}</div>
        <div>Contato: {delivery.user_phone}</div>
      </ListGroupItem>
      <ListGroupItem>
        <strong>Observação</strong>
        <div>{delivery.description}</div>
      </ListGroupItem>
      <ListGroupItem>
        <span className="float-right">
          R$ {formatNumber(delivery.invoice.price)}
        </span>
        <strong>Pagamento:</strong> {getTripPaymentMode(delivery.invoice.payment_type)}
      </ListGroupItem>
      {delivery.invoice.payment_type === "cash" &&
        <ListGroupItem>
          <span className="float-right">
            R$ {formatNumber(delivery.invoice.money_change)}
          </span>
          <strong>Troco</strong>
        </ListGroupItem>
      }
      {/* <ListGroupItem>
        <span className="float-right">
          {getTripConfirmationType(delivery.confirmation_type)}
        </span>
        <strong>Tipo de confirmacao</strong>
      </ListGroupItem> */}
    </Panel>
  );
}

export default Deliveries;