// @ts-nocheck
import React, { useContext, useState } from "react";
import { Vehicle } from "../../../../context/Provider/provider.types";
import { ProviderContext } from "../../../../context/Provider";
import RadioButton from "../../../../components/RadioButton";
import { CustomModal } from "../../../../components/Modal";
import VehicleForm from "./VehicleForm";
import styles from "../styles.module.css";

import { Icon } from "semantic-ui-react";
import moment from "moment";
import {
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Badge,
  Label,
  CustomInput
} from "reactstrap";

type Props = {
  data: Vehicle[];
  provider_id: string;
  services: any[];
};

const ProviderVehicles = ({ data, provider_id, services }: Props) => {
  const {
    action: { removeVehicle, approveVehicle }
  } = useContext(ProviderContext);

  const [state, setState] = useState({
    message_disabled: false,
    disapproval_message: '',
    approve: false
  });

  const onClick = (providerId: string, vehicleId: string) => {
    removeVehicle(providerId, vehicleId);
  };

  const onSwitchChange = () => {
    setState(prev => ({
      ...prev,
      approve: !prev.approve,
      message_disabled: !prev.approve,
      disapproval_message: ''
    }))
  }

  const onMessageChange = (name: string, value: string) =>
    setState(prev => ({ ...prev, [name]: value }))

  const approve = (vehicleId: string, providerId: string) => {
    const { approve, disapproval_message } = state;
    const data = { approve, disapproval_message }
    approveVehicle(vehicleId, providerId, data);
  };

  const onCloseApproveModal = () => {
    setState(prev => ({
      ...prev,
      message_disabled: false,
      disapproval_message: '',
      approve: false
    }));
  };


  return (
    <Card>
      <CardBody>
        <div className={styles.header}>
          <div className={styles.title}>
            <h3>Veículos do entregador</h3>
          </div>
          <VehicleForm provider_id={provider_id} services={services} />
        </div>
        <ListGroup className="mt-3">
          {data &&
            data.map((vehicle, index) => {
              return (
                <ListGroupItem className="p-4" key={index}>
                  <div className={styles.listItemContainer}>
                    <div className={styles.listHeaderContainer}>
                      <ListGroupItemHeading className={styles.title}>
                        {!!vehicle.model
                          ? `${vehicle.name} - ${vehicle.model} - ${vehicle.color}`
                          : `${vehicle.name} - ${vehicle.color}`
                        }
                      </ListGroupItemHeading>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 8
                        }}
                      >
                        <ListGroupItemText className="mr-2">
                          {vehicle.plate_no}
                        </ListGroupItemText>
                      </div>
                      <div className={styles.listItemsContainer}>
                        {/* <Badge
                          className={styles.badge}
                          color={vehicle.is_approved ? "success" : "danger"}
                        >
                          {vehicle.is_approved ? "Aprovado" : "Não aprovado"}
                        </Badge> */}
                        {vehicle.is_selected && (
                          <Badge className={styles.badge} color="primary">Selecionado</Badge>
                        )}
                        {
                          !!vehicle.document &&
                          <Badge
                            className={styles.badge}
                            color={vehicle.document.status === "Não aprovado" ? "danger" : "success"}
                          >
                            {vehicle.document.status}
                          </Badge>
                        }
                      </div>
                      <div className={styles.listItemsContainer}>
                        {
                          vehicle.document && vehicle.document.is_expired_date && !vehicle.document.expired_date &&
                          <Badge className={styles.badge} color="danger">Falta data de vencimento</Badge>
                        }
                        {
                          vehicle.document && moment().isAfter(vehicle.document.expired_date || undefined) &&
                          <Badge className={styles.basge} color="danger">Documento expirado</Badge>
                        }
                      </div>
                    </div>
                    <CustomModal
                      title="Aprovar veículos"
                      footer={true}
                      handleOnClick={() => approve(vehicle._id, vehicle.provider_id)}
                      handleOnClose={onCloseApproveModal}
                      trigger={toggle => (
                        <span onClick={toggle} className={styles.icon}>
                          <Icon name="check" className="link black large" />
                        </span>
                      )}
                    >
                      <React.Fragment>
                        <CustomInput
                          type="switch"
                          bsSize="lg"
                          label={state.approve ? "Aprovar" : "Não aprovar"}
                          id="approve"
                          checked={state.approve}
                          onChange={onSwitchChange}
                        />
                        <Label className="mt-3">Especifique o motivo pela desaprovação</Label>
                        <RadioButton
                          disabled={state.message_disabled}
                          value={state.disapproval_message}
                          name="disapproval_message"
                          options={MessagesOptions}
                          onChange={onMessageChange}
                        />
                      </React.Fragment>
                    </CustomModal>
                    {vehicle.document && vehicle.document.document_picture && (
                      <CustomModal
                        size="fullscreen"
                        footer={true}
                        showPrimaryButton={false}
                        secondaryButtonName="Fechar"
                        trigger={toggle => (
                          <span onClick={toggle} className={styles.icon}>
                            <Icon
                              name="folder open"
                              className="link black large"
                            />
                          </span>
                        )}
                      >
                        {vehicle.document.document_picture.endsWith(".pdf") ? (
                          <embed
                            src={vehicle.document.document_picture}
                            className={styles.docPdf}
                          />
                        ) : (
                            // <img
                            //   src={vehicle.document.document_picture}
                            //   alt=""
                            //   className={styles.docImage}
                            // />
                            <div
                              style={{ backgroundImage: `url(${vehicle.document.document_picture})` }}
                              className={styles.docImage}
                            />
                          )}
                      </CustomModal>
                    )}
                    <VehicleForm
                      vehicle={vehicle}
                      provider_id={provider_id}
                      services={services}
                    />
                    <span
                      onClick={e => onClick(vehicle.provider_id, vehicle._id)}
                      className={styles.link}
                    >
                      <Icon
                        name="trash alternate outline"
                        className="link black large"
                      />
                    </span>
                  </div>
                </ListGroupItem>
              );
            })}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

const MessagesOptions = [
  { label: 'Imagem inválida', value: 'invalid_image' },
  { label: 'Data de vencimento inválida', value: 'invalid_expiry_date' },
  { label: 'Documento expirado', value: 'document_expired' },
  { label: 'Documento inválido', value: 'invalid_document' }
];

export default ProviderVehicles;
