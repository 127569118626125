// @ts-nocheck
import React, { useContext, useEffect } from "react";
import { CompanyContext } from "../../context/Company";
import ListItem from "./components/ListItem";

import moment from "moment";

import { Container, Card, CardBody, Table, Col, Button} from "reactstrap";
import { Loader } from "semantic-ui-react";

const Companies = () => {
  const {
    state: { companiesRetention, cities, city_id },
    action: { fetchCompaniesRetention, fetchCities, setFilterCityId }
  } = useContext(CompanyContext);

  useEffect(() => {
    fetchCompaniesRetention();
    fetchCities();
  }, []);

  return (
    <>
    <Container><h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333"}}>RETENÇÃO DE EMPRESAS</h3></Container>
    <Container>
      {city_id == '' ? <Button color="primary">Todas</Button> : <Button outline color="secondary" onClick={() => { setFilterCityId('') }}>Todas</Button>}
      {cities.map((city: any) => (
        city._id == city_id ? <Button color="primary">{city.citycode} - {city.cityname}</Button>
        : <Button outline color="secondary"  onClick={() => { setFilterCityId(city._id) }}>{city.citycode} - {city.cityname}</Button>
      ))}
    </Container>
    <Container>
      <Card>
        <CardBody>
          <Table
            responsive
            striped
            hover
            className="table table-condensed table--bordered"
          >
            <thead>
              <tr>
                <th>Cidade</th>
                <th>Empresa</th>
                <th>Última entrega</th>
                <th>{moment().subtract(11, 'M').format("MM/yy")}</th>
                <th>{moment().subtract(10, 'M').format("MM/yy")}</th>
                <th>{moment().subtract(9, 'M').format("MM/yy")}</th>
                <th>{moment().subtract(8, 'M').format("MM/yy")}</th>
                <th>{moment().subtract(7, 'M').format("MM/yy")}</th>
                <th>{moment().subtract(6, 'M').format("MM/yy")}</th>
                <th>{moment().subtract(5, 'M').format("MM/yy")}</th>
                <th>{moment().subtract(4, 'M').format("MM/yy")}</th>
                <th>{moment().subtract(3, 'M').format("MM/yy")}</th>
                <th>{moment().subtract(2, 'M').format("MM/yy")}</th>
                <th>{moment().subtract(1, 'M').format("MM/yy")}</th>
                <th>{moment().format("MM/yy")}</th>
              </tr>
            </thead>
            <tbody>
              {companiesRetention.map((company: any) => (
                company._id ? <ListItem key={company._id} company={company} /> : null 
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
    </>
  );
};

export default Companies;
