// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";

interface RadioOptions {
  label: string;
  value: string;
}

interface Props {
  disabled?: boolean;
  onChange(name: string, value: string): void;
  options: RadioOptions[];
  value: string;
  name: string;
}

const RadioButton = ({ options, value, name, onChange, disabled }: Props) => {

  const [state, setState] = useState(value);

  const handleChange = (
    e: React.FormEvent<HTMLInputElement>,
    data: any
  ) => {
    const { value } = data;
    setState(value);
    onChange(name, value);
  };

  useEffect(() => {
    setState(value)
  }, [value])

  return (
    <React.Fragment>
      {options.map((option, index) => (
        <Form.Group key={index}>
          <Form.Radio
            disabled={disabled && disabled || false}
            size="small"
            label={option.label}
            value={option.value}
            checked={option.value === state}
            onChange={handleChange}
          />
        </Form.Group>
      ))}
    </React.Fragment>
  );
};

export default RadioButton;
