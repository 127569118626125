// @ts-nocheck
import React, { useContext, useEffect } from "react";
import { FinanceContext } from '../../context/Finance';
import DateRange from '../../components/DateRange';

import { Col, Row } from "reactstrap";

const Filter = () => {
  const {
    state: { startDate, endDate },
    action: { handleDateChange, getProcessedRequests }
  } = useContext(FinanceContext);
  useEffect(() => {
    if (startDate && endDate) {
      getProcessedRequests(startDate, endDate)
    }
  }, [startDate, endDate]);

  return (
    <Row className="mb-0">
      <Col xl={3} lg={5} md={6}>
        <div style={{ color: '#999', fontStyle: 'italic' }}>
          <small>Filtrar período</small>
        </div>
        <DateRange value={[startDate, endDate]} onChange={handleDateChange} />
      </Col>
    </Row>
  )
}

export default Filter;