// @ts-nocheck
import React, { useState, useContext, useEffect, useRef } from "react";
import { ProviderMetricsContext } from "../../../context/ProviderMetrics";
import { formatDistance, toNumber } from "../../../utils/masks";
import { getLastWeekDay } from "../../../utils/dates";
import DateRange from "../../../components/DateRange";

import { Card, CardBody, Input, Button } from "reactstrap";
//@ts-ignore
import debounce from "lodash.debounce";
import moment from "moment";

type Props = {
  providerId: string;
};

const Filter = ({ providerId }: Props) => {
  const buttonRef = useRef(null);

  const {
    action: {
      getOnlineTime,
      getDailyTrips,
      getCancelledtrips,
      getTripsByDistance
    }
  } = useContext(ProviderMetricsContext);

  const [date, setDate] = useState({
    start_date: getLastWeekDay(),
    end_date: new Date()
  });

  const [reference_distance, setReferenceDistance] = useState("");

  const handleDateChange = (dates: Date[]) =>
    setDate({ start_date: dates[0], end_date: dates[1] });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setReferenceDistance(formatDistance(value));
  };

  const onFilter = () => {
    if (date.start_date && date.end_date) {
      const data: any = {
        start_date: moment(date.start_date).format("YYYYMMDD"),
        end_date: moment(date.end_date).format("YYYYMMDD")
      };
      getOnlineTime(providerId, data);
      getDailyTrips(providerId, data);
      getCancelledtrips(providerId, data);

      if (reference_distance !== "")
        data["reference_distance"] = toNumber(reference_distance);
      getTripsByDistance(providerId, data);
    }
  };

  useEffect(() => {
    onFilter();

    const handleOnKeyDown = debounce((e: React.KeyboardEvent) => {
      if (e.which === 13 && buttonRef.current) {
        e.preventDefault();
        //@ts-ignore
        buttonRef.current.onClick();
      }
    }, 600)
    window.addEventListener("keydown", e => handleOnKeyDown(e));
  }, []);

  return (
    <Card>
      <CardBody>
        <h4>Filtrar data</h4>
        <DateRange
          value={[date.start_date, date.end_date]}
          onChange={handleDateChange}
        />
        <h4 className="mt-1">Filtrar distância de referência</h4>
        <Input
          placeholder="2,5 Km"
          name="reference_distance"
          value={reference_distance}
          onChange={handleOnChange}
        />
        <hr />
        <Button ref={buttonRef} className="btn-primary mb-1" onClick={onFilter}>
          Filtrar
        </Button>
      </CardBody>
    </Card>
  );
};

export default Filter;
