// @ts-nocheck
import React from "react";
import { AuthContext } from "./Auth";

interface ScheduleContext {
  state: State;
  action: {
    getCompanyList(): void;
    getSchedules(page: number, filter: any): void;
    getScheduleDetail(scheduleId: string): void;
  }
}

export const ScheduleContext = React.createContext({} as ScheduleContext);

interface State {
  companyList: any[];
  schedulesPagination: any;
  schedules: any[];
  schedule: any;
  isScheduleLoading: boolean;
}

class ScheduleProvider extends React.PureComponent<{}, State> {

  static contextType = AuthContext;

  state = {
    companyList: [],
    schedulesPagination: {},
    schedules: [],
    schedule: null,
    isScheduleLoading: false,
  }

  getCompanyList = async () => {
    const { request } = this.context.action;
    const url = "admin/v1/filter/company";

    try {
      const response = await request("GET", url);
      const { success, data } = response.data;
      if (success)
        this.setState({
          companyList: data.map((company: any) => ({
            label: company.name,
            value: company._id
          })),
        })
    } catch (error) {
      this.setState({
        companyList: [],
      })
    }
  }

  getSchedules = (page: number, filter: any) => {
    const { request } = this.context.action;
    const url = "admin/v1/tripschedules";
    filter['page_size'] = 13;
    filter['page'] = page;

    this.setState({
      schedules: [],
      schedulesPagination: {}
    }, async () => {
      try {
        const response = await request("POST", url, filter)
        const { success, data: {
          count, current_page, pages, trips
        } } = response.data;
        if (success) {
          const schedulesPagination = {
            count,
            current_page,
            pages
          }
          this.setState({
            schedules: trips,
            schedulesPagination,
          })
        }
      } catch (error) {
        const schedulesPagination = {
          count: 0,
          current_page: 1,
          pages: 1
        }
        this.setState({
          schedules: [],
          schedulesPagination
        })
      }
    })
  }

  getScheduleDetail = (scheduleId: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/tripschedules/${scheduleId}`

    this.setState({
      schedule: null,
      isScheduleLoading: true,
    }, async () => {
      try {
        const response = await request("GET", url)
        const { success, data } = response.data;
        if (success)
          this.setState({
            schedule: data,
            isScheduleLoading: false
          })
      } catch (error) {
        this.setState({
          schedule: null,
          isScheduleLoading: false
        })
      }
    })
  }

  render() {
    const { executeOnlyIfAuthenticated } = this.context.action;

    const value = {
      state: { ...this.state },
      action: {
        getCompanyList: executeOnlyIfAuthenticated(this.getCompanyList),
        getSchedules: executeOnlyIfAuthenticated(this.getSchedules),
        getScheduleDetail: this.getScheduleDetail,
      }
    }

    return (
      <ScheduleContext.Provider value={value}>
        {this.props.children}
      </ScheduleContext.Provider>
    );
  }
}

export default ScheduleProvider;