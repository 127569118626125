// @ts-nocheck
import React, { useState } from "react";
import { formatDistance } from "../../utils/masks";
import { getLastWeekDay } from "../../utils/dates";

interface FilterContext {
  state: State;
  action: {
    handleDateChange(dates: Date[]): void;
    handleCityChange(value: string): void;
    handleFrequencyChange(value: string): void;
    handleOnChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  }
}

export const FilterContext = React.createContext({} as FilterContext);

type Props = {
  children: React.ReactNode;
};

interface State {
  city_id: string,
  distance: string,
  startDate: Date,
  endDate: Date,
  frequency: string,
}

const FilterProvider = ({ children }: Props) => {
  const [state, setState] = useState<State>({
    city_id: "",
    distance: "",
    startDate: getLastWeekDay(),
    endDate: new Date(),
    frequency: 'd',
  })

  const handleDateChange = (dates: Date[]) =>
    setState(prev => ({ ...prev, startDate: dates[0], endDate: dates[1] }));

  const handleFrequencyChange = (value: string) =>
    setState(prev => ({ ...prev, frequency: value }))

  const handleCityChange = (value: string) =>
    setState(prev => ({ ...prev, city_id: value }))

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setState(prev => ({ ...prev, [name]: formatDistance(value) }));
  }

  const value = {
    state: { ...state },
    action: {
      handleDateChange,
      handleCityChange,
      handleFrequencyChange,
      handleOnChange
    }
  }

  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  )
}

export default FilterProvider;