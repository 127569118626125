// @ts-nocheck
import React, { useContext, useEffect } from "react";
import { formatPhone, formatNumber } from "../../utils/masks";
import { ResultContext } from "../../context/Result";
import Loader from "../../components/Loader";
import { Container, Card, CardBody, Table } from "reactstrap";
import { withTranslation } from "react-i18next";
import moment from "moment";

const headers = [
  "Id",
  "Nome",
  "Email",
  "Telefone",
  "Status",
  "Última entrega",
  "Serviços",
  "Saldo devedor"
];

const ProviderDebts = () => {
  const {
    state: { providerDebts, isProviderDebtsLoaded },
    action: { getProviderDebts }
  } = useContext(ResultContext);

  useEffect(() => {
    getProviderDebts();
  }, [])

  return (
    <Container>
      <Card>
        <h3 className="mb-4">
          Entregadores com débitos{" "}
          <small style={{ textTransform: "lowercase", color: "#AAA" }}>
            Demonstrativo de resultados no período, com enfase quantitativa
            detalhando prestadores
            </small>
        </h3>
        <CardBody>
          {isProviderDebtsLoaded ? (
            <Table responsive hover className={"table-condensed"}>
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {providerDebts.map((result, index) => (
                  <tr key={index}>
                    <td>{result.id}</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {result.name.toLowerCase()}
                    </td>
                    <td style={{ textTransform: "lowercase" }}>
                      {result.email}
                    </td>
                    <td>
                      {formatPhone(result.phone)}
                    </td>
                    <td>{result.status}</td>
                    <td>
                      {moment(result.last_trip).format("DD/MM/YYYY")}
                    </td>
                    <td className={"text-success"}>
                      {formatNumber(result.earnings)}
                    </td>
                    <td className={"text-danger"}>
                      {formatNumber(result.debit * -1)}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>
                    <div>TOTAL</div>
                  </th>
                  <th>
                    {formatNumber(providerDebts.length)}{" "}
                    mots.
                    </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    <div className="text-success">
                      {formatNumber(providerDebts.reduce(
                        (prevVal, result) => prevVal + result.earnings,
                        0
                      ))}
                    </div>
                  </th>
                  <th>
                    <div className="text-danger">
                      {
                        formatNumber(providerDebts.reduce(
                          (prevVal, result) => prevVal + result.debit,
                          0
                        ) * -1)
                      }
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <small className="float-right">
                      * Despesas de bônus de deslocamento e outros bônus
                      </small>
                  </th>
                </tr>
              </tfoot>
            </Table>
          ) : (
              <Loader />
            )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default withTranslation("common")(ProviderDebts);
