// @ts-nocheck
import React, { useContext } from "react";
import { SecondaryDashboardContext } from "../../../context/SecondaryDashboard";
import Panel from "../../../components/Panel";
import { Table, Col, Row } from "reactstrap";

const TripsPerProvider = () => {
  const {
    state: { tripsPerProvider: { list, isLoading } }
  } = useContext(SecondaryDashboardContext);

  return (
    <Col
      lg={8}
      xl={8}
      md={8}
    >
      <Row>
        {list.map((type) => (
          <Panel
            lg={6}
            xl={6}
            md={6}
            title={type._id}
            refresh={isLoading}
            containerStyle={{ maxHeight: 420, overflowY: 'auto', marginTop: 22 }}
          >
            <Table responsive className="table dashboard__occupancy-table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Total de corridas</th>
                </tr>
              </thead>
              <tbody>
                {type.providers.map((provider: any) => (
                  <tr key={provider._id}>
                    <td>{provider.first_name} {provider.last_name}</td>
                    <td style={{ textAlign: 'center' }}>{provider.totalTrips}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Panel>
        ))}
      </Row>
    </Col>
  );
}


export default TripsPerProvider;