// @ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import { FranchiseContext } from '../../../context/Franchise';
import { AuthContext } from '../../../context/Auth';
import { getLastWeekDay } from '../../../utils/dates';
import DateRange from '../../../components/DateRange';

import { Card, CardBody, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const Filter = () => {
	const [state, setState] = useState({
		startDate: getLastWeekDay(),
		endDate: new Date(),
		type: [],
	});

	const {
		state: { user },
	} = useContext(AuthContext);

	const {
		action: { getFranchiseBalance, getTransactions },
	} = useContext(FranchiseContext);

	useEffect(() => {
		if (!!user) {
			getFranchiseBalance(user.franchises[0]);
		}
	}, [user]);

	useEffect(() => {
		if (!!user && state.startDate && state.endDate) getTransactions(user.franchises[0], state);
	}, [user, state]);

	const handleDateChange = dates => setState(prev => ({ ...prev, startDate: dates[0], endDate: dates[1] }));

	return (
		<Card>
			<CardBody className="pb-0">
				<Col md={6}>
					<div style={{ color: '#999', fontStyle: 'italic' }}>
						<small>Filtrar período</small>
					</div>
					<DateRange value={[state.startDate, state.endDate]} onChange={handleDateChange} />
				</Col>
			</CardBody>
		</Card>
	);
};

// class Filters extends PureComponent {
// 	// constructor(props) {
// 	// 	super(props);
// 	// }

// 	render() {
// 		const { t, setFilters, filters } = this.props;

// 		return (
// 			<Col xl={8} lg={6} md={12} sm={12} xs={12}>
// 				<Card>
// 					<CardBody className="card-filters">
// 						<div style={{ color: '#999', fontStyle: 'italic' }}>
// 							<small>filtrar por período e tipo de movimentação}</small>
// 						</div>
// 						<div>
// 							<Form inline>
// 								<FormGroup
// 									style={{ marginRight: 10, border: 'solid 1px #AAA', borderRadius: 6, backgroundColor: '#EEE' }}
// 								>
// 									<Input
// 										type="date"
// 										name="start"
// 										value={filters.date.start}
// 										onChange={e => {
// 											filters.date.start = e.target.value;
// 											setFilters(filters);
// 										}}
// 										placeholder="dd/mm/aaaa"
// 										style={{ fontSize: 12, border: 'none', background: 'transparent', textAlign: 'center' }}
// 									/>
// 									<span style={{ paddingLeft: 3, paddingRight: 3 }}>-</span>
// 									<Input
// 										type="date"
// 										name="end"
// 										value={filters.date.end}
// 										onChange={e => {
// 											filters.date.end = e.target.value;
// 											setFilters(filters);
// 										}}
// 										placeholder="dd/mm/aaaa"
// 										style={{ fontSize: 12, border: 'none', background: 'transparent', textAlign: 'center' }}
// 									/>
// 								</FormGroup>
// 								<ButtonToolbar style={{ margin: 0 }}>
// 									<Button
// 										outline={!filters.type.i}
// 										size="sm"
// 										color={filters.type.i ? 'success' : 'secondary'}
// 										onClick={() => {
// 											filters.type.i = !filters.type.i;
// 											setFilters(filters);
// 										}}
// 									>
// 										{t('wallet.receipt')}
// 									</Button>
// 									<Button
// 										outline={!filters.type.o}
// 										size="sm"
// 										color={filters.type.o ? 'danger' : 'secondary'}
// 										onClick={() => {
// 											filters.type.o = !filters.type.o;
// 											setFilters(filters);
// 										}}
// 									>
// 										{t('wallet.debit')}
// 									</Button>
// 									<Button
// 										outline={!filters.type.t}
// 										size="sm"
// 										color={filters.type.t ? 'warning' : 'secondary'}
// 										onClick={() => {
// 											filters.type.t = !filters.type.t;
// 											setFilters(filters);
// 										}}
// 									>
// 										{t('wallet.transfer')}
// 									</Button>
// 								</ButtonToolbar>
// 							</Form>
// 						</div>
// 					</CardBody>
// 				</Card>
// 			</Col>
// 		);
// 	}
// }

export default withTranslation('common')(Filter);
