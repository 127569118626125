// @ts-nocheck
import React, { useState, useContext, useEffect } from "react";
import { ScheduleContext } from "../../context/Schedule";
import { formatNumber, formatPhone } from "../../utils/masks";
import { getScheduleStatus } from "../../utils/translateEnums";
import { getLastWeekDay } from "../../utils/dates";
import Pagination from "../../components/Pagination";
import DateRange from "../../components/DateRange";
import Select from "../../components/Select";
import styles from "./styles.module.css";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import { Link } from 'react-router-dom';
import moment from "moment";

const headers = [
  "Recebedor",
  "Data de criação",
  "Data marcada",
  "Entregador",
  "Status"
];

const Schedules = () => {

  const {
    state: { companyList, schedules, schedulesPagination, schedule },
    action: { getCompanyList, getSchedules, getScheduleDetail }
  } = useContext(ScheduleContext);

  const [state, setState] = useState({
    start_date: getLastWeekDay(),
    end_date: new Date(),
    company_id: '',
    pageNumber: 1,
  });

  const [toggle, setToggle] = useState(false);

  const handleDateChange = (dates: Date[]) =>
    setState(prev => ({ ...prev, start_date: dates[0], end_date: dates[1] }));

  const handleCompanyChange = (value: string) =>
    setState(prev => ({ ...prev, company_id: value }))

  const onChangePage = (pageNumber: number) =>
    setState(prev => ({ ...prev, pageNumber }))

  const handleToggle = () =>
    setToggle(prev => !prev);

  const getDetails = (scheduleId: string) => {
    getScheduleDetail(scheduleId)
    handleToggle()
  }

  const showWarning = (scheduled_date: string) => {
    let color = "light"
    if (moment() > moment(scheduled_date))
      color = "danger"
    if (moment() >= moment(scheduled_date).subtract(1, 'hour') && moment() <= moment(scheduled_date))
      color = "warning"
    return (
      <Badge color={color} className={styles.badge}>
        <span><ClockOutlineIcon className={styles.icon} /></span>
        {moment(scheduled_date).format("DD/MM/YYYY kk:mm")}
      </Badge>
    )
  }

  useEffect(() => {
    getCompanyList()
  }, [])

  useEffect(() => {
    const { start_date, end_date, company_id, pageNumber } = state;
    if (state.start_date && state.end_date) {
      const data: any = {
        start_date: moment(start_date).format('YYYYMMDD'),
        end_date: moment(end_date).format('YYYYMMDD')
      }
      if (company_id !== "")
        data["company_id"] = company_id
      getSchedules(pageNumber, data)
    }
  }, [state])

  return (
    <>
    <Container><h3 style={{marginBottom: 20, fontWeight: "normal", color: "#333"}}>ENTREGAS</h3></Container>
    <Container>
      <Row>
        <Col xl={3} lg={5} md={6}>
          <div style={{ color: '#999', fontStyle: 'italic' }}>
            <small>Filtrar período</small>
          </div>
          <DateRange value={[state.start_date, state.end_date]} onChange={handleDateChange} />
        </Col>
        <Col xl={3} lg={5} md={6}>
          <div style={{ color: '#999', fontStyle: 'italic' }}>
            <small>Filtrar empresa</small>
          </div>
          <Select
            name="frequency"
            value={state.company_id}
            options={companyList}
            onChange={handleCompanyChange}
          />
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Table responsive hover className="table--bordered mb-4">
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {schedules.map((schedule, index) => (
                <tr key={index} onClick={() => getDetails(schedule._id)}>
                  <td>{schedule.user_name}</td>
                  <td>{moment(schedule.created_at).format("DD/MM/YYYY kk:mm")}</td>
                  <td>
                    {
                      schedule.status === 0 || schedule.status === 1
                        ? showWarning(schedule.scheduled_date)
                        : moment(schedule.scheduled_date).format("DD/MM/YYYY kk:mm")
                    }
                  </td>
                  <td>
                    {schedule.status === 0 && "Aguardando entregador"}
                    {!!schedule.provider.first_name &&
                      `${schedule.provider.first_name} - ${schedule.provider.last_name}`
                    }</td>
                  <td>{getScheduleStatus(schedule.status)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            onChangePage={onChangePage}
            current={schedulesPagination.current_page}
            total={schedulesPagination.pages === 0 ? 1 : schedulesPagination.pages}
            initial={1}
            size={10}
          />
          <Modal isOpen={toggle} toggle={handleToggle} sz="lg" centered role="aaaaaaaaaa">
            <ModalHeader>Detalhes do agendamento</ModalHeader>
            <ModalBody style={{ textAlign: 'left' }}>
              {!!schedule &&
                <>
                  <Row>
                    <Col className="mb-2">
                      <strong>Agendado para</strong>
                      {
                        schedule.status === 0 || schedule.status === 1
                          ? showWarning(schedule.scheduled_date)
                          : <p>{moment(schedule.scheduled_date).format("DD/MM/YY kk:mm")}</p>
                      }
                    </Col>
                  </Row>
                  <br />
                  {schedule.destinations.map((d: any, index: number) => (
                    <Row key={index}>
                      <Col>
                        <h5 className="mb-1">Destino {index + 1}</h5>
                        <Col>
                          <p>{d.user_name} {formatPhone(d.user_phone)}</p>
                          <p>{d.address}</p>
                          <p>{d.description}</p>
                        </Col>
                      </Col>
                    </Row>
                  ))}
                  <br />
                  <Row>
                    <Col>
                      <strong>Distância</strong>
                      <p>{formatNumber(schedule.distance)} km</p>
                    </Col>
                    <Col>
                      <strong>Pagamento</strong>
                      <p>{schedule.currencysign} {formatNumber(schedule.amount)}</p>
                    </Col>
                    <Col>
                      <strong>Serviço</strong>
                      <p>{schedule.type_name}</p>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={12} className="mb-4">
                      <strong>Status</strong>
                      <p>{getScheduleStatus(schedule.status)}</p>
                    </Col>
                    <br />
                    {!!schedule.provider &&
                      <Col md={12}>
                        <h5 className="mb-1">{schedule.provider.first_name} {schedule.provider.last_name}</h5>
                        <Col>
                          <p>{formatPhone(schedule.provider.phone)}</p>
                          <p>{schedule.provider.current_vehicle.plate_no}</p>
                        </Col>
                      </Col>
                    }
                  </Row>
                </>
              }
            </ModalBody>
            {!!schedule && schedule.status === 2 &&
              <ModalFooter>
                <Link to={`/schedules/trip_details/${schedule.trip_id}`}>
                  <Button color="primary" className="mb-0" >Acompanhar corrida</Button>
                </Link>
              </ModalFooter>
            }
          </Modal>
        </CardBody>
      </Card>
    </Container>
    </>
  );
}

export default Schedules;