// @ts-nocheck
import React, { useContext } from "react";
import { FranchiseContext } from "../../../context/Franchise";
import { formatNumber } from "../../../utils/masks";
import { Card, CardBody, Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";

const Balance = () => {
  const {
    state: { balance }
  } = useContext(FranchiseContext);

  return (
    <Card>
      <CardBody>
        <Row>
          <Col style={{ textAlign: "right"}}>
            <h4 className="m-0" style={{ color: "#21ba45" }}>
							R$ {formatNumber(balance.current)}
						</h4>
            <p>saldo</p>
          </Col>
          <Col style={{ textAlign: "right"}}>
            <h4 className="m-0" style={{ color: "#767676" }}>
							R$ {formatNumber(balance.notPaid)}
						</h4>
            <p>não pago</p>
          </Col>
          <Col style={{ textAlign: "right"}}>
            <h4 className="m-0" style={{ color: "#767676" }}>
							R$ {formatNumber(balance.pending)}
						</h4>
            <p>á receber</p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

// class Balance extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       selectedBalance: "current"
//     };
//   }

//   selectBalance(balance) {
//     if (balance === "current") {
//       this.setState({ selectedBalance: balance });
//     } else {
//       this.setState({ selectedBalance: balance });
//     }
//   }

//   render() {
//     const {
//       franchiseFinancialBalance = {
//         current: 0,
//         pending: 0
//       },
//       setFilters
//     } = this.props;

//     return (
//       <Col xl={4} lg={6} md={12} sm={12} xs={12}>
//         <Card>
//           <CardBody className="card-balances no-padding">
//             <Row className="balances">
//               <Col
//                 xs={6}
//                 className={`current-balance ${
//                   this.state.selectedBalance === "current" ? "selected" : ""
//                 }`}
//                 onClick={() => {
//                   this.selectBalance("current");
//                   setFilters({
//                     date: {
//                       start: moment(new Date())
//                         .subtract(7, "d")
//                         .format("YYYY-MM-DD"),
//                       end: moment(new Date()).format("YYYY-MM-DD")
//                     },
//                     type: {
//                       o: true,
//                       i: true,
//                       t: true
//                     }
//                   });
//                 }}
//               >
//                 <div className="text-right">
//                   <span>saldo</span>
//                   <h3 className="balances-value">
//                     <NumberFormat
//                       value={franchiseFinancialBalance.current}
//                       displayType={"text"}
//                       // thousandSeparator={true}
//                       fixedDecimalScale={true}
//                       decimalScale={2}
//                       thousandSeparator={"."}
//                       decimalSeparator={","}
//                     />
//                   </h3>
//                 </div>
//               </Col>
//               <Col
//                 xs={6}
//                 className={`receive-balance ${
//                   this.state.selectedBalance === "pending" ? "selected" : ""
//                 }`}
//                 onClick={() => {
//                   this.selectBalance("pending");
//                   setFilters({
//                     date: {
//                       start: moment(new Date())
//                         .add(1, "d")
//                         .format("YYYY-MM-DD"),
//                       end: moment(new Date())
//                         .add(31, "d")
//                         .format("YYYY-MM-DD")
//                     },
//                     type: {
//                       o: true,
//                       i: true,
//                       t: true
//                     }
//                   });
//                 }}
//               >
//                 <div className="text-right float-right">
//                   <span>a receber</span>

//                   <h3 className="balances-value">
//                     <NumberFormat
//                       value={franchiseFinancialBalance.pending}
//                       displayType={"text"}
//                       fixedDecimalScale={true}
//                       decimalScale={2}
//                       thousandSeparator={"."}
//                       decimalSeparator={","}
//                     />
//                   </h3>
//                 </div>
//                 <Badge color="light" className={"float-left"}>
//                   <NumberFormat
//                     value={franchiseFinancialBalance.notPaid}
//                     displayType={"text"}
//                     fixedDecimalScale={true}
//                     decimalScale={2}
//                     thousandSeparator={"."}
//                     decimalSeparator={","}
//                   />{" "}
//                   não pago
//                 </Badge>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//       </Col>
//     );
//   }
// }

export default withTranslation("common")(Balance);
