// @ts-nocheck
import React, { useContext } from "react";
import { FilterContext } from "../context";
import { formatNumber } from "../../../utils/masks";
import ListItem from "../components/ListItem";

import { Table } from "reactstrap";
import moment from "moment";

const dayWeek = [
  "Domingo",
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sábado"
];

const headers = [
  "Período",
  "ID",
  "Documento",
  "Razão",
  "Fantazia",
  "Entregas",
  "Distância",
  "R$ Empresa",
  "R$ Entregador",
  "R$ Receita"
];

type Props = {
  companyResults: any;
}

const CompanyTable = ({ companyResults }: Props) => {
  const {
    state: { frequency, distance }
  } = useContext(FilterContext);

  return (
    <Table hover responsive className="table-condensed">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      {companyResults.map((result: any, index: number) => (
        <tbody key={index}>
          <tr
            style={{
              backgroundColor: "#EEE",
              fontWeight: "bold",
              borderBottom: "solid 2px #AAA"
            }}
          >
            {frequency === "d" ? (
              <td>
                {moment(result.date).format("DD/MM/YYYY")} -{" "}
                {dayWeek[moment(result.date).get("day")]}
              </td>
            ) : frequency === "m" ? (
              <td>{moment(result.date).format("MM/YYYY")}</td>
            ) : (
                  <td>{result.date}</td>
                )}
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              {result.companies.reduce(
                (prev: number, r: any) => prev + r.delivers.total,
                0
              )}
            </td>
            <td>
              {formatNumber(
                result.companies.reduce(
                  (prev: number, r: any) => prev + r.distance.total,
                  0
                )
              )}{" "}
              km
                      </td>
            <td>
              R${" "}{formatNumber(result.companies.reduce((prev: number, r: any) =>prev + r.for_company.total,0))}
            </td>
            <td>R${" "}{formatNumber(result.companies.reduce((prev: number, r: any) =>prev + r.for_provider.total + r.tax_return.total,0))}</td>
            <td>R${" "}{formatNumber( result.companies.reduce((prev: number, r: any) =>prev + r.for_company.total,0) - result.companies.reduce((prev: number, r: any) =>prev + r.for_provider.total + r.tax_return.total ,0))}</td>
          </tr>
          {result.companies.map((company: any, index: number) => (
            <ListItem key={index} result={result} frequency = {frequency} company={company} distance={distance} />
          ))}
        </tbody>
      ))}
    </Table>
  )
}

export default CompanyTable;