// @ts-nocheck
import React, { useContext } from "react";
import { DashboardMapContext } from "..";
import { GoogleApiWrapper, Map, Marker, InfoWindow } from 'google-maps-react';
import { STYLES } from './style';
import moment from 'moment';

import delivery from './icons/delivery.svg';

import provider from './icons/provider.svg';
import provider_active from './icons/provider-active.svg';
import provider_trip from './icons/provider-trip.svg';

import company from './icons/company.svg';
import company_active from './icons/company-active.svg';
import company_trip from './icons/company-trip.svg';

const MapContainer = ({ google }) => {
  const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let labelIndex = 0;

  const { state: { providers, companies, deliveries, viewCompanies, viewProviders, viewDeliveries, days_from_company_active } } = useContext(DashboardMapContext);

  const initMap = (mapProps, map) => {
    // const { Polyline, LatLng } = google.maps;
    // const path = selectedTrip.route.map((path: any[]) => new LatLng(path[0], path[1]))

    // const polyline = new Polyline({
    //   path,
    //   strokeColor: "#1a73e8",
    //   strokeOpacity: 0.6,
    //   strokeWeight: 6 
    // })
    // polyline.setMap(map)
  }

  return (
    <Map styles={STYLES}
      google={google}
      zoom={6.5}
      initialCenter={{
        lat: -6.239128,
        lng: -41.3075137
      }}
      onReady={initMap}
    >
      {deliveries.length > 0 && viewDeliveries ? deliveries.map((d, index) => (<Marker position={{ lat: d.sourceLocation[0], lng: d.sourceLocation[1] }} icon={delivery}/>)) : null }
      {companies.length > 0 && viewCompanies ? companies.map((c, index) => ( c.location ? <Marker title={c.company_name} position={{ lat: c.location[0], lng: c.location[1] }} icon={( moment().diff(c.last_trip_at, "d") > days_from_company_active ? company : company_active)}/> : null )) : null }
      {providers.length > 0 && viewProviders ? providers.map((p, index) => (<Marker title={p.first_name + ` ` + p.last_name} position={{ lat: p.providerLocation[0], lng: p.providerLocation[1] }} icon = {provider_active}/>)) : null }
    </Map>
  );
};

export default GoogleApiWrapper({
	apiKey: "AIzaSyA1EWxwJ6-Mtbv-Ihn2jW34ywyVLD3PuEY",
  libraries: ["geometry"]
})(MapContainer);
