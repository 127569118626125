// @ts-nocheck
import React, { useState, useCallback, useEffect } from "react";

export interface AdminState { }

export const useAdminForm = (admin?: any) => {

  const initialState = {
    username: "",
    email: "",
    password: "",
    type: "",
    permissions: []
  }

  const [state, setState] = useState(!!admin ? transformInitialState(admin) : initialState);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setState(prev => ({ ...prev, [name]: value }));
    }, [])

  const onValuesChage = useCallback((value: string | string[], name: string) => {
    setState(prev => ({ ...prev, [name]: value }))
  }, [])

  // useEffect(() => console.log(state), [state])

  return {
    state,
    handleOnChange,
    onValuesChage
  };
}

const transformInitialState = (admin: any) => {
  return {
    username: admin.username,
    email: admin.email,
    password: "",
    type: admin.type.toString(),
    permissions: admin.permissions
  }
};